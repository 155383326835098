import React from 'react'
import tw from 'twin.macro'
import styled from 'styled-components'
import failed from '../../assets/svg/actions/close-heavy.svg'
import passed from '../../assets/svg/symbols/check-heavy.svg'
import { CustomIcon } from '../shared/CustomIcon'

export enum ComplianceStatus {
  FAILED = 'FAILED',
  NonCompliant = 'NonCompliant',
  ACTIVE = 'ACTIVE',
  PASSED = 'PASSED',
  Compliant = 'Compliant',
  INACTIVE = 'INACTIVE'
}

export interface StateIndicatorProps {
  status: ComplianceStatus
  size: StatusIndicatorSize
  label?: React.ReactNode
}

export enum StatusIndicatorSize {
  'small',
  'default',
  'scaling'
}

const passedStatus = [ComplianceStatus.PASSED, ComplianceStatus.Compliant, ComplianceStatus.INACTIVE]

const failedStatus = [ComplianceStatus.FAILED, ComplianceStatus.NonCompliant, ComplianceStatus.ACTIVE]

export const ComplianceStatusIndicator = ({ status, size, label }: StateIndicatorProps) => {
  const iconSrc = passedStatus.includes(status) ? passed : failed

  return (
    <div className={'flex flex-row gap-2 items-center w-fit'}>
      <Circle size={size} state={status}>
        <CustomIcon
          path={iconSrc}
          styles={`${size === StatusIndicatorSize.small ? 'w-[60%] h-[60%]' : 'w-1/2 h-1/2'} min-w-2 min-h-2 bg-gray-50`}
        />
      </Circle>
      {label && (
        <span
          className={`${size === StatusIndicatorSize.small && 'text-80'} lowercase first-letter:capitalize text-gray-50`}
        >
          {label}
        </span>
      )}
    </div>
  )
}

interface CircleProps {
  size: StatusIndicatorSize
  state: ComplianceStatus
}

const Circle = styled.div<CircleProps>`
  ${tw`flex justify-center items-center rounded-full transition-all ease-in-out`}
  ${({ size }) =>
    size === StatusIndicatorSize.small
      ? tw`min-w-[14px] w-3.5 h-3.5`
      : size === StatusIndicatorSize.scaling
        ? tw`min-w-4 w-4 h-4 sm:min-w-5 sm:w-5 sm:h-5 md:min-w-6 md:w-6 md:h-6`
        : tw`min-w-6 h-6`}
  ${({ state }) => passedStatus.includes(state) && tw`bg-success-100`}
  ${({ state }) => failedStatus.includes(state) && tw`bg-error-100`}
`
