import alertIcon from '../../../assets/svg/symbols/alert-2.svg'
import { CustomIcon } from '../CustomIcon'
import React from 'react'

interface AlertProps {
  size?: 5 | 6
  animate?: boolean
}

export const Alert = ({ size = 6, animate }: AlertProps) => {
  return (
    <div className={`inline-flex items-center relative`}>
      {animate && (
        <CustomIcon
          path={alertIcon}
          styles={`absolute animate-ping w-${size - 1} ml-0.5 h-${size - 2} -mt-2 bg-primary-300/70`}
        />
      )}
      <CustomIcon path={alertIcon} styles={`w-${size} h-${size} bg-primary-500`} />
    </div>
  )
}
