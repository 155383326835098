import React from 'react'
import tw from 'twin.macro'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

export enum SeverityLevel {
  UNSPECIFIED = 'UNSPECIFIED',
  INFORMATIONAL = 'INFORMATIONAL',
  LOW = 'LOW',
  MEDIUM = 'MEDIUM',
  HIGH = 'HIGH',
  CRITICAL = 'CRITICAL'
}

interface SeverityLevelIndicatorProps {
  level: SeverityLevel
  showLabel?: boolean
  small?: boolean
}

export const SeverityLevelIndicator = ({ level, showLabel = true, small = false }: SeverityLevelIndicatorProps) => {
  const { t } = useTranslation()
  const items = new Array(5).fill(null)

  return (
    <IndicatorWrapper>
      <div className={`flex flex-row items-center ${small ? 'gap-1px' : 'gap-0.5'}`}>
        {items.map((item, i) => (
          <Bar key={i} index={i} level={level} smallLabel={small} />
        ))}
      </div>
      {showLabel && (
        <span className={`${small && 'text-80'} text-gray-50 capitalize`}>
          {t(`compliance.tabs.common.findingSeverity.${level}`)}
        </span>
      )}
    </IndicatorWrapper>
  )
}

const IndicatorWrapper = styled.div`
  ${tw`flex flex-row gap-2 py-1 items-center`}
`

interface BarProps {
  index: number
  level: SeverityLevel
  smallLabel?: boolean
}

const Bar = styled.div<BarProps>`
  ${tw`w-1 h-4 flex flex-row bg-gray-300`}
  ${({ smallLabel }) => smallLabel && tw`h-3`}
  ${({ level, index }) => level === SeverityLevel.INFORMATIONAL && index < 1 && tw`bg-success-100`}
  ${({ level, index }) => level === SeverityLevel.LOW && index < 2 && tw`bg-tertiary-500`}
  ${({ level, index }) => level === SeverityLevel.MEDIUM && index < 3 && tw`bg-tertiary-500`}
  ${({ level, index }) => level === SeverityLevel.HIGH && index < 4 && tw`bg-error-100`}
  ${({ level, index }) => level === SeverityLevel.CRITICAL && index < 5 && tw`bg-error-100`}
`
