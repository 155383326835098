import React, { useEffect, useRef, useState } from 'react'
import { DataContainer } from '../shared/containers/DataContainer'
import styled from 'styled-components'
import tw from 'twin.macro'
import { useTranslation } from 'react-i18next'
import {
  ConsumptionData,
  getSustainabilityChartData,
  SustainabilityChartData,
  SustainabilityChartEntry
} from '../../api/sustainability'
import { Loading } from '../shared/Loading'
import { formatMonthShortString, formatNumber, formatToYear, roundNumber } from '../../utils/formats'
import { CustomTooltip } from '../shared/CustomTooltip'
import { ToggleGroup } from '../shared/buttons/ToggleGroup'
import { SwitchButton } from '../shared/buttons/SwitchButton'
import { CountUp } from '../shared/CountUp'
import {
  ChartZoomToggles,
  CustomLineChart,
  LineChartContainer,
  LineChartHeader
} from '../shared/charts/CustomLineChart'
import { Vendor } from '../../utils/vendors'
import { useCancelToken } from '../../api/client'
import { useErrorHandling } from '../../hooks/handleError'
import { CapitalizedText } from '../shared/TextComponents'
import { Timescale } from '../../utils/classes'
import { DomainTuple, Tuple } from 'victory'

interface SustainabilityChartProps {
  selectedVendors: Vendor[]
  selectedProjects: string[]
  selectedResourceGroups: string[]
  selectedServices: string[]
  selectedRegions: string[]
}

export const SustainabilityChart = ({
  selectedVendors,
  selectedProjects,
  selectedResourceGroups,
  selectedServices,
  selectedRegions
}: SustainabilityChartProps) => {
  const initialMount = useRef(true)
  const { createCancelToken } = useCancelToken()
  const handleError = useErrorHandling()
  const [chartData, setChartData] = useState<SustainabilityChartData[]>([])
  const [loading, setLoading] = useState(false)
  const { t } = useTranslation()
  const [displayOption, setDisplayOption] = useState<ConsumptionData>(ConsumptionData.cloudEmissions)
  const [hideComboLine, setHideComboLine] = useState<boolean>(true)
  const minZoomDomain: DomainTuple = [0.5, 13.5]
  const [maxZoomDomain, setMaxZoomDomain] = useState<Tuple<number>>([11.5, 24.5])
  const [zoomDomain, setZoomDomain] = useState<Tuple<number>>(maxZoomDomain)

  useEffect(() => {
    const cancelToken = createCancelToken()
    if (initialMount.current) {
      setLoading(true)
    }
    getSustainabilityChartData(
      selectedVendors,
      selectedProjects,
      selectedResourceGroups,
      selectedServices,
      selectedRegions,
      cancelToken.token
    )
      .then(resp => {
        setChartData(resp)
        if (initialMount.current) {
          initialMount.current = false
          setZoomDomain([resp[0].chartEntries.length - 12.5, resp[0].chartEntries.length + 0.5])
          setMaxZoomDomain([resp[0].chartEntries.length - 12.5, resp[0].chartEntries.length + 0.5])
        }
      })
      .catch(handleError)
      .finally(() => setLoading(false))

    return () => {
      cancelToken.cancel()
      setLoading(false)
    }
  }, [
    selectedVendors,
    selectedProjects,
    selectedResourceGroups,
    selectedServices,
    selectedRegions,
    handleError,
    createCancelToken
  ])

  const displayOptions = [
    {
      clickHandler: () => setDisplayOption(ConsumptionData.kwhSpend),
      checked: displayOption === ConsumptionData.kwhSpend,
      value: t('common.units.kWh')
    },
    {
      clickHandler: () => setDisplayOption(ConsumptionData.cloudEmissions),
      checked: displayOption === ConsumptionData.cloudEmissions,
      value: t('common.units.kgCO2')
    }
  ]

  const showCloudEmissions = displayOption === ConsumptionData.cloudEmissions
  const yAxisData = (datum: any, displayData: ConsumptionData) => {
    return displayData === ConsumptionData.cloudEmissions ? datum.cloudEmissions : datum.kwhSpend
  }
  const chartEntries = chartData.flatMap(vendorEntry => vendorEntry.chartEntries)
  const maxAmount = Math.max(
    ...chartEntries.map(data => (showCloudEmissions ? data.cloudEmissions : data.kwhSpend) ?? 0)
  )
  const total = chartData
    .filter(item => item.vendor === Vendor.ALL)
    .flatMap(item => item.chartEntries)
    .reduce((total, accumulator) => {
      return showCloudEmissions ? total + (accumulator.cloudEmissions ?? 0) : total + (accumulator.kwhSpend ?? 0)
    }, 0)

  if (loading)
    return (
      <DataContainer>
        <Loading paddingY={'14rem'} />
      </DataContainer>
    )

  return (
    <DataContainer looseSpacing={true}>
      <LineChartContainer>
        <LineChartHeader
          heading={
            displayOption === ConsumptionData.cloudEmissions
              ? t('sustainability.chart.title.emissions')
              : t('sustainability.chart.title.electricity')
          }
          subheading={
            <TotalAmountWrapper>
              <CapitalizedText>
                {t('sustainability.chart.total')}{' '}
                <TotalAmount showCloudEmissions={showCloudEmissions}>
                  <CountUp id={'qa-consumption-chart-total'} countTo={roundNumber(total, total < 1 ? 2 : 0)} />
                </TotalAmount>{' '}
                {showCloudEmissions ? t('common.units.kgCO2') : t('common.units.kWh')}
              </CapitalizedText>
            </TotalAmountWrapper>
          }
          actions={
            <>
              <ToggleGroup minWidth={'60px'} maxWidth={'12rem'} buttons={displayOptions} lowercase={true} />
              <SwitchButton
                clickHandler={() => setHideComboLine(!hideComboLine)}
                checked={selectedVendors.length > 1 && !hideComboLine}
                label={t('sustainability.chart.showTotal')}
                disabled={selectedVendors.length <= 1}
              />
              <ChartZoomToggles
                domain={zoomDomain}
                setDomain={setZoomDomain}
                minDomain={minZoomDomain}
                maxDomain={maxZoomDomain}
              />
            </>
          }
        />
        <CustomLineChart
          chartData={chartData}
          domain={[0, maxAmount > 1 ? maxAmount : 1]}
          tooltipLabel={({ datum }) => datum.cloudEmissions}
          tooltip={<ComparisonTooltip showCloudEmissions={showCloudEmissions} />}
          selectedVendors={selectedVendors}
          showVendorLegend={true}
          timescale={Timescale.YEAR}
          showLeftAxis={true}
          leftYAxisDataKey={datum => yAxisData(datum, displayOption)}
          hideComboLine={hideComboLine}
          zoomProps={{
            minDomain: minZoomDomain,
            maxDomain: maxZoomDomain,
            domain: zoomDomain,
            setDomain: setZoomDomain
          }}
        />
      </LineChartContainer>
    </DataContainer>
  )
}

const TotalAmountWrapper = styled.div`
  ${tw`flex gap-2 first-letter:capitalize`}
`

interface TotalAmountProps {
  showCloudEmissions: boolean
}

const TotalAmount = styled.span<TotalAmountProps>`
  ${tw`font-semibold text-112 bg-clip-text text-transparent bg-gradient-to-b`}
  ${props => (props.showCloudEmissions ? tw`from-tertiary-100 to-tertiary-200` : tw`from-tertiary-400 to-tertiary-500`)}
`

interface ComparisonTooltipProps {
  active?: boolean
  datum?: SustainabilityChartEntry
  y?: number
  x?: number
  showCloudEmissions: boolean
}

const ComparisonTooltip = ({ active, datum, y, x, showCloudEmissions }: ComparisonTooltipProps) => {
  const { t } = useTranslation()
  if (!active || !datum || !x || !y || !datum.kwhSpend || !datum.cloudEmissions) return null
  const width = 170
  const height = showCloudEmissions ? 72 : 90
  const posX = x - width / 2
  const posY = y - height

  const tooltipText = showCloudEmissions
    ? `${t('sustainability.chart.emissions')} ${formatNumber(
        roundNumber(datum.cloudEmissions, datum.cloudEmissions < 1 ? 1 : 0)
      )} ${t('common.units.kgCO2')}`
    : `${t('sustainability.electricity')} ${formatNumber(roundNumber(datum.kwhSpend, datum.kwhSpend < 1 ? 1 : 0))} ${t(
        'common.units.kWh'
      )}`

  return (
    <g>
      <foreignObject x={posX} y={posY} height={height} width={width}>
        <CustomTooltip lessPadding={true} pointerDirection={'down'} showIcon={false}>
          <span className={'capitalize font-semibold'}>{`${formatMonthShortString(
            datum.date
          ).toLowerCase()} ${formatToYear(datum.date)}`}</span>
          <span className={'first-letter:capitalize'}>{tooltipText}</span>
        </CustomTooltip>
      </foreignObject>
    </g>
  )
}
