import React from 'react'
import { CustomIcon, IconType } from '../CustomIcon'
import helpIcon from '../../../assets/svg/symbols/help.svg'
import { ModalActions } from '../modal/Modal'
import { CapitalizedText } from '../TextComponents'
import { Trans } from 'react-i18next'
import styled from 'styled-components'
import tw from 'twin.macro'

interface HelpTooltipProps {
  clickHandler: () => void
}

export const HelpTooltip = ({ clickHandler }: HelpTooltipProps) => {
  return (
    <CustomIcon
      iconType={IconType.VECTOR}
      path={helpIcon}
      onClick={clickHandler}
      styles={'bg-gray-300 w-5 h-5 hover:bg-gray-100'}
    />
  )
}

interface HelpTooltipModalProps {
  contentRows: React.ReactNode[]
  actions: React.ReactNode | React.ReactNode[]
}

export const HelpTooltipModal = ({ contentRows, actions }: HelpTooltipModalProps) => {
  return (
    <>
      <div className={'flex flex-col gap-4 text-gray-200 text-90 text-center max-w-125'}>
        {contentRows.map((row, i) => (
          <CapitalizedText key={i}>{row}</CapitalizedText>
        ))}
      </div>
      <ModalActions>
        {Array.isArray(actions) ? actions.map((action, i) => <div key={i}>{action}</div>) : actions}
      </ModalActions>
    </>
  )
}

interface InfoBlockProps {
  text: string
  iconPath: string
}

export const HelpTooltipInfoBlock = ({ iconPath, text }: InfoBlockProps) => {
  return (
    <div className={'flex flex-col items-center'}>
      <CustomIcon path={iconPath} iconType={IconType.VECTOR} />
      <InfoText>
        <Trans>{text}</Trans>
      </InfoText>
    </div>
  )
}

const InfoText = styled(CapitalizedText)`
  strong {
    ${tw`font-semibold text-gray-50`}
  }
`
