import React, { useContext, useEffect, useState } from 'react'
import { initI18n } from './utils/i18n'
import { useCancelToken } from './api/client'
import { UserInfoContext } from './state/context/UserInfoContext'
import { useHistory } from 'react-router-dom'
import { GlobalMessage } from './global-message/GlobalMessage'
import { getAuthInfo } from './api/auth'
import { FullScreenLoading } from './components/shared/Loading'
import { getIntegrationStates, getUserSettings } from './api/settings/profile'
import Routes from './layout/routes/Routes'

initI18n('en')
const App = () => {
  const { createCancelToken } = useCancelToken()
  const { setAuthInfo, setUserSettings, setIntegrationStates } = useContext(UserInfoContext)
  const history = useHistory()
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const cancelToken = createCancelToken()
    setLoading(true)
    getAuthInfo(cancelToken.token)
      .then(setAuthInfo)
      .catch(console.error)
      .then(() => {
        setLoading(false)
        Promise.all([
          getUserSettings(cancelToken.token).then(setUserSettings),
          getIntegrationStates(cancelToken.token).then(setIntegrationStates)
        ]).catch(console.error)
      })
  }, [createCancelToken, history, setAuthInfo, setIntegrationStates, setUserSettings])

  if (loading) return <FullScreenLoading />

  return (
    <>
      <Routes />
      <GlobalMessage />
    </>
  )
}

export default App
