import { UserRole } from '../../api/auth'

export const AWS_ROLES = [UserRole.AWS_ADMIN, UserRole.AWS_USER]
export const AZURE_ROLES = [UserRole.AZURE_ADMIN, UserRole.AZURE_USER]
export const GCP_ROLES = [UserRole.GCP_ADMIN, UserRole.GCP_USER]

export const VISIBILITY_ROLES = [
  UserRole.COSTS_USER,
  UserRole.COMPLIANCE_USER,
  UserRole.INFRA_USER,
  UserRole.OPTIMIZATION_USER,
  UserRole.SUSTAINABILITY_USER,
  UserRole.TICKET_USER
]

export const ACCESS_RIGHT_ROLES = [
  UserRole.ADMIN,
  UserRole.AWS_ADMIN,
  UserRole.AZURE_ADMIN,
  UserRole.GCP_ADMIN,
  UserRole.AWS_USER,
  UserRole.AZURE_USER,
  UserRole.GCP_USER
]

export const ADMIN_ROLES = [UserRole.ADMIN, UserRole.AWS_ADMIN, UserRole.AZURE_ADMIN, UserRole.GCP_ADMIN]

export const VENDOR_USER_ROLES = [UserRole.AWS_USER, UserRole.AZURE_USER, UserRole.GCP_USER]
