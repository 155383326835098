import { CancelToken } from 'axios'
import { client } from '../../client'
import { FilterOption } from '../../../components/shared/filters/FilterSelect'

export const getAwsIntegrations = async (cancelToken: CancelToken): Promise<AwsMasterAccount[]> => {
  return await client.get<AwsMasterAccount[]>('admin/integrations/aws', { cancelToken }).then(resp =>
    resp.data.map(integration => ({
      ...integration,
      lastIntegrationAt: integration.lastIntegrationAt && new Date(integration.lastIntegrationAt),
      deletedAt: integration.deletedAt && new Date(integration.deletedAt)
    }))
  )
}

export const createAwsIntegration = async (
  integrationDetails: AwsIntegrationRequest,
  cancelToken: CancelToken
): Promise<AwsIntegrationData> => {
  return await client
    .post<AwsIntegrationData>('admin/integrations/aws', integrationDetails, { cancelToken })
    .then(resp => getDateFormattedResponse(resp.data))
}

export const getAwsIntegrationDetails = async (
  masterAccountId: string,
  cancelToken: CancelToken
): Promise<AwsIntegrationData> => {
  return await client
    .get<AwsIntegrationData>(`admin/integrations/aws/${masterAccountId}`, { cancelToken })
    .then(resp => getDateFormattedResponse(resp.data))
}

export const editAwsMasterAccount = async (masterAccount: AwsMasterAccount): Promise<AwsIntegrationData> => {
  return await client
    .put<AwsIntegrationData>(`admin/integrations/aws/${masterAccount.accountId}`, masterAccount)
    .then(resp => getDateFormattedResponse(resp.data))
}

export const softDeleteAwsIntegration = async (masterAccountId: string) => {
  return await client.delete(`admin/integrations/aws/${masterAccountId}`)
}

export const recoverAwsIntegration = async (masterAccountId: string) => {
  return await client.post(`admin/integrations/aws/${masterAccountId}/recover`)
}

export const addAwsSecurityAccount = async (
  masterAccountId: string,
  securityAccount: AwsSecurityAccount
): Promise<AwsIntegrationData> => {
  return await client
    .post<AwsIntegrationData>(
      `admin/integrations/aws/${masterAccountId}/security/${securityAccount.accountId}`,
      securityAccount
    )
    .then(resp => getDateFormattedResponse(resp.data))
}

export const softDeleteAwsSecurityAccount = async (
  masterAccountId: string,
  securityAccount: AwsSecurityAccount
): Promise<AwsIntegrationData> => {
  return await client
    .delete<AwsIntegrationData>(`admin/integrations/aws/${masterAccountId}/security/${securityAccount.accountId}`, {
      data: securityAccount
    })
    .then(resp => getDateFormattedResponse(resp.data))
}

export const recoverAwsSecurityAccount = async (
  masterAccountId: string,
  securityAccount: AwsSecurityAccount
): Promise<AwsIntegrationData> => {
  return await client
    .post<AwsIntegrationData>(
      `admin/integrations/aws/${masterAccountId}/security/${securityAccount.accountId}/recover`,
      securityAccount
    )
    .then(resp => getDateFormattedResponse(resp.data))
}

export const addAwsConfigAccount = async (masterAccountId: string, configAccount: AwsConfigAccount) => {
  return await client
    .post<AwsIntegrationData>(
      `admin/integrations/aws/${masterAccountId}/config/${configAccount.accountId}`,
      configAccount
    )
    .then(resp => getDateFormattedResponse(resp.data))
}

export const softDeleteAwsConfigAccount = async (
  masterAccountId: string,
  configAccount: AwsConfigAccount
): Promise<AwsIntegrationData> => {
  return await client
    .delete<AwsIntegrationData>(`admin/integrations/aws/${masterAccountId}/config/${configAccount.accountId}`, {
      data: configAccount
    })
    .then(resp => getDateFormattedResponse(resp.data))
}

export const recoverAwsConfigAccount = async (
  masterAccountId: string,
  configAccount: AwsConfigAccount
): Promise<AwsIntegrationData> => {
  return await client
    .post<AwsIntegrationData>(
      `admin/integrations/aws/${masterAccountId}/config/${configAccount.accountId}/recover`,
      configAccount
    )
    .then(resp => getDateFormattedResponse(resp.data))
}

export const getAwsRegionOptions = async (cancelToken: CancelToken): Promise<FilterOption[]> => {
  return await client.get('admin/integrations/aws/regions', { cancelToken }).then(resp => resp.data)
}

const getDateFormattedResponse = (data: AwsIntegrationData): AwsIntegrationData => ({
  ...data,
  master: {
    ...data.master,
    lastIntegrationAt: data.master.lastIntegrationAt && new Date(data.master.lastIntegrationAt),
    lastIntegrations: {
      organization: data.master.lastIntegrations?.organization && new Date(data.master.lastIntegrations.organization),
      costs: data.master.lastIntegrations?.costs && new Date(data.master.lastIntegrations.costs),
      optimization: data.master.lastIntegrations?.optimization && new Date(data.master.lastIntegrations.optimization),
      sustainability:
        data.master.lastIntegrations?.sustainability && new Date(data.master.lastIntegrations.sustainability)
    },
    deletedAt: data.master.deletedAt && new Date(data.master.deletedAt)
  },
  config: data.config.map(account => ({
    ...account,
    lastIntegrationAt: account.lastIntegrationAt && new Date(account.lastIntegrationAt),
    deletedAt: account.deletedAt && new Date(account.deletedAt)
  })),
  security: data.security.map(account => ({
    ...account,
    lastIntegrationAt: account.lastIntegrationAt && new Date(account.lastIntegrationAt),
    deletedAt: account.deletedAt && new Date(account.deletedAt)
  }))
})

export interface AwsIntegrationData {
  master: AwsMasterAccount
  config: AwsConfigAccount[]
  security: AwsSecurityAccount[]
}

export interface AwsMasterAccount {
  accountId: string
  name: string
  role: string
  lastIntegrationAt: Date | undefined
  lastIntegrations: MasterAccountIntegrations | undefined
  deletedAt: Date | undefined
}

interface MasterAccountIntegrations {
  organization: Date | undefined
  costs: Date | undefined
  optimization: Date | undefined
  sustainability: Date | undefined
}

export interface AwsConfigAccount {
  accountId: string
  region: FilterOption
  aggregatorName: string
  lastIntegrationAt: Date | undefined
  deletedAt: Date | undefined
}

export interface AwsSecurityAccount {
  accountId: string
  region: FilterOption
  lastIntegrationAt: Date | undefined
  deletedAt: Date | undefined
}

export interface AwsIntegrationRequest {
  master: AwsMasterAccount
  config: AwsConfigAccount
  security: AwsSecurityAccount
}
