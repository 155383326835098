import { Vendor } from '../../utils/vendors'
import { CancelToken } from 'axios'
import { client } from '../client'
import { CloudProject } from '../../utils/classes'
import { DisabledCostNotificationData, LimitsByCostAnomalyState } from '../notifications'
import { CurrencyId } from '../../utils/Currency'

export const getCostAnomalyProjectLimits = async (
  vendor: Vendor,
  cancelToken: CancelToken
): Promise<CostAnomalyProjectLimits[]> => {
  const resp = await client.get<CostAnomalyProjectLimits[]>(`settings/notifications/${vendor.toLowerCase()}/projects`, {
    cancelToken
  })
  return resp.data.map(limit => ({
    ...limit,
    costNotificationsDisabledAt: limit.costNotificationsDisabledAt ? new Date(limit.costNotificationsDisabledAt) : null
  }))
}

export const changeCostAnomalyProjectLimits = async (
  vendor: Vendor,
  projectLimits: CostAnomalyProjectLimits[],
  cancelToken: CancelToken
): Promise<CostAnomalyProjectLimits[]> => {
  return await client
    .post(`settings/notifications/${vendor.toLowerCase()}/projects`, { projectLimits }, { cancelToken })
    .then(resp => resp.data)
}

export const getCostAnomalyServiceLimits = async (
  vendor: Vendor,
  cancelToken: CancelToken
): Promise<CostAnomalyServiceLimits[]> => {
  const resp = await client.get(`settings/notifications/service-limits/${vendor}`, {
    cancelToken: cancelToken
  })
  return resp.data
}

export const changeCostAnomalyServiceLimits = async (
  vendor: Vendor,
  limits: CostAnomalyServiceLimits[],
  cancelToken: CancelToken
): Promise<CostAnomalyServiceLimits[]> => {
  return await client
    .post(`settings/notifications/service-limits/${vendor}`, { limits }, { cancelToken })
    .then(resp => resp.data)
}

export const restoreDefaultCostAnomalyProjectLimits = async (
  vendor: Vendor,
  project: string,
  cancelToken: CancelToken
): Promise<CostAnomalyProjectLimits[]> => {
  return await client
    .delete(`settings/notifications/${vendor.toLowerCase()}/projects/${project}`, { cancelToken })
    .then(resp => resp.data)
}

export const restoreDefaultCostAnomalyServiceLimits = async (
  vendor: Vendor,
  service: string,
  cancelToken: CancelToken
): Promise<CostAnomalyServiceLimits[]> => {
  return await client
    .delete(`settings/notifications/service-limits/${vendor}/${service}`, { cancelToken })
    .then(resp => resp.data)
}

export const restoreAllDefaultCostAnomalyProjectLimits = async (
  vendor: Vendor,
  cancelToken: CancelToken
): Promise<CostAnomalyProjectLimits[]> => {
  return await client
    .delete(`settings/notifications/${vendor.toLowerCase()}/projects`, { cancelToken })
    .then(resp => resp.data)
}

export const restoreAllDefaultCostAnomalyServiceLimits = async (
  vendor: Vendor,
  cancelToken: CancelToken
): Promise<CostAnomalyServiceLimits[]> => {
  return await client.delete(`settings/notifications/service-limits/${vendor}`, { cancelToken }).then(resp => resp.data)
}

export const subscribeProjectEmailNotifications = async (
  vendor: Vendor,
  projectId: String,
  cancelToken: CancelToken
): Promise<CostAnomalyProjectLimits[]> => {
  return await client
    .put(`settings/notifications/${vendor}/emails/enable/project/${projectId}`, {}, { cancelToken })
    .then(resp => resp.data)
}

export const unsubscribeProjectEmailNotifications = async (
  vendor: Vendor,
  projectId: String,
  cancelToken: CancelToken
): Promise<CostAnomalyProjectLimits[]> => {
  return await client
    .put(`settings/notifications/${vendor}/emails/disable/project/${projectId}`, {}, { cancelToken })
    .then(resp => resp.data)
}

export const subscribeServiceEmailNotifications = async (
  vendor: Vendor,
  service: string,
  cancelToken: CancelToken
): Promise<CostAnomalyServiceLimits[]> => {
  return await client
    .put(`settings/notifications/${vendor}/emails/enable/service/${service}`, {}, { cancelToken })
    .then(resp => resp.data)
}

export const unsubscribeServiceEmailNotifications = async (
  vendor: Vendor,
  service: string,
  cancelToken: CancelToken
): Promise<CostAnomalyServiceLimits[]> => {
  return await client
    .put(`settings/notifications/${vendor}/emails/disable/service/${service}`, {}, { cancelToken })
    .then(resp => resp.data)
}

export const enableCostNotifications = async (
  projects: DisabledCostNotificationData[],
  cancelToken: CancelToken
): Promise<CostAnomalyProjectLimits[]> => {
  return await client
    .put(`settings/notifications/${projects[0].vendor.toLowerCase()}/projects/enable`, { projects }, { cancelToken })
    .then(resp => resp.data)
}

export const disableCostNotifications = async (
  project: DisabledCostNotificationData,
  cancelToken: CancelToken
): Promise<CostAnomalyProjectLimits[]> => {
  return await client
    .put(`settings/notifications/${project.vendor.toLowerCase()}/projects/disable`, { ...project }, { cancelToken })
    .then(resp => resp.data)
}

export interface CostAnomalyProjectLimits {
  vendor: Vendor
  project: CloudProject
  dailyChangePercent: LimitsByCostAnomalyState
  estMonthlyImpact: LimitsByCostAnomalyState
  currency: CurrencyId
  isDefault: boolean
  isSubscribedToEmail: boolean
  costNotificationsDisabledAt: Date | null
}

export interface CostAnomalyServiceLimits {
  vendor: Vendor
  service: string
  dailyChangePercent: LimitsByCostAnomalyState
  estMonthlyImpact: LimitsByCostAnomalyState
  currency: CurrencyId
  isDefault: boolean
  isSubscribedToEmail: boolean
}
