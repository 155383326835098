import { UserRole } from '../auth'
import { client } from '../client'
import { CancelToken } from 'axios'
import { Vendor } from '../../utils/vendors'

export const getOrganizationUsers = async (cancelToken: CancelToken): Promise<OrganizationUser[]> => {
  const resp = await client.get<OrganizationUser[]>('admin/users', {
    cancelToken
  })
  return resp.data.map(user => ({
    ...user,
    updatedAt: new Date(user.updatedAt),
    lastLoginAt: user.lastLoginAt && new Date(user.lastLoginAt)
  }))
}

export const getUserDetails = async (userId: string, cancelToken: CancelToken): Promise<OrganizationUser> => {
  const resp = await client.get<OrganizationUser>(`admin/users/${userId}`, {
    cancelToken
  })
  return {
    ...resp.data,
    updatedAt: new Date(resp.data.updatedAt),
    lastLoginAt: resp.data.lastLoginAt && new Date(resp.data.lastLoginAt)
  }
}

export const updateUserRoles = async (
  roleType: RoleType,
  userId: string,
  roles: UserRole[],
  cancelToken: CancelToken
): Promise<OrganizationUser> => {
  const resp = await client.post<OrganizationUser>(
    `admin/users/${userId}/roles/${roleType.toLowerCase()}`,
    { roles: roles },
    {
      cancelToken: cancelToken
    }
  )
  return {
    ...resp.data,
    updatedAt: new Date(resp.data.updatedAt),
    lastLoginAt: resp.data.lastLoginAt && new Date(resp.data.lastLoginAt)
  }
}

export const updateUserVisibility = async (
  userId: string,
  role: UserRole,
  cancelToken: CancelToken
): Promise<OrganizationUser> => {
  const resp = await client.post<OrganizationUser>(
    `admin/users/${userId}/visibility`,
    { role: role },
    {
      cancelToken: cancelToken
    }
  )
  return {
    ...resp.data,
    updatedAt: new Date(resp.data.updatedAt),
    lastLoginAt: resp.data.lastLoginAt && new Date(resp.data.lastLoginAt)
  }
}

export const updateProjectPermission = async (
  vendor: Vendor,
  userId: string,
  projects: PermittedVendorProject[],
  cancelToken: CancelToken
): Promise<OrganizationUser> => {
  const resp = await client.post<OrganizationUser>(
    `admin/users/${userId}/project-access/${vendor.toLowerCase()}`,
    {
      projects:
        projects.length > 0 && vendor === Vendor.AWS
          ? projects.map(project => ({
              masterAccountId: project.vendorTenantId,
              id: project.value,
              name: project.label
            }))
          : projects.length > 0 && vendor === Vendor.AZURE
          ? projects.map(project => ({
              azureTenantId: project.vendorTenantId,
              id: project.value,
              name: project.label
            }))
          : projects.map(project => ({
              organizationId: project.vendorTenantId,
              id: project.value,
              name: project.label
            }))
    },
    {
      cancelToken: cancelToken
    }
  )
  return {
    ...resp.data,
    updatedAt: new Date(resp.data.updatedAt),
    lastLoginAt: resp.data.lastLoginAt && new Date(resp.data.lastLoginAt)
  }
}

export const adminCreateUser = async (firstName: string, lastName: string, emailAddress: string): Promise<string> => {
  const resp = await client.post<string>(`admin/users`, {
    firstName,
    lastName,
    emailAddress
  })
  return resp.data
}

export const adminDeleteUser = async (userId: string): Promise<OrganizationUser[]> => {
  const resp = await client.delete<OrganizationUser[]>(`admin/users/${userId}`)
  return resp.data.map(user => ({
    ...user,
    updatedAt: new Date(user.updatedAt),
    lastLoginAt: user.lastLoginAt && new Date(user.lastLoginAt)
  }))
}
export const getAwsAccountOptions = async (cancelToken: CancelToken): Promise<PermittedAwsAccount[]> => {
  const resp = await client.get('admin/project-options/aws', { cancelToken })
  return resp.data
}

export const getAzureSubOptions = async (cancelToken: CancelToken): Promise<PermittedAzureSub[]> => {
  const resp = await client.get<PermittedAzureSub[]>('admin/project-options/azure', { cancelToken })
  return resp.data
}

export const getGcpProjectOptions = async (cancelToken: CancelToken): Promise<PermittedGcpProject[]> => {
  const resp = await client.get<PermittedGcpProject[]>('admin/project-options/gcp', { cancelToken })
  return resp.data
}

export type RoleType = Vendor | 'admin'

export interface OrganizationUser {
  id: string
  firstName: string
  lastName: string
  emailAddress: string
  tenantId: string
  roles: UserRole[]
  awsAccess: boolean
  azureAccess: boolean
  gcpAccess: boolean
  costsAccess: boolean
  optimizationAccess: boolean
  complianceAccess: boolean
  infraAccess: boolean
  sustainabilityAccess: boolean
  ticketsAccess: boolean
  permittedAwsAccounts: PermittedAwsAccount[]
  permittedAzureSubs: PermittedAzureSub[]
  permittedGcpProjects: PermittedGcpProject[]
  lastLoginAt: Date | undefined
  updatedAt: Date
}

export interface PermittedAwsAccount {
  masterAccountId: string
  id: string
  name: string
}

export interface PermittedAzureSub {
  azureTenantId: string
  id: string
  name: string
}

export interface PermittedGcpProject {
  organizationId: string
  id: string
  name: string
}

export interface PermittedVendorProject {
  vendorTenantId: string
  value: string
  label: string
}
