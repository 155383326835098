import React, { createContext, useMemo, useState } from 'react'
import { SpotterModal } from '../../components/shared/modal/Modal'

export interface ModalState {
  modal: SpotterModal | null
  setModal: (modal: SpotterModal | null) => void
}

export const defaultModalState: ModalState = {
  modal: null,
  setModal: () => undefined
}

export const ModalContext = createContext<ModalState>(defaultModalState)

interface Props {
  children: JSX.Element
}

export const ModalContextProvider = React.memo(({ children }: Props) => {
  const [modal, setModal] = useState<SpotterModal | null>(defaultModalState.modal)

  const value = useMemo(
    () => ({
      modal,
      setModal
    }),
    [modal, setModal]
  )

  return <ModalContext.Provider value={value}>{children}</ModalContext.Provider>
})
