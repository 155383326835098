import React, { useEffect, useState } from 'react'
import { getComplianceSummary } from '../../api/home'
import styled from 'styled-components'
import { Trans, useTranslation } from 'react-i18next'
import { useCancelToken } from '../../api/client'
import { useErrorHandling } from '../../hooks/handleError'
import { HomeNoDataSummaryContainer, HomeSummaryContainer, SummaryAmount, SummaryText } from './HomeSharedComponents'
import { CountUp } from '../shared/CountUp'
import tw from 'twin.macro'
import { BarChartsDisplay, ScoreBarChart } from '../shared/charts/ScoreBarChart'

export const ComplianceSummary = () => {
  const handleError = useErrorHandling()
  const { createCancelToken } = useCancelToken()
  const { t } = useTranslation()
  const [score, setScore] = useState<number | null>(null)
  const [loading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    const cancelToken = createCancelToken()
    setLoading(true)
    getComplianceSummary(cancelToken.token)
      .then(setScore)
      .catch(handleError)
      .finally(() => setLoading(false))

    return () => {
      cancelToken.cancel()
      setLoading(false)
      setScore(null)
    }
  }, [createCancelToken, handleError])

  if (loading || score === null)
    return <HomeNoDataSummaryContainer title={t('home.complianceSummary.title')} loading={loading} />

  return (
    <HomeSummaryContainer title={t('home.complianceSummary.title')}>
      <SummaryAmount color={'gradient-green'} textSize={'larger'}>
        <CountUp id={'qa-home-compliance-score'} countTo={score} />
        {'%'}
      </SummaryAmount>
      <InnerWrapper>
        <SummaryText largerText={true}>
          <Trans>{t('home.complianceSummary.description')}</Trans>
        </SummaryText>
        <ScoreBarChart height={'h-3 lg:h-4'} displayStyle={BarChartsDisplay.score} percent={score} />
      </InnerWrapper>
    </HomeSummaryContainer>
  )
}

const InnerWrapper = styled.div`
  ${tw`flex flex-col gap-4 w-full max-w-75`}
`
