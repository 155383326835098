import { Button, ButtonSize, ButtonStyle, ButtonType } from './Button'
import { CustomIcon, IconType } from '../CustomIcon'
import exportCsv from '../../../assets/svg/actions/export-csv.svg'
import { useTranslation } from 'react-i18next'

interface ExportButtonProps {
  clickHandler: () => void
  disabled?: boolean
  type?: ButtonType
}

export const ExportButton = ({ clickHandler, disabled, type = ButtonType.ICON }: ExportButtonProps) => {
  const { t } = useTranslation()
  const text = t('common.export')
  if (type === ButtonType.ICON)
    return (
      <Button
        buttonStyle={ButtonStyle.PRIMARY}
        value={
          <CustomIcon
            styles={`w-6 h-6 ${disabled ? 'bg-gray-300' : 'bg-gray-50'}`}
            path={exportCsv}
            alt={text}
            iconType={IconType.VECTOR}
          />
        }
        size={ButtonSize.MEDIUM}
        clickHandler={clickHandler}
        disabled={disabled}
        type={ButtonType.ICON}
      />
    )
  return (
    <Button
      buttonStyle={ButtonStyle.SECONDARY}
      value={text}
      size={ButtonSize.SMALL}
      clickHandler={clickHandler}
      disabled={disabled}
    />
  )
}
