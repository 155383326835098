import React, { useContext, useEffect, useRef, useState } from 'react'
import { ViewWrapper } from '../shared/layout/ViewWrapper'
import { FilterOption } from '../shared/filters/FilterSelect'
import { ComplianceFilters } from './ComplianceFilters'
import { ComplianceTotalSummary } from './total-summary/ComplianceTotalSummary'
import { ComplianceTabs } from './tabs/ComplianceTabs'
import { Vendor } from '../../utils/vendors'
import { UserInfoContext } from '../../state/context/UserInfoContext'
import { MessageContext, MessageType } from '../../state/context/MessageContext'
import { useTranslation } from 'react-i18next'
import { capitalizeFirstLetter } from '../../utils/formats'
import { ADMIN_ROLES } from '../admin/roleConstants'
import { ToastActionComponent } from '../../global-message/GlobalMessage'
import { useHistory } from 'react-router-dom'

export const Compliance = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const { authInfo, integrationStates, userSettings } = useContext(UserInfoContext)
  const { setMessage } = useContext(MessageContext)
  const [selectedVendors, setSelectedVendors] = useState<FilterOption[]>([])
  const [selectedProjects, setSelectedProjects] = useState<FilterOption[]>([])
  const [selectedResourceGroups, setSelectedResourceGroups] = useState<FilterOption[]>([])
  const [selectedStandards, setSelectedStandards] = useState<FilterOption[]>([])
  const refVisibleVendors = useRef(userSettings.visibleVendors)

  if (
    (!selectedVendors.length && userSettings.visibleVendors.length > 0) ||
    refVisibleVendors.current !== userSettings.visibleVendors
  ) {
    setSelectedVendors(
      userSettings.visibleVendors.map(v => ({
        label: t(`vendors.${v}.short`),
        value: v
      }))
    )
    refVisibleVendors.current = userSettings.visibleVendors
  }

  useEffect(() => {
    integrationStates?.aws?.find(i => i.lastComplianceIntegrationAt === null) &&
      setMessage({
        type: MessageType.INFO,
        heading: t('integrationStates.vendorErrorHeading', { vendor: Vendor.AWS, label: t('sideNav.compliance') }),
        message: t('integrationStates.awsSecurityAccountError'),
        action: authInfo.roles.some(role => ADMIN_ROLES.includes(role)) ? (
          <ToastActionComponent
            label={t('admin.integrations.viewError')}
            onClick={() => history.push('/admin/integrations/aws')}
          />
        ) : undefined
      })
    integrationStates?.azure?.find(i => i.lastComplianceIntegrationAt === null) &&
      setMessage({
        type: MessageType.INFO,
        heading: t('integrationStates.vendorErrorHeading', {
          vendor: capitalizeFirstLetter(Vendor.AZURE.toLowerCase()),
          label: t('sideNav.compliance')
        }),
        message: t('integrationStates.azureTenantError'),
        action: authInfo.roles.some(role => ADMIN_ROLES.includes(role)) ? (
          <ToastActionComponent
            label={t('admin.integrations.viewError')}
            onClick={() => history.push('/admin/integrations/azure')}
          />
        ) : undefined
      })
    integrationStates?.gcp?.find(i => i.lastComplianceIntegrationAt === null) &&
      setMessage({
        type: MessageType.INFO,
        heading: t('integrationStates.vendorErrorHeading', { vendor: Vendor.GCP, label: t('sideNav.compliance') }),
        message: t('integrationStates.gcpOrganizationError'),
        action: authInfo.roles.some(role => ADMIN_ROLES.includes(role)) ? (
          <ToastActionComponent
            label={t('admin.integrations.viewError')}
            onClick={() => history.push('/admin/integrations/gcp')}
          />
        ) : undefined
      })
  }, [integrationStates])

  return (
    <ViewWrapper>
      <ComplianceFilters
        selectedVendors={selectedVendors}
        setSelectedVendors={setSelectedVendors}
        selectedProjects={selectedProjects}
        setSelectedProjects={setSelectedProjects}
        selectedResourceGroups={selectedResourceGroups}
        setSelectedResourceGroups={setSelectedResourceGroups}
        selectedStandards={selectedStandards}
        setSelectedStandards={setSelectedStandards}
      />
      <ComplianceTotalSummary
        selectedVendors={selectedVendors.map(v => v.value as Vendor)}
        selectedProjects={selectedProjects.map(p => p.value)}
        selectedResourceGroups={selectedResourceGroups.map(r => r.value)}
        selectedStandards={selectedStandards.map(s => s.value)}
      />
      <ComplianceTabs
        selectedVendors={selectedVendors.map(v => v.value as Vendor)}
        selectedProjects={selectedProjects.map(p => p.value)}
        selectedResourceGroups={selectedResourceGroups.map(r => r.value)}
        selectedStandards={selectedStandards.map(s => s.value)}
      />
    </ViewWrapper>
  )
}
