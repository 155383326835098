import React from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'
import { Sidenav } from './navigation/Sidenav'
import { ScrollToTop } from '../components/shared/ScrollToTop'
import { Modal } from '../components/shared/modal/Modal'
import { TopNav } from './navigation/Topnav'

interface Props {
  children: JSX.Element
}

export const MainLayout = ({ children }: Props) => {
  return (
    <>
      <LayoutContainer>
        <TopNav />
        <ContentWrapper>
          <Sidenav />
          <Content className={'no-scrollbar overflow-y-auto'}>
            {children}
            <ScrollToTop />
          </Content>
        </ContentWrapper>
      </LayoutContainer>
      <Modal />
    </>
  )
}

interface LayoutContainerProps {
  loading?: boolean
}

export const LayoutContainer = styled.div<LayoutContainerProps>`
  ${tw`bg-gradient-to-tl from-gray-800 to-gray-700 text-gray-50 min-h-[100%]`}
  & > div:first-child {
    ${tw`max-w-[1980px] mx-auto`}
  }
`

const ContentWrapper = styled.div`
  ${tw`flex max-w-[1980px] mx-auto`}
  & > div:first-child:not(:last-child) {
    ${tw`hidden h-full sticky top-14 z-[999] xl:block xl:w-50`}
  }
`

const Content = styled.div`
  ${tw`flex-1 p-2 items-start transition transition-all ease-in-out w-full`}
`
