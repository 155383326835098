import { useTranslation } from 'react-i18next'
import { HomeSummaryContainer } from './HomeSharedComponents'
import { UserInfoContext } from '../../state/context/UserInfoContext'
import { useContext } from 'react'
import { formatDate, getReadableTimeDifference } from '../../utils/formats'
import styled from 'styled-components'
import tw from 'twin.macro'
import { CapitalizedText, GrayText } from '../shared/TextComponents'

export const IntegrationSummary = () => {
  const { t } = useTranslation()
  const { authInfo } = useContext(UserInfoContext)

  return (
    <HomeSummaryContainer title={t('home.integrationSummary.title')} isClickable={false}>
      <GridContainer>
        {authInfo.awsAccess && (
          <IntegrationItem
            heading={t('vendors.AWS.short')}
            date={authInfo.awsIntegration}
            headingClass={'text-brand-aws'}
          />
        )}
        {authInfo.azureAccess && (
          <IntegrationItem
            heading={t('vendors.AZURE.short')}
            date={authInfo.azureIntegration}
            headingClass={'text-brand-azure'}
          />
        )}
        {authInfo.gcpAccess && (
          <IntegrationItem
            heading={t('vendors.GCP.short')}
            date={authInfo.gcpIntegration}
            headingClass={'text-brand-gcp'}
          />
        )}
        {authInfo.ticketAccess && (
          <IntegrationItem
            heading={t('home.integrationSummary.freshservice')}
            date={authInfo.freshIntegration}
            headingClass={'text-gray-200'}
          />
        )}
        {authInfo.infraAccess && (
          <IntegrationItem
            heading={t('home.integrationSummary.site247')}
            date={authInfo.site247Integration}
            headingClass={'text-gray-200'}
          />
        )}
      </GridContainer>
    </HomeSummaryContainer>
  )
}

const GridContainer = styled.div`
  ${tw`grid gap-x-4`}
  grid-template-columns: 1.5fr 2fr 2fr;
`

interface IntegrationItemProps {
  heading: string
  date: Date | undefined | null
  headingClass?: string
}

const IntegrationItem = ({ heading, date, headingClass }: IntegrationItemProps) => {
  const { t } = useTranslation()

  if (!date) {
    return null
  }

  return (
    <>
      <CapitalizedText className={`${headingClass} font-semibold`}>{heading}</CapitalizedText>
      <div className={'min-w-max'}>{getReadableTimeDifference(date, t)}</div>
      <GrayText className={'text-right w-full min-w-max'}>{formatDate(date)}</GrayText>
    </>
  )
}
