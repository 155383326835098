import React from 'react'
import { Vendor } from '../../../utils/vendors'
import { OptimizationAwsTab } from './aws/OptimizationAwsTab'
import { OptimizationAzureTab } from './azure/OptimizationAzureTab'
import { OptimizationGcpTab } from './gcp/OptimizationGcpTab'
import { MissingDataNotification } from '../../shared/MissingDataNotification'
import { AwsRecommendations } from '../../../api/optimization/aws'
import { AzureRecommendations } from '../../../api/optimization/azure'
import { GcpRecommendations } from '../../../api/optimization/gcp'

interface VendorTabProps {
  vendor: Vendor
  selectedVendors: Vendor[]
  awsRecommendations: AwsRecommendations | null
  azureRecommendations: AzureRecommendations | null
  gcpRecommendations: GcpRecommendations | null
}

export const OptimizationVendorTab = ({
  vendor,
  selectedVendors,
  awsRecommendations,
  azureRecommendations,
  gcpRecommendations
}: VendorTabProps) => {
  return (
    <>
      {vendor === Vendor.AWS ? (
        <OptimizationAwsTab selectedVendors={selectedVendors} data={awsRecommendations} />
      ) : vendor === Vendor.AZURE ? (
        <OptimizationAzureTab selectedVendors={selectedVendors} data={azureRecommendations} />
      ) : vendor === Vendor.GCP ? (
        <OptimizationGcpTab selectedVendors={selectedVendors} recommendations={gcpRecommendations} />
      ) : (
        <MissingDataNotification justify={'center'} />
      )}
    </>
  )
}
