import { error100, gray50, gray600, primary400, primary500, success100, warn100 } from '../../../design/constants'
import { VictoryPie } from 'victory'
import React from 'react'
import styled from 'styled-components'

interface DonutScoreChartProps {
  maxWidth: number
  score: number
}

export const DonutScoreChart = ({ maxWidth, score }: DonutScoreChartProps) => {
  const innerRadius = 116
  const data = [
    { x: 'score', y: score },
    { x: 'empty', y: 100 - score }
  ]
  const scoreColor =
    score < 50 ? error100 : score < 70 ? warn100 : score < 80 ? success100 : score < 95 ? primary400 : primary500

  return (
    <ChartWrapper maxWidth={maxWidth}>
      <VictoryPie
        innerRadius={innerRadius}
        labels={({ datum }) => (datum.x === 'score' ? `${Math.round(datum.y)} %` : null)}
        labelPosition={'startAngle'}
        labelRadius={-32}
        cornerRadius={({ datum }) => (datum.x === 'score' ? 25 : 0)}
        animate={{
          duration: 500,
          easing: 'linear',
          animationWhitelist: ['data']
        }}
        style={{
          labels: { fill: gray50, fontSize: 65, fontWeight: 'bold', textShadow: '0 2px 2px 2px rgba(0, 0, 0, 0.15)' },
          data: {
            fill: ({ datum }) => (datum.x === 'score' ? scoreColor : gray600),
            opacity: ({ datum }) => (datum.x === 'score' ? 1 : 0.7)
          }
        }}
        data={data}
      />
    </ChartWrapper>
  )
}

interface ChartWrapperProps {
  maxWidth: number
}

const ChartWrapper = styled.div<ChartWrapperProps>`
  max-width: ${({ maxWidth }) => maxWidth}px;

  .VictoryContainer {
    max-width: ${({ maxWidth }) => maxWidth}px;
  }
`
