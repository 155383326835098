import styled from 'styled-components'
import tw from 'twin.macro'
import { Vendor } from '../../../utils/vendors'
import React, { useRef, useState } from 'react'
import { CustomIcon, IconType } from '../CustomIcon'
import infoIcon from '../../../assets/svg/symbols/info.svg'
import { useMousePosition } from '../../../hooks/useMousePosition'
import { useOnClickOutside } from '../../../hooks/useOnClickOutside'

export interface InfoTooltipProps {
  vendor?: Vendor
  sticky?: boolean
  styles?: string
  useIcon?: boolean
  x?: number
  y?: number
  mousePosition?: boolean
  children: React.ReactNode
}

export const InfoTooltip = ({
  vendor,
  sticky,
  styles,
  useIcon = false,
  x,
  y,
  mousePosition,
  children
}: InfoTooltipProps) => {
  const [hovered, setHovered] = useState(false)
  const mousePos = useMousePosition()
  const ref = useRef(null)
  const handleHoverStateOnClick = () => {
    setHovered(true)
    setTimeout(() => setHovered(false), 4000)
  }

  useOnClickOutside(ref, () => {
    setHovered(false)
  })

  if (useIcon)
    return (
      <div ref={ref} className={`inline-flex align-text-top mx-1 ${sticky && 'relative'}`}>
        {useIcon && (
          <CustomIcon
            iconType={IconType.VECTOR}
            path={infoIcon}
            onClick={handleHoverStateOnClick}
            onHover={setHovered}
            styles={'bg-gray-200 w-4 h-4 hover:bg-gray-100'}
          />
        )}
        {hovered && (
          <TooltipWrapper
            vendor={vendor}
            x={mousePosition ? mousePos.x : x}
            y={mousePosition ? mousePos.y : y}
            sticky={sticky}
            className={`backdrop-blur ${styles}`}
          >
            <div className={'w-full'}>{children}</div>
          </TooltipWrapper>
        )}
      </div>
    )

  return (
    <TooltipWrapper vendor={vendor} x={x} y={y} sticky={sticky} className={`backdrop-blur ${styles}`}>
      <div className={'w-full'}>{children}</div>
    </TooltipWrapper>
  )
}

interface TooltipWrapperProps {
  vendor?: Vendor
  x?: number
  y?: number
  sticky?: boolean
}

const TooltipWrapper = styled.div<TooltipWrapperProps>`
  ${tw`flex z-20 mr-4 px-2 py-0.5 text-90 items-center break-normal text-center bg-gray-600/60 shadow-lg border border-gray-500 rounded text-gray-50`} {
    left: ${({ x }) => x && x + 14}px;
    top: ${({ y }) => y && y + 6}px;
    position: ${({ sticky }) => (sticky ? 'absolute' : 'fixed')};
  }

  ${({ vendor }) => vendor && (vendor === Vendor.AZURE ? tw`capitalize` : tw`uppercase`)}
  div {
    ${tw`first-letter:capitalize`}
  }
`
