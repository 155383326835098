import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import tw from 'twin.macro'
import { FullScreenLogoContainer, Logo } from '../shared/layout/Logo'
import { FullScreenContainer, FullScreenFooter } from '../shared/layout/FullScreenPage'
import { CapitalizedText } from '../shared/TextComponents'

export const MarketplaceSignupInfo = () => {
  const { t } = useTranslation()

  return (
    <FullScreenContainer>
      <Content>
        <FullScreenLogoContainer>
          <Logo animated={true} />
        </FullScreenLogoContainer>
        <CapitalizedText className={'text-center'}>{t('login.signUp.marketplaceInfo')}</CapitalizedText>
      </Content>
      <FullScreenFooter>{t('footer.message')}</FullScreenFooter>
    </FullScreenContainer>
  )
}

const Content = styled.div`
  ${tw`h-full flex flex-col justify-center items-center w-max z-10 text-gray-100`}
`
