// colors
export const primary400 = '#FF7AA3'
export const primary500 = '#FF578C'
export const primary600 = '#D54B7B'
export const primary700 = '#AB4169'
export const primary800 = '#823654'

export const tertiary100 = '#74C365'
export const tertiary200 = '#44AB31'
export const tertiary300 = '#2F8320'
export const tertiary400 = '#FFD594'
export const tertiary500 = '#FFAA2A'

export const gray50 = '#EDEDED'
export const gray100 = '#D3D3E2'
export const gray200 = '#A8A7BB'
export const gray300 = '#858599'
export const gray400 = '#575775'
export const gray500 = '#2C2B4F'
export const gray600 = '#15142F'
export const gray700 = '#0E0F2A'
export const gray800 = '#0B0C29'

export const success100 = '#53A653'
export const success500 = '#408140'
export const warn100 = '#FFA500'
export const warn500 = '#F38800'
export const error100 = '#E33E51'
export const error500 = '#BD404F'

export const awsColor = '#FF9900'
export const azureColor = '#007FFF'
export const gcpColor = '#DB4437'

// font sizes
export const small = '14px'
export const medium = '18px'
export const large = '22px'
export const extraLarge = '26px'

// tw breakpoints in px
export const twSm = 640
export const twMd = 768
export const twLg = 1024
export const twXl = 1280
