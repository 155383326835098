import React, { createContext, useMemo, useState } from 'react'
import { ToastPosition } from 'react-toastify'

export enum MessageType {
  ERROR = 'ERROR',
  WARNING = 'WARNING',
  SUCCESS = 'SUCCESS',
  INFO = 'INFO'
}

export interface SpotterMessage {
  heading?: string
  message?: string | undefined
  action?: JSX.Element
  position?: ToastPosition
  globalMessage?: boolean
  type: MessageType
}

export interface MessageState {
  message: SpotterMessage | null
  setMessage: (message: SpotterMessage | null) => void
}

export const defaultMessageState: MessageState = {
  message: null,
  setMessage: () => undefined
}

export const MessageContext = createContext<MessageState>(defaultMessageState)

interface Props {
  children: JSX.Element
}

export const MessageContextProvider = React.memo(({ children }: Props) => {
  const [message, setMessage] = useState<SpotterMessage | null>(defaultMessageState.message)
  const value = useMemo(() => ({ message, setMessage }), [message, setMessage])

  return <MessageContext.Provider value={value}>{children}</MessageContext.Provider>
})
