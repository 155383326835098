import { createContext, useState } from 'react'

export interface FiltersState {
  activeFilters: number
  setActiveFilters: (activeFilters: number) => void
  filtersOpen: boolean
  setFiltersOpen: (isOpen: boolean) => void
}

const defaultFiltersState: FiltersState = {
  activeFilters: 0,
  setActiveFilters: () => undefined,
  filtersOpen: false,
  setFiltersOpen: () => undefined
}

export const FiltersContext = createContext<FiltersState>(defaultFiltersState)

interface Props {
  children: JSX.Element
}

export const FiltersContextProvider = ({ children }: Props) => {
  const [filtersOpen, setFiltersOpen] = useState(defaultFiltersState.filtersOpen)
  const [activeFilters, setActiveFilters] = useState(defaultFiltersState.activeFilters)
  const value = {
    activeFilters,
    setActiveFilters,
    filtersOpen,
    setFiltersOpen
  }

  return <FiltersContext.Provider value={value}>{children}</FiltersContext.Provider>
}
