import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import tw from 'twin.macro'
import { FullScreenLogoContainer, Logo } from '../shared/layout/Logo'
import { FullScreenContainer, FullScreenFooter } from '../shared/layout/FullScreenPage'
import { useQueryParameters } from '../../utils/router'

export const ConsentAzure = () => {
  const { t } = useTranslation()
  const queryParams = useQueryParameters()
  const error = queryParams.get('error')
  const consent = Boolean(queryParams.get('admin_consent'))

  return (
    <FullScreenContainer>
      <Content>
        <FullScreenLogoContainer>
          <Logo animated={true} />
        </FullScreenLogoContainer>
        {consent ? (
          <MessageContainer>{t('azureAdminConsent.success')}</MessageContainer>
        ) : error ? (
          <>
            <MessageContainer>{t('azureAdminConsent.failure')}</MessageContainer>
            <MessageContainer>{t('azureAdminConsent.instruction')}</MessageContainer>
            <MessageContainer>error: {error}</MessageContainer>
          </>
        ) : (
          <MessageContainer>{t('azureAdminConsent.emptyPage')}</MessageContainer>
        )}
      </Content>
      <FullScreenFooter>{t('footer.message')}</FullScreenFooter>
    </FullScreenContainer>
  )
}

const Content = styled.div`
  ${tw`h-full flex flex-col justify-center items-center w-max z-10 text-gray-100`}
`

const MessageContainer = styled.div`
  ${tw`mb-1`}
`
