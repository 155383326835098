import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import en from '../assets/i18n/en.json'
import resourcesTypes from '../assets/resource-types.json'

export const initI18n = (lang: string) => {
  i18n.use(initReactI18next).init({
    resources: {
      en: {
        translation: { ...en, ...resourcesTypes }
      }
    },
    lng: lang,
    fallbackLng: 'en',
    returnNull: false,
    returnEmptyString: false,
    interpolation: {
      escapeValue: false
    }
  })
}
