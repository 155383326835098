import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'
import { useCancelToken } from '../../api/client'
import { getInfraMapData, globalRegion, InfraMapData } from '../../api/infra'
import { useErrorHandling } from '../../hooks/handleError'
import { Loading } from '../shared/Loading'
import { MapContainer, Marker, TileLayer, Tooltip } from 'react-leaflet'
import L from 'leaflet'
import MarkerClusterGroup from 'react-leaflet-cluster'
import { LocationMarker } from './LocationMarker'
import ReactDOMServer from 'react-dom/server'
import { CustomTooltip } from '../shared/CustomTooltip'
import { Vendor } from '../../utils/vendors'

interface Props {
  selectedVendors: Vendor[]
  selectedRegions: string[]
  selectedProjects: string[]
  selectedResourceGroups: string[]
  selectedResources: string[]
}

export const InfraMap = ({
  selectedVendors,
  selectedRegions,
  selectedProjects,
  selectedResourceGroups,
  selectedResources
}: Props) => {
  const { createCancelToken } = useCancelToken()
  const handleError = useErrorHandling()
  const [loading, setLoading] = useState<boolean>(true)
  const [mapData, setMapData] = useState<InfraMapData[]>([])

  useEffect(() => {
    const cancelToken = createCancelToken()
    getInfraMapData(
      selectedVendors,
      selectedRegions,
      selectedProjects,
      selectedResourceGroups,
      selectedResources,
      cancelToken.token
    )
      .then(setMapData)
      .catch(handleError)
      .finally(() => setLoading(false))

    return () => {
      cancelToken.cancel()
      setLoading(false)
      setMapData([])
    }
  }, [
    createCancelToken,
    handleError,
    selectedProjects,
    selectedRegions,
    selectedResourceGroups,
    selectedResources,
    selectedVendors
  ])

  if (loading)
    return (
      <MapWrapper className={'bg-gray-700'}>
        <Loading />
      </MapWrapper>
    )

  return (
    <MapWrapper>
      <MapContainer
        style={{ zIndex: 0 }}
        scrollWheelZoom={false}
        className='font-sans'
        center={[53.63006540407154, 9.893044764031071]}
        zoom={2}
        minZoom={2}
      >
        <TileLayer
          url={`https://api.mapbox.com/styles/v1/${process.env.REACT_APP_MAPBOX_USERNAME}/ckuwo344e38ro18uvg2ddlcfj/tiles/256/{z}/{x}/{y}@2x?access_token=${process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}`}
        />

        <MarkerClusterGroup maxClusterRadius={30} showCoverageOnHover={false} spiderLegPolylineOptions={{ opacity: 0 }}>
          {mapData.map(dataPoint => {
            const locationMarker = L.divIcon({
              className: 'custom-icon',
              iconAnchor: [10, 50],
              html: ReactDOMServer.renderToString(<LocationMarker vendor={dataPoint.vendor} />)
            })

            return (
              <Marker
                key={`${dataPoint.vendor}-${dataPoint.region.id}`}
                icon={locationMarker}
                position={[
                  dataPoint.region.latitude ? dataPoint.region.latitude : globalRegion.latitude,
                  dataPoint.region.longitude ? dataPoint.region.longitude : globalRegion.longitude
                ]}
                eventHandlers={{
                  click: e => {
                    e.originalEvent.preventDefault()
                  }
                }}
              >
                <Tooltip direction={'right'} className='infra-map-tooltip'>
                  <CustomTooltip pointerDirection='left' showIcon={false}>
                    <VendorName>{dataPoint.vendor}</VendorName>
                    <p>
                      {dataPoint.region.description} - {dataPoint.count}
                    </p>
                  </CustomTooltip>
                </Tooltip>
              </Marker>
            )
          })}
        </MarkerClusterGroup>
      </MapContainer>
    </MapWrapper>
  )
}

const MapWrapper = styled.div`
  ${tw`h-75 md:h-100 xl:h-125 2xl:h-150`}
  .infra-map-tooltip {
    ${tw`ml-8 -mt-10 bg-transparent shadow-none border-none`}
  }
`

const VendorName = styled.div`
  ${tw`font-bold`}
`
