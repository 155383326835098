import { OptimizationTabCollapsibleSummary } from '../OptimizationTabsSharedComponents'
import { TreeBranch } from '../../../shared/TreeBranch'
import React from 'react'
import { GcpRightsizing, GcpRightsizingByCategory, GcpRightsizingByProject } from '../../../../api/optimization/gcp'
import { useTranslation } from 'react-i18next'
import { getCurrency } from '../../../../utils/Currency'
import { DataTableCell, DataTableLabel, DataTableRow, DataTableValue } from '../../../shared/DataTableSharedComponents'
import { ClipboardCopy } from '../../../shared/ClipboardCopy'
import { TabDataTableRegionCell } from '../../../shared/tabs/TabSharedComponents'
import { formatNumber, roundNumber } from '../../../../utils/formats'

interface GcpTabRightsizingProps {
  id: string
  rightsizing: GcpRightsizingByCategory
}

export const GcpTabRightsizing = ({ id, rightsizing }: GcpTabRightsizingProps) => {
  const { t } = useTranslation()
  return (
    <TreeBranch
      id={id}
      headerData={
        <OptimizationTabCollapsibleSummary
          headerLeft={rightsizing.category.toLowerCase().replaceAll('_', ' ')}
          headerRight={t('optimization.tabs.gcp.rightSizingRecommendations', {
            count: rightsizing.recommendations.length
          })}
          useExclamationMark={false}
        />
      }
      contentData={rightsizing.recommendations.map((recommendation, i) => (
        <RightsizingRecommendation key={i} recommendation={recommendation} />
      ))}
    />
  )
}

interface RightsizingRecommendationProps {
  recommendation: GcpRightsizingByProject
}

const RightsizingRecommendation = ({ recommendation }: RightsizingRecommendationProps) => {
  const { t } = useTranslation()

  return (
    <TreeBranch
      depth={1}
      headerData={
        <OptimizationTabCollapsibleSummary
          headerLeft={
            <ClipboardCopy
              copyText={recommendation.project.id}
              customDisplayText={recommendation.project.name}
              restrictClickArea={true}
              limit={30}
            />
          }
          headerRight={t('optimization.tabs.gcp.rightSizingCount', {
            count: recommendation.recommendations.length
          })}
          useExclamationMark={false}
        />
      }
      contentData={
        <>
          <DataTableRow isHeader={true} styles={'px-5 grid-cols-11'}>
            <DataTableCell styles={'col-span-6'}>
              <DataTableLabel isHeader={true}>{t('optimization.tabs.gcp.recommendation')}</DataTableLabel>
            </DataTableCell>
            <DataTableCell styles={'col-span-2'}>
              <DataTableLabel isHeader={true}>{t('optimization.tabs.gcp.target')}</DataTableLabel>
            </DataTableCell>
            <DataTableCell styles={'col-span-2'}>
              <DataTableLabel isHeader={true}>{t('common.region_one')}</DataTableLabel>
            </DataTableCell>
            <DataTableCell>
              <DataTableLabel isHeader={true}>{t('optimization.tabs.gcp.cost')}</DataTableLabel>
            </DataTableCell>
          </DataTableRow>
          {recommendation.recommendations.map((rightsizing, i) => (
            <TreeBranch
              key={i}
              depth={2}
              headerData={<RightSizingRow recommendation={rightsizing} />}
              contentData={false}
            />
          ))}
        </>
      }
    />
  )
}

interface RightsizingRowProps {
  recommendation: GcpRightsizing
}

const RightSizingRow = ({ recommendation }: RightsizingRowProps) => {
  const { t } = useTranslation()
  const currency = getCurrency(recommendation.currency)

  return (
    <DataTableRow styles={`grid-cols-1 xl:grid-cols-11`}>
      <DataTableCell styles={'xl:mr-4 xl:col-span-6'}>
        <DataTableLabel>{t('optimization.tabs.gcp.recommendation')}</DataTableLabel>
        <div className={'text-gray-50 text-right text-90 xl:text-left'}>{recommendation.description}</div>
      </DataTableCell>
      <DataTableCell styles={'xl:col-span-2'}>
        <DataTableLabel>{t('optimization.tabs.gcp.target')}</DataTableLabel>
        <DataTableValue>
          <ClipboardCopy
            customDisplayText={recommendation.target.split('/').pop()}
            copyText={recommendation.target}
            limit={20}
          />
        </DataTableValue>
      </DataTableCell>
      <TabDataTableRegionCell
        styles={'xl:col-span-2'}
        regionId={recommendation.region.id}
        regionName={recommendation.region.name}
      />
      <DataTableCell>
        <DataTableLabel>{t('optimization.tabs.gcp.cost')}</DataTableLabel>
        <DataTableValue>
          {formatNumber(roundNumber(recommendation.cost, 0))} {currency.symbol}
        </DataTableValue>
      </DataTableCell>
    </DataTableRow>
  )
}
