import React from 'react'

import { ModalContextProvider } from './context/ModalContext'
import { UserInfoContextProvider } from './context/UserInfoContext'
import { MessageContextProvider } from './context/MessageContext'
import { NotificationsContextProvider } from './context/NotificationsContext'
import { FiltersContextProvider } from './context/FiltersContext'
import { NavbarContextProvider } from './context/NavbarContext'

const StateProvider = React.memo(({ children }: { children: JSX.Element }) => {
  return (
    <MessageContextProvider>
      <UserInfoContextProvider>
        <NavbarContextProvider>
          <NotificationsContextProvider>
            <ModalContextProvider>
              <FiltersContextProvider>{children}</FiltersContextProvider>
            </ModalContextProvider>
          </NotificationsContextProvider>
        </NavbarContextProvider>
      </UserInfoContextProvider>
    </MessageContextProvider>
  )
})

export default StateProvider
