import React from 'react'
import { DataBlock } from '../../shared/containers/DataBlock'
import check from '../../../assets/svg/objects/flat-colored/check.svg'
import rightsize from '../../../assets/svg/objects/flat-colored/re-size.svg'
import utilize from '../../../assets/svg/objects/flat-colored/utilize.svg'
import { CustomIcon, IconType } from '../../shared/CustomIcon'
import styled from 'styled-components'
import tw from 'twin.macro'
import { useTranslation } from 'react-i18next'
import { DataBlocksWrapper } from '../../shared/layout/DataBlocksWrapper'
import { Heading } from '../../shared/TextComponents'
import { TopRecommendations } from '../../../api/optimization/shared'

interface SummaryTabProps {
  recommendations: TopRecommendations | null
  loading: boolean
}

export const OptimizationSummaryTab = ({ recommendations, loading }: SummaryTabProps) => {
  const { t } = useTranslation()

  return (
    <Wrapper>
      <Heading>{t('optimization.tabs.summary.title')}</Heading>
      <DataBlocksWrapper>
        <DataBlock
          id={'qa-optimization-summary-tab-commitments'}
          headerIcon={<CustomIcon styles={'h-9 w-9'} iconType={IconType.FLAT} path={check} />}
          heading={t('optimization.tabs.summary.commitments')}
          subHeading={t('optimization.common.potentialMonthlySavings')}
          data={recommendations?.commitments ?? []}
          loading={loading}
        />
        <DataBlock
          id={'qa-optimization-summary-tab-right-sizing'}
          headerIcon={<CustomIcon styles={'h-9 w-9'} iconType={IconType.FLAT} path={rightsize} />}
          heading={t('optimization.tabs.summary.rightsizing')}
          subHeading={t('optimization.common.potentialMonthlySavings')}
          data={recommendations?.rightsizing ?? []}
          loading={loading}
        />
        <DataBlock
          id={'qa-optimization-summary-tab-utilization'}
          headerIcon={<CustomIcon styles={'h-9 w-9'} iconType={IconType.FLAT} path={utilize} />}
          heading={t('optimization.tabs.summary.utilization')}
          subHeading={t('optimization.common.potentialMonthlySavings')}
          data={recommendations?.utilization ?? []}
          loading={loading}
        />
      </DataBlocksWrapper>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  ${tw`flex flex-col gap-6 w-full`}
`
