import React from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'
import { useTranslation } from 'react-i18next'
import { GrayText } from '../TextComponents'
import { InfoTooltip } from '../tooltips/InfoTooltip'

interface SwitchButtonProps {
  clickHandler: () => void
  checked: boolean
  disabled?: boolean
  label: string
  labelPosition?: 'left' | 'right' | 'top' | 'bottom'
  colorScale?: 'primary' | 'gray'
  tooltipText?: string
  tooltipStyles?: string
}

export const SwitchButton = ({
  clickHandler,
  checked,
  disabled = false,
  label,
  labelPosition = 'right',
  colorScale,
  tooltipText,
  tooltipStyles
}: SwitchButtonProps) => {
  const { t } = useTranslation()
  return (
    <Wrapper
      className={
        labelPosition === 'top'
          ? 'flex-col-reverse text-center'
          : labelPosition === 'bottom'
            ? 'flex-col text-center'
            : labelPosition === 'left'
              ? 'flex-row-reverse'
              : 'flex-row'
      }
    >
      <Switch
        onClick={!disabled ? clickHandler : undefined}
        disabled={disabled}
        checked={checked}
        colorScale={colorScale}
      >
        <span>{checked ? t('common.on') : t('common.off')}</span>
      </Switch>
      <GrayText className={'w-max self-end text-80 first-letter:capitalize'}>
        {label}
        {tooltipText && (
          <InfoTooltip useIcon={true} mousePosition={true} styles={tooltipStyles}>
            {tooltipText}
          </InfoTooltip>
        )}
      </GrayText>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  ${tw`flex gap-2 items-center`}
`

interface SwitchProps {
  checked?: boolean
  disabled?: boolean
  colorScale?: 'primary' | 'gray'
}

const Switch = styled.div<SwitchProps>`
  ${tw`flex cursor-pointer min-w-14 w-14 h-6 p-1 rounded-full bg-gray-500 transition-all ease-linear duration-200`}
  ${({ checked, colorScale }) =>
    checked ? (colorScale === 'gray' ? tw`bg-gray-50` : tw`bg-primary-500`) : tw`bg-gray-500`}
  ${({ disabled }) => disabled && tw`cursor-not-allowed text-gray-300`}
  span {
    ${tw`self-center text-80 leading-tight font-semibold transition-all ease-linear duration-200`}
    ${({ checked, colorScale }) =>
      checked ? (colorScale === 'gray' ? tw`text-gray-700 ml-[55%]` : tw`text-gray-50 ml-[55%]`) : tw`ml-0.5`}
  }
`
