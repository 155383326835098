import React, { useContext, useEffect, useState } from 'react'
import { DataContainer } from '../../shared/containers/DataContainer'
import {
  AwsAzureComplianceTotalSummary,
  ComplianceSecuritySummary,
  GcpComplianceTotalSummary,
  getAwsAzureComplianceTotalSummary,
  getComplianceSecuritySummary,
  getComplianceStandardSummary,
  getGcpComplianceTotalSummary
} from '../../../api/compliance/common'
import { Loading } from '../../shared/Loading'
import styled from 'styled-components'
import tw from 'twin.macro'
import { useTranslation } from 'react-i18next'
import { Vendor } from '../../../utils/vendors'
import { formatNumber } from '../../../utils/formats'
import { GrayText, Heading2, WhiteText } from '../../shared/TextComponents'
import { DonutScoreChart } from '../../shared/charts/DonutScoreChart'
import { AwsAndAzureSummary, GcpComplianceSummary } from './ComplianceVendorTotals'
import { ComplianceSummaryContainerWrapper } from './Shared'
import { MissingDataNotification } from '../../shared/MissingDataNotification'
import { useCancelToken } from '../../../api/client'
import { useErrorHandling } from '../../../hooks/handleError'
import { DataBlockData } from '../../shared/containers/DataBlock'
import { ComplianceStatus, ComplianceStatusIndicator, StatusIndicatorSize } from '../ComplianceStatusIndicator'
import { UserInfoContext } from '../../../state/context/UserInfoContext'

interface TotalSummaryProps {
  selectedVendors: Vendor[]
  selectedProjects: string[]
  selectedResourceGroups: string[]
  selectedStandards: string[]
}

export const ComplianceTotalSummary = ({
  selectedVendors,
  selectedProjects,
  selectedResourceGroups,
  selectedStandards
}: TotalSummaryProps) => {
  const { createCancelToken } = useCancelToken()
  const { userSettings } = useContext(UserInfoContext)
  const handleError = useErrorHandling()
  const [awsAzureTotalSummary, setAwsAzureTotalSummary] = useState<AwsAzureComplianceTotalSummary | null>(null)
  const [gcpTotalSummary, setGcpTotalSummary] = useState<GcpComplianceTotalSummary | null>(null)
  const [securitySummary, setSecuritySummary] = useState<ComplianceSecuritySummary[]>([])
  const [standardSummaries, setStandardSummaries] = useState<DataBlockData[]>([])
  const [awsAzureLoading, setAwsAzureLoading] = useState(false)
  const [gcpLoading, setGcpLoading] = useState(false)
  const [securityLoading, setSecurityLoading] = useState(false)
  const [standardLoading, setStandardLoading] = useState(false)

  useEffect(() => {
    const cancelToken = createCancelToken()
    setAwsAzureLoading(true)
    setGcpLoading(true)
    setSecurityLoading(true)
    setStandardLoading(true)
    Promise.all([
      getAwsAzureComplianceTotalSummary(
        selectedVendors,
        selectedProjects,
        selectedResourceGroups,
        selectedStandards,
        cancelToken.token
      ).then(resp => {
        setAwsAzureTotalSummary(resp)
        setAwsAzureLoading(false)
      }),
      getGcpComplianceTotalSummary(
        selectedVendors,
        selectedProjects,
        selectedResourceGroups,
        selectedStandards,
        cancelToken.token
      ).then(resp => {
        setGcpTotalSummary(resp)
        setGcpLoading(false)
      }),
      getComplianceSecuritySummary(
        selectedVendors,
        selectedProjects,
        selectedResourceGroups,
        selectedStandards,
        cancelToken.token
      ).then(resp => {
        setSecuritySummary(resp)
        setSecurityLoading(false)
      }),
      getComplianceStandardSummary(
        selectedVendors,
        selectedProjects,
        selectedResourceGroups,
        selectedStandards,
        cancelToken.token
      ).then(data => {
        setStandardSummaries(data)
        setStandardLoading(false)
      })
    ]).catch(handleError)

    return () => {
      cancelToken.cancel()
      setAwsAzureLoading(false)
      setGcpLoading(false)
      setSecurityLoading(false)
      setStandardLoading(false)
    }
  }, [createCancelToken, handleError, selectedProjects, selectedResourceGroups, selectedStandards, selectedVendors])

  const showAwsAzure =
    userSettings.visibleVendors.includes(Vendor.AWS) || userSettings.visibleVendors.includes(Vendor.AZURE)
  const showGcp = userSettings.visibleVendors.includes(Vendor.GCP)

  if (userSettings.visibleVendors.length <= 2 && showAwsAzure && !showGcp)
    return (
      <SummariesContainer
        className={`grid-cols-1 ${userSettings.visibleVendors.length === 1 ? 'lg:grid-cols-5' : 'xl:grid-cols-6'}`}
      >
        <div className={'w-full lg:col-span-2'}>
          <DataContainer flexCol={true} looseSpacing={true}>
            {showAwsAzure && (
              <AwsAndAzureSummary
                total={awsAzureTotalSummary}
                selectedVendors={selectedVendors}
                loading={awsAzureLoading}
              />
            )}
            {showGcp && <GcpComplianceSummary data={gcpTotalSummary} loading={gcpLoading} />}
          </DataContainer>
        </div>
        <div
          className={`flex flex-col w-full gap-5 ${userSettings.visibleVendors.length === 1 ? 'lg:col-end-6 lg:col-span-3' : 'xl:col-end-7 xl:col-span-4'}`}
        >
          {showAwsAzure && (
            <DataContainer looseSpacing={true}>
              <SecurityCharts data={securitySummary} loading={securityLoading} />
            </DataContainer>
          )}
          <DataContainer looseSpacing={true}>
            <FailedControls failedControls={standardSummaries} loading={standardLoading} />
          </DataContainer>
        </div>
      </SummariesContainer>
    )

  return (
    <SummariesContainer className={'grid-cols-1 lg:grid-cols-2'}>
      {showAwsAzure && (
        <DataContainer looseSpacing={true}>
          <AwsAndAzureSummary
            total={awsAzureTotalSummary}
            selectedVendors={selectedVendors}
            loading={awsAzureLoading}
          />
        </DataContainer>
      )}
      {showGcp && (
        <DataContainer looseSpacing={true}>
          <GcpComplianceSummary data={gcpTotalSummary} loading={gcpLoading} />
        </DataContainer>
      )}
      {showAwsAzure && (
        <DataContainer looseSpacing={true}>
          <SecurityCharts data={securitySummary} loading={securityLoading} />
        </DataContainer>
      )}
      <DataContainer looseSpacing={true}>
        <FailedControls failedControls={standardSummaries} loading={standardLoading} />
      </DataContainer>
    </SummariesContainer>
  )
}

const SummariesContainer = styled.div`
  ${tw`grid w-full gap-5`}
  > div > div {
    ${tw`h-full`}
  }
`

interface FailedControlsProps {
  failedControls: DataBlockData[]
  loading: boolean
}

const FailedControls = ({ failedControls, loading }: FailedControlsProps) => {
  const { t } = useTranslation()
  const { userSettings } = useContext(UserInfoContext)
  if (loading || !failedControls?.length)
    return (
      <ComplianceSummaryContainerWrapper>
        <Heading2>{t('compliance.totalSummary.standardSummaryHeading')}</Heading2>
        {loading ? <Loading height={64} paddingY={'1rem'} /> : <MissingDataNotification />}
      </ComplianceSummaryContainerWrapper>
    )

  return (
    <ComplianceSummaryContainerWrapper>
      <Heading2>{t('compliance.totalSummary.standardSummaryHeading')}</Heading2>
      <div className={'flex flex-col gap-3'}>
        {failedControls
          .filter(v => userSettings.visibleVendors.includes(v.vendor))
          .map((item, index) => (
            <div
              id={`qa-compliance-total-summary-failed-controls-${index}`}
              key={index}
              className={'flex w-full justify-between items-center w-full gap-8'}
            >
              <WhiteText>{item.label}</WhiteText>
              <div className={'flex gap-4'}>
                <WhiteText className={'font-semibold'}>{item.value}</WhiteText>
                <ComplianceStatusIndicator size={StatusIndicatorSize.small} status={ComplianceStatus.FAILED} />
              </div>
            </div>
          ))}
      </div>
    </ComplianceSummaryContainerWrapper>
  )
}

interface SecurityChartsProps {
  data: ComplianceSecuritySummary[] | null
  loading: boolean
}

const SecurityCharts = ({ data, loading }: SecurityChartsProps) => {
  const { t } = useTranslation()
  const { userSettings } = useContext(UserInfoContext)
  const vendorChartLegend = (entry: ComplianceSecuritySummary) => {
    return entry.totalCount && (entry.compliantCount || entry.compliantCount === 0) ? (
      entry.totalCount === 0 ? (
        t('compliance.totalSummary.notEnabled')
      ) : (
        <>
          <span id={`qa-compliance-${entry.vendor}-security-resources`}>
            {formatNumber(entry.compliantCount)} / {formatNumber(entry.totalCount)}
          </span>{' '}
          {t('compliance.totalSummary.compliantResources')}
        </>
      )
    ) : (
      t('error.noData')
    )
  }

  return (
    <ComplianceSummaryContainerWrapper className={'w-full'}>
      <Heading2>{t('compliance.totalSummary.securityBestPractises')}</Heading2>
      {loading ? (
        <Loading height={64} paddingY={'1rem'} />
      ) : !data?.length ? (
        <MissingDataNotification />
      ) : (
        <div className={'flex flex-wrap gap-2'}>
          {data
            .filter(v => userSettings.visibleVendors.includes(v.vendor))
            .map(entry => (
              <div key={entry.vendor} className={'flex gap-2 items-center first:mr-10'}>
                <DonutScoreChart score={entry.percent} maxWidth={100} />
                <div>
                  <WhiteText className={'font-semibold'}>
                    {t(`compliance.totalSummary.securityChartLabel.${entry.vendor}`)}
                  </WhiteText>
                  <GrayText>{vendorChartLegend(entry)}</GrayText>
                </div>
              </div>
            ))}
        </div>
      )}
    </ComplianceSummaryContainerWrapper>
  )
}
