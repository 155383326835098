import React, { useContext, useEffect } from 'react'
import { Redirect, Route, Switch, useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { logOut, UserRole } from '../../api/auth'
import { Costs } from '../../components/costs/Costs'
import { Compliance } from '../../components/compliance/Compliance'
import { featuresFlags } from '../../state/featuresFlags'
import { Tickets } from '../../components/tickets/Tickets'
import { ConsentAzure } from '../../components/login/ConsentAzure'
import { FederatedAuth, Login } from '../../components/login/Login'
import { PrivateRoute } from './PrivateRoute'
import { Home } from '../../components/home/Home'
import { Sustainability } from '../../components/sustainability/Sustainability'
import { SystemAdmin } from '../../components/SystemAdmin'
import { Infra } from '../../components/infra/Infra'
import { defaultUserInfoState, UserInfoContext } from '../../state/context/UserInfoContext'
import { clearPersistedLoginInfo } from '../../api/localStorage'
import { ADMIN_ROLES } from '../../components/admin/roleConstants'
import { Optimization } from '../../components/optimization/Optimization'
import { SignUp } from '../../components/login/SignUp'
import { ContactSupport } from '../../components/ContactSupport'
import { AdminRoutes } from './AdminRoutes'
import { SettingsRoutes } from './SettingsRoutes'
import { MasterAdminRoutes } from './MasterAdminRoutes'
import { MarketplaceSignupInfo } from '../../components/login/MarketplaceSignupInfo'

export interface NestedRoutesProps {
  path: string
}

const Routes = () => {
  const { t } = useTranslation()
  const { authInfo } = useContext(UserInfoContext)
  const isAdmin = authInfo.roles.some(role => ADMIN_ROLES.includes(role))

  return (
    <Switch>
      <Route exact={true} path={'/'}>
        {authInfo.isLoggedIn ? <Redirect to={'/home'} /> : <Login />}
      </Route>
      <Route exact={true} path={'/signup'}>
        <SignUp />
      </Route>
      <Route path={`/aws-marketplace/signup-info`}>
        <MarketplaceSignupInfo />
      </Route>
      <Route path={`/aws-marketplace/signup/:tenantId`}>
        <SignUp />
      </Route>
      <Route exact={true} path={'/login'}>
        <Login />
      </Route>
      <Route exact={true} path={'/federated-auth'}>
        <FederatedAuth />
      </Route>
      <Route exact={true} path={'/azure-admin-consent'} component={ConsentAzure} />
      <PrivateRoute exact={true} path={'/logout'}>
        <Logout />
      </PrivateRoute>
      {featuresFlags.home && (
        <PrivateRoute exact={true} path={'/home'}>
          <Home />
        </PrivateRoute>
      )}
      {featuresFlags.costs && authInfo.costsAccess && (
        <PrivateRoute exact={true} path={'/costs'}>
          <Costs />
        </PrivateRoute>
      )}
      {featuresFlags.optimization && authInfo.optimizationAccess && (
        <PrivateRoute exact={true} path={'/optimization'}>
          <Optimization />
        </PrivateRoute>
      )}
      {featuresFlags.compliance && authInfo.complianceAccess && (
        <PrivateRoute exact={true} path={'/compliance'}>
          <Compliance />
        </PrivateRoute>
      )}
      {featuresFlags.tickets && authInfo.ticketAccess && authInfo.freshIntegration && (
        <PrivateRoute exact={true} path={'/tickets'}>
          <Tickets />
        </PrivateRoute>
      )}
      {featuresFlags.infra && authInfo.infraAccess && (
        <PrivateRoute exact={true} path={'/infra'}>
          <Infra />
        </PrivateRoute>
      )}
      {featuresFlags.sustainability && authInfo.sustainabilityAccess && (
        <PrivateRoute exact={true} path={'/sustainability'}>
          <Sustainability />
        </PrivateRoute>
      )}
      {featuresFlags.admin && isAdmin && <AdminRoutes path={'/admin'} />}
      {featuresFlags.systemAdmin &&
        authInfo.roles.some(role => role === UserRole.SYSTEM_ADMIN || role === UserRole.MASTER_ADMIN) && (
          <PrivateRoute exact={true} path={'/system-admin'}>
            <SystemAdmin />
          </PrivateRoute>
        )}
      {featuresFlags.masterAdmin && authInfo.roles.includes(UserRole.MASTER_ADMIN) && (
        <MasterAdminRoutes path={'/master-admin'} />
      )}
      <SettingsRoutes path={'/settings'} />
      <PrivateRoute exact={true} path={'/support'}>
        <ContactSupport />
      </PrivateRoute>
      <PrivateRoute exact={true} path={'*'}>
        <>{t('routeNotFound')}</>
      </PrivateRoute>
    </Switch>
  )
}

const Logout = () => {
  const history = useHistory()
  const { setAuthInfo } = useContext(UserInfoContext)

  useEffect(() => {
    logOut().then(() => {
      setAuthInfo(defaultUserInfoState.authInfo)
      clearPersistedLoginInfo()
      sessionStorage.clear()
      history.push('/login')
    })
  }, [history, setAuthInfo])

  return null
}

export default Routes
