import React, { useContext, useEffect, useState } from 'react'
import {
  completeNewPassword,
  configureCognito,
  forgotPassword,
  forgotPasswordSubmit,
  getAuthInfo,
  getLoginInfo,
  login,
  LoginInfo,
  resendActivationCode
} from '../../api/auth'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { MessageContext, MessageState, MessageType } from '../../state/context/MessageContext'
import tw from 'twin.macro'
import { Button, ButtonSize, ButtonStyle } from '../shared/buttons/Button'
import { useCancelToken } from '../../api/client'
import { CognitoUser } from '@aws-amplify/auth'
import { emailRegExp, passwordRegExp } from '../../utils/formats'
import { UserInfoContext } from '../../state/context/UserInfoContext'
import { getPersistedLoginInfo, persistLoginInfo } from '../../api/localStorage'
import { useErrorHandling } from '../../hooks/handleError'
import { CustomInput, CustomLabel } from '../shared/filters/FormComponents'
import { BoldLink, CapitalizedText } from '../shared/TextComponents'
import { FrontPageContainer } from './FrontPageContainer'
import { useQueryParameters } from '../../utils/router'
import { FullScreenLoading } from '../shared/Loading'
import { LoginBackButton, LoginFlowContainer, LoginFormContainer } from './Shared'
import { SignUpLogin } from './SignUpLogin'
import { ADMIN_ROLES } from '../admin/roleConstants'
import { getSignupEmail, removeSignupEmail, setFirstLogin } from '../../state/storage'
import { AxiosError } from 'axios'
import { getIntegrationStates, getUserSettings } from '../../api/settings/profile'

export enum LoginFlowState {
  SIGNUP_LOGIN,
  ENTER_EMAIL,
  ENTER_PASSWORD,
  ACTIVATE_ACCOUNT,
  RESET_PASSWORD
}

export const Login = () => {
  const { createCancelToken } = useCancelToken()
  const handleError = useErrorHandling()
  const { authInfo, setAuthInfo, setUserSettings, setIntegrationStates } = useContext(UserInfoContext)
  const { setMessage } = useContext<MessageState>(MessageContext)
  const { t } = useTranslation()
  const history = useHistory()
  const queryParams = useQueryParameters()
  const isSignUp = Boolean(queryParams.get('signup'))
  const isMarketplaceSetupComplete = queryParams.get('setup-completed')
  const hasIntegration = authInfo.awsIntegration || authInfo.azureIntegration || authInfo.gcpIntegration
  const isAdmin = authInfo.roles.some(role => ADMIN_ROLES.includes(role))

  const [loading, setLoading] = useState<boolean>(false)
  const [email, setEmail] = useState<string>('')
  const [cognitoUser, setCognitoUser] = useState<CognitoUser | null>(null)
  const [loginFlow, setLoginFlow] = useState<LoginFlowState>(LoginFlowState.ENTER_EMAIL)

  useEffect(() => {
    isSignUp && getSignupEmail() && setLoginFlow(LoginFlowState.SIGNUP_LOGIN)
    isMarketplaceSetupComplete === 'true' &&
      setMessage({
        heading: t('login.setupCompletedHeading'),
        message: t('login.setupCompletedMessage'),
        type: MessageType.INFO,
        position: 'top-center'
      })
    if (authInfo.isLoggedIn) {
      !authInfo.lastLoginAt && setFirstLogin()
      history.push(!hasIntegration && isAdmin ? '/admin/integrations?first=true' : '/home', { from: '/login' })
      removeSignupEmail()
    }
  }, [history, authInfo, isSignUp])

  const handleLogin = () => {
    setLoading(true)
    const cancelToken = createCancelToken()
    getAuthInfo(cancelToken.token)
      .then(setAuthInfo)
      .catch(handleError)
      .then(() => {
        setLoading(false)
        Promise.all([
          getUserSettings(cancelToken.token).then(setUserSettings),
          getIntegrationStates(cancelToken.token).then(setIntegrationStates)
        ]).catch(handleError)
      })
  }

  if (loading) return <FullScreenLoading />

  return (
    <FrontPageContainer>
      {
        loginFlow === LoginFlowState.SIGNUP_LOGIN ? (
          <SignUpLogin
            email={getSignupEmail()}
            setEmail={setEmail}
            setCognitoUser={setCognitoUser}
            setLoginFlow={setLoginFlow}
          />
        ) : loginFlow === LoginFlowState.ENTER_EMAIL ? (
          <EnterEmail email={email} setEmail={setEmail} setLoginFlow={setLoginFlow} />
        ) : loginFlow === LoginFlowState.ENTER_PASSWORD ? (
          <EnterPassword
            email={email}
            setLoginFlow={setLoginFlow}
            setCognitoUser={setCognitoUser}
            handleLogin={handleLogin}
          />
        ) : loginFlow === LoginFlowState.ACTIVATE_ACCOUNT ? (
          <ActivateAccount cognitoUser={cognitoUser} setLoginFlow={setLoginFlow} handleLogin={handleLogin} />
        ) : loginFlow === LoginFlowState.RESET_PASSWORD ? (
          <ResetPassword email={email} setLoginFlow={setLoginFlow} />
        ) : undefined /* code never reaches here */
      }
    </FrontPageContainer>
  )
}

interface EnterEmailProps {
  email: string
  setEmail: (email: string) => void
  setLoginFlow: (state: LoginFlowState) => void
}

const EnterEmail = ({ email, setEmail, setLoginFlow }: EnterEmailProps) => {
  const { t } = useTranslation()
  const { setMessage } = useContext<MessageState>(MessageContext)
  const history = useHistory()

  const [requestInFlight, setRequestInFlight] = useState<boolean>(false)
  const handleError = useErrorHandling()

  const getSSOUrl = (
    loginInfo: LoginInfo
  ) => `https://${loginInfo.cognitoPoolDomain}.auth.${loginInfo.cognitoRegion}.amazoncognito.com/oauth2/authorize?identity_provider=${loginInfo.cognitoIdP}&redirect_uri=${process.env.REACT_APP_BASE_URL}/federated-auth&response_type=CODE&client_id=${loginInfo.cognitoClientId}&scope=email openid profile aws.cognito.signin.user.admin
`

  const handleEmailSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault()
    setRequestInFlight(true)
    setMessage(null)
    getLoginInfo(email)
      .then(loginInfo => {
        setEmail(loginInfo.emailAddress)
        persistLoginInfo(loginInfo)
        configureCognito(getPersistedLoginInfo())
        loginInfo.ssoEnabled
          ? (window.location.href = getSSOUrl(loginInfo))
          : setLoginFlow(LoginFlowState.ENTER_PASSWORD)
      })
      .catch(handleError)
      .finally(() => setRequestInFlight(false))
  }

  return (
    <div className={'flex flex-col gap-12'}>
      <LoginFlowContainer title={t('login.loginTitle')} instructions={t('login.loginInstruction')}>
        <LoginFormContainer onSubmit={handleEmailSubmit}>
          <div>
            <CustomLabel>{t('common.email')}</CustomLabel>
            <CustomInput
              id={'qa-login-email'}
              autoFocus={true}
              type={'email'}
              value={email}
              autoComplete={'username'}
              onChange={e => setEmail(e.currentTarget.value)}
            />
          </div>
          <Button
            id={'qa-login-next-button'}
            value={t('common.next')}
            disabled={requestInFlight || !emailRegExp.test(email)}
          />
        </LoginFormContainer>
      </LoginFlowContainer>
      <div className={'flex flex-col gap-2 text-center items-center'}>
        <div className={'text-90 text-gray-200'}>
          <CapitalizedText>{t('login.newUserQuestion')}</CapitalizedText>
          <CapitalizedText>{t('login.becomePro')}</CapitalizedText>
        </div>
        <BoldLink capitalize={true} onClick={() => history.push('/signup')}>
          {t('login.signUp.signUp')}
        </BoldLink>
      </div>
    </div>
  )
}

interface EnterPasswordProps {
  email: string
  setLoginFlow: (state: LoginFlowState) => void
  setCognitoUser: (user: CognitoUser) => void
  handleLogin: () => void
}

const EnterPassword = ({ email, setLoginFlow, setCognitoUser, handleLogin }: EnterPasswordProps) => {
  const { t } = useTranslation()
  const { setMessage } = useContext<MessageState>(MessageContext)
  const [requestInFlight, setRequestInFlight] = useState<boolean>(false)
  const [password, setPassword] = useState<string>('')

  const onSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault()
    setRequestInFlight(true)
    setMessage(null)
    login(email, password)
      .then(cognitoUser => {
        if (cognitoUser.challengeName === 'NEW_PASSWORD_REQUIRED') {
          setCognitoUser(cognitoUser)
          setLoginFlow(LoginFlowState.ACTIVATE_ACCOUNT)
          setRequestInFlight(false)
        } else {
          handleLogin()
        }
      })
      .catch(e => {
        setMessage({
          message: e.message,
          type: MessageType.ERROR
        })
        setPassword('')
        setRequestInFlight(false)
      })
  }

  return (
    <LoginFlowContainer
      title={!!email ? t('login.enterPasswordTitle') : t('login.loginTitle')}
      instructions={!email ? t('login.enterPasswordInstruction') : undefined}
    >
      <LoginFormContainer onSubmit={onSubmit}>
        <div>
          <CustomLabel>{t('common.email')}</CustomLabel>
          <CustomInput
            tabIndex={0}
            disabled={!!email}
            type={'email'}
            value={email}
            autoComplete={'username'}
            id={'qa-login-email'}
          />
        </div>
        <div>
          <CustomLabel>{t('login.password')}</CustomLabel>
          <CustomInput
            autoFocus={true}
            placeholder={''}
            type={'password'}
            value={password}
            autoComplete={'current-password'}
            onChange={e => setPassword(e.currentTarget.value)}
            id={'qa-login-password'}
          />
        </div>
        <Button id={'qa-login-login-button'} value={t('login.login')} disabled={requestInFlight || !password.length} />
      </LoginFormContainer>

      <div className={'flex flex-col items-center gap-3'}>
        <div className={'flex flex-col'}>
          <CapitalizedText className={'text-gray-300 text-center text-80'}>{t('login.helpLinkTitle')}</CapitalizedText>
          <Button
            value={t('login.helpLink')}
            buttonStyle={ButtonStyle.GHOST}
            size={ButtonSize.SMALL}
            clickHandler={() => setLoginFlow(LoginFlowState.RESET_PASSWORD)}
            disabled={requestInFlight}
          />
        </div>
        <LoginBackButton clickHandler={() => setLoginFlow(LoginFlowState.ENTER_EMAIL)} disabled={requestInFlight} />
      </div>
    </LoginFlowContainer>
  )
}

interface ActivationFlowProps {
  cognitoUser: CognitoUser | null
  setLoginFlow: (state: LoginFlowState) => void
  handleLogin: () => void
}

const ActivateAccount = ({ cognitoUser, setLoginFlow, handleLogin }: ActivationFlowProps) => {
  const { t } = useTranslation()
  const { setMessage } = useContext<MessageState>(MessageContext)
  const [newPassword, setNewPassword] = useState<string>('')
  const [confirmNewPassword, setConfirmNewPassword] = useState<string>('')
  const [requestInFlight, setRequestInFlight] = useState<boolean>(false)
  const [instruction, setInstruction] = useState('')

  useEffect(() => {
    newPassword !== '' && !passwordRegExp.test(newPassword)
      ? setInstruction(t('login.resetPassword.invalidPassword'))
      : newPassword !== confirmNewPassword
        ? setInstruction(t('login.resetPassword.passwordMismatch'))
        : setInstruction(t('login.passwordFact'))
  }, [newPassword, confirmNewPassword])

  const handleChangePassword = (e: React.SyntheticEvent) => {
    e.preventDefault()
    setRequestInFlight(true)
    setMessage(null)
    completeNewPassword(cognitoUser, newPassword)
      .then(() => handleLogin())
      .catch(e => {
        setNewPassword('')
        setConfirmNewPassword('')
        setMessage({
          message: e.message,
          type: MessageType.ERROR
        })
        setLoginFlow(LoginFlowState.ENTER_PASSWORD)
      })
      .finally(() => setRequestInFlight(false))
  }

  return (
    <LoginFlowContainer title={t('login.activationTitle')} instructions={instruction}>
      <LoginFormContainer onSubmit={handleChangePassword}>
        <div>
          <CustomLabel>{t('login.newPassword')}</CustomLabel>
          <CustomInput
            placeholder={''}
            type={'password'}
            value={newPassword}
            autoComplete={'new-password'}
            onChange={e => setNewPassword(e.currentTarget.value)}
          />
        </div>
        <div>
          <CustomLabel>{t('login.confirmNewPassword')}</CustomLabel>
          <CustomInput
            placeholder={''}
            type={'password'}
            value={confirmNewPassword}
            autoComplete={'new-password'}
            onChange={e => setConfirmNewPassword(e.currentTarget.value)}
          />
          <PasswordRequirements>{t('login.passwordRequirements')}</PasswordRequirements>
        </div>
        <Button
          value={t('login.activateButton')}
          disabled={
            requestInFlight ? true : passwordRegExp.test(newPassword) ? newPassword !== confirmNewPassword : true
          }
        />
      </LoginFormContainer>
    </LoginFlowContainer>
  )
}

interface ResetPasswordProps {
  email: string
  setLoginFlow: (state: LoginFlowState) => void
}

const ResetPassword = ({ email, setLoginFlow }: ResetPasswordProps) => {
  const { t } = useTranslation()
  const { setMessage } = useContext<MessageState>(MessageContext)
  const handleError = useErrorHandling()
  const [requestInFlight, setRequestInFlight] = useState<boolean>(false)
  const [newPassword, setNewPassword] = useState<string>('')
  const [confirmNewPassword, setConfirmNewPassword] = useState<string>('')
  const [code, setCode] = useState<string>('')
  const [codeSent, setCodeSent] = useState<boolean>(false)
  const [instruction, setInstruction] = useState('')

  useEffect(() => {
    code !== '' && !passwordRegExp.test(newPassword)
      ? setInstruction(t('login.resetPassword.invalidPassword'))
      : code !== '' && newPassword !== confirmNewPassword
        ? setInstruction(t('login.resetPassword.passwordMismatch'))
        : setInstruction(t('login.resetPassword.checkEmail'))
  }, [code, newPassword, confirmNewPassword, t])

  const resetPassword = () => {
    setMessage(null)
    setRequestInFlight(true)
    forgotPassword(email)
      .then(() => {
        setCodeSent(true)
        setMessage({
          message: t('login.resetPassword.codeInstructions', { email: email }),
          type: MessageType.SUCCESS
        })
      })
      .catch(e =>
        setMessage({
          message: e.message,
          type: MessageType.ERROR
        })
      )
      .finally(() => setRequestInFlight(false))
  }

  const requestTemporaryPassword = () => {
    setMessage(null)
    setRequestInFlight(true)
    resendActivationCode(email)
      .then(() => {
        setMessage({
          message: t('login.activationSuccess'),
          type: MessageType.SUCCESS
        })
        setLoginFlow(LoginFlowState.ENTER_PASSWORD)
      })
      .catch(handleError)
      .finally(() => setRequestInFlight(false))
  }

  const changePassword = (e: React.SyntheticEvent) => {
    e.preventDefault()
    setMessage(null)
    if (newPassword !== confirmNewPassword) {
      setMessage({
        message: t('login.passwordsDontMatch'),
        type: MessageType.ERROR
      })
      return
    }
    if (!passwordRegExp.test(newPassword)) {
      setMessage({
        message: t('login.passwordRequirements'),
        type: MessageType.ERROR
      })
      return
    }
    setRequestInFlight(true)
    forgotPasswordSubmit(email, code, newPassword)
      .then(() => {
        setLoginFlow(LoginFlowState.ENTER_PASSWORD)
        setMessage({
          message: t('login.recoverySuccess'),
          type: MessageType.SUCCESS
        })
      })
      .catch((error: AxiosError) => {
        if (error.name === 'CodeMismatchException')
          setMessage({
            message: t('login.resetPassword.invalidVerificationCode'),
            type: MessageType.ERROR
          })
        else handleError(error)
      })
      .finally(() => setRequestInFlight(false))
  }

  return codeSent ? (
    <LoginFlowContainer title={t('login.activationTitle')} instructions={instruction}>
      <LoginFormContainer onSubmit={changePassword}>
        <div>
          <CustomLabel>{t('login.resetPassword.verificationCode')}</CustomLabel>
          <CustomInput
            autoFocus
            placeholder={''}
            type={'text'}
            value={code}
            autoComplete={'one-time-code'}
            onChange={e => setCode(e.currentTarget.value)}
          />
        </div>
        <div>
          <CustomLabel>{t('login.newPassword')}</CustomLabel>
          <CustomInput
            placeholder={''}
            type={'password'}
            value={newPassword}
            autoComplete={'new-password'}
            onChange={e => setNewPassword(e.currentTarget.value)}
          />
        </div>
        <div>
          <CustomLabel>{t('login.confirmNewPassword')}</CustomLabel>
          <CustomInput
            placeholder={''}
            type={'password'}
            value={confirmNewPassword}
            autoComplete={'new-password'}
            onChange={e => setConfirmNewPassword(e.currentTarget.value)}
          />
          <PasswordRequirements>{t('login.passwordRequirements')}</PasswordRequirements>
        </div>
        <Button
          value={t('login.resetPassword.confirmPasswordButton')}
          size={ButtonSize.XSMALL}
          disabled={requestInFlight || !passwordRegExp.test(newPassword) || newPassword !== confirmNewPassword}
        />
      </LoginFormContainer>
      <LoginBackButton
        clickHandler={() => {
          setCodeSent(true)
        }}
        disabled={requestInFlight}
      />
    </LoginFlowContainer>
  ) : (
    <LoginFlowContainer title={t('login.resetPassword.title')} instructions={t('login.resetPassword.forgotPassword')}>
      <Button
        size={ButtonSize.XSMALL}
        value={t('login.resetPassword.resetOption')}
        clickHandler={() => resetPassword()}
        disabled={requestInFlight}
      />
      <Button
        size={ButtonSize.XSMALL}
        value={t('login.resetPassword.newTemporaryPassword')}
        clickHandler={() => requestTemporaryPassword()}
        disabled={requestInFlight}
      />
      <LoginBackButton clickHandler={() => setLoginFlow(LoginFlowState.ENTER_PASSWORD)} disabled={requestInFlight} />
    </LoginFlowContainer>
  )
}

const PasswordRequirements = styled(CapitalizedText)`
  ${tw`max-w-80 text-gray-200 text-75 mt-0.5`}
`

export const FederatedAuth = () => {
  const history = useHistory()
  const { t } = useTranslation()
  const { authInfo } = useContext(UserInfoContext)
  const { setMessage } = useContext<MessageState>(MessageContext)

  useEffect(() => {
    if (authInfo.isLoggedIn) history.push('/home')
    else {
      setMessage({
        message: t('error.general'),
        type: MessageType.ERROR
      })
      history.push('/login', { from: '/login' })
    }
  }, [authInfo, history, setMessage, t])

  return <FrontPageContainer />
}
