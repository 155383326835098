import { CancelToken } from 'axios'
import { client } from '../../client'

export const getAzureIntegrations = async (cancelToken: CancelToken): Promise<AzureIntegration[]> => {
  return await client.get<AzureIntegration[]>('admin/integrations/azure', { cancelToken }).then(resp =>
    resp.data.map(integration => ({
      ...integration,
      lastIntegrationAt: integration.lastIntegrationAt && new Date(integration.lastIntegrationAt),
      deletedAt: integration.deletedAt && new Date(integration.deletedAt)
    }))
  )
}

export const getAzureIntegrationDetails = async (
  azureTenantId: string,
  cancelToken: CancelToken
): Promise<AzureIntegration> => {
  return await client
    .get<AzureIntegration>(`admin/integrations/azure/${azureTenantId}`, { cancelToken })
    .then(resp => getDateFormattedResponse(resp.data))
}

export const createAzureIntegration = async (
  integrationDetails: AzureIntegration,
  cancelToken: CancelToken
): Promise<AzureIntegration> => {
  return await client
    .post<AzureIntegration>('admin/integrations/azure', integrationDetails, { cancelToken })
    .then(resp => getDateFormattedResponse(resp.data))
}

export const editAzureIntegration = async (
  integrationDetails: AzureIntegration,
  cancelToken: CancelToken
): Promise<AzureIntegration> => {
  return await client
    .put<AzureIntegration>(`admin/integrations/azure/${integrationDetails.azureTenantId}`, integrationDetails, {
      cancelToken
    })
    .then(resp => getDateFormattedResponse(resp.data))
}

export const deleteAzureIntegration = async (azureTenantId: string) => {
  return client.delete(`admin/integrations/azure/${azureTenantId}`)
}

export const recoverAzureIntegration = async (azureTenantId: string): Promise<AzureIntegration> => {
  return client
    .post<AzureIntegration>(`admin/integrations/azure/${azureTenantId}/recover`)
    .then(resp => getDateFormattedResponse(resp.data))
}

const getDateFormattedResponse = (resp: AzureIntegration) => {
  return {
    ...resp,
    lastIntegrationAt: resp.lastIntegrationAt && new Date(resp.lastIntegrationAt),
    lastIntegrations: {
      organization: resp.lastIntegrations.organization && new Date(resp.lastIntegrations.organization),
      costs: resp.lastIntegrations.costs && new Date(resp.lastIntegrations.costs),
      optimization: resp.lastIntegrations.optimization && new Date(resp.lastIntegrations.optimization),
      compliance: resp.lastIntegrations.compliance && new Date(resp.lastIntegrations.compliance),
      infra: resp.lastIntegrations.infra && new Date(resp.lastIntegrations.infra),
      sustainability: resp.lastIntegrations.sustainability && new Date(resp.lastIntegrations.sustainability)
    },
    deletedAt: resp.deletedAt && new Date(resp.deletedAt)
  }
}

export interface AzureIntegration {
  azureTenantId: string
  name: string
  lastIntegrationAt: Date | undefined
  lastIntegrations: AzureTenantIntegrations
  deletedAt: Date | undefined
}

interface AzureTenantIntegrations {
  organization: Date | undefined
  costs: Date | undefined
  optimization: Date | undefined
  compliance: Date | undefined
  infra: Date | undefined
  sustainability: Date | undefined
}
