import {
  IntegrationAccountStatusRow,
  IntegrationDetailRow,
  IntegrationDetailRowsWrapper,
  IntegrationDetailsHeader
} from '../Shared'
import React, { useContext, useState } from 'react'
import { AwsAccountType } from './AwsIntegrationDetails'
import {
  AwsConfigAccount,
  AwsIntegrationData,
  AwsMasterAccount,
  AwsSecurityAccount
} from '../../../../api/admin/integrations/aws'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { UnsavedChangesPromptModal } from '../../../shared/modal/UnsavedChangesPropmtModal'
import { CustomInput, CustomLabel } from '../../../shared/filters/FormComponents'
import { AdminTabContent } from '../../adminStyles'
import { UserInfoContext } from '../../../../state/context/UserInfoContext'

interface AwsIntegrationAccountsProps {
  selectedIntegration: AwsIntegrationData
  submitAction: (request: AwsMasterAccount) => void
  openAccountAddModal: (accountType: AwsAccountType, account?: AwsSecurityAccount | AwsConfigAccount) => void
  openAccountDeleteModal: (accountType: AwsAccountType, account: AwsSecurityAccount | AwsConfigAccount) => void
}

export const AwsIntegrationAccounts = ({
  selectedIntegration,
  submitAction,
  openAccountAddModal,
  openAccountDeleteModal
}: AwsIntegrationAccountsProps) => {
  return (
    <>
      <MasterAccountSection selectedIntegration={selectedIntegration} submitAction={submitAction} />
      <SecurityAccountSection
        selectedIntegration={selectedIntegration}
        openAccountAddModal={openAccountAddModal}
        openAccountDeleteModal={openAccountDeleteModal}
      />
      <ConfigAccountSection
        selectedIntegration={selectedIntegration}
        openAccountAddModal={openAccountAddModal}
        openAccountDeleteModal={openAccountDeleteModal}
      />
    </>
  )
}

interface EditMasterAccountProps {
  selectedIntegration: AwsIntegrationData
  submitAction: (request: AwsMasterAccount) => void
}

const MasterAccountSection = ({ selectedIntegration, submitAction }: EditMasterAccountProps) => {
  const { t } = useTranslation()
  const { authInfo } = useContext(UserInfoContext)
  const [isOnEditMode, setIsOnEditMode] = useState(false)
  const [defaultValues, setDefaultValues] = useState<AwsMasterAccount>({
    accountId: selectedIntegration.master.accountId,
    name: selectedIntegration.master.name,
    role: selectedIntegration.master.role
  } as AwsMasterAccount)
  const {
    handleSubmit,
    register,
    reset,
    formState: { isValid, isSubmitting, isDirty }
  } = useForm<AwsMasterAccount>({
    defaultValues: defaultValues
  })

  const onSubmit = (data: AwsMasterAccount) => {
    submitAction(data)
    setDefaultValues(data)
    reset(data)
  }

  return (
    <form>
      <UnsavedChangesPromptModal showModal={isDirty} />
      <AdminTabContent>
        <IntegrationDetailsHeader
          header={t('admin.integrations.aws.account', { type: AwsAccountType.MASTER })}
          headerAction={'formActions'}
          isEditable={!selectedIntegration.master.deletedAt}
          isOnEditMode={isOnEditMode}
          disableSubmit={!isValid || isSubmitting}
          isDirty={isDirty}
          submitAction={event => {
            if (isOnEditMode) {
              setIsOnEditMode(false)
              handleSubmit(onSubmit)()
            } else {
              setIsOnEditMode(true)
            }
            event.preventDefault()
          }}
          resetAction={() => reset(defaultValues)}
        />
        <IntegrationDetailRowsWrapper
          hasActions={false}
          detailRows={
            <>
              <IntegrationDetailRow
                label={t('admin.integrations.accountId')}
                value={selectedIntegration.master.accountId}
              />
              {isOnEditMode ? (
                <>
                  <div className={'p-2 bg-gray-500/20'}>
                    <CustomLabel>{t('admin.integrations.name')} *</CustomLabel>
                    <CustomInput
                      {...register('name', {
                        value: selectedIntegration.master.name,
                        required: true,
                        minLength: 1
                      })}
                    />
                  </div>
                  <div className={'p-2 bg-gray-500/20'}>
                    <CustomLabel>{t('admin.integrations.aws.role')} *</CustomLabel>
                    <CustomInput
                      {...register('role', {
                        value: selectedIntegration.master.role,
                        required: true,
                        minLength: 1
                      })}
                    />
                  </div>
                </>
              ) : (
                <>
                  <IntegrationDetailRow label={t('admin.integrations.name')} value={selectedIntegration.master.name} />
                  <IntegrationDetailRow
                    label={t('admin.integrations.aws.role')}
                    value={selectedIntegration.master.role}
                  />
                </>
              )}
              <IntegrationDetailRow label={t('admin.integrations.aws.externalId')} value={authInfo.tenant.id} />
            </>
          }
        />
      </AdminTabContent>
    </form>
  )
}

interface AccountSectionProps {
  selectedIntegration: AwsIntegrationData
  openAccountAddModal: (accountType: AwsAccountType, account?: AwsSecurityAccount | AwsConfigAccount) => void
  openAccountDeleteModal: (accountType: AwsAccountType, account: AwsSecurityAccount | AwsConfigAccount) => void
}

const SecurityAccountSection = ({
  selectedIntegration,
  openAccountAddModal,
  openAccountDeleteModal
}: AccountSectionProps) => {
  const { t } = useTranslation()
  return (
    <AdminTabContent>
      <IntegrationDetailsHeader
        header={t('admin.integrations.aws.account', { type: AwsAccountType.SECURITY })}
        headerAction={() => openAccountAddModal(AwsAccountType.SECURITY)}
      />
      {selectedIntegration.security.map(account => (
        <IntegrationDetailRowsWrapper
          key={`${account.accountId}-${account.region.value}`}
          hasActions={true}
          isRemoved={!!account.deletedAt}
          statusAction={() => {
            account.deletedAt
              ? openAccountAddModal(AwsAccountType.SECURITY, account)
              : openAccountDeleteModal(AwsAccountType.SECURITY, account)
          }}
          detailRows={
            <>
              <IntegrationDetailRow label={t('admin.integrations.accountId')} value={account.accountId} />
              <IntegrationDetailRow label={t('common.region_one')} value={account.region} />
              <IntegrationAccountStatusRow accountDeleteDate={account.deletedAt} />
            </>
          }
        />
      ))}
    </AdminTabContent>
  )
}

const ConfigAccountSection = ({
  selectedIntegration,
  openAccountAddModal,
  openAccountDeleteModal
}: AccountSectionProps) => {
  const { t } = useTranslation()
  return (
    <AdminTabContent>
      <IntegrationDetailsHeader
        header={t('admin.integrations.aws.account', { type: AwsAccountType.CONFIG })}
        headerAction={() => openAccountAddModal(AwsAccountType.CONFIG)}
      />
      {selectedIntegration.config.map(account => (
        <IntegrationDetailRowsWrapper
          key={`${account.accountId}-${account.aggregatorName}-${account.region.value}`}
          hasActions={true}
          isRemoved={!!account.deletedAt}
          statusAction={() => {
            account.deletedAt
              ? openAccountAddModal(AwsAccountType.CONFIG, account)
              : openAccountDeleteModal(AwsAccountType.CONFIG, account)
          }}
          detailRows={
            <>
              <IntegrationDetailRow label={t('admin.integrations.accountId')} value={account.accountId} />
              <IntegrationDetailRow label={t('common.region_one')} value={account.region} />
              <IntegrationDetailRow label={t('admin.integrations.aws.aggregatorName')} value={account.aggregatorName} />
              <IntegrationAccountStatusRow accountDeleteDate={account.deletedAt} />
            </>
          }
        />
      ))}
    </AdminTabContent>
  )
}
