import { GrayText, WhiteText } from '../../shared/TextComponents'
import { NotificationLimitsInput } from '../../shared/NotificationLimitsInput'
import { ChangeType, LimitsByCostAnomalyState } from '../../../api/notifications'
import { getCurrency } from '../../../utils/Currency'
import React, { useContext } from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'
import { useTranslation } from 'react-i18next'
import { UserInfoContext } from '../../../state/context/UserInfoContext'

interface CostAnomalyInputsProps {
  percent: LimitsByCostAnomalyState
  onPercentChange: (limits?: LimitsByCostAnomalyState) => void
  impact: LimitsByCostAnomalyState
  onImpactChange: (limits?: LimitsByCostAnomalyState) => void
  onBlur?: () => void
}

export const CostAnomalySettingsInputs = ({
  percent,
  onPercentChange,
  impact,
  onImpactChange,
  onBlur
}: CostAnomalyInputsProps) => {
  return (
    <>
      <CostAnomalyInputs type={'percent'} limits={percent} onChange={onPercentChange} onBlur={onBlur} />
      <CostAnomalyInputs type={'impact'} limits={impact} onChange={onImpactChange} onBlur={onBlur} />
    </>
  )
}

interface InputProps {
  limits: LimitsByCostAnomalyState
  onChange: (limits?: LimitsByCostAnomalyState) => void
  onBlur?: () => void
  type: 'percent' | 'impact'
}

export const CostAnomalyInputs = ({ limits, onChange, onBlur, type }: InputProps) => {
  const { t } = useTranslation()
  const { userSettings } = useContext(UserInfoContext)
  return (
    <div>
      <WhiteText className={'first-letter:capitalize text-80 font-semibold'}>
        {type === 'percent'
          ? t('settings.notifications.changePercent')
          : t('settings.notifications.monthlyImpact', { currency: getCurrency(userSettings.currency).symbol })}
      </WhiteText>
      <Row>
        <GrayText>{t('notifications.costAnomalies.decrease')}</GrayText>
        <NotificationLimitsInput
          limits={limits}
          onChange={onChange}
          onBlur={onBlur}
          min={type === 'percent' ? -100 : -99999}
          max={-1}
          type={ChangeType.DECREASING}
        />
      </Row>
      <Row>
        <GrayText>{t('notifications.costAnomalies.increase')}</GrayText>
        <NotificationLimitsInput
          limits={limits}
          onChange={onChange}
          onBlur={onBlur}
          min={1}
          max={type === 'percent' ? 100 : 99999}
          type={ChangeType.INCREASING}
        />
      </Row>
    </div>
  )
}

const Row = styled.div`
  ${tw`flex w-full justify-between items-center gap-5 py-1 text-90 first:pt-0 last:pb-0`}
`
