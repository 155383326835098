import { OptimizationTabCollapsibleSummary, OptimizationTabTitleRow } from '../OptimizationTabsSharedComponents'
import { formatNumber, roundNumber } from '../../../../utils/formats'
import { getCurrency } from '../../../../utils/Currency'
import { TreeBranch } from '../../../shared/TreeBranch'
import React from 'react'
import { GcpCommitment, GcpCommitmentsByCategory, GcpCommitmentsByDescription } from '../../../../api/optimization/gcp'
import { useTranslation } from 'react-i18next'
import { DataTableCell, DataTableLabel, DataTableRow, DataTableValue } from '../../../shared/DataTableSharedComponents'
import { ClipboardCopy } from '../../../shared/ClipboardCopy'
import { TabDataTableRegionCell } from '../../../shared/tabs/TabSharedComponents'

interface GcpTabCommitmentsProps {
  id: string
  commitment: GcpCommitmentsByCategory
}

export const GcpTabCommitment = ({ id, commitment }: GcpTabCommitmentsProps) => {
  const { t } = useTranslation()
  return (
    <TreeBranch
      id={id}
      headerData={
        <OptimizationTabCollapsibleSummary
          headerLeft={commitment.category.toLowerCase().replaceAll('_', ' ')}
          headerRight={t('optimization.tabs.common.annualSavingsCommitments', {
            count: commitment.recommendationCount,
            amount: formatNumber(commitment.potentialAnnualSavings.amount),
            currency: getCurrency(commitment.potentialAnnualSavings.currency).symbol
          })}
        />
      }
      contentData={commitment.recommendations.map(recommendationGroup => (
        <RecommendationsByCategory key={recommendationGroup.description} recommendationGroup={recommendationGroup} />
      ))}
    />
  )
}

interface CommitmentsProps {
  recommendationGroup: GcpCommitmentsByDescription
}

const RecommendationsByCategory = ({ recommendationGroup }: CommitmentsProps) => {
  const { t } = useTranslation()
  return (
    <TreeBranch
      depth={1}
      headerData={
        <OptimizationTabCollapsibleSummary
          headerLeft={recommendationGroup.description}
          useExclamationMark={recommendationGroup.potentialAnnualSavings.amount > 400}
          headerRight={t('optimization.tabs.common.annualUpToSavings', {
            amount: formatNumber(recommendationGroup.potentialAnnualSavings.amount),
            currency: getCurrency(recommendationGroup.potentialAnnualSavings.currency).symbol
          })}
        />
      }
      contentData={
        <>
          <OptimizationTabTitleRow
            widerIndex={[0, 1, 2, 3]}
            labels={[
              t('vendors.GCP.projectPhrase_one'),
              t('optimization.tabs.gcp.target'),
              t('common.region_one'),
              recommendationGroup.recommendations.some(r => r.recommendedPurchase !== null) &&
                t('optimization.tabs.common.recommendedPurchase'),
              t('optimization.tabs.common.monthlySavings'),
              t('optimization.tabs.common.annualSavings')
            ]}
          />
          {recommendationGroup.recommendations.map((recommendation, index) => (
            <TreeBranch
              key={index}
              depth={2}
              headerData={<CommitmentRow recommendation={recommendation} />}
              contentData={false}
            />
          ))}
        </>
      }
    />
  )
}

interface CommitmentRowProps {
  recommendation: GcpCommitment
}

const CommitmentRow = ({ recommendation }: CommitmentRowProps) => {
  const { t } = useTranslation()
  const columns = 10 - (recommendation.recommendedPurchase ? 0 : 1) - (recommendation.quantity ? 0 : 1)
  const currency = getCurrency(recommendation.currency)

  return (
    <DataTableRow styles={`grid-cols-1 xl:grid-cols-${columns}`}>
      <DataTableCell styles={'xl:col-span-2'}>
        <DataTableLabel>{t('vendors.GCP.projectPhrase_one')}</DataTableLabel>
        <DataTableValue>
          <ClipboardCopy
            copyText={recommendation.project.name}
            secondCopyText={
              recommendation.project.id !== recommendation.project.name ? recommendation.project.id : undefined
            }
            limit={30}
          />
        </DataTableValue>
      </DataTableCell>
      <DataTableCell styles={'xl:col-span-2'}>
        <DataTableLabel>{t('optimization.tabs.gcp.target')}</DataTableLabel>
        <DataTableValue>
          <ClipboardCopy
            customDisplayText={recommendation.target.split('/').pop()}
            copyText={recommendation.target}
            limit={20}
          />
        </DataTableValue>
      </DataTableCell>
      <TabDataTableRegionCell
        regionName={recommendation.region.name}
        regionId={recommendation.region.id}
        styles={'xl:col-span-2'}
      />
      {recommendation.recommendedPurchase && (
        <DataTableCell styles={'xl:col-span-2'}>
          <DataTableLabel>{t('optimization.tabs.common.recommendedPurchase')}</DataTableLabel>
          <DataTableValue>{`${recommendation.quantity}${recommendation.quantityUnit ? ` ${recommendation.quantityUnit} ` : ' '}${recommendation.recommendedPurchase.toLowerCase()}`}</DataTableValue>
        </DataTableCell>
      )}
      <DataTableCell>
        <DataTableLabel>{t('optimization.tabs.common.monthlySavings')}</DataTableLabel>
        <DataTableValue>
          {formatNumber(roundNumber(recommendation.monthlySavings, 0))} {currency.symbol}
        </DataTableValue>
      </DataTableCell>
      <DataTableCell>
        <DataTableLabel>{t('optimization.tabs.common.annualSavings')}</DataTableLabel>
        <DataTableValue>
          {formatNumber(roundNumber(recommendation.annualSavings, 0))} {currency.symbol}
        </DataTableValue>
      </DataTableCell>
    </DataTableRow>
  )
}
