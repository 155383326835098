import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useErrorHandling } from '../../hooks/handleError'
import { useCancelToken } from '../../api/client'
import { getTicketSummary, TicketStatus, TicketSummary } from '../../api/tickets'
import { Loading } from '../shared/Loading'
import { InfoTooltip } from '../shared/tooltips/InfoTooltip'
import { useMousePosition } from '../../hooks/useMousePosition'
import { MissingDataNotification } from '../shared/MissingDataNotification'
import { DataContainer } from '../shared/containers/DataContainer'
import { GradientText, GrayText, Heading2 } from '../shared/TextComponents'
import { CountUp } from '../shared/CountUp'
import { roundNumber } from '../../utils/formats'

export const TicketsHeader = () => {
  const handleError = useErrorHandling()
  const { t } = useTranslation()
  const { createCancelToken } = useCancelToken()
  const mousePos = useMousePosition()
  const [ticketCount, setTicketCount] = useState<TicketSummary | null>(null)
  const [loading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    setLoading(true)
    const cancelToken = createCancelToken()
    getTicketSummary(cancelToken.token)
      .then(setTicketCount)
      .catch(handleError)
      .finally(() => setLoading(false))

    return () => {
      cancelToken.cancel()
      setLoading(false)
      setTicketCount(null)
    }
  }, [setTicketCount, handleError, createCancelToken])

  if (loading || !ticketCount)
    return (
      <DataContainer>
        {loading ? <Loading paddingY={'1rem'} /> : <MissingDataNotification justify={'center'} />}
      </DataContainer>
    )

  return (
    <DataContainer looseSpacing={true}>
      <div className={'flex flex-col w-full gap-4'}>
        <div className={'flex gap-2 items-center justify-center lg:justify-start'}>
          <Heading2 className={'max-w-max'}>{t('tickets.header.title')}</Heading2>
          <InfoTooltip useIcon={true} styles={'max-w-80'} x={mousePos.x} y={mousePos.y}>
            {t('tickets.header.info')}
          </InfoTooltip>
        </div>
        <div className={'flex flex-row items-center justify-center lg:justify-start gap-10'}>
          <HeaderCounter value={ticketCount.open} description={TicketStatus.OPEN} />
          <HeaderCounter value={ticketCount.pending} description={TicketStatus.PENDING} />
          <HeaderCounter value={ticketCount.resolved} description={TicketStatus.RESOLVED} />
          <HeaderCounter value={ticketCount.closed} description={TicketStatus.CLOSED} />
        </div>
      </div>
    </DataContainer>
  )
}

interface HeaderCounterProps {
  value: number
  description: string
  id?: string
}

export const HeaderCounter = ({ value, description, id }: HeaderCounterProps) => {
  return (
    <div className={'flex flex-col min-w-20 items-center justify-center'}>
      <GradientText className={'font-black text-200 from-gray-50 to-gray-200'}>
        <CountUp id={id} countTo={roundNumber(value, 0)} />
      </GradientText>
      <GrayText className={'text-90'}>{description}</GrayText>
    </div>
  )
}
