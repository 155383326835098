import React, { useContext, useState } from 'react'
import { FrontPageContainer } from './FrontPageContainer'
import { useForm } from 'react-hook-form'
import { handleMarketplaceSignUp, handleSignUp, SignUpInfo } from '../../api/auth'
import { CustomInput, CustomLabel } from '../shared/filters/FormComponents'
import { Button, ButtonSize } from '../shared/buttons/Button'
import { useTranslation } from 'react-i18next'
import { MessageContext, MessageType } from '../../state/context/MessageContext'
import { testCognitoEmail } from '../../utils/formats'
import { useErrorHandling } from '../../hooks/handleError'
import { useCancelToken } from '../../api/client'
import { useHistory, useParams } from 'react-router-dom'
import { LoginBackButton, LoginFlowContainer, LoginFormContainer } from './Shared'
import { CapitalizedText } from '../shared/TextComponents'
import { setSignupEmail } from '../../state/storage'

export const SignUp = () => {
  const { t } = useTranslation()
  const { setMessage } = useContext(MessageContext)
  const handleError = useErrorHandling()
  const { createCancelToken } = useCancelToken()
  const history = useHistory()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const { tenantId } = useParams<{ tenantId: string }>()

  const submitAction = (request: SignUpInfo) => {
    setIsSubmitting(true)
    Promise.any([
      tenantId
        ? handleMarketplaceSignUp(tenantId, request, createCancelToken().token)
        : handleSignUp(request, createCancelToken().token)
    ])
      .then(email => {
        setSignupEmail(email)
        setMessage({
          type: MessageType.SUCCESS,
          message: t('login.signUp.signUpSuccess', { email: email })
        })
        history.push(`/login?signup=true`)
      })
      .catch(handleError)
      .finally(() => setIsSubmitting(false))
  }

  return (
    <FrontPageContainer>
      <LoginFlowContainer title={`${t('login.signUp.welcome')}  🚀`} instructions={t('login.signUp.setUpAccount')}>
        <div className={'flex flex-col gap-6 w-full'}>
          <SignUpForm isSubmitting={isSubmitting} submitAction={submitAction} isMarketplaceSignUp={tenantId !== ''} />

          <div className={'flex flex-col items-center text-center'}>
            {tenantId === '' ? (
              <>
                <CapitalizedText className={'w-full text-90 text-gray-200'}>
                  {t('login.signUp.spotterPricing_1')}
                </CapitalizedText>
                <CapitalizedText className={'w-full text-90 text-gray-200'}>
                  {t('login.signUp.spotterPricing_2')}
                  <a
                    className={'text-primary-500 hover:text-primary-300'}
                    href={'http://cloud2.fi/spotter'}
                    target={'_blank'}
                    rel='noreferrer'
                  >
                    {t('login.signUp.pricingCalculator')}
                  </a>
                  .
                </CapitalizedText>
              </>
            ) : (
              <CapitalizedText className={'w-full text-90 text-gray-200'}>
                {t('login.signUp.learnMoreAbout')}
                <a
                  className={'text-primary-500 hover:text-primary-300'}
                  href={'http://cloud2.fi/spotter'}
                  target={'_blank'}
                  rel='noreferrer'
                >
                  {t('projectName')}
                </a>
                .
              </CapitalizedText>
            )}
          </div>
        </div>
      </LoginFlowContainer>
    </FrontPageContainer>
  )
}

interface SignUpFormProps {
  isSubmitting: boolean
  submitAction: (request: SignUpInfo) => void
  isMarketplaceSignUp: boolean
}

const SignUpForm = ({ submitAction, isSubmitting, isMarketplaceSignUp }: SignUpFormProps) => {
  const { t } = useTranslation()
  const history = useHistory()
  const {
    handleSubmit,
    register,
    formState: { isValid }
  } = useForm<SignUpInfo>()

  const onSubmit = (data: SignUpInfo) => {
    submitAction(data)
  }

  return (
    <div className={'flex flex-col items-center gap-4'}>
      <LoginFormContainer>
        <div>
          <CustomLabel>{t('login.signUp.organizationName')}</CustomLabel>
          <CustomInput
            {...register('tenantName', {
              required: true,
              validate: value => value.trim().length > 0
            })}
            autoFocus={true}
          />
        </div>
        <div>
          <CustomLabel>{t('common.firstName')}</CustomLabel>
          <CustomInput
            {...register('firstName', {
              required: true,
              validate: value => value.trim().length > 0
            })}
          />
        </div>
        <div>
          <CustomLabel>{t('common.lastName')}</CustomLabel>
          <CustomInput
            {...register('lastName', {
              required: true,
              validate: value => value.trim().length > 0
            })}
          />
        </div>
        <div>
          <CustomLabel>{t('common.emailAddress')}</CustomLabel>
          <CustomInput
            type={'email'}
            {...register('emailAddress', {
              required: true,
              validate: value => testCognitoEmail(value)
            })}
          />
        </div>
        <Button
          size={ButtonSize.XSMALL}
          disabled={!isValid || isSubmitting}
          value={t('login.signUp.createAccount')}
          clickHandler={handleSubmit(onSubmit)}
        />
      </LoginFormContainer>
      {!isMarketplaceSignUp && <LoginBackButton clickHandler={() => history.push('/login')} disabled={isSubmitting} />}
    </div>
  )
}
