import { useEffect, useState } from 'react'

export const useScreenSize = (): ScreenSize => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth)
  const [screenHeight, setScreenHeight] = useState(window.innerHeight)
  useEffect(() => {
    const getScreenWidth = () => {
      setScreenWidth(window.innerWidth)
    }
    const getScreenHeight = () => {
      setScreenHeight(window.innerHeight)
    }
    window.addEventListener('resize', getScreenWidth)
    window.addEventListener('resize', getScreenHeight)

    return () => {
      window.removeEventListener('resize', getScreenHeight)
      window.removeEventListener('resize', getScreenWidth)
    }
  })

  return { width: screenWidth, height: screenHeight }
}

interface ScreenSize {
  width: number
  height: number
}
