import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'
import { CapitalizedText, GrayText } from '../TextComponents'
import { ScrollContainer } from './ScrollContainer'
import { useScreenSize } from '../../../hooks/useScreenSize'
import { twLg } from '../../../design/constants'
import { CustomIcon, IconType } from '../CustomIcon'
import arrow from '../../../assets/svg/directional/caret-heavy.svg'
import Select from 'react-select'
import { useTranslation } from 'react-i18next'
import { SelectDropdownIndicator, selectMenuListStyles, selectOptionStyles } from '../filters/ReactSelectStyles'
import { FilterOption } from '../filters/FilterSelect'

interface TableTitle {
  title: string
  sortable?: boolean
}

export type ScrollTableProps = {
  smallScreenTitle?: React.ReactNode
  rows: React.ReactNode[]
  customColumns?: string
  styles?: string
  spacing?: 'small' | 'medium' | 'large'
} & (
  | {
      sortable: true
      titles: (string | TableTitle)[]
      sortingTitle: string
      setSortingTitle: (sorting: string) => void
      sortAsc: boolean
      setSortAsc: (sortAsc: boolean) => void
    }
  | {
      sortable: false
      titles: string[]
      sortingTitle?: never
      setSortingTitle?: never
      sortAsc?: never
      setSortAsc?: never
    }
)

export const ScrollTable = ({
  titles,
  sortable,
  sortingTitle,
  setSortingTitle,
  sortAsc,
  setSortAsc,
  smallScreenTitle,
  rows,
  customColumns,
  styles,
  spacing = 'medium'
}: ScrollTableProps) => {
  const columnCount = titles.length
  const screenSize = useScreenSize()
  const [tableTitles, setTableTitles] = useState<TableTitle[]>([])
  const [options, setOptions] = useState<FilterOption[]>([])

  useEffect(() => {
    setTableTitles(
      titles.map(title =>
        typeof title === 'string' ? { title, sortable: sortable } : { ...title, sortable: title.sortable ?? true }
      )
    )
    setOptions(
      tableTitles
        .filter(title => title.sortable)
        .map(title => ({
          label: title.title,
          value: title.title
        }))
    )
  }, [sortingTitle, titles])

  return (
    <div className={'rounded border border-gray-500 w-full'}>
      <TableTitleRow
        columnCount={columnCount}
        customColumns={customColumns}
        showOnSmallScreen={!!smallScreenTitle || sortable}
        spacing={spacing}
      >
        {screenSize.width >= twLg ? (
          tableTitles.map((title, index) => (
            <div key={index} className={'flex items-center'}>
              <CapitalizedText>{title.title}</CapitalizedText>
              {sortable && title.sortable && (
                <div className={'transition-all ease duration-500 ml-2'}>
                  <SortingButtons
                    sortedByTitle={title.title === sortingTitle}
                    title={title.title}
                    setSortingTitle={setSortingTitle}
                    sortAsc={sortAsc}
                    setSortAsc={setSortAsc}
                  />
                </div>
              )}
            </div>
          ))
        ) : (
          <>
            {smallScreenTitle}
            {sortable && tableTitles.some(t => t.sortable) && options.length && (
              <SortingMenu
                options={options}
                sortingTitle={sortingTitle}
                setSortingTitle={setSortingTitle}
                sortAsc={sortAsc}
                setSortAsc={setSortAsc}
              />
            )}
          </>
        )}
      </TableTitleRow>
      <ScrollContainer className={`${styles ?? ''} w-full divide-y divide-gray-500`}>
        {rows.map((row, index) => (
          <TableDataRow key={index} columnCount={columnCount} customColumns={customColumns} spacing={spacing}>
            {row}
          </TableDataRow>
        ))}
      </ScrollContainer>
    </div>
  )
}

interface SortingMenuProps {
  options: FilterOption[]
  sortingTitle: string
  setSortingTitle: (sorting: string) => void
  sortAsc: boolean
  setSortAsc: (sortAsc: boolean) => void
}

export const SortingMenu = ({ options, sortingTitle, setSortingTitle, sortAsc, setSortAsc }: SortingMenuProps) => {
  const { t } = useTranslation()
  const [selectedTitle, setSelectedTitle] = useState(sortingTitle)

  useEffect(() => {
    setSelectedTitle(sortingTitle)
  }, [sortingTitle])

  return (
    <SelectWrapper key={sortingTitle}>
      <GrayText className={'min-w-max'}>{t('common.sortBy')}</GrayText>
      <Select
        unstyled
        isSearchable={false}
        isClearable={false}
        classNames={{
          control: state =>
            `group w-fit min-w-50 pl-3 rounded-sm border-b border-gray-400 hover:cursor-pointer hover:border-gray-300 ${state.isFocused && 'border-gray-300'}`,
          menuList: () => selectMenuListStyles,
          option: () => selectOptionStyles + ' min-w-max',
          valueContainer: () => 'text-gray-50 font-semibold'
        }}
        formatOptionLabel={option => <CapitalizedText>{option.label}</CapitalizedText>}
        defaultValue={{ label: sortingTitle, value: sortingTitle }}
        hideSelectedOptions={true}
        options={options}
        onChange={selected => {
          if (selected) {
            sortingTitle === selected.value && setSortAsc(!sortAsc)
            setSortingTitle(selected.value)
            setSelectedTitle(selected.value)
          }
        }}
        components={{
          DropdownIndicator: SelectDropdownIndicator
        }}
      />
      <SortingButtons
        sortedByTitle={selectedTitle === sortingTitle}
        title={selectedTitle ?? sortingTitle}
        sortAsc={sortAsc}
        setSortAsc={setSortAsc}
      />
    </SelectWrapper>
  )
}

const SelectWrapper = styled.div`
  ${tw`flex w-full gap-2 items-center text-90`}
`

interface SortingButtonsProps {
  sortedByTitle: boolean
  title: string
  setSortingTitle?: (sorting: string) => void
  sortAsc?: boolean
  setSortAsc?: (sortAsc: boolean) => void
}

const SortingButtons = ({ sortedByTitle, title, setSortingTitle, sortAsc, setSortAsc }: SortingButtonsProps) => {
  const screenSize = useScreenSize()
  if (sortedByTitle || screenSize.width < twLg)
    return (
      <CustomIcon
        styles={`w-6 h-6 min-w-6 bg-gray-50 transition-all ease-in-out duration-200 ${sortAsc && 'rotate-180'}`}
        iconType={IconType.VECTOR}
        path={arrow}
        onClick={() => {
          setSortAsc && setSortAsc(!sortAsc)
        }}
      />
    )

  return (
    <div
      className={'group'}
      onClick={() => {
        setSortingTitle && setSortingTitle(title)
        setSortAsc && setSortAsc(true)
      }}
    >
      <CustomIcon
        styles={'w-5 h-5 min-w-5 -mb-1.5 bg-gray-200 rotate-180 group-hover:bg-gray-50'}
        iconType={IconType.VECTOR}
        path={arrow}
      />
      <CustomIcon
        styles={'w-5 h-5 min-w-5 -mt-1.5 bg-gray-200 group-hover:bg-gray-50'}
        iconType={IconType.VECTOR}
        path={arrow}
      />
    </div>
  )
}

interface CustomTableRowProps {
  columnCount: number
  customColumns?: string
  showOnSmallScreen?: boolean
  spacing: 'small' | 'medium' | 'large'
}

const TableRow = styled.div<CustomTableRowProps>`
  ${tw` w-full items-center gap-x-8`}
  ${({ spacing }) =>
    spacing === 'small'
      ? tw`px-4 py-2 gap-x-6 md:gap-x-8`
      : spacing === 'medium'
        ? tw`p-4 lg:px-5 lg:py-4`
        : tw`p-4 lg:p-6`}
  grid-template-columns: ${({ customColumns, columnCount }) =>
    customColumns ? customColumns : `repeat(${columnCount}, 1fr)`};
`

const TableTitleRow = styled(TableRow)<CustomTableRowProps>`
  ${tw`justify-between border-b border-gray-500 text-gray-200 pr-6 bg-opacity-30 bg-gray-500 lg:grid`}
  ${({ showOnSmallScreen }) => !showOnSmallScreen && tw`hidden`}
`

const TableDataRow = styled(TableRow)<CustomTableRowProps>`
  ${tw`flex flex-col justify-between text-gray-100/90 lg:grid lg:gap-8`}
  div:nth-child(${({ columnCount }) => columnCount}) {
    ${tw`border-none`}
  }
`

export const ScrollTableRowItem = styled.div`
  ${tw`flex w-full items-center justify-between gap-8 p-2 break-all border-b border-dotted border-gray-500 lg:border-none lg:p-0 last:border-none`}
`
