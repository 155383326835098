import React from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'
import { DataContainer } from '../components/shared/containers/DataContainer'

interface SubNavLayoutProps {
  children: React.ReactNode
}

export const SubLayout = ({ children }: SubNavLayoutProps) => {
  return (
    <DataContainer looseSpacing={false} subNav={true}>
      <div className={'w-full h-full lg:min-h-[92vh]'}>{children}</div>
    </DataContainer>
  )
}

interface SubNavContentWrapperProps {
  divider?: boolean
}

export const SubNavContentWrapper = styled.div<SubNavContentWrapperProps>`
  ${tw`flex flex-col w-full gap-5 py-8 min-h-[70vh] px-3 md:px-10`}
  ${({ divider }) => (divider ? tw`divide-y divide-gray-500 gap-0` : tw`gap-0 lg:gap-8`)}
  > div {
    ${({ divider }) => divider && tw`py-8 first:pt-0 last:pb-0`}
  }
`
