import React from 'react'
import { CapitalizedText } from './TextComponents'
import { useTranslation } from 'react-i18next'

interface StepMeterProps {
  currentStep: number
  totalSteps: number
  description?: string
}

export const StepMeter = ({ currentStep, totalSteps, description }: StepMeterProps) => {
  const { t } = useTranslation()

  return (
    <div className={'flex flex-col gap-1 w-full'}>
      <div className={'flex gap-1.5 text-left text-gray-200 text-80 font-normal'}>
        <CapitalizedText>{t('common.steps', { current: currentStep, total: totalSteps })}</CapitalizedText>
        {description && (
          <>
            -<CapitalizedText>{description}</CapitalizedText>
          </>
        )}
      </div>
      <div className={'flex justify-evenly w-full gap-3'}>
        {[...Array(totalSteps)].map((_, index) => (
          <div
            key={index}
            className={`w-full h-2 rounded-full ${
              currentStep === index + 1
                ? 'bg-primary-500'
                : index + 1 < currentStep
                  ? 'bg-primary-500/40'
                  : 'bg-gray-500/40'
            }`}
          />
        ))}
      </div>
    </div>
  )
}
