import React, { useEffect, useState } from 'react'
import { CSSTransition } from 'react-transition-group'
import styled from 'styled-components'
import tw from 'twin.macro'
import caret from '../../assets/svg/directional/caret-heavy.svg'
import { CustomIcon, IconType } from './CustomIcon'

export const ScrollToTop = () => {
  const [isVisible, setIsVisible] = useState(true)
  const nodeRef = React.useRef(null)

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }

  useEffect(() => {
    const toggleVisibility = () => {
      setIsVisible(window.scrollY > 400)
    }
    window.addEventListener('scroll', toggleVisibility)
    return () => window.removeEventListener('scroll', toggleVisibility)
  }, [])

  return (
    <CSSTransition in={isVisible} timeout={100} nodeRef={nodeRef}>
      <Wrapper ref={nodeRef} onClick={scrollToTop} className={'group backdrop-blur-sm'}>
        <CustomIcon path={caret} iconType={IconType.VECTOR} styles={'bg-gray-50 w-6 h-6 rotate-180'} />
      </Wrapper>
    </CSSTransition>
  )
}

const Wrapper = styled.div`
  ${tw`sticky w-max m-auto inset-x-0 bottom-0 shadow-sm bg-gray-500/60 hover:bg-gray-500/70 z-40 py-2 px-6 cursor-pointer opacity-0 bg-opacity-100 rounded-full transition-all duration-100`}
  &.enter-done {
    ${tw`opacity-100 bottom-10`}
  }

  &.exit-done {
    ${tw`opacity-0`}
  }
`
