import { useTranslation } from 'react-i18next'
import { TreeBranch } from '../../../shared/TreeBranch'
import { Vendor } from '../../../../utils/vendors'
import { OptimizationTabCollapsibleSummary, OptimizationTabTitleRow } from '../OptimizationTabsSharedComponents'
import { formatNumber, roundNumber } from '../../../../utils/formats'
import { DataTableCell, DataTableLabel, DataTableRow, DataTableValue } from '../../../shared/DataTableSharedComponents'
import { ClipboardCopy } from '../../../shared/ClipboardCopy'
import React from 'react'
import { TabDataTableRegionCell } from '../../../shared/tabs/TabSharedComponents'
import { AzureRightsizing, AzureRightsizingDetails } from '../../../../api/optimization/azure'
import { getCurrency } from '../../../../utils/Currency'

interface RightsizingProps {
  data: AzureRightsizing[]
}

export const AzureTabRightsizing = ({ data }: RightsizingProps) => {
  const { t } = useTranslation()

  return (
    <>
      <TreeBranch
        id={`qa-optimization-${Vendor.AZURE}-tab-right-sizing`}
        headerData={
          <OptimizationTabCollapsibleSummary
            headerLeft={t('optimization.common.rightsizing')}
            headerRight={t('optimization.tabs.azure.rightsizingAnnualSavings', {
              count: data.reduce((acc, curr) => acc + curr.vmCount, 0),
              amount: formatNumber(data.reduce((acc, curr) => acc + curr.annualSavings.amount, 0)),
              currency: getCurrency(data[0].annualSavings.currency).symbol
            })}
          />
        }
        contentData={<RightsizingBySub data={data} />}
      />
    </>
  )
}

interface SecondLevelProps {
  data: AzureRightsizing[]
}

const RightsizingBySub = ({ data }: SecondLevelProps) => {
  const { t } = useTranslation()

  return (
    <>
      {data.map((recommendationGroup, index) => (
        <TreeBranch
          depth={1}
          id={`qa-optimization-${Vendor.AZURE}-tab-content-${index}`}
          key={index}
          headerData={
            <OptimizationTabCollapsibleSummary
              headerLeft={
                <ClipboardCopy
                  copyText={recommendationGroup.sub.name}
                  secondCopyText={recommendationGroup.sub.id}
                  limit={30}
                  restrictClickArea={true}
                />
              }
              headerRight={t(`optimization.tabs.azure.rightsizingAnnualSavings`, {
                count: recommendationGroup.vmCount,
                amount: formatNumber(recommendationGroup.annualSavings.amount),
                currency: getCurrency(recommendationGroup.annualSavings.currency).symbol
              })}
              useExclamationMark={recommendationGroup.annualSavings.amount > 400}
            />
          }
          contentData={
            <>
              <OptimizationTabTitleRow
                widerIndex={[0]}
                labels={[
                  t('optimization.tabs.common.impactedResource'),
                  t('common.region_one'),
                  t('optimization.tabs.azure.currentVm'),
                  t('optimization.tabs.azure.recommendedVm'),
                  t('optimization.tabs.common.monthlySavings'),
                  t('optimization.tabs.common.annualSavings')
                ]}
              />
              {recommendationGroup.recommendations.map((recommendation, i) => (
                <TreeBranch
                  key={i}
                  depth={2}
                  headerData={<RightsizingRow recommendation={recommendation} />}
                  contentData={false}
                />
              ))}
            </>
          }
        />
      ))}
    </>
  )
}

interface RightsizingRowProps {
  recommendation: AzureRightsizingDetails
}

const RightsizingRow = ({ recommendation }: RightsizingRowProps) => {
  const { t } = useTranslation()
  const currency = getCurrency(recommendation.currency)

  return (
    <DataTableRow styles={`grid-cols-1 xl:grid-cols-7`}>
      <DataTableCell styles={'xl:col-span-2'}>
        <DataTableLabel>{t('optimization.tabs.common.impactedResource')}</DataTableLabel>
        <DataTableValue>
          <ClipboardCopy copyText={recommendation.resourceName} limit={30} />
        </DataTableValue>
      </DataTableCell>
      <TabDataTableRegionCell regionName={recommendation.region?.name} regionId={recommendation.region?.id} />
      <DataTableCell>
        <DataTableLabel>{t('optimization.tabs.azure.currentVm')}</DataTableLabel>
        <DataTableValue>{recommendation.currentVm ? recommendation.currentVm : '-'}</DataTableValue>
      </DataTableCell>
      <DataTableCell>
        <DataTableLabel>{t('optimization.tabs.azure.recommendedVm')}</DataTableLabel>
        <DataTableValue>{recommendation.targetVm}</DataTableValue>
      </DataTableCell>
      <DataTableCell>
        <DataTableLabel>{t('optimization.tabs.common.monthlySavings')}</DataTableLabel>
        <DataTableValue>
          {formatNumber(roundNumber(recommendation.monthlySavings, 0))} {currency.symbol}
        </DataTableValue>
      </DataTableCell>
      <DataTableCell>
        <DataTableLabel>{t('optimization.tabs.common.annualSavings')}</DataTableLabel>
        <DataTableValue>
          {formatNumber(roundNumber(recommendation.annualSavings, 0))} {currency.symbol}
        </DataTableValue>
      </DataTableCell>
    </DataTableRow>
  )
}
