import { useTranslation } from 'react-i18next'
import { Vendor } from '../../../utils/vendors'
import {
  DataTableCell,
  DataTableLabel,
  DataTableRow,
  DataTableValue,
  TabInfoBox,
  xAlignment
} from '../../shared/DataTableSharedComponents'
import { TreeBranch } from '../../shared/TreeBranch'
import { ClipboardCopy } from '../../shared/ClipboardCopy'
import { SeverityLevel, SeverityLevelIndicator } from '../../shared/indicators/SeverityLevelIndicator'
import { ComplianceStatusIndicator, StatusIndicatorSize } from '../ComplianceStatusIndicator'
import React from 'react'
import { TabDataTableRegionCell } from '../../shared/tabs/TabSharedComponents'
import {
  AwsComplianceFindingsByControl,
  AwsFinding,
  AzureComplianceFindingsByControl,
  AzureFinding,
  GcpComplianceFindingsByControl,
  GcpFinding
} from '../../../api/compliance/tabs'
import { formatDate } from '../../../utils/formats'
import { CapitalizedText, GrayText, WhiteText } from '../../shared/TextComponents'
import { CustomIcon, IconType } from '../../shared/CustomIcon'
import timerIcon from '../../../assets/svg/objects/timer.svg'
import { useScreenSize } from '../../../hooks/useScreenSize'
import { twXl } from '../../../design/constants'
import styled from 'styled-components'
import tw from 'twin.macro'

interface ComplianceFindingsProps {
  vendor: Vendor
  findingsByControl: AwsComplianceFindingsByControl | AzureComplianceFindingsByControl | GcpComplianceFindingsByControl
}

export const ComplianceControlFindings = ({ vendor, findingsByControl }: ComplianceFindingsProps) => {
  const { t } = useTranslation()

  return (
    <>
      <TreeBranch
        headerData={
          <>
            {'explanation' in findingsByControl && (
              <TabInfoBox
                textSections={[
                  {
                    text: findingsByControl.explanation,
                    links: 'explanationUrl' in findingsByControl ? findingsByControl.explanationUrl : null
                  },
                  {
                    text: findingsByControl.remediation,
                    links: findingsByControl.remediationUrl
                  }
                ]}
              />
            )}
            <div className={'flex'}>
              <DataTableRow
                isHeader={true}
                styles={'mt-4'}
                customColumns={vendor === Vendor.AWS ? awsColumns : vendor === Vendor.AZURE ? azureColumns : gcpColumns}
              >
                <DataTableCell>{t(`vendors.${vendor}.projectPhrase_one`)}</DataTableCell>
                <DataTableCell>{t('common.resource_one')}</DataTableCell>
                {'observedAt' in findingsByControl.findings[0] && (
                  <DataTableCell>{t('compliance.tabs.common.observation')}</DataTableCell>
                )}
                <DataTableCell>{t('common.region_one')}</DataTableCell>
                {vendor === Vendor.AWS ? (
                  <DataTableCell>{t('common.details')}</DataTableCell>
                ) : (
                  <>
                    <DataTableCell>{t('compliance.tabs.common.severity')}</DataTableCell>
                    <DataTableCell xAlign={xAlignment.CENTER}>
                      {vendor === Vendor.GCP ? t('compliance.tabs.gcp.state') : t('compliance.tabs.common.compliance')}
                    </DataTableCell>
                  </>
                )}
              </DataTableRow>
              <div className={'w-8'} />
            </div>
          </>
        }
        depth={2}
        showConnector={false}
        contentData={false}
      />

      {findingsByControl.findings.map((finding, index) => (
        <TreeBranch
          key={`compliance-finding-${index}`}
          depth={2}
          headerData={
            vendor === Vendor.AZURE || vendor === Vendor.GCP ? (
              <ComplianceFinding key={index} vendor={vendor} finding={finding as AzureFinding | GcpFinding} />
            ) : (
              <AwsFindingRow finding={finding as AwsFinding} />
            )
          }
          contentData={<FindingDetailsRow finding={finding} />}
          combineContent={true}
        />
      ))}
    </>
  )
}

interface ComplianceFindingProps {
  vendor: Vendor.AZURE | Vendor.GCP
  finding: AzureFinding | GcpFinding
}

const ComplianceFinding = ({ vendor, finding }: ComplianceFindingProps) => {
  const { t } = useTranslation()

  const displayResourceId = () => {
    if (finding.resource.id.includes('/')) {
      const index = finding.resource.id.lastIndexOf('/')
      return finding.resource.id.substring(index + 1)
    } else {
      return finding.resource.id
    }
  }

  return (
    <DataTableRow customColumns={vendor === Vendor.AZURE ? azureColumns : gcpColumns}>
      <DataTableCell>
        <DataTableLabel>{t(`vendors.${vendor}.projectPhrase_one`)}</DataTableLabel>
        <DataTableValue className={'text-right xl:text-left'}>
          <ClipboardCopy copyText={finding.project.name} secondCopyText={finding.project.id} limit={30} />
        </DataTableValue>
      </DataTableCell>
      <DataTableCell>
        <DataTableLabel>{t('common.resource_one')}</DataTableLabel>
        <DataTableValue className={'text-right xl:text-left'}>
          <ClipboardCopy
            customDisplayText={displayResourceId()}
            copyText={finding.resource.id}
            secondCopyText={finding.resource.type}
            limit={30}
          />
        </DataTableValue>
      </DataTableCell>
      <TabDataTableRegionCell regionName={finding?.region?.name} regionId={finding?.region?.id} />
      <DataTableCell>
        <DataTableLabel>{t('compliance.tabs.common.severity')}</DataTableLabel>
        <SeverityLevelIndicator level={finding.severity ? finding.severity : SeverityLevel.UNSPECIFIED} />
      </DataTableCell>
      <DataTableCell xAlign={xAlignment.CENTER}>
        <DataTableLabel>
          {vendor === Vendor.GCP ? t('compliance.tabs.gcp.state') : t('compliance.tabs.common.compliance')}
        </DataTableLabel>
        <ComplianceStatusIndicator size={StatusIndicatorSize.small} status={finding.status} />
      </DataTableCell>
    </DataTableRow>
  )
}

interface AwsFindingRowProps {
  finding: AwsFinding
}

const AwsFindingRow = ({ finding }: AwsFindingRowProps) => {
  const { t } = useTranslation()
  const displayResourceId = () => {
    if (finding.resource.id.includes('/')) {
      const index = finding.resource.id.lastIndexOf('/')
      return finding.resource.id.substring(index + 1)
    } else {
      return finding.resource.id
    }
  }

  return (
    <DataTableRow customColumns={awsColumns}>
      <DataTableCell>
        <DataTableLabel>{t(`vendors.${Vendor.AWS}.projectPhrase_one`)}</DataTableLabel>
        <DataTableValue className={'text-right xl:text-left'}>
          <ClipboardCopy copyText={finding.project.name} secondCopyText={finding.project.id} limit={30} />
        </DataTableValue>
      </DataTableCell>
      <DataTableCell>
        <DataTableLabel>{t('common.resource_one')}</DataTableLabel>
        <DataTableValue className={'text-right xl:text-left'}>
          <ClipboardCopy
            customDisplayText={displayResourceId()}
            copyText={finding.resource.id}
            secondCopyText={finding.resource.type}
            limit={30}
          />
        </DataTableValue>
      </DataTableCell>
      <DataTableCell>
        <DataTableLabel>{t('compliance.tabs.common.observation')}</DataTableLabel>
        <DataTableValue className={'text-right text-90 xl:text-left'}>
          <GrayText>1: {formatDate(finding.observedAt.first, true, true, true)}</GrayText>
          <div className={'flex items-center gap-1.5'}>
            <CustomIcon
              iconType={IconType.VECTOR}
              styles={'min-w-5 w-5 h-5 bg-gray-50'}
              path={timerIcon}
              alt={t('compliance.tabs.aws.lastObservedAt')}
            />
            {formatDate(finding.observedAt.last, true, true, true)}
          </div>
        </DataTableValue>
      </DataTableCell>
      <TabDataTableRegionCell regionName={finding.region.name} regionId={finding.region.id} />
      <DataTableCell>
        <DataTableLabel>{t('common.details')}</DataTableLabel>
        <div className={'flex gap-3 items-center'}>
          <div>
            <SeverityLevelIndicator
              small={true}
              level={finding.severity ? finding.severity : SeverityLevel.UNSPECIFIED}
            />
            <ComplianceStatusIndicator
              size={StatusIndicatorSize.small}
              status={finding.status}
              label={finding.status}
            />
          </div>
          {finding.workflowStatus !== 'NEW' || finding.recordState !== 'ACTIVE' ? (
            <div className={'text-80'}>
              <GrayText>
                {t('compliance.tabs.aws.workflow')}:{' '}
                <WhiteText className={'inline-flex'}>{finding.workflowStatus.toLowerCase()}</WhiteText>
              </GrayText>
              <GrayText>
                {t('compliance.tabs.aws.recordState')}:{' '}
                <WhiteText className={'inline-flex'}>{finding.recordState.toLowerCase()}</WhiteText>
              </GrayText>
            </div>
          ) : null}
        </div>
      </DataTableCell>
    </DataTableRow>
  )
}

interface FindingDetailsRowProps {
  finding: AwsFinding | AzureFinding | GcpFinding
}

const FindingDetailsRow = ({ finding }: FindingDetailsRowProps) => {
  const { t } = useTranslation()
  const screenSize = useScreenSize()
  if (screenSize.width >= twXl)
    return (
      <div className={'flex flex-col'}>
        {finding.standards.length > 0 && (
          <DetailSet>
            <CapitalizedText className={'text-gray-200'}>{t('compliance.tabs.common.standards')}</CapitalizedText>
            {finding.standards.map(standard =>
              typeof standard === 'string' ? (
                <WhiteText key={standard}>{standard}</WhiteText>
              ) : (
                <div className={'flex gap-1.5 items-center py-0.5'}>
                  <WhiteText key={standard.standard}>{standard.standard}</WhiteText>
                  {standard.versions.map(v => (
                    <GrayText
                      key={v.version}
                      className={'text-80 inline-flex px-1.5 rounded-full border border-gray-200 bg-gray-600/10'}
                    >
                      {v.version !== '' && `v${v.version} - `}
                      {v.controls.join(', ')}
                    </GrayText>
                  ))}
                </div>
              )
            )}
          </DetailSet>
        )}
        {'relatedRequirements' in finding && finding.relatedRequirements.length > 0 && (
          <DetailSet>
            <CapitalizedText className={'text-gray-200'}>
              {t('compliance.tabs.aws.relatedRequirements')}
            </CapitalizedText>
            {finding.relatedRequirements.map(requirement => (
              <WhiteText key={requirement}>{requirement}</WhiteText>
            ))}
          </DetailSet>
        )}
        {'statusReasons' in finding && finding.statusReasons.length > 0 && (
          <DetailSet>
            <CapitalizedText className={'text-gray-200'}>{t('compliance.tabs.aws.statusReasons')}</CapitalizedText>
            {finding.statusReasons.map(reason => (
              <WhiteText key={reason}>{reason}</WhiteText>
            ))}
          </DetailSet>
        )}
      </div>
    )
  else
    return (
      <>
        <DataTableCell>
          <DataTableLabel>{t('compliance.tabs.common.standards')}</DataTableLabel>
          <DataTableValue className={'text-right text-90'}>
            {finding.standards.map(standard =>
              typeof standard === 'string' ? (
                <div key={standard}>{standard}</div>
              ) : (
                <div key={standard.standard}>
                  {standard.standard}
                  {standard.versions.map(v => (
                    <div key={v.version}>
                      {v.version}:{v.controls.join(', ')}
                    </div>
                  ))}
                </div>
              )
            )}
          </DataTableValue>
        </DataTableCell>
        {'relatedRequirements' in finding && finding.relatedRequirements.length > 0 && (
          <DataTableCell>
            <DataTableLabel>{t('compliance.tabs.aws.relatedRequirements')}</DataTableLabel>
            <DataTableValue className={'text-right text-90'}>
              {finding.relatedRequirements.map(requirement => (
                <div key={requirement}>{requirement}</div>
              ))}
            </DataTableValue>
          </DataTableCell>
        )}
        {'statusReasons' in finding && finding.statusReasons.length > 0 && (
          <DataTableCell>
            <DataTableLabel>{t('compliance.tabs.aws.statusReasons')}</DataTableLabel>
            <DataTableValue className={'text-right text-90'}>
              {finding.statusReasons.map(reason => (
                <div key={reason}>{reason}</div>
              ))}
            </DataTableValue>
          </DataTableCell>
        )}
      </>
    )
}

const DetailSet = styled.div`
  ${tw`text-left xl:p-2`}
`

const awsColumns = 'repeat(2, minmax(11rem, 1fr)) minmax(13rem, 14.5rem) minmax(9rem, 14rem) minmax(8rem, 1fr)'
const azureColumns = 'repeat(2, 1fr) minmax(11.25rem, 13.25rem) minmax(7rem, 8rem) minmax(1.25rem, 6.25rem)'
const gcpColumns = 'repeat(2, minmax(15rem, 1fr)) repeat(2, minmax(7.5rem, 10rem)) 3.75rem'
