import React, { useContext } from 'react'
import { OrganizationUser, updateUserVisibility } from '../../../../api/admin/users'
import { AuthInfo, UserRole } from '../../../../api/auth'
import { MessageContext, MessageState, MessageType } from '../../../../state/context/MessageContext'
import { useTranslation } from 'react-i18next'
import { useErrorHandling } from '../../../../hooks/handleError'
import { useCancelToken } from '../../../../api/client'
import { UserInfoContext } from '../../../../state/context/UserInfoContext'
import { CheckboxOption, CheckboxOptionProps } from '../../../shared/buttons/CheckboxOption'
import { ADMIN_ROLES } from '../../roleConstants'
import { CapitalizedText, GrayText } from '../../../shared/TextComponents'
import { AdminTabContent } from '../../adminStyles'

interface VisibilityProps {
  user: OrganizationUser
  setUser: (user: OrganizationUser) => void
}

export const EditVisibility = ({ user, setUser }: VisibilityProps) => {
  const { authInfo } = useContext(UserInfoContext)
  const { t } = useTranslation()
  const { createCancelToken } = useCancelToken()
  const { setMessage } = useContext<MessageState>(MessageContext)
  const handleError = useErrorHandling()

  const updateVisibility = (role: UserRole) => {
    const cancelToken = createCancelToken()
    updateUserVisibility(user.id, role, cancelToken.token)
      .then(resp => {
        setUser(resp)
        setMessage({
          message: t('admin.tabs.visibility.visibilityUpdated'),
          type: MessageType.SUCCESS
        })
      })
      .catch(handleError)
  }

  const hasRightToEditVisibility = (): boolean => {
    return (
      (authInfo.roles.includes(UserRole.ADMIN) && !user.roles.some(role => ADMIN_ROLES.includes(role))) ||
      (authInfo.roles.includes(UserRole.AWS_ADMIN) && user.roles.includes(UserRole.AWS_USER)) ||
      (authInfo.roles.includes(UserRole.AZURE_ADMIN) && user.roles.includes(UserRole.AZURE_USER)) ||
      (authInfo.roles.includes(UserRole.GCP_ADMIN) && user.roles.includes(UserRole.GCP_USER))
    )
  }

  const visibilityOptions: CheckboxOptionProps[] = [
    {
      label: t('sideNav.costs'),
      checked: user.costsAccess,
      clickHandler: () => updateVisibility(UserRole.COSTS_USER)
    },
    {
      label: t('sideNav.optimization'),
      checked: user.optimizationAccess,
      clickHandler: () => updateVisibility(UserRole.OPTIMIZATION_USER)
    },
    {
      label: t('sideNav.compliance'),
      checked: user.complianceAccess,
      clickHandler: () => updateVisibility(UserRole.COMPLIANCE_USER)
    }
  ]

  authInfo.freshIntegration &&
    visibilityOptions.push({
      label: t('sideNav.tickets'),
      checked: user.ticketsAccess,
      clickHandler: () => updateVisibility(UserRole.TICKET_USER)
    })

  visibilityOptions.push(
    {
      label: t('sideNav.infra'),
      checked: user.infraAccess,
      clickHandler: () => updateVisibility(UserRole.INFRA_USER)
    },
    {
      label: t('sideNav.sustainability'),
      checked: user.sustainabilityAccess,
      clickHandler: () => updateVisibility(UserRole.SUSTAINABILITY_USER)
    }
  )

  return (
    <AdminTabContent>
      <VisibilityDescription authInfo={authInfo} user={user} />
      <div className={'flex flex-col gap-8'}>
        {visibilityOptions.map((props, i) => (
          <CheckboxOption
            key={i}
            {...props}
            capitalize={true}
            disabled={!hasRightToEditVisibility()}
            tooltipText={t('admin.tabs.visibility.disabledTooltip')}
          />
        ))}
      </div>
    </AdminTabContent>
  )
}

interface VisibilityDescriptionProps {
  user: OrganizationUser
  authInfo: AuthInfo
}

const VisibilityDescription = ({ user, authInfo }: VisibilityDescriptionProps) => {
  const { t } = useTranslation()
  return (
    <div>
      {authInfo.id === user.id ? (
        <GrayText>{t('admin.tabs.visibility.selfEdit')}</GrayText>
      ) : user.roles.find(role => ADMIN_ROLES.includes(role)) ? (
        <GrayText>{t('admin.tabs.visibility.adminVisibility')}</GrayText>
      ) : (
        <div>
          <CapitalizedText className={'font-semibold text-gray-50'}>
            {t('admin.tabs.visibility.userVisibility.intro')}
          </CapitalizedText>
          <GrayText>{t('admin.tabs.visibility.userVisibility.explanation')}</GrayText>
        </div>
      )}
    </div>
  )
}
