import styled from 'styled-components'
import tw from 'twin.macro'

export const ScrollContainer = styled.div`
  ${tw`flex flex-col overflow-y-auto overflow-x-hidden`}
  ::-webkit-scrollbar {
    ${tw`bg-transparent`}
  }
  @media (max-width: 1610px) {
    ${tw`max-w-[110%]`}
  }
`
