export interface CloudRegion {
  id: string
  name: string
}

export interface CloudProject {
  id: string
  name: string
}

export interface CloudResource {
  id: string
  type: string
  name: string | null | undefined
}

export enum TimeframeOption {
  LAST_MONTH = 'LAST_MONTH',
  FROM_LAST_YEAR = 'FROM_LAST_YEAR',
  LAST_YEAR = 'LAST_YEAR',
  CURRENT_YEAR = 'CURRENT_YEAR'
}

export enum Timescale {
  YEAR = 'YEAR',
  MONTH = 'MONTH'
}
