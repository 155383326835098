import { Vendor } from '../../utils/vendors'
import { CancelToken } from 'axios'
import { client } from '../client'
import { CloudProject, CloudRegion } from '../../utils/classes'
import { CurrencyEntry, CurrencyId } from '../../utils/Currency'

export const getOptimizationGcpTabData = async (
  vendors: Vendor[],
  projects: string[],
  recommendations: string[],
  currency: CurrencyId,
  cancelToken: CancelToken
): Promise<GcpRecommendations | null> => {
  const resp = await client.post(
    'optimization/tabs/gcp-recommendations',
    {
      vendors,
      projects,
      recommendations,
      currency
    },
    { cancelToken }
  )
  return resp.data
}

export interface GcpRecommendations {
  commitments: GcpCommitmentsByCategory[]
  rightsizing: GcpRightsizingByCategory[]
  utilization: GcpUtilizationByCategory[]
}

export interface GcpCommitmentsByCategory {
  category: string
  potentialAnnualSavings: CurrencyEntry
  recommendationCount: number
  recommendations: GcpCommitmentsByDescription[]
}

export interface GcpCommitmentsByDescription {
  description: string
  potentialAnnualSavings: CurrencyEntry
  recommendations: GcpCommitment[]
}

export interface GcpCommitment {
  project: CloudProject
  region: CloudRegion
  category: string
  description: string
  target: string
  recommendedPurchase: string | null
  quantity: number | null
  quantityUnit: string | null
  monthlySavings: number
  annualSavings: number
  currency: CurrencyId
}

export interface GcpRightsizingByCategory {
  category: string
  recommendationCount: number
  recommendations: GcpRightsizingByProject[]
}

export interface GcpRightsizingByProject {
  project: CloudProject
  recommendations: GcpRightsizing[]
}

export interface GcpRightsizing {
  project: CloudProject
  region: CloudRegion
  category: string
  subcategory: string
  description: string
  target: string
  cost: number
  currency: CurrencyId
}

export interface GcpUtilizationByCategory {
  category: string
  monthlySavings: CurrencyEntry
  annualSavings: CurrencyEntry
  recommendations: GcpUtilization[]
}

export interface GcpUtilization {
  project: CloudProject
  region: CloudRegion
  description: string
  target: string | null
  monthlySavings: number
  annualSavings: number
  currency: CurrencyId
}
