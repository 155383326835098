import { TFunction } from 'react-i18next'
import { CurrencyId, getCurrency } from './Currency'

export const formatCurrency = (currency: CurrencyId, amount?: number | null, longSymbol: boolean = false): string =>
  !amount && amount !== 0
    ? 'NA'
    : `${formatNumber(amount)} ${longSymbol ? getCurrency(currency).symbolLong : getCurrency(currency).symbol}`

export const formatNumber = (value: number | null, decimals?: number) => {
  if (value === null) return '-'

  const format = new Intl.NumberFormat('fi-FI', {
    minimumFractionDigits: decimals || 0,
    maximumFractionDigits: decimals || 0
  })
  return format.format(value)
}

export const formatMonthShort = (date: string | undefined): string => {
  if (!date) return ''

  const splits = date.toString().split('-')
  return `${splits[1]}/${splits[0].slice(2)}`
}

export const formatMonthShortString = (date: string | undefined): string => {
  if (!date) return ''

  const months = [
    { number: '01-01', name: 'JAN' },
    { number: '02-01', name: 'FEB' },
    { number: '03-01', name: 'MAR' },
    { number: '04-01', name: 'APR' },
    { number: '05-01', name: 'MAY' },
    { number: '06-01', name: 'JUN' },
    { number: '07-01', name: 'JUL' },
    { number: '08-01', name: 'AUG' },
    { number: '09-01', name: 'SEP' },
    { number: '10-01', name: 'OCT' },
    { number: '11-01', name: 'NOV' },
    { number: '12-01', name: 'DEC' }
  ]
  const month = months.find(entry => (date.toString().includes(entry.number) ? entry.name : ''))
  return month ? month.name : ''
}

export const formatToYear = (date: string | undefined) => {
  if (!date) return ''
  const parts = date.toString().split('-')
  return `${parts[0]}`
}

export const formatToDay = (date: string | undefined): string => {
  if (!date) return ''
  const parts = date.toString().split('-')
  return `${parts[2]}`
}

export const formatMonthLong = (date: Date | string | undefined, year?: boolean) => {
  if (!date) return ''

  if (year)
    return new Intl.DateTimeFormat('en-EN', {
      month: 'long',
      year: 'numeric'
    }).format(new Date(date))

  return new Intl.DateTimeFormat('en-EN', {
    month: 'long'
  }).format(new Date(date))
}
export const roundNumber = (value: number, decimals: number) => {
  return Number(value.toFixed(decimals))
}

export const numberToPositive = (value: number) => {
  return value < 0 ? value * -1 : value
}

export const formatDate = (
  date?: Date | null,
  showWeekDay: boolean = true,
  showTime: boolean = true,
  pointBlank: boolean = false
) => {
  if (!date) return pointBlank ? '-' : ''
  const weekDay = date.toLocaleDateString('en-EN', { weekday: 'short' })
  const formattedDate = date.toLocaleDateString('fi-FI', {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric'
  })
  const time = date.toLocaleTimeString('fi-FI', {
    hour: '2-digit',
    minute: '2-digit'
  })

  return `${showWeekDay ? weekDay + ' ' : ''}${formattedDate}${showTime ? ' ' + time : ''}`
}

export const capitalizeFirstLetter = (text: string) => text.charAt(0).toUpperCase() + text.slice(1)

export const getReadableTimeDifference = (date: Date, t: TFunction): string => {
  const now = new Date()
  const difference = now.getTime() - date.getTime() // in milliseconds
  const seconds = Math.floor(difference / 1000)
  const minutes = Math.floor(seconds / 60)
  const hours = Math.floor(minutes / 60)
  const days = Math.floor(hours / 24)
  const months = Math.floor(days / 30)
  const years = Math.floor(months / 12)

  if (seconds < 60) {
    return t('time.ago.seconds', { count: seconds })
  }
  if (minutes < 60) {
    return t('time.ago.minutes', { count: minutes })
  }
  if (hours < 24) {
    return t('time.ago.hours', { count: hours })
  }
  if (days < 30) {
    return t('time.ago.days', { count: days })
  }
  if (months < 12) {
    return t('time.ago.months', { count: months })
  }
  return t('time.ago.years', { count: years })
}

// source https://emailregex.com
export const emailRegExp = new RegExp(
  '^(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$'
)

export const passwordRegExp = new RegExp('^(?=.*[0-9]).{8,99}', 'i')

// cognito doesn't allow special characters in the beginning or the end of domain name
export const testCognitoEmail = (email: string): boolean => {
  if (email.match(emailRegExp) === null) return false

  const specialCharsRegexp = new RegExp(/^[a-öA-Ö0-9]+$/)
  const domainName = email.split('@')[1].split('.')[0]
  return specialCharsRegexp.test(domainName[0]) && specialCharsRegexp.test(domainName[domainName.length - 1])
}

export const uuidRegExp = new RegExp(/^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/)
