import styled from 'styled-components'
import tw from 'twin.macro'
import React from 'react'

interface TreeProps {
  children: React.ReactNode
}

export const Tree = ({ children }: TreeProps) => {
  return <TreeWrapper>{children}</TreeWrapper>
}

export const TreeWrapper = styled.ul`
  ${tw`w-full flex flex-col gap-2`}
`
