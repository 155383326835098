import React from 'react'
import { useTranslation } from 'react-i18next'
import { GrayText, Heading } from '../../shared/TextComponents'
import { Loading } from '../../shared/Loading'
import { CustomIcon, IconType } from '../../shared/CustomIcon'
import skull from '../../../assets/svg/objects/flat-colored/skull.svg'
import styled from 'styled-components'
import tw from 'twin.macro'
import { ScrollTable, ScrollTableProps } from '../../shared/containers/ScrollTable'

interface TableLayoutProps {
  loading?: boolean
  noData: boolean
  heading: string
  headerActions: React.ReactNode
  scrollTableProps: ScrollTableProps
}

export const IntegrationsLayout = ({ loading, noData, heading, headerActions, scrollTableProps }: TableLayoutProps) => {
  const { t } = useTranslation()

  if (noData)
    return (
      <Wrapper>
        <Header>
          <Heading>{heading}</Heading>
          <>{headerActions}</>
        </Header>
        {loading ? (
          <Loading paddingY={'1rem'} />
        ) : (
          <div className={'flex items-center gap-3 w-full py-8'}>
            <CustomIcon iconType={IconType.FLAT} path={skull} />
            <GrayText>{t('error.noData')}</GrayText>
          </div>
        )}
      </Wrapper>
    )

  return (
    <Wrapper>
      <Header>
        <Heading>{heading}</Heading>
        <>{headerActions}</>
      </Header>
      {scrollTableProps.sortable ? (
        <ScrollTable
          titles={scrollTableProps.titles}
          rows={scrollTableProps.rows}
          customColumns={scrollTableProps.customColumns}
          smallScreenTitle={scrollTableProps.smallScreenTitle}
          spacing={scrollTableProps.spacing}
          styles={scrollTableProps.styles}
          sortable={scrollTableProps.sortable}
          sortAsc={scrollTableProps.sortAsc}
          setSortAsc={scrollTableProps.setSortAsc}
          sortingTitle={scrollTableProps.sortingTitle}
          setSortingTitle={scrollTableProps.setSortingTitle}
        />
      ) : (
        <div>
          <div className={'text-75 w-full text-center md:text-80 md:text-right mb-1'}>
            <GrayText>{t('admin.integrations.integrationExecutionInfo_1')}</GrayText>
            <GrayText>{t('admin.integrations.integrationExecutionInfo_2')}</GrayText>
          </div>
          <ScrollTable
            titles={scrollTableProps.titles}
            rows={scrollTableProps.rows}
            customColumns={scrollTableProps.customColumns}
            smallScreenTitle={scrollTableProps.smallScreenTitle}
            spacing={scrollTableProps.spacing}
            styles={scrollTableProps.styles}
            sortable={scrollTableProps.sortable}
          />
        </div>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  ${tw`flex flex-col gap-4 xl:px-4`}
`

const Header = styled.div`
  ${tw`flex justify-between gap-10 w-full items-center flex-col sm:flex-row`}
`
