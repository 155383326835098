import React, { useEffect, useState } from 'react'
import { HomeNoDataSummaryContainer, HomeSummaryContainer, SummaryAmount, SummaryText } from './HomeSharedComponents'
import { CountUp } from '../shared/CountUp'
import { useCancelToken } from '../../api/client'
import { Trans, useTranslation } from 'react-i18next'
import { useErrorHandling } from '../../hooks/handleError'
import { getHomeInfraRegionCount } from '../../api/home'

export const InfraSummary = () => {
  const { t } = useTranslation()
  const [regionCount, setRegionCount] = useState<number | null>(null)
  const { createCancelToken } = useCancelToken()
  const handleError = useErrorHandling()
  const [loading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    setLoading(true)
    const cancelToken = createCancelToken()
    getHomeInfraRegionCount(cancelToken.token)
      .then(setRegionCount)
      .catch(handleError)
      .finally(() => setLoading(false))

    return () => {
      setLoading(false)
      setRegionCount(null)
      cancelToken.cancel()
    }
  }, [createCancelToken, handleError])

  if (loading || regionCount === null)
    return <HomeNoDataSummaryContainer title={t('home.infraSummary.title')} loading={loading} />

  return (
    <HomeSummaryContainer title={t('home.infraSummary.title')}>
      <SummaryAmount textSize={'extra-large'}>
        <CountUp id={'qa-home-infra-regions'} countTo={regionCount} />
      </SummaryAmount>
      <SummaryText largerText={true}>
        <Trans>{t('home.infraSummary.regionPhrase', { count: regionCount })}</Trans>
      </SummaryText>
    </HomeSummaryContainer>
  )
}
