import { CostAnomalyNotification } from '../../api/notifications'
import { createContext, useState } from 'react'

export interface NotificationsState {
  notificationsOpen: boolean
  setNotificationsOpen: (isOpen: boolean) => void
  notifications: CostAnomalyNotification[]
  setNotifications: (notifications: CostAnomalyNotification[]) => void
}

const defaultNotificationsState: NotificationsState = {
  notificationsOpen: false,
  setNotificationsOpen: () => undefined,
  notifications: [],
  setNotifications: () => undefined
}

export const NotificationsContext = createContext<NotificationsState>(defaultNotificationsState)

interface Props {
  children: JSX.Element
}

export const NotificationsContextProvider = ({ children }: Props) => {
  const [notificationsOpen, setNotificationsOpen] = useState<boolean>(defaultNotificationsState.notificationsOpen)
  const [notifications, setNotifications] = useState<CostAnomalyNotification[]>(defaultNotificationsState.notifications)
  const notificationsValue = {
    notificationsOpen,
    setNotificationsOpen,
    notifications,
    setNotifications
  }

  return <NotificationsContext.Provider value={notificationsValue}>{children}</NotificationsContext.Provider>
}
