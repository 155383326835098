import { CancelToken } from 'axios'
import { client } from './client'
import { Vendor } from '../utils/vendors'
import { UserRole } from './auth'
import { CurrencyEntry, CurrencyId } from '../utils/Currency'

export const getOrganizationAdmins = async (cancelToken: CancelToken): Promise<UserContact[]> => {
  const resp = await client.get<UserContact[]>('/home/organization-admins', { cancelToken })
  return resp.data
}

export const getHomeAccountsSummary = async (cancelToken: CancelToken): Promise<HomeHeaderSummary[]> => {
  const resp = await client.get<HomeHeaderSummary[]>(`/home/header-summary`, {
    cancelToken: cancelToken
  })
  return resp.data
}

export const getHomeCurrentMonthEmissions = async (cancelToken: CancelToken): Promise<number> => {
  const resp = await client.get<number>('/home/current-month-emissions', {
    cancelToken: cancelToken
  })
  return resp.data
}

export const getHomeInfraRegionCount = async (cancelToken: CancelToken) => {
  const resp = await client.get<number>('/home/region-count', {
    cancelToken: cancelToken
  })
  return resp.data
}

export const getHomeTicketCount = async (cancelToken: CancelToken): Promise<number> => {
  const resp = await client.get<number>('/home/ticket-count', {
    cancelToken: cancelToken
  })
  return resp.data
}

export const getHomeCostChange = async (cancelToken: CancelToken): Promise<number> => {
  const resp = await client.get<number>('/home/costs-change', {
    cancelToken: cancelToken
  })
  return resp.data
}

export const getComplianceSummary = async (cancelToken: CancelToken): Promise<number> => {
  const resp = await client.get<number>('/home/compliance', {
    cancelToken: cancelToken
  })
  return resp.data
}

export const getHomeOptimizationSummary = async (cancelToken: CancelToken): Promise<CurrencyEntry> => {
  return await client.get('home/optimization', { cancelToken }).then(resp => resp.data)
}

export interface UserContact {
  firstName: string
  lastName: string
  emailAddress: string
  roles: UserRole[]
}

export interface HomeHeaderSummary {
  vendor: Vendor
  projects: number
  resources: number
  currentCosts: number
  forecast: number
  currency: CurrencyId
}
