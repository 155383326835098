import React, { useEffect, useState } from 'react'

interface CountProps {
  countTo: number
  duration?: number
  id?: string
}

export const CountUp = ({ countTo, duration = 500, id }: CountProps) => {
  const [count, setCount] = useState(0)
  // browsers can't process the function in 1ms
  const minIncrementTime = 10

  useEffect(() => {
    let runner = 0
    if (runner === countTo) return

    let incrementTime =
      Math.ceil(duration / countTo) > minIncrementTime ? Math.ceil(duration / countTo) : minIncrementTime

    let incrementStep = Math.ceil((countTo / duration) * incrementTime)

    const timer = setInterval(() => {
      // logarithmic ending when exceeding the goal
      if (runner + incrementStep > countTo) {
        incrementStep = Math.floor(incrementStep / 2)
      }

      runner += incrementStep
      setCount(runner)

      if (runner >= countTo) {
        setCount(countTo)
        clearInterval(timer)
      }

      return () => setCount(0)
    }, incrementTime)

    return () => setCount(0)
  }, [countTo, duration])

  return <span id={id}>{count.toLocaleString('fi-FI')}</span>
}
