import { useTranslation } from 'react-i18next'
import React, { useEffect, useState } from 'react'
import { FilterOption, FilterOptionContainer, GroupedOptions } from './FilterSelect'
import { CheckboxOption } from '../buttons/CheckboxOption'
import {
  SelectClearIndicator,
  selectControlStyles,
  SelectDropdownIndicator,
  selectGroupHeadingStyles,
  SelectIndicatorSeparator,
  selectMenuListStyles,
  selectNoOptionsStyles,
  selectOptionStyles
} from './ReactSelectStyles'
import { Vendors } from '../../../utils/vendors'
import { formatNumber } from '../../../utils/formats'
import Select, { Props } from 'react-select'
import { WhiteText } from '../TextComponents'
import { CustomIcon, IconType } from '../CustomIcon'
import caret from '../../../assets/svg/directional/caret-heavy.svg'

interface NestedFilterProps extends Props {
  keyOptions: GroupedOptions[]
  selectedOptions: FilterOption[]
  setSelectedOptions: (options: FilterOption[]) => void
  props?: Props
}

export const FilterNestedSelect = ({ keyOptions, selectedOptions, setSelectedOptions, props }: NestedFilterProps) => {
  const { t } = useTranslation()
  const [keySearchText, setKeySearchText] = useState('')
  const [valueSearchText, setValueSearchText] = useState('')
  const [prevSelectedKey, setPrevSelectedKey] = useState<FilterOption | null>(null)
  const [selectedKey, setSelectedKey] = useState<FilterOption | null>(null)
  const [valueOptions, setValueOptions] = useState<FilterOption[]>([])
  const [selectedValues, setSelectedValues] = useState<FilterOption[]>([])

  useEffect(() => {
    selectedKey && setValueOptions([{ ...selectedKey }, ...(selectedKey.nest ?? [])])
    selectedKey &&
      prevSelectedKey &&
      prevSelectedKey === selectedKey &&
      (selectedValues.length > 0
        ? setSelectedOptions([
            ...selectedOptions.filter(option => option.vendor !== selectedKey.vendor),
            {
              ...selectedKey,
              nest: selectedValues
            }
          ])
        : setSelectedOptions(selectedOptions.filter(option => option.vendor !== selectedKey.vendor)))
  }, [selectedKey, selectedValues])

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      keySearchText.length === 1 && event.key === 'Backspace' && setKeySearchText('')
      selectedKey && valueSearchText.length === 1 && event.key === 'Backspace' && setValueSearchText('')
    }

    window.addEventListener('keydown', handleKeyDown)

    return () => window.removeEventListener('keydown', handleKeyDown)
  }, [keySearchText, valueSearchText])

  const formatOptionLabel = (data: FilterOption | unknown) => {
    if (!data || typeof data !== 'object') return null

    if (selectedKey && valueOptions.length > 0) {
      const option = data as FilterOption
      const isSelected = selectedOptions
        .filter(o => o.value === selectedKey.value && o.vendor === selectedKey.vendor)
        .flatMap(o => o.nest as FilterOption[])
        .some(o => o.value === option.value && o.vendor === option.vendor)
      const isFirstItem = valueOptions[0].value === option.value
      return option.value === selectedKey.value && isFirstItem ? (
        <div
          className={'group flex gap-2 items-center'}
          onClick={() => {
            setValueSearchText('')
            setPrevSelectedKey(selectedKey)
            setSelectedKey(null)
          }}
        >
          <CaretIcon direction={'left'} />
          {selectedKey.label}
        </div>
      ) : (
        <FilterOptionContainer title={option.label}>
          <CheckboxOption
            label={Vendors.find(vendor => vendor === option.label) ? t(`vendors.${option.label}.short`) : option.label}
            type={'filter'}
            clickHandler={() => {
              if (selectedKey) {
                if (selectedKey !== prevSelectedKey) {
                  setPrevSelectedKey(selectedKey)
                  setSelectedValues([option])
                } else {
                  setSelectedValues(
                    isSelected ? selectedValues.filter(o => o.value !== option.value) : [...selectedValues, option]
                  )
                }
              }
            }}
            checked={isSelected}
          />
        </FilterOptionContainer>
      )
    } else {
      const option = data as FilterOption
      return (
        <FilterOptionContainer title={selectedKey?.label} className={'group'}>
          <CheckboxOption
            label={Vendors.find(vendor => vendor === option.label) ? t(`vendors.${option.label}.short`) : option.label}
            type={'filter'}
            clickHandler={() => {
              setSelectedKey(option)
              setValueOptions(option.nest ?? [])
            }}
            checked={selectedOptions.some(o => o.vendor === option.vendor && o.value === option.value)}
          />
          {(option.count || option.count === 0) && (
            <WhiteText className={'flex gap-2 items-center text-80'}>
              {`(${formatNumber(option.count)})`}
              <CaretIcon direction={'right'} />
            </WhiteText>
          )}
        </FilterOptionContainer>
      )
    }
  }

  const filterOption = (option: FilterOption, query: string) => {
    return (
      query === '' ||
      (selectedKey !== null && option.value === selectedKey.value) ||
      option.label.toLowerCase().includes(query.toLowerCase()) ||
      option.value.toLowerCase().includes(query.toLowerCase())
    )
  }

  return (
    <Select
      {...props}
      unstyled
      options={selectedKey ? valueOptions : keyOptions}
      classNames={{
        control: () => selectControlStyles + ' flex items-center',
        menuList: () => selectMenuListStyles + ' w-full shadow-xs',
        noOptionsMessage: () => selectNoOptionsStyles,
        placeholder: () => 'text-gray-200',
        option: state => selectOptionStyles + ` max-w-full ${state.isFocused && 'bg-gray-600 text-gray-50'}`,
        group: () => 'my-4',
        groupHeading: () => selectGroupHeadingStyles
      }}
      inputValue={selectedKey ? valueSearchText : keySearchText}
      onInputChange={inputValue =>
        inputValue !== '' && (selectedKey ? setValueSearchText(inputValue) : setKeySearchText(inputValue))
      }
      onBlur={() => (selectedKey ? setValueSearchText('') : setKeySearchText(''))}
      isClearable={selectedOptions.length > 0}
      onChange={e => {
        if (!e) {
          setSelectedOptions([])
          setSelectedValues([])
        }
      }}
      filterOption={filterOption}
      closeMenuOnSelect={false}
      controlShouldRenderValue={false}
      hideSelectedOptions={false}
      formatOptionLabel={formatOptionLabel}
      placeholder={
        selectedOptions.length === 0
          ? t('common.search')
          : t('filters.multipleSelected', { count: selectedOptions.map(o => o.nest).flat().length })
      }
      components={{
        ClearIndicator: SelectClearIndicator,
        IndicatorSeparator: SelectIndicatorSeparator,
        DropdownIndicator: SelectDropdownIndicator
      }}
    />
  )
}

interface IconProps {
  direction: 'left' | 'right'
}

const CaretIcon = ({ direction }: IconProps) => {
  return (
    <CustomIcon
      iconType={IconType.VECTOR}
      path={caret}
      styles={`w-4 h-4 bg-gray-50 transition-all ease-in-out group-hover:scale-105 ${direction === 'left' ? 'rotate-90' : '-rotate-90'}`}
    />
  )
}
