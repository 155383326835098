export const featuresFlags = {
  home: true,
  costs: true,
  optimization: true,
  compliance: true,
  tickets: true,
  sustainability: true,
  infra: true,
  notifications: true,
  admin: true,
  systemAdmin: true,
  masterAdmin: true
}
