import { OptimizationTabCollapsibleSummary, OptimizationTabTitleRow } from '../OptimizationTabsSharedComponents'
import { formatNumber, roundNumber } from '../../../../utils/formats'
import { getCurrency } from '../../../../utils/Currency'
import {
  DataTableCell,
  DataTableLabel,
  DataTableRow,
  DataTableValue,
  TabInfoBox
} from '../../../shared/DataTableSharedComponents'
import { TreeBranch } from '../../../shared/TreeBranch'
import React from 'react'
import { GcpUtilization, GcpUtilizationByCategory } from '../../../../api/optimization/gcp'
import { useTranslation } from 'react-i18next'
import { ClipboardCopy } from '../../../shared/ClipboardCopy'
import { TabDataTableRegionCell } from '../../../shared/tabs/TabSharedComponents'

interface GcpTabUtilizationProps {
  id: string
  utilization: GcpUtilizationByCategory
}

export const GcpTabUtilization = ({ id, utilization }: GcpTabUtilizationProps) => {
  const { t } = useTranslation()
  return (
    <TreeBranch
      id={id}
      depth={1}
      headerData={
        <OptimizationTabCollapsibleSummary
          headerLeft={utilization.category.toLowerCase().replaceAll('_', ' ')}
          headerRight={
            utilization.category === 'RECLAIM_PROJECT'
              ? t(`optimization.tabs.gcp.projectRecommendations`, {
                  count: utilization.recommendations.length,
                  amount: formatNumber(utilization.annualSavings.amount),
                  currency: getCurrency(utilization.annualSavings.currency).symbol
                })
              : t(`optimization.tabs.gcp.resourcesRecommendations`, {
                  count: utilization.recommendations.length,
                  amount: formatNumber(utilization.annualSavings.amount),
                  currency: getCurrency(utilization.annualSavings.currency).symbol
                })
          }
          useExclamationMark={false}
        />
      }
      contentData={
        <>
          {utilization.recommendations.every(r => r.description === utilization.recommendations[0].description) && (
            <TabInfoBox textSections={[{ text: utilization.recommendations[0].description }]} />
          )}
          <OptimizationTabTitleRow
            widerIndex={[0, 1, 2, 3]}
            labels={[
              t('vendors.GCP.projectPhrase_one'),
              utilization.recommendations.some(r => r.target) && t('optimization.tabs.gcp.target'),
              t('common.region_one'),
              t('optimization.tabs.common.monthlySavings'),
              t('optimization.tabs.common.annualSavings')
            ]}
          />
          {utilization.recommendations.map((recommendation, i) => (
            <TreeBranch
              key={i}
              depth={2}
              headerData={<UtilizationRow recommendation={recommendation} />}
              contentData={false}
            />
          ))}
        </>
      }
    />
  )
}

interface UtilizationRowProps {
  recommendation: GcpUtilization
}

const UtilizationRow = ({ recommendation }: UtilizationRowProps) => {
  const { t } = useTranslation()
  const columns = recommendation.target ? 9 : 8
  return (
    <DataTableRow styles={`grid-cols-1 xl:grid-cols-${columns}`}>
      <DataTableCell styles={'xl:col-span-2'}>
        <DataTableLabel>{t('vendors.GCP.projectPhrase_one')}</DataTableLabel>
        <DataTableValue>
          <ClipboardCopy
            copyText={recommendation.project.name}
            secondCopyText={
              recommendation.project.id !== recommendation.project.name ? recommendation.project.id : undefined
            }
            limit={30}
          />
        </DataTableValue>
      </DataTableCell>
      {recommendation.target && (
        <DataTableCell styles={'xl:col-span-2'}>
          <DataTableLabel>{t('optimization.tabs.gcp.target')}</DataTableLabel>
          <DataTableValue>
            <ClipboardCopy
              customDisplayText={recommendation.target.split('/').pop()}
              copyText={recommendation.target}
              limit={30}
            />
          </DataTableValue>
        </DataTableCell>
      )}
      <TabDataTableRegionCell
        styles={'xl:col-span-2'}
        regionId={recommendation.region.id}
        regionName={recommendation.region.name}
      />
      <DataTableCell styles={'xl:col-span-2'}>
        <DataTableLabel>{t('optimization.tabs.common.monthlySavings')}</DataTableLabel>
        <DataTableValue>
          {formatNumber(roundNumber(recommendation.monthlySavings, 0))} {getCurrency(recommendation.currency).symbol}
        </DataTableValue>
      </DataTableCell>
      <DataTableCell>
        <DataTableLabel>{t('optimization.tabs.common.annualSavings')}</DataTableLabel>
        <DataTableValue>
          {formatNumber(roundNumber(recommendation.annualSavings, 0))} {getCurrency(recommendation.currency).symbol}
        </DataTableValue>
      </DataTableCell>
    </DataTableRow>
  )
}
