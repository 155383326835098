import axios, { AxiosError, AxiosResponse } from 'axios'
import { useCallback, useContext } from 'react'
import { TFunction, useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { MessageContext, MessageState, MessageType, SpotterMessage } from '../state/context/MessageContext'

export const useErrorHandling = () => {
  const { setMessage } = useContext<MessageState>(MessageContext)
  const history = useHistory()
  const { t } = useTranslation()

  return useCallback(
    (axiosError: AxiosError) => {
      const error = axiosError as AxiosError
      const errorResponse = error.response as AxiosResponse
      if (axios.isCancel(axiosError)) {
        return
      } else if (error.name === 'QuotaExceededError') {
        window.localStorage.clear()
        setMessage({
          heading: error.name,
          message: t('error.tryAgain'),
          type: MessageType.ERROR
        })
      } else if (error.name === 'LimitExceededException') {
        setMessage({
          heading: error.name,
          message: t('error.tryAgainLater'),
          type: MessageType.ERROR
        })
      } else if (error.code === 'NotAuthorizedException') {
        setMessage({
          heading: t('error.notAuthenticated'),
          message: t('error.login'),
          type: MessageType.ERROR
        })
        history.push('/logout')
      } else {
        setMessage(handleApiError(t, errorResponse))
      }
    },
    [history, setMessage, t]
  )
}

const handleApiError = (t: TFunction, errorResponse?: AxiosResponse): SpotterMessage => {
  return errorResponse?.data?.message
    ? { message: errorResponse.data.message, type: MessageType.ERROR }
    : { message: t('error.general'), type: MessageType.ERROR }
}
