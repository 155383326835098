import tw from 'twin.macro'
import styled from 'styled-components'
import { GradientText, GrayText, Heading2, WhiteText } from '../../shared/TextComponents'
import { CountUp } from '../../shared/CountUp'
import { Vendor, Vendors } from '../../../utils/vendors'
import { MissingDataNotification } from '../../shared/MissingDataNotification'
import { Loading } from '../../shared/Loading'
import { Trans, useTranslation } from 'react-i18next'
import { AwsAzureComplianceTotalSummary, GcpComplianceTotalSummary } from '../../../api/compliance/common'
import { ComplianceSummaryContainerWrapper } from './Shared'
import xIcon from '../../../assets/svg/symbols/x-heavy.svg'
import stopIcon from '../../../assets/svg/gestures/stop.svg'
import pointIcon from '../../../assets/svg/gestures/point.svg'
import okIcon from '../../../assets/svg/gestures/ok-hand.svg'
import checkIcon from '../../../assets/svg/symbols/check-heavy.svg'
import heartIcon from '../../../assets/svg/symbols/heart-flat.svg'
import { CustomIcon, IconType } from '../../shared/CustomIcon'
import React, { useContext } from 'react'
import { formatNumber } from '../../../utils/formats'
import { UserInfoContext } from '../../../state/context/UserInfoContext'

interface AwsAndAzureSummaryProps {
  total: AwsAzureComplianceTotalSummary | null
  selectedVendors: Vendor[]
  loading: boolean
}

export const AwsAndAzureSummary = ({ total, selectedVendors, loading }: AwsAndAzureSummaryProps) => {
  const { t } = useTranslation()
  const { userSettings } = useContext(UserInfoContext)
  const heading = Vendors.filter(vendor => vendor !== Vendor.GCP && userSettings.visibleVendors.includes(vendor))
    .filter(v => (selectedVendors.filter(s => s !== Vendor.GCP).length > 0 ? selectedVendors.includes(v) : true))
    .map(vendor => t(`vendors.${vendor}.short`))
    .join(' & ')

  return (
    <div className={'flex flex-col gap-6 w-full h-full'}>
      <Heading2>{heading}</Heading2>
      {loading ? (
        <Loading height={64} paddingY={'1rem'} />
      ) : total ? (
        <CenteredWrapper className={'h-full place-content-center'}>
          <TotalScore data={total} />
          <CISScore data={total} />
        </CenteredWrapper>
      ) : (
        <MissingDataNotification />
      )}
    </div>
  )
}

interface TotalScoreProps {
  data: AwsAzureComplianceTotalSummary
}

const TotalScore = ({ data }: TotalScoreProps) => {
  const { t } = useTranslation()
  const getScoreDescription = (score: number) =>
    score <= 10
      ? t('compliance.totalSummary.score.0')
      : score <= 20
        ? t('compliance.totalSummary.score.1')
        : score <= 30
          ? t('compliance.totalSummary.score.2')
          : score <= 40
            ? t('compliance.totalSummary.score.3')
            : score <= 50
              ? t('compliance.totalSummary.score.4')
              : score <= 60
                ? t('compliance.totalSummary.score.5')
                : score <= 70
                  ? t('compliance.totalSummary.score.6')
                  : score <= 80
                    ? t('compliance.totalSummary.score.7')
                    : score <= 90
                      ? t('compliance.totalSummary.score.8')
                      : score < 95
                        ? t('compliance.totalSummary.score.9')
                        : score < 100
                          ? t('compliance.totalSummary.score.10')
                          : t('compliance.totalSummary.score.max')

  const getIconPath = (score: number) =>
    score <= 30
      ? xIcon
      : score < 50
        ? stopIcon
        : score < 70
          ? pointIcon
          : score < 80
            ? okIcon
            : score < 90
              ? checkIcon
              : heartIcon
  const getIconColor = (score: number) =>
    score < 50
      ? 'bg-error-100'
      : score < 70
        ? 'bg-warn-100'
        : score < 80
          ? 'bg-success-100'
          : score < 95
            ? 'bg-primary-400'
            : 'bg-primary-500'

  return (
    <MainCount
      count={data.totalScore}
      countId={'qa-compliance-total-summary-total-score'}
      countUnit={'%'}
      countDescription={
        <>
          {t('compliance.totalSummary.totalScoreIntro')}{' '}
          <span className={'inline-flex gap-2 items-center text-gray-50'}>
            {getScoreDescription(data.totalScore)}
            <CustomIcon
              path={getIconPath(data.totalScore)}
              iconType={IconType.VECTOR}
              styles={`${getIconColor(data.totalScore)} w-6 h-6`}
            />
          </span>
        </>
      }
      additionalDetails={
        <div>
          <WhiteText className={'text-90'}>
            {formatNumber(data.totalResources)} {t('compliance.totalSummary.resourcesChecked')}
          </WhiteText>
          <GrayText className={'text-90'}>
            {formatNumber(data.compliantControls)} / {formatNumber(data.totalControls)}{' '}
            {t('compliance.totalSummary.controlsPassed')}
          </GrayText>
        </div>
      }
    />
  )
}

const CISScore = ({ data }: TotalScoreProps) => {
  const { t } = useTranslation()
  return (
    <div className={'flex flex-col gap-2'}>
      <WhiteText className={'text-110 font-semibold'}>{t('compliance.totalSummary.CISBenchmarkScore')}</WhiteText>
      <div className={'flex gap-4 justify-center items-center'}>
        <CisCount className={'min-w-24'}>
          <CountUp id={'qa-compliance-total-summary-cis-score'} countTo={data.cisScore} />
          <span className={'text-200 lg:text-250'}>%</span>
        </CisCount>
        <div className={'text-left'}>
          <WhiteText className={'text-90'}>
            {formatNumber(data.cisResources)} {t('compliance.totalSummary.resourcesChecked')}
          </WhiteText>
          <GrayText className={'text-90'}>
            {formatNumber(data.compliantCisControls)} / {formatNumber(data.cisControls)}{' '}
            {t('compliance.totalSummary.controlsPassed')}
          </GrayText>
        </div>
      </div>
    </div>
  )
}

interface GcpSummaryProps {
  data: GcpComplianceTotalSummary | null
  loading: boolean
}

export const GcpComplianceSummary = ({ data, loading }: GcpSummaryProps) => {
  const { t } = useTranslation()

  if (loading || !data)
    return (
      <ComplianceSummaryContainerWrapper>
        <Heading2>{Vendor.GCP}</Heading2>
        <CenteredWrapper>
          {loading ? <Loading height={64} paddingY={'1rem'} /> : <MissingDataNotification />}
        </CenteredWrapper>
      </ComplianceSummaryContainerWrapper>
    )

  return (
    <ComplianceSummaryContainerWrapper>
      <Heading2>{Vendor.GCP}</Heading2>
      <CenteredWrapper>
        <MainCount
          countId={`qa-compliance-total-summary-${Vendor.GCP}-total-count`}
          count={data.findingsCount}
          countDescription={
            <>
              <div className={'inline-flex'}>
                {t('compliance.totalSummary.gcpTotalFindings')}
                <CustomIcon
                  path={xIcon}
                  iconType={IconType.VECTOR}
                  styles={`align-text-bottom ml-1.5 bg-gradient-to-b from-error-100 to-tertiary-600 w-6 h-6`}
                />
              </div>
              <WhiteText className={'font-normal'}>
                <Trans>
                  {t('compliance.totalSummary.gcpTargetedAssets', {
                    amount: formatNumber(data.activeAssetsCount),
                    standardCount: formatNumber(data.standardCount)
                  })}
                </Trans>
              </WhiteText>
            </>
          }
          additionalDetails={
            <WhiteText className={'pt-2'}>
              <GradientText className={'leading-none font-bold from-gray-50 to-gray-200 md:min-w-32 text-175'}>
                <CountUp countTo={data.assetScore} />
                <span className={'text-125'}>%</span>
              </GradientText>{' '}
              {t('compliance.totalSummary.gcpAssetScoreDescription')}
            </WhiteText>
          }
        />
        <div className={'flex flex-col gap-2'}>
          <WhiteText className={'text-110 font-semibold'}>{t('compliance.totalSummary.CISBenchmark')}</WhiteText>
          <div className={'flex gap-2 items-center text-left self-center'}>
            <CisCount className={'text-150'}>
              <CountUp id={`qa-compliance-total-summary-${Vendor.GCP}-cis-count`} countTo={data.cisFindingsCount} />
            </CisCount>
            <div>
              <WhiteText>{t('compliance.totalSummary.gcpFindings')}</WhiteText>
              <GrayText lowercase={true} className={'text-90'}>
                {t('compliance.totalSummary.gcpInAssets', { amount: formatNumber(data.cisAssetsCount) })}
              </GrayText>
            </div>
          </div>
        </div>
      </CenteredWrapper>
    </ComplianceSummaryContainerWrapper>
  )
}

interface MainCountProps {
  countId: string
  count: number
  countUnit?: string
  countDescription: React.ReactNode
  additionalDetails: React.ReactNode
}

const MainCount = ({ count, countId, countUnit, countDescription, additionalDetails }: MainCountProps) => {
  return (
    <div className={'flex flex-col gap-4'}>
      <GradientText className={'leading-none font-bold from-gray-50 to-gray-200 md:min-w-32 text-350 lg:text-450'}>
        <CountUp id={countId} countTo={count} />
        <span className={'text-250 lg:text-350'}>{countUnit}</span>
      </GradientText>
      <div className={'flex flex-col gap-2'}>
        <GrayText className={'text-center font-semibold'}>{countDescription}</GrayText>
        {additionalDetails}
      </div>
    </div>
  )
}

const CisCount = styled(GradientText)`
  ${tw`font-bold leading-none from-tertiary-700 to-tertiary-800 text-250 lg:text-300`}
`

const CenteredWrapper = styled.div`
  ${tw`flex flex-col gap-8 text-center w-full`}
`
