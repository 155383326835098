import { Vendor } from '../../utils/vendors'
import { CancelToken } from 'axios'
import { client } from '../client'
import { CloudProject, CloudRegion, CloudResource } from '../../utils/classes'
import { CurrencyEntry, CurrencyId } from '../../utils/Currency'

export const getOptimizationAzureTabData = async (
  vendors: Vendor[],
  projects: string[],
  recommendations: string[],
  currency: CurrencyId,
  cancelToken: CancelToken
): Promise<AzureRecommendations> => {
  const resp = await client.post<AzureRecommendations>(
    'optimization/tabs/azure-recommendations',
    {
      vendors,
      projects,
      recommendations,
      currency
    },
    { cancelToken }
  )
  return resp.data
}

export interface AzureRecommendations {
  commitments: AzureCommitments
  rightsizing: AzureRightsizing[]
  utilization: AzureUtilization[]
}

export interface AzureCommitments {
  recommendationCount: number
  annualSavings: CurrencyEntry
  recommendations: AzureRecommendationsByName[]
}

export interface AzureRecommendationsByName {
  recommendationName: string
  annualSavings: CurrencyEntry
  recommendations: AzureCommitment[]
}

export interface AzureCommitment {
  sub: CloudProject
  solution: string
  resource: CloudResource
  region: CloudRegion | null
  recommendedPurchase: string | null
  quantity: number | null
  commitmentPeriod: string | null
  monthlySavings: number
  annualSavings: number
  savingsCurrency: CurrencyId
}

export interface AzureRightsizing {
  sub: CloudProject
  annualSavings: CurrencyEntry
  vmCount: number
  recommendations: AzureRightsizingDetails[]
}

export interface AzureRightsizingDetails {
  region: CloudRegion | null
  resourceId: string
  resourceName: string
  currentVm: string | null
  targetVm: string
  monthlySavings: number
  annualSavings: number
  currency: CurrencyId
}

export interface AzureUtilization {
  recommendationName: string
  solution: string | null
  annualSavings: CurrencyEntry | null
  recommendations: AzureUtilizationRecommendation[]
}

export interface AzureUtilizationRecommendation {
  sub: CloudProject
  problem: string
  solution: string
  resource: CloudResource
  monthlySavings: number
  annualSavings: number
  savingsCurrency: CurrencyId
}
