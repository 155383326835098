import { LoginInfo } from './auth'

export const persistLoginInfo = (loginInfo: LoginInfo) => {
  const newLoginInfo: LoginInfo =
    loginInfo.cognitoRegion &&
    loginInfo.cognitoClientId &&
    loginInfo.cognitoPoolId &&
    loginInfo.cognitoIdP &&
    loginInfo.cognitoPoolDomain
      ? {
          cognitoRegion: loginInfo.cognitoRegion,
          cognitoClientId: loginInfo.cognitoClientId,
          cognitoPoolId: loginInfo.cognitoPoolId,
          cognitoPoolDomain: loginInfo.cognitoPoolDomain,
          cognitoIdP: loginInfo.cognitoIdP,
          ssoEnabled: loginInfo.ssoEnabled,
          emailAddress: loginInfo.emailAddress
        }
      : {
          cognitoRegion: process.env.REACT_APP_COGNITO_REGION,
          cognitoClientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
          cognitoPoolId: process.env.REACT_APP_COGNITO_POOL_ID,
          cognitoPoolDomain: process.env.REACT_APP_COGNITO_POOL_DOMAIN,
          cognitoIdP: null,
          ssoEnabled: false,
          emailAddress: loginInfo.emailAddress
        }

  window.localStorage.setItem('login-info', JSON.stringify(newLoginInfo))
}

export const getPersistedLoginInfo = (): LoginInfo => {
  const data = window.localStorage.getItem('login-info')
  if (data === null) {
    return {
      cognitoRegion: null,
      cognitoClientId: null,
      cognitoPoolId: null,
      cognitoPoolDomain: null,
      cognitoIdP: null,
      ssoEnabled: false,
      emailAddress: ''
    }
  }

  return JSON.parse(data)
}

export const clearPersistedLoginInfo = () => window.localStorage.removeItem('login-info')
