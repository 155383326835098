import styled from 'styled-components'
import tw from 'twin.macro'
import { Vendor } from '../../../utils/vendors'
import { useTranslation } from 'react-i18next'
import React from 'react'
import { MissingDataNotification } from '../MissingDataNotification'
import { DataTableCell, DataTableLabel, DataTableValue } from '../DataTableSharedComponents'
import { GrayText } from '../TextComponents'
import { TabLevel } from './Tab'

interface OptionsWrapperProps {
  level?: TabLevel
}
export const TabOptionsWrapper = styled.div<OptionsWrapperProps>`
  ${tw`flex gap-2 justify-center lg:justify-start border-b border-gray-500`}
  ${({ level }) => level === TabLevel.SECONDARY && tw`justify-start border-dotted`}
`

export const TabsContainer = styled.div`
  ${tw`flex flex-col w-full transition-all ease-in-out gap-4 rounded-md bg-gray-600 py-4 px-2 bg-opacity-100`}
`

interface TabDataTableRegionCellProps {
  regionName: string | null | undefined
  regionId: string | null | undefined
  styles?: string
}

export const TabDataTableRegionCell = ({ regionName, regionId, styles }: TabDataTableRegionCellProps) => {
  const { t } = useTranslation()
  return (
    <DataTableCell styles={styles}>
      <DataTableLabel>{t('common.region_one')}</DataTableLabel>
      <DataTableValue>
        {regionName ? regionName : '-'}
        {regionName && regionId && <div className={'text-right xl:text-left text-90 text-gray-200'}>{regionId}</div>}
      </DataTableValue>
    </DataTableCell>
  )
}

interface TabContentWrapperProps {
  verticalPadding?: boolean
}

export const TabContent = styled.div<TabContentWrapperProps>`
  ${tw`px-1 sm:px-6 lg:px-8`}
  ${({ verticalPadding }) => verticalPadding && tw`py-8 sm:px-8 lg:px-10`}
`

interface TabNoDataMessageProps {
  selectedVendors: Vendor[]
  vendor: Vendor
}

export const TabNoDataMessage = ({ selectedVendors, vendor }: TabNoDataMessageProps) => {
  const { t } = useTranslation()
  return (
    <div className={'py-6'}>
      {!selectedVendors.includes(vendor) && vendor !== Vendor.ALL ? (
        <GrayText className={'py-4 px-8 w-full text-center'}>{`${t('common.missingInfoIntro')} ${vendor} ${t(
          'common.missingInfoEnding'
        )}`}</GrayText>
      ) : (
        <MissingDataNotification justify={'center'} />
      )}
    </div>
  )
}
