import { CancelToken } from 'axios'
import { client } from './client'
import { FilterOption, GroupedOptions } from '../components/shared/filters/FilterSelect'
import { Vendor } from '../utils/vendors'
import { DataBlockData } from '../components/shared/containers/DataBlock'
import { CurrencyFilterOption } from '../components/shared/filters/Filters'
import { CurrencyId } from '../utils/Currency'
import { TimeframeOption } from '../utils/classes'

export const getCostFilterData = async (projects: string[], cancelToken: CancelToken): Promise<CostFilterData> => {
  return await client
    .post<CostFilterData>(`/costs/filters/options`, projects, {
      cancelToken
    })
    .then(resp => resp.data)
}

export const saveCostFilterPreset = async (
  filter: CostFilterPreset,
  cancelToken: CancelToken
): Promise<CostFilterPreset> => {
  const resp = await client.post<CostFilterPreset>('/costs/filters/presets', filter, {
    cancelToken
  })
  return resp.data
}

export const getCostFilterPresets = async (cancelToken: CancelToken): Promise<CostFilterPreset[]> => {
  const resp = await client.get<CostFilterPreset[]>('/costs/filters/presets', {
    cancelToken
  })
  return resp.data
}

export const deleteCostFilter = async (filterName: string, cancelToken: CancelToken): Promise<void> => {
  const resp = await client.delete<void>(`/costs/filters/${filterName}`, {
    cancelToken
  })
  return resp.data
}

export interface CostFilterPreset {
  name: string
  vendors: FilterOption[]
  projects: FilterOption[]
  resourceGroups: FilterOption[]
  services: FilterOption[]
  tags: FilterOption[]
  currency: CurrencyFilterOption
}

export const getCostsTotalSummary = async (
  vendors: Vendor[],
  projects: string[],
  resourceGroups: string[],
  services: string[],
  tags: TagFilter[],
  currency: CurrencyId,
  cancelToken: CancelToken
): Promise<CostTotalSummary> => {
  const resp = await client.post<CostTotalSummary>(
    '/costs/current-costs-summary',
    {
      vendors,
      projects,
      resourceGroups,
      services,
      tags,
      currency
    },
    {
      cancelToken: cancelToken
    }
  )
  return resp.data
}

export const getCostChartData = async (
  vendors: Vendor[],
  projects: string[],
  resourceGroups: string[],
  services: string[],
  tags: TagFilter[],
  currency: CurrencyId,
  cancelToken: CancelToken
): Promise<CostChartDataResponse> => {
  const resp = await client.post<CostChartDataResponse>(
    `/costs/chart-data`,
    {
      vendors,
      projects,
      resourceGroups,
      services,
      tags,
      currency
    },
    {
      cancelToken: cancelToken
    }
  )
  return resp.data
}

export const getCostSummaryTabData = async (
  vendors: Vendor[],
  projects: string[],
  resourceGroups: string[],
  services: string[],
  tags: TagFilter[],
  currency: CurrencyId,
  timeframe: TimeframeOption,
  cancelToken: CancelToken
): Promise<CostSummaryTabResponse> => {
  const resp = await client.post<CostSummaryTabResponse>(
    'costs/tabs/summary',
    {
      filters: {
        vendors,
        projects,
        resourceGroups,
        services,
        tags,
        currency
      },
      timeframe
    },
    { cancelToken }
  )
  return resp.data
}

export const getCostBreakdown = async (
  vendors: Vendor[],
  projects: string[],
  resourceGroups: string[],
  services: string[],
  tags: TagFilter[],
  currency: CurrencyId,
  timeframe: TimeframeOption,
  cancelToken: CancelToken
): Promise<CostVendor[]> => {
  const resp = await client.post<CostVendor[]>(
    `/costs/tabs/vendor-breakdown`,
    {
      filters: {
        vendors,
        projects,
        resourceGroups,
        services,
        tags,
        currency
      },
      timeframe
    },
    {
      cancelToken: cancelToken
    }
  )
  return resp.data
}

export const downloadCostsCsv = async (
  vendors: Vendor[],
  projects: string[],
  resourceGroups: string[],
  services: string[],
  tags: TagFilter[],
  currency: CurrencyId,
  timeframe: CostCsvTimeFrame,
  cancelToken: CancelToken
) => {
  const resp = await client.post<string>(
    `/costs/download-csv`,
    {
      filters: {
        vendors,
        projects,
        resourceGroups,
        services,
        tags,
        currency
      },
      timeframe
    },
    {
      cancelToken: cancelToken,
      responseType: 'blob'
    }
  )
  const url = window.URL.createObjectURL(new Blob([resp.data]))
  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', `spotter_costs_${timeframe.toLowerCase()}_${new Date().toLocaleDateString()}.csv`)
  document.body.appendChild(link)
  link.click()
}

export enum CostCsvTimeFrame {
  LAST_MONTH = 'LAST_MONTH',
  CURRENT_YEAR = 'CURRENT_YEAR',
  LAST_YEAR = 'LAST_YEAR',
  ALL_TIME = 'ALL_TIME'
}

export interface CostTotalSummary {
  lastMonth: number
  currentMonth: number
  changePercent: number
  initialForecast: number | null
  currentForecast: number | null
  forecastPercent: number | null
  currency: CurrencyId
  vendorSummaries: VendorCostSummary[]
}

export interface VendorCostSummary {
  vendor: Vendor
  projectCount: number
  lastMonthCosts: number | null
  currentCosts: number | null
  initialForecast: number | null
  currentForecast: number | null
  currency: CurrencyId
  forecastPercent: number | null
}

export interface TopCostsByLabel {
  projects: DataBlockData[]
  services: DataBlockData[]
  tags: DataBlockData[]
}

export interface CostSummaryTabResponse {
  lastMonth: TopCostsByLabel
  currentMonth: TopCostsByLabel
}

export interface CostVendor {
  vendor: Vendor
  previousCost: number
  currentCost: number
  forecastCost: number | null
  forecastChange: number | null
  currency: CurrencyId
  projects: CostProject[]
}

export interface CostProject {
  id?: string
  name?: string
  previousCost: number
  currentCost: number
  forecastCost: number | null
  forecastChange: number | null
  currency: CurrencyId
  chartData: CostEntry[]
  services: CostService[]
  resourceGroups?: CostProject[]
}

export interface CostService {
  name: string
  currentCost: number
  previousCost: number
  costsChange: number
  currency: CurrencyId
  costs: CostEntry[]
}

export interface CostChartDataResponse {
  currentTotal: number
  lastYearCurrent: number
  changeFromLastYear: number | null
  chartEntries: VendorCostEntries[]
}

export interface VendorCostEntries {
  vendor: Vendor
  history: CostEntry[]
  forecast: CostEntry[]
  initForecast: CostEntry[]
}

export interface CostEntry {
  date: string
  amount: number
  currency: CurrencyId
}

export interface CostFilterData {
  projectOptions: GroupedOptions[]
  resourceGroupOptions: FilterOption[]
  serviceOptions: GroupedOptions[]
  tagOptions: GroupedOptions[]
  currencyOptions: CurrencyFilterOption[]
}

interface TagFilter {
  vendor: Vendor
  tagKey: string
  tagValues: string[]
}
