import { UserRole } from '../../../../api/auth'
import { VendorAccess } from './rolesAndProjectAccess/VendorAccess'
import { Vendor } from '../../../../utils/vendors'
import React, { useContext } from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'
import { OrganizationUser } from '../../../../api/admin/users'
import { UserInfoContext } from '../../../../state/context/UserInfoContext'
import { Roles } from './rolesAndProjectAccess/Roles'
import { ACCESS_RIGHT_ROLES, ADMIN_ROLES } from '../../roleConstants'
import { AdminTabContent } from '../../adminStyles'

interface EditAccessRightsProps {
  user: OrganizationUser
  setUser: (user: OrganizationUser) => void
}

export const EditAccessRights = ({ user, setUser }: EditAccessRightsProps) => {
  const { authInfo } = useContext(UserInfoContext)
  const userEditableRoles = user.roles.filter(role => ACCESS_RIGHT_ROLES.includes(role))

  return (
    <Wrapper
      divided={
        authInfo.id !== user.id &&
        (authInfo.roles.filter(role => ADMIN_ROLES.includes(role)).length > 1 ||
          authInfo.roles.includes(UserRole.ADMIN) ||
          userEditableRoles.length === 0 ||
          userEditableRoles.length > 1 ||
          user.roles.some(role => role === UserRole.ADMIN))
      }
    >
      {authInfo.roles.includes(UserRole.ADMIN) && (
        <AdminTabContent>
          <Roles user={user} setUser={setUser} />
        </AdminTabContent>
      )}
      {authInfo.awsAccess && authInfo.awsIntegration && !authInfo.roles.find(role => role === UserRole.AWS_USER) && (
        <VendorAccess
          vendor={Vendor.AWS}
          editedUser={user}
          setEditedUser={setUser}
          permittedProjects={user.permittedAwsAccounts.map(account => ({
            vendorTenantId: account.masterAccountId,
            value: account.id,
            label: account.name
          }))}
        />
      )}
      {authInfo.azureAccess &&
        authInfo.azureIntegration &&
        !authInfo.roles.find(role => role === UserRole.AZURE_USER) && (
          <VendorAccess
            vendor={Vendor.AZURE}
            editedUser={user}
            setEditedUser={setUser}
            permittedProjects={user.permittedAzureSubs.map(sub => ({
              vendorTenantId: sub.azureTenantId,
              value: sub.id,
              label: sub.name
            }))}
          />
        )}
      {authInfo.gcpAccess && authInfo.gcpIntegration && !authInfo.roles.find(role => role === UserRole.GCP_USER) && (
        <VendorAccess
          vendor={Vendor.GCP}
          editedUser={user}
          setEditedUser={setUser}
          permittedProjects={user.permittedGcpProjects.map(project => ({
            vendorTenantId: project.organizationId,
            value: project.id,
            label: project.name
          }))}
        />
      )}
    </Wrapper>
  )
}

interface WrapperProps {
  divided: boolean
}

const Wrapper = styled.div<WrapperProps>`
  ${tw`flex flex-col`}
  ${({ divided }) => divided && tw`divide-y divide-gray-500`}
`
