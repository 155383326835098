import tw from 'twin.macro'
import { CustomIcon } from '../CustomIcon'
import open from '../../../assets/svg/directional/caret-heavy.svg'
import React from 'react'
import styled from 'styled-components'
import close from '../../../assets/svg/actions/close-heavy.svg'
import { ClearIndicatorProps } from 'react-select'

export const selectMenuListStyles = 'border border-gray-500 bg-gray-700 shadow-lg'

export const selectControlStyles =
  'text-gray-50 pl-3 rounded border border-gray-400/60 bg-gray-700/60 hover:cursor-pointer hover:border-gray-300'

export const selectGroupHeadingStyles = 'pl-3 pb-1 text-75 text-gray-50 font-bold'

export const selectOptionStyles =
  'py-2.5 px-4 w-full text-gray-100 hover:text-gray-50 hover:bg-gray-500/40 hover:cursor-pointer'

export const selectNoOptionsStyles = 'py-2.5 px-4 w-full text-gray-200 hover:text-gray-200 hover:bg-transparent'

export const SelectDropdownIndicator = () => {
  return (
    <FilterIndicatorWrapper>
      <FilterActionIcon type={'open'} />
    </FilterIndicatorWrapper>
  )
}

export const SelectIndicatorSeparator = () => {
  return <div className={'h-[60%] bg-gray-100 w-1px'} />
}

export const SelectClearIndicator = (props: ClearIndicatorProps) => {
  return (
    <FilterIndicatorWrapper
      onMouseDown={event => {
        props.clearValue()
        event.preventDefault()
        event.stopPropagation()
      }}
    >
      <FilterActionIcon type={'clear'} />
    </FilterIndicatorWrapper>
  )
}

interface IndicatorIconProps {
  clickHandler?: () => void
  type: 'open' | 'clear'
}

export const FilterActionIcon = ({ clickHandler, type }: IndicatorIconProps) => {
  if (type === 'open')
    return <CustomIcon styles={'w-4 h-4 bg-gray-200 group-hover:bg-gray-50'} path={open} onClick={clickHandler} />

  return <CustomIcon styles={'w-4 h-4 bg-gray-200 hover:bg-gray-50'} path={close} onClick={clickHandler} />
}

const FilterIndicatorWrapper = styled.div`
  ${tw`p-3`}
`
