import { useTranslation } from 'react-i18next'
import { useCancelToken } from '../../api/client'
import React, { useEffect, useState } from 'react'
import { getOrganizationAdmins, UserContact } from '../../api/home'
import { DataContainer } from '../shared/containers/DataContainer'
import { CapitalizedText, GrayText } from '../shared/TextComponents'
import styled from 'styled-components'
import tw from 'twin.macro'
import { useErrorHandling } from '../../hooks/handleError'
import { Loading } from '../shared/Loading'
import axios from 'axios'
import { ScrollContainer } from '../shared/containers/ScrollContainer'

export const AdminContacts = () => {
  const { t } = useTranslation()
  const { createCancelToken } = useCancelToken()
  const handleError = useErrorHandling()
  const [loading, setLoading] = useState(true)
  const [adminContacts, setAdminContacts] = useState<UserContact[]>([])

  useEffect(() => {
    const cancelToken = createCancelToken()
    setLoading(true)
    getOrganizationAdmins(cancelToken.token)
      .then(admins => {
        setAdminContacts(admins)
        setLoading(false)
      })
      .catch(error => {
        if (!axios.isCancel(error)) setLoading(false)
        handleError(error)
      })

    return () => {
      cancelToken.cancel()
      setLoading(false)
    }
  }, [createCancelToken, handleError])

  if (loading) {
    return (
      <Container>
        <DataContainer>
          <Loading paddingY={'0.75rem'} />
        </DataContainer>
      </Container>
    )
  }

  return (
    <Container>
      <DataContainer flexCol={false} looseSpacing={true}>
        <div className={'flex flex-col w-full gap-4 items-center'}>
          <GrayText className={'text-center'}>{t(`common.noAccessRights`)}</GrayText>
          <Contacts adminContacts={adminContacts} />
        </div>
      </DataContainer>
    </Container>
  )
}

const Container = styled.div`
  > div {
    ${tw`rounded-t-none`}
  }
`

interface ContactsProps {
  adminContacts: UserContact[]
}

const Contacts = ({ adminContacts }: ContactsProps) => {
  const { t } = useTranslation()

  return (
    <div className={'border border-gray-500 rounded divide-y divide-gray-500 w-fit lg:w-full lg:max-w-200'}>
      <Row className={'bg-gray-500 bg-opacity-30'}>
        <GrayText>{t('common.name')}</GrayText>
        <GrayText>{t('common.email')}</GrayText>
        <GrayText>{t('admin.usersList.roles')}</GrayText>
      </Row>
      <ScrollContainer className={'divide-y divide-gray-500 max-h-50'}>
        {adminContacts.map(admin => (
          <Row key={admin.emailAddress}>
            <div>
              {admin.firstName} {admin.lastName}
            </div>
            <div className={'break-all'}>{admin.emailAddress}</div>
            <div>
              {admin.roles.map(role => (
                <CapitalizedText capitalizeAll={true} key={role}>
                  {t(`admin.roles.${role}`)}
                </CapitalizedText>
              ))}
            </div>
          </Row>
        ))}
      </ScrollContainer>
    </div>
  )
}

const Row = styled.div`
  ${tw`grid grid-cols-3 gap-x-4 py-2 px-4 w-full text-90 items-center`}
`
