import { CancelToken } from 'axios'
import { client } from '../client'
import { Vendor } from '../../utils/vendors'
import { DataBlockData } from '../../components/shared/containers/DataBlock'
import { Timescale } from '../../utils/classes'

export const getAwsAzureComplianceTotalSummary = async (
  vendors: Vendor[],
  projects: string[],
  resourceGroups: string[],
  standards: string[],
  cancelToken: CancelToken
): Promise<AwsAzureComplianceTotalSummary> => {
  return await client
    .post(`/compliance/aws-azure-total-summary`, { vendors, projects, resourceGroups, standards }, { cancelToken })
    .then(resp => resp.data)
}

export const getGcpComplianceTotalSummary = async (
  vendors: Vendor[],
  projects: string[],
  resourceGroups: string[],
  standards: string[],
  cancelToken: CancelToken
): Promise<GcpComplianceTotalSummary> => {
  const resp = await client.post(
    'compliance/gcp-total-summary',
    { vendors, projects, resourceGroups, standards },
    { cancelToken }
  )
  return resp.data
}

export const getComplianceSecuritySummary = async (
  vendors: Vendor[],
  projects: string[],
  resourceGroups: string[],
  standards: string[],
  cancelToken: CancelToken
): Promise<ComplianceSecuritySummary[]> => {
  return await client
    .post('/compliance/security-summary', { vendors, projects, resourceGroups, standards }, { cancelToken })
    .then(resp => resp.data)
}

export const getComplianceStandardSummary = async (
  vendors: Vendor[],
  projects: string[],
  resourceGroups: string[],
  standards: string[],
  cancelToken: CancelToken
): Promise<DataBlockData[]> => {
  return await client
    .post('compliance/standard-summary', { vendors, projects, resourceGroups, standards }, { cancelToken })
    .then(resp => resp.data)
}

export const getComplianceSummaryChartResults = async (
  vendors: Vendor[],
  projects: string[],
  resourceGroups: string[],
  standards: string[],
  timescale: Timescale,
  cancelToken: CancelToken
): Promise<ComplianceChartData[]> => {
  const resp = await client.post(
    `/compliance/tabs/history-chart/${timescale}`,
    { vendors, projects, resourceGroups, standards },
    { cancelToken }
  )
  return resp.data
}

export interface AwsAzureComplianceTotalSummary {
  totalScore: number
  totalResources: number
  totalControls: number
  compliantControls: number
  cisScore: number
  cisResources: number
  cisControls: number
  compliantCisControls: number
}

export interface ComplianceSecuritySummary {
  vendor: Vendor
  percent: number
  compliantCount: number
  totalCount: number
}

export interface ComplianceChartData {
  vendor: Vendor
  chartEntries: ComplianceChartEntry[]
}

export interface ComplianceChartEntry {
  vendor: Vendor
  compliantPercent: number | null
  compliantCount: number
  totalCount: number | null
  date: string
}

export interface GcpComplianceTotalSummary {
  cisFindingsCount: number
  findingsCount: number
  standardCount: number
  activeAssetsCount: number
  assetScore: number
  cisAssetsCount: number
}
