import React, { useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { CountUp } from '../shared/CountUp'
import { useCancelToken } from '../../api/client'
import { useErrorHandling } from '../../hooks/handleError'
import { HomeNoDataSummaryContainer, HomeSummaryContainer, SummaryAmount, SummaryText } from './HomeSharedComponents'
import { getHomeTicketCount } from '../../api/home'

export const TicketSummary = () => {
  const { t } = useTranslation()
  const [ticketCount, setTicketCount] = useState<number | null>(null)
  const { createCancelToken } = useCancelToken()
  const [loading, setLoading] = useState<boolean>(true)
  const handleError = useErrorHandling()

  useEffect(() => {
    setLoading(true)
    const cancelToken = createCancelToken()
    getHomeTicketCount(cancelToken.token)
      .then(setTicketCount)
      .catch(handleError)
      .finally(() => setLoading(false))

    return () => {
      cancelToken.cancel()
      setLoading(false)
      setTicketCount(null)
    }
  }, [createCancelToken, handleError])

  if (loading || ticketCount === null)
    return <HomeNoDataSummaryContainer title={t('home.ticketSummary.title')} loading={loading} />

  return (
    <HomeSummaryContainer title={t('home.ticketSummary.title')} isClickable={true}>
      <SummaryAmount color={'gradient-primary'} textSize={'extra-large'}>
        <CountUp id={'qa-home-ticket-count'} countTo={ticketCount} />
      </SummaryAmount>
      <SummaryText>
        <Trans>{t('home.ticketSummary.description', { count: ticketCount })}</Trans>
      </SummaryText>
    </HomeSummaryContainer>
  )
}
