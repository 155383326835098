import { Tree } from '../../../shared/Tree'
import React from 'react'
import { TabNoDataMessage } from '../../../shared/tabs/TabSharedComponents'
import { Vendor } from '../../../../utils/vendors'
import { AzureTabCommitments } from './AzureTabCommitments'
import { AzureTabRightsizing } from './AzureTabRightsizing'
import { AzureRecommendations } from '../../../../api/optimization/azure'
import { AzureTabUtilization } from './AzureTabUtilization'

interface AzureTabProps {
  selectedVendors: Vendor[]
  data: AzureRecommendations | null
}

export const OptimizationAzureTab = ({ selectedVendors, data }: AzureTabProps) => {
  if (!data || (!data.commitments.recommendations.length && !data.rightsizing.length && !data.utilization.length))
    return <TabNoDataMessage selectedVendors={selectedVendors} vendor={Vendor.AZURE} />

  return (
    <Tree>
      {data.commitments.recommendations.length > 0 && <AzureTabCommitments data={data.commitments} />}
      {data.rightsizing.length > 0 && <AzureTabRightsizing data={data.rightsizing} />}
      {data.utilization.length > 0 && <AzureTabUtilization data={data.utilization} />}
    </Tree>
  )
}
