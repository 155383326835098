import React, { useRef } from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'
import { CSSTransition } from 'react-transition-group'

export enum positionType {
  'FIXED',
  'ABSOLUTE'
}

interface Props {
  clickHandler?: () => void
  visible: boolean
  zIndex?: number
  rounded?: boolean
  position?: positionType
}

export const BackgroundOverlay = ({
  clickHandler,
  visible,
  zIndex = 810,
  rounded = false,
  position = positionType.FIXED
}: Props) => {
  const nodeRef = useRef(null)

  return (
    <CSSTransition nodeRef={nodeRef} in={visible} timeout={1000}>
      {() => {
        return (
          visible && (
            <Overlay ref={nodeRef} zIndex={zIndex} rounded={rounded} onClick={clickHandler} position={position} />
          )
        )
      }}
    </CSSTransition>
  )
}

interface OverlayProps {
  zIndex: number
  rounded: boolean
  position: positionType
}

const Overlay = styled.div<OverlayProps>`
  ${tw`inset-0 w-full bg-gray-800 transition-all ease-in-out`}
  ${props => (props.position === positionType.FIXED ? tw`fixed h-screen` : tw`absolute`)}
  ${props => props.rounded && tw`rounded-lg`}
  &.enter {
    ${tw`bg-opacity-0`}
    z-index: ${props => props.zIndex};
  }

  &.enter-active {
    ${tw`bg-opacity-90 transition-all duration-500 ease-in-out`}
    z-index: ${props => props.zIndex};
  }

  &.enter-done {
    ${tw`bg-opacity-90`}
    z-index: ${props => props.zIndex};
  }

  &.exit {
    ${tw`bg-opacity-90`}
    z-index: ${props => props.zIndex};
  }

  &.exit-active {
    ${tw`bg-opacity-0 transition-all duration-500 ease-in-out`}
    z-index: ${props => props.zIndex};
  }

  &.exit-done {
    ${tw`bg-opacity-0 z-hidden`}
  }
`
