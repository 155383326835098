import { CancelToken } from 'axios'
import { client } from './client'
import { UserRole } from './auth'

export const getSystemAdminTenantOptions = async (cancelToken: CancelToken): Promise<Tenant[]> => {
  const result = await client.get('system-admin/tenant-options', {
    cancelToken: cancelToken
  })
  return result.data
}

export const changeCurrentTenant = async (cancelToken: CancelToken, tenantId: string): Promise<string> => {
  const result = await client.post('system-admin/change-tenant', tenantId, {
    cancelToken: cancelToken
  })
  return result.data
}

export const systemAdminCreateUser = async (cancelToken: CancelToken, user: CreateUserRequest): Promise<string> => {
  const result = await client.post('system-admin/create-user', user, {
    cancelToken: cancelToken
  })

  return result.data
}

export const getUsersAsSystemAdmin = async (cancelToken: CancelToken): Promise<EndUser[]> => {
  const result = await client.get<EndUser[]>('system-admin/users', {
    cancelToken: cancelToken
  })

  return result.data.map(user => ({
    ...user,
    lastLoginAt: user.lastLoginAt && new Date(user.lastLoginAt)
  }))
}

export interface EndUser {
  id: string
  firstName: string
  lastName: string
  emailAddress: string
  tenantName: string
  tenantId: string
  roles: UserRole[]
  isLoggedIn: boolean
  lastLoginAt: Date | undefined
  updatedAt: Date
}

export interface CreateUserRequest {
  emailAddress: string
  firstName: string
  lastName: string
  tenantId: string
}

export interface Tenant {
  id: string
  name: string
}
