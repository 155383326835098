import { ToggleGroup, ToggleGroupOption } from '../buttons/ToggleGroup'
import { useTranslation } from 'react-i18next'
import { CapitalizedText, GrayText } from '../TextComponents'
import React from 'react'

interface TimeframeFilterProps {
  timeframeOptions: ToggleGroupOption[]
  additionalInfo?: string
}

export const TimeframeFilter = ({ timeframeOptions, additionalInfo }: TimeframeFilterProps) => {
  const { t } = useTranslation()
  return (
    <div className={'flex flex-col w-full gap-1'}>
      <div className={'flex justify-between w-full'}>
        <div className={'flex gap-2 items-center'}>
          <div className={'bg-primary-300 w-2.5 h-2.5 rounded-full'} />
          <CapitalizedText className={'text-90 text-gray-100'}>{t('filters.timeframe')}</CapitalizedText>
        </div>
        {additionalInfo && <GrayText className={'text-75 text-right'}>{additionalInfo}</GrayText>}
      </div>

      <ToggleGroup minWidth={'13rem'} maxWidth={'14rem'} buttons={timeframeOptions} />
    </div>
  )
}
