import serviceRequest from '../../../assets/svg/objects/service-tray.svg'
import incident from '../../../assets/svg/symbols/incident.svg'
import low from '../../../assets/svg/symbols/alert.svg'
import medium from '../../../assets/svg/objects/flame.svg'
import high from '../../../assets/svg/objects/volcano.svg'
import urgent from '../../../assets/svg/objects/skull.svg'
import open from '../../../assets/svg/actions/open-in-new-tab.svg'
import styled from 'styled-components'
import tw from 'twin.macro'
import React, { useContext, useState } from 'react'
import { FreshTicket } from '../../../api/tickets'
import { useTranslation } from 'react-i18next'
import { UserInfoContext } from '../../../state/context/UserInfoContext'
import { CustomIcon, IconType } from '../../shared/CustomIcon'

interface TableProps {
  tickets: FreshTicket[]
}

export const TicketTable = ({ tickets }: TableProps) => {
  return (
    <Table>
      {tickets.map(ticket => (
        <Ticket key={ticket.ticketId} ticket={ticket} />
      ))}
    </Table>
  )
}

const Table = styled.div`
  ${tw`grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 w-full gap-4`}
`

interface TicketProps {
  ticket: FreshTicket
}

const Ticket = ({ ticket }: TicketProps) => {
  const [selected, setSelected] = useState(false)

  return (
    <TicketContainer key={ticket.ticketId}>
      <TicketSummary onClick={() => setSelected(!selected)}>
        <Wrapper>
          <Icon>
            <TicketIcon ticketElement={ticket.type} />
          </Icon>
          <SubjectWrapper>
            <Type>{ticket.type}</Type>
            <Subject>{ticket.subject}</Subject>
          </SubjectWrapper>
          <PriorityWrapper>
            <Icon>
              <TicketIcon ticketElement={ticket.priority} />
            </Icon>
            <Priority>{ticket.priority}</Priority>
          </PriorityWrapper>
        </Wrapper>
        <Description>
          {ticket.description.substr(0, 100)}
          {'...'}
        </Description>
      </TicketSummary>
      {selected ? <TicketDetails ticket={ticket} /> : null}
    </TicketContainer>
  )
}

const TicketContainer = styled.div`
  ${tw`flex flex-col`}
`

const TicketSummary = styled.div`
  ${tw`flex flex-col w-full gap-6 py-6 bg-gray-600 items-center rounded-md origin-top cursor-pointer animate-scaleDown shadow-container`}
`

const Wrapper = styled.div`
  ${tw`flex flex-row px-6 items-center w-full gap-4 justify-between`}
`

const Icon = styled.div`
  ${tw`items-center min-w-6`}
`

const SubjectWrapper = styled.div`
  ${tw`flex flex-col justify-start flex-grow`}
`

const Type = styled.div`
  ${tw`text-100 text-gray-200 font-light`}
`

const Subject = styled.div`
  ${tw`flex flex-row gap-1 text-100 w-full text-gray-50 font-medium`}
`

const PriorityWrapper = styled.div`
  ${tw`flex flex-col items-center gap-1 justify-center`}
`

const Priority = styled.div`
  ${tw`text-80 font-light text-gray-50`}
`

const Description = styled.div`
  ${tw`text-100 align-middle px-6 text-gray-200 justify-start w-full font-light overflow-hidden break-words`}
`

const TicketDetails = ({ ticket }: TicketProps) => {
  const { t } = useTranslation()
  const { authInfo } = useContext(UserInfoContext)

  const ticketDomain = authInfo.emailAddress.includes('@cloud2.fi') ? 'cloud2oy.freshservice.com' : ticket.domain

  return (
    <Container>
      <ResponsiveWrapper>
        <DetailsWrapper>
          <InnerWrapper>
            <div>{t('tickets.ticketDetails.id')}</div>
            <div>{t('tickets.ticketDetails.created')}</div>
            {ticket.status === 'OPEN' || ticket.status === 'PENDING' ? (
              <div>{t('tickets.ticketDetails.due')}</div>
            ) : ticket.status === 'RESOLVED' || ticket.status === 'CLOSED' ? (
              <div>{t('tickets.ticketDetails.resolved')}</div>
            ) : null}
            <div>{t('tickets.ticketDetails.category')}</div>
          </InnerWrapper>
          <InnerWrapper>
            <Detail>{ticket.ticketId}</Detail>
            <Detail>{ticket.ticketCreatedAt.substr(0, 10)}</Detail>
            {ticket.status === 'OPEN' || ticket.status === 'PENDING' ? (
              <Detail>{ticket.dueBy.substr(0, 10)}</Detail>
            ) : ticket.status === 'RESOLVED' || ticket.status === 'CLOSED' ? (
              <Detail>{ticket.ticketUpdatedAt.substr(0, 10)}</Detail>
            ) : null}
            {ticket.category === null ? <Detail>{' -'}</Detail> : <Detail>{ticket.category}</Detail>}
          </InnerWrapper>
        </DetailsWrapper>
        {ticket.domain && (
          <a target={'_blank'} rel='noreferrer' href={`https://${ticketDomain}/helpdesk/tickets/${ticket.ticketId}`}>
            <LinkToTab>
              {t('tickets.ticketDetails.openFreshTicket')}
              <CustomIcon iconType={IconType.VECTOR} path={open} styles={'w-3 h-3 bg-gray-50'} />
            </LinkToTab>
          </a>
        )}
      </ResponsiveWrapper>
      <DescriptionWrapper>
        <Heading>{t('tickets.ticketDetails.fullDescription')}</Heading>
        <FullDescription>{ticket.description}</FullDescription>
      </DescriptionWrapper>
    </Container>
  )
}

const Container = styled.div`
  ${tw`flex flex-col bg-gray-600 gap-6 p-6 w-full border-t border-gray-400 border-dashed text-90 origin-top animate-printEffect`}
`

const ResponsiveWrapper = styled.div`
  ${tw`flex flex-col xl:flex-row gap-4 xl:gap-0 items-start justify-between`}
`

const DetailsWrapper = styled.div`
  ${tw`flex flex-row gap-4`}
`

const InnerWrapper = styled.div`
  ${tw`flex flex-col items-start`}
`

const Detail = styled.span`
  ${tw`font-light text-gray-200`}
`

const DescriptionWrapper = styled.div`
  ${tw`flex flex-col gap-4`}
`

const LinkToTab = styled.span`
  ${tw`flex flex-row text-center w-full text-75 gap-1`}
`

const Heading = styled.div`
  ${tw`font-medium text-gray-50`}
`

const FullDescription = styled.div`
  ${tw`font-light text-gray-200 break-words`}
`

interface TicketIconProps {
  ticketElement: string
}

const TicketIcon = ({ ticketElement }: TicketIconProps) => {
  return (
    <CustomIcon
      iconType={IconType.VECTOR}
      path={
        ticketElement === 'URGENT'
          ? urgent
          : ticketElement === 'HIGH'
            ? high
            : ticketElement === 'MEDIUM'
              ? medium
              : ticketElement === 'LOW'
                ? low
                : ticketElement === 'Incident'
                  ? incident
                  : ticketElement === 'Service Request'
                    ? serviceRequest
                    : ''
      }
      styles={'w-6 h-6 bg-gray-50'}
    />
  )
}
