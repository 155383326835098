import React, { useEffect, useState } from 'react'
import { Vendor } from '../../../utils/vendors'
import { TabNoDataMessage } from '../../shared/tabs/TabSharedComponents'
import { MissingDataNotification } from '../../shared/MissingDataNotification'
import { TreeWrapper } from '../../shared/Tree'
import {
  AwsComplianceFindingsByControl,
  AzureComplianceFindingsByControl,
  GcpComplianceFindingsByControl
} from '../../../api/compliance/tabs'
import { SearchInput, SearchInputProps } from '../../shared/filters/SearchInput'
import { useTranslation } from 'react-i18next'
import { ComplianceStatus } from '../ComplianceStatusIndicator'
import { GrayText } from '../../shared/TextComponents'
import { useScreenSize } from '../../../hooks/useScreenSize'
import { twLg } from '../../../design/constants'
import { Loading } from '../../shared/Loading'
import { ComplianceControl } from './ComplianceControl'
import { formatNumber } from '../../../utils/formats'

const compliantFindingStates = [ComplianceStatus.PASSED, ComplianceStatus.Compliant, ComplianceStatus.INACTIVE]

const nonCompliantFindingStates = [ComplianceStatus.FAILED, ComplianceStatus.NonCompliant, ComplianceStatus.ACTIVE]

interface VendorTabProps {
  selectedVendors: Vendor[]
  findings: AwsComplianceFindingsByControl[] | AzureComplianceFindingsByControl[] | GcpComplianceFindingsByControl[]
  vendor: Vendor
  loading: boolean
}

export const ComplianceVendorTab = ({ selectedVendors, findings, vendor, loading }: VendorTabProps) => {
  const { t } = useTranslation()
  const [controlSearchText, setControlSearchText] = useState('')
  const [resourceSearchText, setResourceSearchText] = useState('')
  const [filteredFindingsByControl, setFilteredFindingsByControl] = useState<
    AwsComplianceFindingsByControl[] | AzureComplianceFindingsByControl[] | GcpComplianceFindingsByControl[]
  >(findings)
  const [matchingResources, setMatchingResources] = useState<number | null>(null)

  useEffect(() => {
    if (resourceSearchText === '' && controlSearchText === '') {
      setMatchingResources(null)
      setFilteredFindingsByControl(findings)
    } else {
      const filtered = filterFindings(findings, resourceSearchText, controlSearchText)
      const resources = filtered.map(control => control.findings.map(finding => finding.resource.id)).flat()
      const matches = new Set(resources).size
      setMatchingResources(matches)
      setFilteredFindingsByControl(filtered)
    }
  }, [findings, controlSearchText, resourceSearchText])

  useEffect(() => {
    setResourceSearchText('')
    setControlSearchText('')
    setMatchingResources(null)
    setFilteredFindingsByControl(findings)
  }, [findings])

  if (loading || !selectedVendors.includes(vendor) || (selectedVendors.includes(vendor) && !findings.length))
    return (
      <TreeWrapper>
        <TabHeader
          vendor={vendor}
          controlSearchText={controlSearchText}
          setControlSearchText={setControlSearchText}
          resourceSearchText={resourceSearchText}
          setResourceSearchText={setResourceSearchText}
          matches={null}
        />
        {loading ? (
          <Loading />
        ) : !selectedVendors.includes(vendor) ? (
          <TabNoDataMessage vendor={vendor} selectedVendors={selectedVendors} />
        ) : (
          <MissingDataNotification />
        )}
      </TreeWrapper>
    )

  return (
    <TreeWrapper>
      <TabHeader
        vendor={vendor}
        controlSearchText={controlSearchText}
        setControlSearchText={setControlSearchText}
        resourceSearchText={resourceSearchText}
        setResourceSearchText={setResourceSearchText}
        matches={matchingResources}
      />
      <TreeWrapper className={'py-4'}>
        {filteredFindingsByControl.length > 0 ? (
          filteredFindingsByControl.map((control, index) => (
            <ComplianceControl
              key={`${index}-${vendor}-${control.control}`}
              id={`qa-compliance-${vendor}-tab-content-${index}`}
              vendor={vendor}
              findingsByControl={control}
            />
          ))
        ) : (
          <MissingDataNotification displayText={t('compliance.tabs.common.nothingFound')} />
        )}
      </TreeWrapper>
    </TreeWrapper>
  )
}

type TabHeaderProps = {
  vendor: Vendor
  controlSearchText: string
  setControlSearchText: (text: string) => void
  resourceSearchText: string
  setResourceSearchText: (text: string) => void
  matches: number | null
}

const TabHeader = ({
  vendor,
  controlSearchText,
  setControlSearchText,
  resourceSearchText,
  setResourceSearchText,
  matches
}: TabHeaderProps) => {
  const { t } = useTranslation()
  return (
    <div className={'w-full border-b border-dotted border-gray-500'}>
      <div className={'flex gap-5 pt-4 pb-6 px-2 flex-wrap'}>
        <TabFilter
          id={`${vendor}-control`}
          inputLabel={t('compliance.tabs.common.controlSearchInputLabel')}
          placeholder={t('compliance.tabs.common.controlSearchInputPlaceholder')}
          searchText={controlSearchText}
          setSearchText={setControlSearchText}
        />
        <TabFilter
          id={`${vendor}-resource`}
          inputLabel={t('compliance.tabs.common.resourceSearchInputLabel')}
          placeholder={t('compliance.tabs.common.resourceSearchInputPlaceholder')}
          searchText={resourceSearchText}
          setSearchText={setResourceSearchText}
        />
        {matches !== null && (
          <ComplianceCountLabel count={matches} label={t('compliance.tabs.common.matchingResults')} />
        )}
      </div>
    </div>
  )
}

type TabFilterProps = SearchInputProps & {
  inputLabel: string
}

const TabFilter = ({ inputLabel, id, placeholder, searchText, setSearchText }: TabFilterProps) => {
  const screenSize = useScreenSize()
  return (
    <div className={'flex flex-col gap-0.5'}>
      <GrayText className={'text-90'}>{inputLabel}</GrayText>
      <SearchInput
        id={id}
        size={screenSize.width < twLg ? 'small' : 'default'}
        placeholder={placeholder}
        searchText={searchText}
        setSearchText={setSearchText}
      />
    </div>
  )
}

interface IndicatorLabelProps {
  count: number
  label: string
}

export const ComplianceCountLabel = ({ count, label }: IndicatorLabelProps) => {
  return (
    <div className={'flex gap-1.5 items-center text-gray-200 w-fit text-left text-90 lg:text-100 lg:w-max'}>
      <div className={'text-100 text-gray-50 lg:text-112'}>{formatNumber(count)}</div>
      {` ${label}`}
    </div>
  )
}

const filterFindings = (
  findings: AwsComplianceFindingsByControl[] | AzureComplianceFindingsByControl[] | GcpComplianceFindingsByControl[],
  resourceSearchText: string,
  controlSearchText: string
) => {
  return findings
    .map(control => {
      const findings = control.findings.filter(finding =>
        finding.resource.id.toLowerCase().includes(resourceSearchText.toLowerCase())
      )
      const compliantCount = findings.filter(finding => compliantFindingStates.includes(finding.status)).length
      if (findings.length)
        return {
          ...control,
          controlSeverity: 'severity' in findings[0] && findings[0].severity,
          compliantCount: compliantCount,
          totalCount: findings.length,
          percent: (compliantCount / findings.length) * 100,
          findings: findings
        }
      else return null
    })
    .filter(c => c && c.findings.length > 0 && c.control.toLowerCase().includes(controlSearchText.toLowerCase())) as
    | AwsComplianceFindingsByControl[]
    | AzureComplianceFindingsByControl[]
    | GcpComplianceFindingsByControl[]
}
