import React, { useContext, useEffect } from 'react'
import { MessageContext, MessageState, MessageType, SpotterMessage } from '../state/context/MessageContext'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { CapitalizedText, WhiteText } from '../components/shared/TextComponents'
import { CustomIcon, IconType } from '../components/shared/CustomIcon'
import clap from '../assets/svg/gestures/clap.svg'
import point from '../assets/svg/gestures/point.svg'
import wave from '../assets/svg/gestures/wave.svg'
import hand from '../assets/svg/gestures/stop.svg'
import { useLocation } from 'react-router-dom'
import { Trans, useTranslation } from 'react-i18next'
import { Env } from '../utils/env'
import styled from 'styled-components'
import tw from 'twin.macro'
import { Button, ButtonStyle, ButtonType } from '../components/shared/buttons/Button'
import close from '../assets/svg/actions/close.svg'
import { error100, primary400, success100, warn100 } from '../design/constants'

type ToastTypeStyle = {
  svg: string
  color: string
  hex: string
}

enum ContainerId {
  GLOBAL = 'global-container',
  DEFAULT = 'default-container'
}

export const GlobalMessage = () => {
  const { message } = useContext<MessageState>(MessageContext)
  const location = useLocation()

  const styleFromType = (type?: MessageType): ToastTypeStyle =>
    type === MessageType.SUCCESS
      ? {
          svg: clap,
          color: 'bg-success-100',
          hex: success100
        }
      : type === MessageType.ERROR
        ? {
            svg: hand,
            color: 'bg-error-100',
            hex: error100
          }
        : type === MessageType.WARNING
          ? {
              svg: point,
              color: 'bg-warn-100',
              hex: warn100
            }
          : {
              svg: wave,
              color: 'bg-primary-400',
              hex: primary400
            }

  useEffect(() => {
    if (location.pathname === '/logout') toast.dismiss()
    // @ts-ignore
    if (location.state && location.state.from === '/login') toast.dismiss()

    message?.type === MessageType.INFO && !message.globalMessage && toast.dismiss()
  }, [location])

  useEffect(() => {
    if (message) {
      const toastId = message?.globalMessage ? 'global' : crypto.randomUUID()
      const typeStyle = styleFromType(message.type)
      toast(<Message toastId={toastId} message={message} typeStyle={typeStyle} />, {
        toastId: toastId,
        containerId: message.globalMessage ? ContainerId.GLOBAL : ContainerId.DEFAULT,
        position: message.globalMessage ? 'top-center' : message.position ?? 'bottom-right',
        autoClose:
          message.type === MessageType.SUCCESS || message.type === MessageType.INFO
            ? message.globalMessage || message.type === MessageType.INFO
              ? 15000
              : 3000
            : false,
        progress: message.type === MessageType.SUCCESS || message.type === MessageType.INFO ? undefined : 1
      })
    }
  }, [message])

  const prodClassName = process.env.REACT_APP_ENV === Env.PROD ? 'm-0 mb-18' : 'mb-0'
  const toastClassName = 'rounded-md p-0 bg-transparent backdrop-filter backdrop-blur-md border-t border-gray-400'

  return (
    <>
      <ToastContainer
        containerId={ContainerId.GLOBAL}
        className={`min-w-100 w-fit max-w-[80vw] ${prodClassName}`}
        bodyClassName={'m-0 p-0'}
        toastClassName={toastClassName}
        progressStyle={{ background: styleFromType(message?.type).hex }}
        closeButton={false}
        newestOnTop={true}
        closeOnClick={false}
        draggable={false}
        pauseOnHover
      />
      <ToastContainer
        containerId={ContainerId.DEFAULT}
        className={`p-0 m-0 min-w-100 w-fit max-w-150 ${prodClassName}`}
        bodyClassName={'m-0 p-0'}
        toastClassName={toastClassName}
        progressStyle={{ background: styleFromType(message?.type).hex }}
        closeButton={false}
        newestOnTop={true}
        closeOnClick={false}
        draggable={false}
        pauseOnHover
      />
    </>
  )
}

interface MessageProps {
  toastId: string
  typeStyle: ToastTypeStyle
  message: SpotterMessage
}

export const Message = ({ toastId, typeStyle, message }: MessageProps) => {
  const { t } = useTranslation()
  const { setMessage } = useContext(MessageContext)
  const messageHeading = message.heading ? message.heading : t(`common.notificationHeading.${message.type}`)

  return (
    <div className={'flex w-full h-full justify-between bg-gray-600/50 p-2'}>
      <Wrapper>
        <CustomIcon iconType={IconType.VECTOR} path={typeStyle.svg} styles={`h-6 w-6 ${typeStyle.color}`} />
        <CapitalizedText className={'text-gray-50 font-semibold'}>{messageHeading}</CapitalizedText>
        <div className={'col-start-2 flex w-full flex-col gap-2'}>
          <ToastMessage>
            <Trans>{message.message}</Trans>
          </ToastMessage>
          {message.action}
        </div>
      </Wrapper>
      <Button
        buttonStyle={ButtonStyle.GHOST}
        clickHandler={() => {
          setMessage(null)
          toast.dismiss(toastId)
        }}
        type={ButtonType.ICON}
        value={<CustomIcon path={close} iconType={IconType.VECTOR} styles={'w-5 h-5 bg-gray-50'} />}
      />
    </div>
  )
}

const Wrapper = styled.div`
  ${tw`grid gap-x-3 gap-y-2 items-center w-full p-5 pt-4`}
  grid-template-columns: auto 1fr;
`

const ToastMessage = styled(CapitalizedText)`
  ${tw`text-gray-100 text-90`}
  strong {
    ${tw`text-gray-50`}
  }
`

interface ToastActionProps {
  label: string
  onClick: () => void
}

export const ToastActionComponent = ({ label, onClick }: ToastActionProps) => {
  return (
    <WhiteText
      className={
        'first-letter:capitalize font-semibold text-90 min-w-max cursor-pointer hover:text-primary-200 active:text-gray-50/60'
      }
      onClick={onClick}
    >
      {label}
    </WhiteText>
  )
}
