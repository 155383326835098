import {
  IntegrationAccountStatusRow,
  IntegrationDetailRow,
  IntegrationDetailRowsWrapper,
  IntegrationDetailsHeader
} from '../Shared'
import React, { useEffect, useState } from 'react'
import { GcpBillingAccount, GcpIntegration, GcpIntegrationData } from '../../../../api/admin/integrations/gcp'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { CustomInput, CustomLabel } from '../../../shared/filters/FormComponents'
import { AdminTabContent } from '../../adminStyles'

interface GcpIntegrationsAccountsProps {
  selectedIntegration: GcpIntegrationData
  editIntegrationAction: (name: string) => void
  editBillingAccountAction: (request: GcpBillingAccount) => void
  openAccountAddModal: (account?: GcpBillingAccount) => void
  openAccountDeleteModal: (account: GcpBillingAccount) => void
  setShowPrompt: (show: boolean) => void
}

export const GcpIntegrationAccounts = ({
  selectedIntegration,
  editIntegrationAction,
  openAccountAddModal,
  openAccountDeleteModal,
  setShowPrompt,
  editBillingAccountAction
}: GcpIntegrationsAccountsProps) => {
  const { t } = useTranslation()
  return (
    <>
      <OrganizationSection
        selectedIntegration={selectedIntegration}
        submitAction={editIntegrationAction}
        setShowPrompt={setShowPrompt}
      />
      <AdminTabContent>
        <IntegrationDetailsHeader
          header={t('admin.integrations.gcp.billingDetails')}
          headerAction={() => openAccountAddModal()}
        />
        {selectedIntegration.billingAccounts.map(account => (
          <EditBillingAccountForm
            key={`${account.accountId}-${account.projectId}-${account.tableId}`}
            billingAccount={account}
            submitAction={editBillingAccountAction}
            openAccountAddModal={openAccountAddModal}
            openAccountDeleteModal={openAccountDeleteModal}
            setShowPrompt={setShowPrompt}
          />
        ))}
      </AdminTabContent>
    </>
  )
}

interface OrganizationProps {
  selectedIntegration: GcpIntegration
  submitAction: (name: string) => void
  setShowPrompt: (show: boolean) => void
}

const OrganizationSection = ({ selectedIntegration, submitAction, setShowPrompt }: OrganizationProps) => {
  const { t } = useTranslation()
  const [isOnEditMode, setIsOnEditMode] = useState(false)
  const [defaultValues, setDefaultValues] = useState<GcpIntegration>({ ...selectedIntegration })
  const {
    register,
    handleSubmit,
    reset,
    formState: { isValid, isSubmitting, isDirty }
  } = useForm<GcpIntegration>({
    defaultValues: defaultValues
  })

  useEffect(() => {
    isDirty ? setShowPrompt(true) : setShowPrompt(false)
  }, [isDirty])

  const onSubmit = (data: GcpIntegration) => {
    submitAction(data.name)
    setDefaultValues(data)
    reset(data)
  }

  return (
    <form>
      <AdminTabContent>
        <IntegrationDetailsHeader
          header={t('admin.integrations.gcp.organization')}
          headerAction={'formActions'}
          isEditable={!selectedIntegration.deletedAt}
          isOnEditMode={isOnEditMode}
          disableSubmit={!isValid || isSubmitting}
          isDirty={isDirty}
          submitAction={event => {
            if (isOnEditMode) {
              setIsOnEditMode(false)
              handleSubmit(onSubmit)()
            } else {
              setIsOnEditMode(true)
            }
            event.preventDefault()
          }}
          resetAction={() => reset(defaultValues)}
        />
        <IntegrationDetailRowsWrapper
          hasActions={false}
          detailRows={
            <>
              <IntegrationDetailRow
                label={t('admin.integrations.gcp.organizationId')}
                value={selectedIntegration.organizationId}
              />
              {isOnEditMode ? (
                <div className={'p-2 bg-gray-500/20'}>
                  <CustomLabel>{t('admin.integrations.name')} *</CustomLabel>
                  <CustomInput
                    {...register('name', {
                      required: true,
                      minLength: 1
                    })}
                  />
                </div>
              ) : (
                <IntegrationDetailRow label={t('admin.integrations.name')} value={selectedIntegration.name} />
              )}
            </>
          }
        />
      </AdminTabContent>
    </form>
  )
}

interface EditBillingAccountProps {
  submitAction: (request: GcpBillingAccount) => void
  billingAccount: GcpBillingAccount
  openAccountAddModal: (account?: GcpBillingAccount) => void
  openAccountDeleteModal: (account: GcpBillingAccount) => void
  setShowPrompt: (show: boolean) => void
}

const EditBillingAccountForm = ({
  submitAction,
  billingAccount,
  openAccountAddModal,
  openAccountDeleteModal,
  setShowPrompt
}: EditBillingAccountProps) => {
  const { t } = useTranslation()
  const [isOnEditMode, setIsOnEditMode] = useState(false)
  const [defaultValues, setDefaultValues] = useState<GcpBillingAccount>({ ...billingAccount })
  const {
    register,
    handleSubmit,
    reset,
    formState: { isValid, isSubmitting, isDirty }
  } = useForm<GcpBillingAccount>({
    defaultValues: defaultValues
  })

  useEffect(() => {
    isDirty ? setShowPrompt(true) : setShowPrompt(false)
  }, [isDirty])

  const onSubmit = (data: GcpBillingAccount) => {
    submitAction(data)
    setDefaultValues(data)
    reset(data)
  }

  return (
    <form>
      <IntegrationDetailRowsWrapper
        hasActions={true}
        isOnEditMode={isOnEditMode}
        isDirty={isDirty}
        isRemoved={!!billingAccount.deletedAt}
        disableSubmit={isSubmitting || !isValid}
        submitAction={event => {
          if (isOnEditMode) {
            setIsOnEditMode(false)
            handleSubmit(onSubmit)()
          } else {
            setIsOnEditMode(true)
          }
          event?.preventDefault()
        }}
        resetAction={() => reset(defaultValues)}
        statusAction={event => {
          billingAccount.deletedAt ? openAccountAddModal(billingAccount) : openAccountDeleteModal(billingAccount)
          setIsOnEditMode(false)
          event?.preventDefault()
        }}
        detailRows={
          <>
            <IntegrationDetailRow label={t('admin.integrations.accountId')} value={billingAccount.accountId} />
            <IntegrationDetailRow label={t('admin.integrations.gcp.projectId')} value={billingAccount.projectId} />
            {isOnEditMode ? (
              <div className={'p-2 bg-gray-500/20'}>
                <CustomLabel>{t('admin.integrations.gcp.tableId')} *</CustomLabel>
                <CustomInput
                  {...register(`tableId`, {
                    required: true,
                    minLength: 1
                  })}
                />
              </div>
            ) : (
              <IntegrationDetailRow label={t('admin.integrations.gcp.tableId')} value={billingAccount.tableId} />
            )}
            <IntegrationAccountStatusRow accountDeleteDate={billingAccount.deletedAt} />
          </>
        }
      />
    </form>
  )
}
