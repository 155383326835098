import { NestedPageHeader } from '../shared/NestedPageHeader'
import { CapitalizedText, GrayText } from '../shared/TextComponents'
import { SubNavContentWrapper } from '../../layout/SubLayout'
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useCancelToken } from '../../api/client'
import { useErrorHandling } from '../../hooks/handleError'
import { getUsersByTenant, UsersByTenant } from '../../api/master-admin'
import { LabeledSearchInput } from '../shared/filters/SearchInput'
import { MissingDataNotification } from '../shared/MissingDataNotification'
import { DimmedListRow, DimmedRow, TenantHeader } from './Shared'
import { ScrollContainer } from '../shared/containers/ScrollContainer'
import { formatDate } from '../../utils/formats'
import { UserInfoContext } from '../../state/context/UserInfoContext'
import { CustomIcon, IconType } from '../shared/CustomIcon'
import editIcon from '../../assets/svg/actions/edit.svg'
import { Button, ButtonSize, ButtonStyle, ButtonType } from '../shared/buttons/Button'
import { useHistory } from 'react-router-dom'
import { EndUser, Tenant } from '../../api/system-admin'

export const MasterAdminUsersList = () => {
  const { t } = useTranslation()
  const { createCancelToken } = useCancelToken()
  const handleError = useErrorHandling()
  const [tenants, setTenants] = useState<UsersByTenant[]>([])
  const [filteredTenants, setFilteredTenants] = useState<UsersByTenant[]>([])
  const [selectedTenant, setSelectedTenant] = useState<Tenant>()
  const [searchText, setSearchText] = useState('')

  useEffect(() => {
    getUsersByTenant(createCancelToken().token).then(setTenants).catch(handleError)
  }, [createCancelToken, handleError])

  useEffect(() => {
    if (searchText !== '') {
      const filtered = tenants.filter(
        tenant =>
          tenant.tenant.name.toLowerCase().includes(searchText.toLowerCase()) ||
          tenant.users.some(
            user =>
              user.firstName.toLowerCase().includes(searchText.toLowerCase()) ||
              user.lastName.toLowerCase().includes(searchText.toLowerCase()) ||
              user.emailAddress.toLowerCase().includes(searchText.toLowerCase())
          )
      )
      setFilteredTenants(
        filtered.map(tenant => ({
          ...tenant,
          users: tenant.users.filter(
            user =>
              user.firstName.toLowerCase().includes(searchText.toLowerCase()) ||
              user.lastName.toLowerCase().includes(searchText.toLowerCase()) ||
              user.emailAddress.toLowerCase().includes(searchText.toLowerCase())
          )
        }))
      )
    } else setFilteredTenants(tenants)
  }, [tenants, searchText])

  return (
    <>
      <NestedPageHeader
        mainHeading={t('masterAdmin.users.heading')}
        capitalize={true}
        subHeading={<GrayText>{t('masterAdmin.users.subheading')} ⛵️</GrayText>}
      />
      <SubNavContentWrapper>
        <div className={'flex flex-col gap-12 w-full xl:max-w-[90%]'}>
          <LabeledSearchInput
            label={t('masterAdmin.users.searchUser')}
            placeholder={t('masterAdmin.users.searchPlaceholder')}
            searchText={searchText}
            setSearchText={setSearchText}
          />
          {!filteredTenants.length ? (
            <MissingDataNotification justify={'start'} displayText={t('admin.userNotFound_other')} />
          ) : (
            <div>
              {filteredTenants.map(tenant => (
                <TenantUsers
                  key={tenant.tenant.id}
                  usersByTenant={tenant}
                  selectedTenant={selectedTenant}
                  setSelectedTenant={setSelectedTenant}
                />
              ))}
            </div>
          )}
        </div>
      </SubNavContentWrapper>
    </>
  )
}

interface TenantUsersProps {
  usersByTenant: UsersByTenant
  selectedTenant: Tenant | undefined
  setSelectedTenant: (tenant: Tenant | undefined) => void
}

const TenantUsers = ({ usersByTenant, selectedTenant, setSelectedTenant }: TenantUsersProps) => {
  const { t } = useTranslation()
  const [showRolesFrom, setShowRolesFrom] = useState<string | undefined>()

  return (
    <div className={`flex flex-col p-2 w-full max-w-140`}>
      <TenantHeader
        tenant={usersByTenant.tenant}
        selectedTenant={selectedTenant}
        setSelectedTenant={setSelectedTenant}
        rightComponent={
          <GrayText className={'min-w-max'}>
            {t('masterAdmin.users.users')} ({usersByTenant.users.length})
          </GrayText>
        }
      />
      {selectedTenant?.id === usersByTenant.tenant.id &&
        (usersByTenant.users.length === 0 ? (
          <DimmedRow>
            <MissingDataNotification justify={'center'} displayText={t('admin.userNotFound_other')} />
          </DimmedRow>
        ) : (
          <ScrollContainer key={usersByTenant.tenant.id} className={'max-h-[70vh]'}>
            {usersByTenant.users.map(user => (
              <DimmedListRow key={user.id} className={'flex-col mr-2 p-4 gap-2 last:border-b last:border-gray-500'}>
                <UserContact user={user} />
                <UserRoles user={user} showRolesFrom={showRolesFrom} setShowRolesFrom={setShowRolesFrom} />
                <div className={'flex items-center justify-between w-full gap-6 text-90'}>
                  <GrayText>{t('admin.usersList.lastLogin')}</GrayText>
                  <GrayText>{formatDate(user.lastLoginAt, true, true, true)}</GrayText>
                </div>
              </DimmedListRow>
            ))}
          </ScrollContainer>
        ))}
    </div>
  )
}

interface UserContactProps {
  user: EndUser
}

const UserContact = ({ user }: UserContactProps) => {
  const { t } = useTranslation()
  const { authInfo } = useContext(UserInfoContext)
  const history = useHistory()
  return (
    <div className={'flex justify-between items-center gap-4 w-full'}>
      <div>
        <div>
          {`${user.firstName} ${user.lastName}`}{' '}
          <span className={'text-gray-200 text-90'}>{user.id === authInfo.id ? '(you)' : ''}</span>
        </div>
        <div className={'text-90 break-all'}>{user.emailAddress}</div>
      </div>
      <Button
        type={ButtonType.ICON}
        clickHandler={() => history.push(`${history.location.pathname}/${user.id}`)}
        buttonStyle={ButtonStyle.PRIMARY}
        value={
          <CustomIcon
            iconType={IconType.VECTOR}
            path={editIcon}
            alt={t('masterAdmin.userAccessDetails')}
            styles={'bg-gray-50 w-5 h-5'}
          />
        }
      />
    </div>
  )
}

interface UserRolesProps extends UserContactProps {
  showRolesFrom: string | undefined
  setShowRolesFrom: (id: string | undefined) => void
}

const UserRoles = ({ user, showRolesFrom, setShowRolesFrom }: UserRolesProps) => {
  const { t } = useTranslation()
  return (
    <div className={'flex items-center justify-between w-full gap-6 text-90'}>
      <GrayText>{t('admin.usersList.roles')}</GrayText>
      <div className={'relative'}>
        {user.roles.length > 2 && (
          <Button
            size={ButtonSize.XSMALL}
            buttonStyle={ButtonStyle.GHOST}
            value={
              showRolesFrom !== user.id
                ? t('masterAdmin.users.showCount', { count: user.roles.length })
                : t('common.hide')
            }
            clickHandler={() => (showRolesFrom !== user.id ? setShowRolesFrom(user.id) : setShowRolesFrom(undefined))}
          />
        )}
        {user.roles.length > 0
          ? (showRolesFrom === user.id || user.roles.length <= 2) && (
              <div
                className={
                  user.roles.length > 2
                    ? 'flex flex-col z-[1] -ml-12 absolute p-3 bg-gray-600/80 backdrop-blur-sm border border-gray-500 rounded-sm shadow-sm w-max'
                    : 'text-right'
                }
              >
                {user.roles.map(role => (
                  <CapitalizedText key={role}>{t(`admin.roles.${role}`)}</CapitalizedText>
                ))}
              </div>
            )
          : '-'}
      </div>
    </div>
  )
}
