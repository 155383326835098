import styled from 'styled-components'
import tw from 'twin.macro'
import { Vendor } from '../../../utils/vendors'
import aws from '../../../assets/svg/vendors/aws-shadow.svg'
import azure from '../../../assets/svg/vendors/azure-shadow.svg'
import gcp from '../../../assets/svg/vendors/gcp-shadow.svg'

export enum VendorIndicatorState {
  ACTIVE,
  INACTIVE,
  DISABLED
}

interface VendorIndicationProps {
  vendor: Vendor
  state?: VendorIndicatorState
  size?: number
  padding?: number
  onClick?: () => void
}

export const VendorIndicator = ({
  vendor,
  state = VendorIndicatorState.ACTIVE,
  size = 20,
  padding = 2,
  onClick
}: VendorIndicationProps) => {
  const path = vendor === Vendor.AWS ? aws : vendor === Vendor.AZURE ? azure : vendor === Vendor.GCP ? gcp : undefined
  return (
    <IndicatorBase
      vendor={vendor}
      state={state}
      padding={padding}
      onClick={onClick}
      className={onClick && 'cursor-pointer'}
    >
      <img alt={vendor} src={path} width={size} height={size} />
    </IndicatorBase>
  )
}

interface BaseProps {
  vendor: Vendor
  state: VendorIndicatorState
  padding: number
}

const IndicatorBase = styled.div<BaseProps>`
  ${tw`flex items-center bg-gray-300 w-max h-fit rounded-full transition-all ease-in-out duration-200`}
  ${({ vendor }) =>
    vendor === Vendor.AWS
      ? tw`bg-brand-aws`
      : vendor === Vendor.AZURE
        ? tw`bg-brand-azure`
        : vendor === Vendor.GCP
          ? tw`bg-brand-gcp`
          : tw`hidden`}
  ${({ state }) =>
    state === VendorIndicatorState.DISABLED
      ? tw`bg-gray-500`
      : state === VendorIndicatorState.INACTIVE
        ? tw`bg-opacity-20`
        : tw`bg-opacity-100`}
  padding: ${({ padding }) => padding / 4}rem;

  img {
    opacity: ${({ state }) => (state !== VendorIndicatorState.ACTIVE ? 0.5 : 1)};
  }
`
