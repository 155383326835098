import React, { useEffect, useRef, useState } from 'react'
import { DataContainer } from '../shared/containers/DataContainer'
import { CountUp } from '../shared/CountUp'
import styled from 'styled-components'
import tw from 'twin.macro'
import { useTranslation } from 'react-i18next'
import {
  ConsumptionSummary,
  EmissionBreakdown,
  EmissionEquivalent,
  getSustainabilityTotalSummary,
  SustainabilityTotalSummary
} from '../../api/sustainability'
import { roundNumber } from '../../utils/formats'
import { Loading } from '../shared/Loading'
import { MissingDataNotification } from '../shared/MissingDataNotification'
import { BarChartsDisplay, ScoreBarChart } from '../shared/charts/ScoreBarChart'
import { CustomIcon, IconType } from '../shared/CustomIcon'
import beer from '../../assets/svg/objects/illustrations/beer.svg'
import googleSearch from '../../assets/svg/objects/illustrations/google-search.svg'
import meat from '../../assets/svg/objects/illustrations/meat.svg'
import streaming from '../../assets/svg/objects/illustrations/streaming.svg'
import bread from '../../assets/svg/objects/illustrations/bread.svg'
import plane from '../../assets/svg/objects/illustrations/plane.svg'
import trees from '../../assets/svg/objects/illustrations/trees.svg'
import car from '../../assets/svg/objects/illustrations/car.svg'
import cheese from '../../assets/svg/objects/illustrations/cheese.svg'
import milk from '../../assets/svg/objects/illustrations/milk.svg'
import iPhone from '../../assets/svg/objects/illustrations/iPhone.svg'
import { Vendor } from '../../utils/vendors'
import { useErrorHandling } from '../../hooks/handleError'
import { useCancelToken } from '../../api/client'
import { GradientText, GrayText, Heading, WhiteText } from '../shared/TextComponents'
import { TimeframeOption } from '../../utils/classes'

interface SustainabilitySummaryProps {
  selectedVendors: Vendor[]
  selectedProjects: string[]
  selectedResourceGroups: string[]
  selectedServices: string[]
  selectedRegions: string[]
  selectedTimeframe: TimeframeOption
}

export const SustainabilitySummary = ({
  selectedVendors,
  selectedProjects,
  selectedResourceGroups,
  selectedServices,
  selectedRegions,
  selectedTimeframe
}: SustainabilitySummaryProps) => {
  const { t } = useTranslation()
  const handleError = useErrorHandling()
  const { createCancelToken } = useCancelToken()
  const initialMount = useRef(true)
  const [loading, setLoading] = useState(false)
  const [summaryData, setSummaryData] = useState<SustainabilityTotalSummary | null>(null)

  useEffect(() => {
    if (initialMount.current) {
      initialMount.current = false
      setLoading(true)
    }
    getSustainabilityTotalSummary(
      selectedVendors,
      selectedProjects,
      selectedResourceGroups,
      selectedServices,
      selectedRegions,
      selectedTimeframe,
      createCancelToken().token
    )
      .then(setSummaryData)
      .catch(handleError)
      .finally(() => setLoading(false))

    return () => setLoading(false)
  }, [
    handleError,
    createCancelToken,
    selectedVendors,
    selectedProjects,
    selectedResourceGroups,
    selectedServices,
    selectedRegions,
    selectedTimeframe
  ])

  if (loading || !summaryData)
    return (
      <DataContainer>
        <Loading paddingY={'5.97rem'} />
      </DataContainer>
    )

  return (
    <ContainersGrid>
      <DataContainer looseSpacing={true}>
        <EmissionNumbers data={summaryData.totals} selectedTimeframe={selectedTimeframe} />
      </DataContainer>
      <DataContainer looseSpacing={true}>
        <GrayText className={'absolute top-4 right-5 text-75 text-right max-w-[50%]'}>
          {t('sustainability.reportingInfo')}
        </GrayText>
        <EmissionsBreakdown data={summaryData.breakdown} />
      </DataContainer>
    </ContainersGrid>
  )
}

const ContainersGrid = styled.div`
  ${tw`grid gap-5 grid-cols-1 lg:grid-cols-3`}
  > div:nth-child(2) {
    ${tw`lg:col-span-2`}
  }
`

interface EmissionNumbersProps {
  selectedTimeframe: TimeframeOption
  data: ConsumptionSummary
}

const EmissionNumbers = ({ selectedTimeframe, data }: EmissionNumbersProps) => {
  const { t } = useTranslation()

  return (
    <SummaryWrapper>
      <Heading>
        {selectedTimeframe === TimeframeOption.LAST_MONTH
          ? t('sustainability.heading.lastMonths')
          : t('sustainability.heading.fromYear', {
              year: new Date().getFullYear() - (selectedTimeframe === TimeframeOption.LAST_YEAR ? 1 : 0)
            })}
      </Heading>
      {data && data.cloudEmissions && data.kwhSpend && data.emissionSavings && data.savingsEquivalency ? (
        <div className={'flex flex-col gap-10'}>
          <Consumptions data={data} />
          <Savings data={data} />
        </div>
      ) : (
        <MissingDataNotification paddingY={0} justify={'start'} />
      )}
    </SummaryWrapper>
  )
}

interface ConsumptionProps {
  data: ConsumptionSummary
}

const Consumptions = ({ data }: ConsumptionProps) => {
  const { t } = useTranslation()
  return (
    <div className={'flex flex-wrap gap-5 justify-evenly w-full items-center leading-none'}>
      <div className={'flex flex-col gap-2'}>
        <GrayText>{t('sustainability.summary.producedEmissions')}</GrayText>
        <GradientText className={'from-tertiary-100 to-tertiary-300 font-black text-300'}>
          <CountUp id={'qa-produced-emissions'} countTo={roundNumber(data.cloudEmissions ?? 0, 0)} />{' '}
          <WhiteText className={'inline-flex font-semibold text-112'}>{t('common.units.kgCO2')}</WhiteText>
        </GradientText>
      </div>
      <div className={'flex flex-col gap-2'}>
        <GrayText>{t('sustainability.electricity')}</GrayText>
        <GradientText className={'from-tertiary-400 to-tertiary-500 font-black text-300'}>
          <CountUp id={'qa-consumed-electricity'} countTo={roundNumber(data.kwhSpend ?? 0, 0)} />{' '}
          <WhiteText className={'inline-flex font-semibold text-112'}>{t('common.units.kWh')}</WhiteText>
        </GradientText>
      </div>
    </div>
  )
}

const Savings = ({ data }: ConsumptionProps) => {
  const { t } = useTranslation()
  const equivalents = [
    { label: EmissionEquivalent.BEER, icon: beer },
    { label: EmissionEquivalent.TREES, icon: trees },
    { label: EmissionEquivalent.PLANE, icon: plane },
    { label: EmissionEquivalent.MEAT, icon: meat },
    { label: EmissionEquivalent.BREAD, icon: bread },
    { label: EmissionEquivalent.CAR, icon: car },
    { label: EmissionEquivalent.STREAMING, icon: streaming },
    { label: EmissionEquivalent.GOOGLESEARCH, icon: googleSearch },
    { label: EmissionEquivalent.CHEESE, icon: cheese },
    { label: EmissionEquivalent.MILK, icon: milk },
    { label: EmissionEquivalent.IPHONE, icon: iPhone }
  ]

  const getIconPath = (equivalent: string) => equivalents.find(item => item.label === equivalent)?.icon ?? ''

  return (
    <div className={'flex flex-col gap-8 leading-none'}>
      <div className={'text-center w-full'}>
        <GrayText>{t('sustainability.summary.savingIntro')}</GrayText>
        <GradientText className={'text-150 font-black from-tertiary-100 to-tertiary-300'}>
          <CountUp id={'qa-emission-savings'} countTo={roundNumber(data.emissionSavings ?? 0, 0)} />{' '}
          <WhiteText className={'inline-flex font-semibold text-100'}>{t('common.units.kgCO2')}</WhiteText>
        </GradientText>{' '}
        <GrayText className={'inline-flex'}>{t('sustainability.summary.savingComparison')}</GrayText>
      </div>

      <div className={'flex justify-center gap-4 w-full'}>
        <CustomIcon
          iconType={IconType.FLAT}
          path={getIconPath(data.savingsEquivalency?.equivalent ?? '')}
          styles={'w-15 h-11'}
        />
        <div>
          <GrayText>{t('sustainability.summary.equivalency.intro')}</GrayText>
          <GradientText className={'text-150 font-black from-primary-200 to-primary-400'}>
            <CountUp id={'qa-emissions-equivalency'} countTo={roundNumber(data.savingsEquivalency?.value ?? 0, 0)} />{' '}
            <WhiteText className={'inline-flex font-semibold text-100'}>{t('common.units.kgCO2')}</WhiteText>
          </GradientText>{' '}
          <GrayText className={'inline-flex'}>
            {t(`sustainability.summary.equivalency.references.${data.savingsEquivalency?.equivalent}`)}
          </GrayText>
        </div>
      </div>
    </div>
  )
}

const SummaryWrapper = styled.div`
  ${tw`flex flex-col gap-8 w-full h-full`}
`

interface CategoriesProps {
  data: EmissionBreakdown[]
}

const EmissionsBreakdown = ({ data }: CategoriesProps) => {
  const { t } = useTranslation()
  const noData = data.every(item => item.percent === null)

  return (
    <SummaryWrapper className={'w-full'}>
      <Heading>{t('sustainability.summary.breakdownHeading')}</Heading>
      {noData && <MissingDataNotification paddingY={!data.length ? 5 : 0} justify={'start'} />}
      <BarChartsWrapper>
        {data.map((category, index) => (
          <ScoreBarChart
            labelId={`qa-emissions-breakdown-${category.category}`}
            key={index}
            chartLabel={
              !category.percent || (category.percent && category.percent < 96)
                ? category.category.toLowerCase()
                : `${category.category.toLowerCase()} ${category.percent} %`
            }
            displayStyle={BarChartsDisplay.pink}
            colorIndex={index}
            percent={category.percent ?? 0}
            showPercent={true}
          />
        ))}
      </BarChartsWrapper>
    </SummaryWrapper>
  )
}

const BarChartsWrapper = styled.div`
  ${tw`flex flex-col w-full h-full gap-6`}
`
