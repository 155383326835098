import React from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'
import markerIcon from '../../assets/svg/objects/location-marker.svg'
import { getVendor, Vendor } from '../../utils/vendors'

interface Props {
  vendor: Vendor
}

export const LocationMarker = ({ vendor }: Props) => {
  return (
    <div className={'group cursor-default'}>
      <Wrapper>
        <MarkerIcon vendor={vendor} />
        <MarkerShadow />
      </Wrapper>
    </div>
  )
}

const Wrapper = styled.div`
  ${tw`relative h-14 w-10 group-hover:h-18 group-hover:-mt-3 transition-all duration-200`}
`

interface IconProps {
  vendor: Vendor
}

const MarkerIcon = styled.div<IconProps>`
  mask: url(${markerIcon}) no-repeat center;
  ${tw`w-10 h-13 bg-brand-aws absolute drop-shadow-lg z-20`}
  ${({ vendor }) =>
    getVendor(vendor) === Vendor.AWS
      ? tw`bg-brand-aws`
      : getVendor(vendor) === Vendor.GCP
        ? tw`bg-brand-gcp`
        : tw`bg-brand-azure`}
`

const MarkerShadow = styled.div`
  ${tw`w-2 h-2 left-4 bg-gray-600 rounded-xl absolute bottom-0 z-10 group-hover:w-4 group-hover:h-3 group-hover:left-3 transition-all duration-200`}
`
