import React from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'

export interface ToggleGroupOption {
  clickHandler: () => void
  checked: boolean
  value: string
}

interface ToggleGroupProps {
  maxWidth: string
  minWidth?: string
  buttons: ToggleGroupOption[]
  lowercase?: boolean
}

export const ToggleGroup = ({ maxWidth, minWidth, buttons, lowercase }: ToggleGroupProps) => {
  return (
    <Wrapper minWidth={minWidth} maxWidth={maxWidth}>
      {buttons.map((prop, i) => (
        <Option lowercase={lowercase} key={i} checked={prop.checked} onClick={prop.clickHandler}>
          {prop.value}
          <input id={crypto.randomUUID()} hidden type={'radio'} checked={prop.checked} readOnly />
        </Option>
      ))}
    </Wrapper>
  )
}

interface WrapperProps {
  maxWidth: string
  minWidth?: string
}

const Wrapper = styled.div<WrapperProps>`
  ${tw`flex flex-row w-full gap-1px`} {
    max-width: ${({ maxWidth }) => maxWidth};
    min-width: ${({ minWidth }) => minWidth};
  }
  div:first-child {
    ${tw`rounded-l-full`}
  }
  div:last-child {
    ${tw`rounded-r-full`}
  }
`

interface OptionProps {
  checked: boolean
  lowercase?: boolean
}

const Option = styled.div<OptionProps>`
  ${tw`py-1 w-full min-w-max text-center cursor-pointer text-gray-50 bg-gray-500 transition-all duration-200 ease-linear text-80`}
  ${({ checked }) => checked && tw`font-semibold bg-gray-50 text-gray-600`}
  ${({ lowercase }) => !lowercase && tw`first-letter:capitalize`}
`
