export enum Vendor {
  ALL = 'ALL',
  AWS = 'AWS',
  AZURE = 'AZURE',
  GCP = 'GCP'
}

// @ts-ignore
export const getVendor = (vendor: Vendor) => Vendor[vendor]

export const Vendors = [Vendor.AWS, Vendor.AZURE, Vendor.GCP]
