import usa from '../assets/svg/flags/usa.svg'
import denmark from '../assets/svg/flags/denmark.svg'
import sweden from '../assets/svg/flags/sweden.svg'
import norway from '../assets/svg/flags/norway.svg'
import eu from '../assets/svg/flags/eu.svg'
import { CustomIcon, IconType } from '../components/shared/CustomIcon'
import React from 'react'

export enum CurrencyId {
  DKK = 'DKK',
  EUR = 'EUR',
  NOK = 'NOK',
  SEK = 'SEK',
  USD = 'USD',
  UNKNOWN = 'NA'
}

export const Currencies = [CurrencyId.DKK, CurrencyId.EUR, CurrencyId.NOK, CurrencyId.SEK, CurrencyId.USD]
export const KronaCurrencies = [CurrencyId.DKK, CurrencyId.NOK, CurrencyId.SEK]

export const getCurrency = (id?: CurrencyId): Currency => {
  const unknownCurrency = { id: CurrencyId.UNKNOWN, symbol: 'N/A', symbolLong: 'N/A' }
  const currencies: Currency[] = [
    { id: CurrencyId.DKK, symbol: 'kr', symbolLong: 'kr (dkk)' },
    { id: CurrencyId.EUR, symbol: '€', symbolLong: '€ (eur)' },
    { id: CurrencyId.NOK, symbol: 'kr', symbolLong: 'kr (nok)' },
    { id: CurrencyId.SEK, symbol: 'kr', symbolLong: 'kr (sek)' },
    { id: CurrencyId.USD, symbol: '$', symbolLong: '$ (usd)' },
    unknownCurrency
  ]
  return currencies.find(c => c.id === id) || unknownCurrency
}

interface CurrencyFlagProps {
  currencyId: CurrencyId
  size?: number
}

export const CurrencyFlag = ({ currencyId, size }: CurrencyFlagProps) => {
  return (
    <CustomIcon
      path={
        currencyId === CurrencyId.USD
          ? usa
          : currencyId === CurrencyId.DKK
            ? denmark
            : currencyId === CurrencyId.SEK
              ? sweden
              : currencyId === CurrencyId.NOK
                ? norway
                : eu
      }
      styles={size ? `h-${size} w-${size}` : 'w-6 h-6'}
      iconType={IconType.FLAT}
    />
  )
}

export interface Currency {
  id: CurrencyId
  symbol: string
  symbolLong: string
}

export interface CurrencyEntry {
  amount: number
  currency: CurrencyId
}
