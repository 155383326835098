import { Tree } from '../../../shared/Tree'
import React from 'react'
import { TabNoDataMessage } from '../../../shared/tabs/TabSharedComponents'
import { Vendor } from '../../../../utils/vendors'
import { AwsTabCommitments } from './AwsTabCommitments'
import { AwsTabRightSizing } from './AwsTabRightSizing'
import { AwsRecommendations } from '../../../../api/optimization/aws'
import { AwsTabUtilization } from './AwsTabUtilization'
import { GrayText } from '../../../shared/TextComponents'
import { useTranslation } from 'react-i18next'

interface AwsTabProps {
  selectedVendors: Vendor[]
  data: AwsRecommendations | null
}

export const OptimizationAwsTab = ({ selectedVendors, data }: AwsTabProps) => {
  const { t } = useTranslation()
  const commitments = data?.commitments?.recommendations
  if (
    !data ||
    (!commitments?.savingsPlans.length &&
      !commitments?.reservedInstances.length &&
      !data.rightsizing.length &&
      !data.utilization.length)
  )
    return <TabNoDataMessage selectedVendors={selectedVendors} vendor={Vendor.AWS} />

  return (
    <Tree>
      {(!commitments || !data.rightsizing.length) && (
        <GrayText className={'first-letter:capitalize text-90 p-3'}>
          {t('optimization.tabs.aws.recommendationsInfo')}
        </GrayText>
      )}
      {commitments && (commitments.savingsPlans.length > 0 || commitments.reservedInstances.length > 0) && (
        <AwsTabCommitments data={data.commitments} />
      )}
      {data.rightsizing.length > 0 && <AwsTabRightSizing data={data.rightsizing} />}
      {data.utilization.length > 0 && <AwsTabUtilization data={data.utilization} />}
    </Tree>
  )
}
