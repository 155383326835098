import { client } from './client'
import { CancelToken } from 'axios'

export const getTicketSummary = async (cancelToken: CancelToken): Promise<TicketSummary> => {
  const resp = await client.get<TicketSummary>('/tickets/summary', {
    cancelToken: cancelToken
  })
  return resp.data
}

export const getTicketsByStatus = async (status: string, cancelToken: CancelToken): Promise<FreshTicket[]> => {
  const resp = await client.get<FreshTicket[]>(`/tickets/by-status/${status}`, {
    cancelToken: cancelToken
  })
  return resp.data
}

export interface FreshTicket {
  domain: string | null
  ticketId: number
  category: string | null
  status: string
  type: string
  priority: string
  escalated: boolean
  subject: string
  description: string
  dueBy: string
  ticketCreatedAt: string
  ticketUpdatedAt: string
}

export interface TicketSummary {
  open: number
  pending: number
  resolved: number
  closed: number
}

export enum TicketStatus {
  OPEN = 'OPEN',
  PENDING = 'PENDING',
  RESOLVED = 'RESOLVED',
  CLOSED = 'CLOSED'
}

export const TicketStatuses = [TicketStatus.OPEN, TicketStatus.PENDING, TicketStatus.RESOLVED, TicketStatus.CLOSED]

export enum TicketPriority {
  URGENT = 'URGENT',
  HIGH = 'HIGH',
  MEDIUM = 'MEDIUM',
  LOW = 'LOW'
}
