import React from 'react'
import { Tree } from '../../../shared/Tree'
import { TreeBranch } from '../../../shared/TreeBranch'
import { useTranslation } from 'react-i18next'
import { formatNumber } from '../../../../utils/formats'
import { OptimizationTabCollapsibleSummary } from '../OptimizationTabsSharedComponents'
import { TabNoDataMessage } from '../../../shared/tabs/TabSharedComponents'
import { Vendor } from '../../../../utils/vendors'
import { GcpRecommendations } from '../../../../api/optimization/gcp'
import { getCurrency } from '../../../../utils/Currency'
import { GcpTabCommitment } from './GcpTabCommitment'
import { GcpTabRightsizing } from './GcpTabRightsizing'
import { GcpTabUtilization } from './GcpTabUtilization'

interface GcpTabProps {
  selectedVendors: Vendor[]
  recommendations: GcpRecommendations | null
}

export const OptimizationGcpTab = ({ selectedVendors, recommendations }: GcpTabProps) => {
  const { t } = useTranslation()

  if (
    !recommendations ||
    (!recommendations.commitments.length && !recommendations.rightsizing.length && !recommendations.utilization.length)
  )
    return <TabNoDataMessage selectedVendors={selectedVendors} vendor={Vendor.GCP} />

  return (
    <Tree>
      {recommendations.commitments.map((commitment, index) => (
        <GcpTabCommitment
          key={index}
          id={`qa-optimization-${Vendor.GCP}-tab-content-${index}`}
          commitment={commitment}
        />
      ))}
      {recommendations.rightsizing.map((rightsizing, index) => (
        <GcpTabRightsizing
          key={index}
          id={`qa-optimization-${Vendor.GCP}-tab-content-${index}`}
          rightsizing={rightsizing}
        />
      ))}
      {recommendations.utilization.length > 0 && (
        <TreeBranch
          headerData={
            <OptimizationTabCollapsibleSummary
              headerLeft={t('optimization.tabs.common.resourceUtilization')}
              headerRight={t('optimization.tabs.gcp.utilizationRecommendations', {
                count: recommendations.utilization.length,
                amount: formatNumber(
                  recommendations.utilization.reduce((acc, curr) => acc + curr.annualSavings.amount, 0)
                ),
                currency: getCurrency(recommendations.utilization[0].annualSavings.currency).symbol
              })}
              useExclamationMark={recommendations.utilization.length > 1}
            />
          }
          contentData={recommendations.utilization.map((utilization, index) => (
            <GcpTabUtilization id={`qa-optimization-${Vendor.GCP}-tab-content-${index}`} utilization={utilization} />
          ))}
        />
      )}
    </Tree>
  )
}
