import { CancelToken } from 'axios'
import { client } from '../../client'

export const getGcpIntegrations = async (cancelToken: CancelToken): Promise<GcpIntegration[]> => {
  return await client.get<GcpIntegration[]>('admin/integrations/gcp', { cancelToken }).then(resp =>
    resp.data.map(integration => ({
      ...integration,
      lastIntegrationAt: integration.lastIntegrationAt && new Date(integration.lastIntegrationAt),
      deletedAt: integration.deletedAt && new Date(integration.deletedAt)
    }))
  )
}

export const createGcpIntegration = async (
  integrationDetails: GcpIntegrationRequest,
  cancelToken: CancelToken
): Promise<GcpIntegrationData> => {
  return await client
    .post<GcpIntegrationData>('admin/integrations/gcp', integrationDetails, { cancelToken })
    .then(resp => getDateFormattedResponse(resp.data))
}

export const deleteGcpIntegration = async (organizationId: string) => {
  return await client.delete(`admin/integrations/gcp/${organizationId}`)
}

export const recoverGcpIntegration = async (organizationId: string) => {
  return await client.post(`admin/integrations/gcp/${organizationId}/recover`)
}

export const getGcpIntegrationDetails = async (
  organizationId: string,
  cancelToken: CancelToken
): Promise<GcpIntegrationData> => {
  return client
    .get<GcpIntegrationData>(`admin/integrations/gcp/${organizationId}`, { cancelToken })
    .then(resp => getDateFormattedResponse(resp.data))
}

export const editGcpIntegration = async (
  organizationId: string,
  name: string,
  cancelToken: CancelToken
): Promise<GcpIntegrationData> => {
  return await client
    .put<GcpIntegrationData>(
      `admin/integrations/gcp/${organizationId}`,
      { name: name },
      {
        cancelToken
      }
    )
    .then(resp => getDateFormattedResponse(resp.data))
}

export const addGcpBillingAccount = async (
  integrationDetails: GcpIntegrationRequest,
  cancelToken: CancelToken
): Promise<GcpIntegrationData> => {
  return await client
    .post<GcpIntegrationData>(
      `admin/integrations/gcp/${integrationDetails.organizationId}/${integrationDetails.accountId}`,
      integrationDetails,
      {
        cancelToken
      }
    )
    .then(resp => getDateFormattedResponse(resp.data))
}

export const editGcpBillingAccount = async (
  organizationId: string,
  billingAccount: GcpBillingAccount,
  cancelToken: CancelToken
): Promise<GcpIntegrationData> => {
  return await client
    .put<GcpIntegrationData>(`admin/integrations/gcp/${organizationId}/${billingAccount.accountId}`, billingAccount, {
      cancelToken
    })
    .then(resp => getDateFormattedResponse(resp.data))
}

export const softDeleteGcpBillingAccount = async (
  organizationId: string,
  billingAccount: GcpBillingAccount
): Promise<GcpIntegrationData> => {
  return await client
    .delete<GcpIntegrationData>(`admin/integrations/gcp/${organizationId}/${billingAccount.accountId}`, {
      data: billingAccount
    })
    .then(resp => getDateFormattedResponse(resp.data))
}

export const recoverGcpBillingAccount = async (
  organizationId: string,
  billingAccount: GcpBillingAccount,
  cancelToken: CancelToken
): Promise<GcpIntegrationData> => {
  return await client
    .post<GcpIntegrationData>(
      `admin/integrations/gcp/${organizationId}/${billingAccount.accountId}/recover`,
      billingAccount,
      {
        cancelToken
      }
    )
    .then(resp => getDateFormattedResponse(resp.data))
}

const getDateFormattedResponse = (resp: GcpIntegrationData): GcpIntegrationData => ({
  ...resp,
  lastIntegrationAt: resp.lastIntegrationAt && new Date(resp.lastIntegrationAt),
  lastIntegrations: {
    organization: resp.lastIntegrations.organization && new Date(resp.lastIntegrations.organization),
    optimization: resp.lastIntegrations.optimization && new Date(resp.lastIntegrations.optimization),
    compliance: resp.lastIntegrations.compliance && new Date(resp.lastIntegrations.compliance),
    infra: resp.lastIntegrations.infra && new Date(resp.lastIntegrations.infra)
  },
  deletedAt: resp.deletedAt && new Date(resp.deletedAt),
  billingAccounts: resp.billingAccounts.map(billingAccount => ({
    ...billingAccount,
    lastIntegrations: {
      costs: billingAccount.lastIntegrations.costs && new Date(billingAccount.lastIntegrations.costs),
      sustainability:
        billingAccount.lastIntegrations.sustainability && new Date(billingAccount.lastIntegrations.sustainability)
    },
    deletedAt: billingAccount.deletedAt && new Date(billingAccount.deletedAt)
  }))
})

export interface GcpIntegration {
  organizationId: string
  name: string
  lastIntegrationAt: Date | undefined
  deletedAt: Date | undefined
}

export interface GcpIntegration {
  organizationId: string
  name: string
  lastIntegrationAt: Date | undefined
  deletedAt: Date | undefined
}

export interface GcpIntegrationData {
  organizationId: string
  name: string
  lastIntegrationAt: Date | undefined
  lastIntegrations: OrganizationIntegrations
  billingAccounts: GcpBillingAccount[]
  deletedAt: Date | undefined
}

interface OrganizationIntegrations {
  organization: Date | undefined
  optimization: Date | undefined
  compliance: Date | undefined
  infra: Date | undefined
}

export interface GcpBillingAccount {
  accountId: string
  projectId: string
  tableId: string
  lastIntegrations: BillingAccountIntegrations
  deletedAt: Date | undefined
}

interface BillingAccountIntegrations {
  costs: Date | undefined
  sustainability: Date | undefined
}

export interface GcpIntegrationRequest {
  organizationId: string
  name: string
  accountId: string
  projectId: string
  tableId: string
}
