import React, { useContext, useEffect, useState } from 'react'
import { useCancelToken } from '../../../../api/client'
import { useErrorHandling } from '../../../../hooks/handleError'
import { ScrollTableRowItem } from '../../../shared/containers/ScrollTable'
import { formatDate, uuidRegExp } from '../../../../utils/formats'
import { useTranslation } from 'react-i18next'
import {
  AzureIntegration,
  createAzureIntegration,
  getAzureIntegrations,
  recoverAzureIntegration
} from '../../../../api/admin/integrations/azure'
import { MessageContext, MessageType } from '../../../../state/context/MessageContext'
import { ModalContext } from '../../../../state/context/ModalContext'
import { useForm } from 'react-hook-form'
import { ModalActions } from '../../../shared/modal/Modal'
import {
  DetailedIntegrationModal,
  IntegrationModalHighlight,
  IntegrationsModalBody,
  IntegrationsModalInputs
} from '../Shared'
import { Button, ButtonSize, ButtonStyle, ButtonType } from '../../../shared/buttons/Button'
import { CustomInput, CustomLabel } from '../../../shared/filters/FormComponents'
import { IntegrationsLayout } from '../IntegrationsLayout'
import { CapitalizedText, ErrorText } from '../../../shared/TextComponents'
import { useQueryParameters } from '../../../../utils/router'
import { useHistory } from 'react-router-dom'
import { firstLogin } from '../../../../state/storage'
import { UserInfoContext } from '../../../../state/context/UserInfoContext'
import { Alert } from '../../../shared/indicators/Alert'
import { hasIntegrationErrors } from '../../../../api/settings/profile'

interface AzureIntegrationsProps {
  integrations: AzureIntegration[]
  setIntegrations: (azureIntegrations: AzureIntegration[]) => void
}

export const AzureIntegrations = ({ integrations, setIntegrations }: AzureIntegrationsProps) => {
  const { setModal } = useContext(ModalContext)
  const { setMessage } = useContext(MessageContext)
  const { integrationStates } = useContext(UserInfoContext)
  const { createCancelToken } = useCancelToken()
  const { t } = useTranslation()
  const handleError = useErrorHandling()
  const history = useHistory()
  const queryParams = useQueryParameters()
  const isFirst = Boolean(queryParams.get('first'))
  const [loading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    if (isFirst) {
      openCreateModal()
    } else {
      setLoading(true)
      getAzureIntegrations(createCancelToken().token)
        .then(setIntegrations)
        .catch(handleError)
        .finally(() => setLoading(false))
    }

    return () => {
      createCancelToken().cancel()
      setLoading(false)
    }
  }, [createCancelToken, handleError])

  const openCreateModal = () => {
    const createIntegration = (request: AzureIntegration) => {
      const cancelToken = createCancelToken()
      const recoverAction = () => {
        recoverAzureIntegration(request.azureTenantId)
          .then(resp => {
            setMessage({
              type: MessageType.SUCCESS,
              message: t('admin.integrations.azure.recoverSuccessToast')
            })
            history.push(`azure/${resp.azureTenantId}`)
          })
          .catch(handleError)
      }

      createAzureIntegration(request, cancelToken.token)
        .then(resp => {
          if (resp.deletedAt) {
            setModal({
              header: t('admin.integrations.azure.recoveryHeader'),
              body: (
                <DetailedIntegrationModal
                  description={t('admin.integrations.azure.recoveryMessage')}
                  detailRows={[
                    { label: t('admin.integrations.azure.tenantId'), value: resp.azureTenantId },
                    { label: t('admin.integrations.name'), value: resp.name }
                  ]}
                  action={recoverAction}
                  actionLabel={t('admin.integrations.recover')}
                />
              )
            })
          } else {
            setIntegrations([...integrations, resp])
            setModal(null)
            setMessage({ type: MessageType.SUCCESS, message: t('admin.integrations.azure.addSuccessToast') })
            !firstLogin() && history.push(`azure/${resp.azureTenantId}`)
          }
        })
        .catch(handleError)
    }

    setModal({
      header: t('admin.integrations.azure.createTitle'),
      returnAction: isFirst ? () => history.goBack() : undefined,
      body: <CreateModal submitAction={createIntegration} />
    })
  }

  return (
    <IntegrationsLayout
      loading={loading}
      noData={!integrations.length}
      heading={t('admin.integrations.azure.title')}
      headerActions={<Button clickHandler={() => openCreateModal()} value={t('admin.integrations.addNew')} />}
      scrollTableProps={{
        sortable: false,
        customColumns: '220px repeat(3, 1fr) minmax(90px, auto)',
        titles: [
          t('admin.integrations.name'),
          t('admin.integrations.azure.tenantId'),
          t('admin.integrations.lastIntegrationAt'),
          t('admin.integrations.status')
        ],
        rows: integrations.map(azureTenant => (
          <>
            <ScrollTableRowItem>{azureTenant.name}</ScrollTableRowItem>
            <ScrollTableRowItem>{azureTenant.azureTenantId}</ScrollTableRowItem>
            <ScrollTableRowItem>{formatDate(azureTenant.lastIntegrationAt, true, true, true)}</ScrollTableRowItem>
            <ScrollTableRowItem>
              {azureTenant.deletedAt ? (
                <ErrorText>{t('common.status.removed')}</ErrorText>
              ) : (
                <CapitalizedText className={'text-gray-100/90'}>{t('common.status.active')}</CapitalizedText>
              )}
              {hasIntegrationErrors(integrationStates.azure) && <Alert />}
            </ScrollTableRowItem>
            <Button
              value={t('admin.integrations.viewDetails')}
              clickHandler={() => history.push(`azure/${azureTenant.azureTenantId}`)}
              buttonStyle={ButtonStyle.SECONDARY}
              size={ButtonSize.XSMALL}
            />
          </>
        ))
      }}
    />
  )
}

interface FormModalProps {
  submitAction: (request: AzureIntegration) => void
}

const CreateModal = ({ submitAction }: FormModalProps) => {
  const { setModal } = useContext(ModalContext)
  const { t } = useTranslation()
  const {
    register,
    handleSubmit,
    formState: { isValid, isSubmitting, isDirty }
  } = useForm<AzureIntegration>()

  return (
    <form className={'max-w-116'}>
      <IntegrationsModalBody>
        <IntegrationModalHighlight type={'create'} path={'azure-get-started-guide'} />
        <IntegrationsModalInputs>
          <div>
            <CustomLabel>{t('admin.integrations.azure.tenantId')} *</CustomLabel>
            <CustomInput
              {...register('azureTenantId', {
                required: true,
                validate: value => uuidRegExp.test(value)
              })}
              autoFocus={true}
            />
          </div>
          <div>
            <CustomLabel>{t('admin.integrations.name')} *</CustomLabel>
            <CustomInput
              {...register('name', {
                required: true,
                minLength: 1
              })}
            />
          </div>
        </IntegrationsModalInputs>
      </IntegrationsModalBody>
      <ModalActions>
        <Button
          buttonStyle={ButtonStyle.SECONDARY}
          type={ButtonType.FORM}
          value={t('common.cancel')}
          clickHandler={() => setModal(null)}
        />
        <Button
          type={ButtonType.FORM}
          disabled={!isValid || isSubmitting || !isDirty}
          value={t('admin.integrations.addNew')}
          clickHandler={handleSubmit(submitAction)}
        />
      </ModalActions>
    </form>
  )
}
