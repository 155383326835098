import styled from 'styled-components'
import tw from 'twin.macro'
import { CustomIcon, IconType } from './CustomIcon'
import blankIcon from '../../assets/svg/actions/open-in-new-window.svg'
import React, { useState } from 'react'
import { HtmlLink } from '../../api/compliance/tabs'
import { CapitalizedText } from './TextComponents'
import arrow from '../../assets/svg/directional/nested-property-arrow.svg'

interface RowProps {
  children: React.ReactNode
  isHeader?: boolean
  styles?: string
  divider?: boolean
  customColumns?: string
}

export const DataTableRow = ({ children, isHeader, styles = '', divider = true, customColumns }: RowProps) => (
  <RowWrapper isHeader={isHeader} className={styles} divider={divider} customColumns={customColumns}>
    {children}
  </RowWrapper>
)

interface RowWrapperProps {
  isHeader?: boolean
  divider: boolean
  customColumns?: string
}

const RowWrapper = styled.div<RowWrapperProps>`
  ${tw`grid w-full items-center py-1 bg-opacity-40 xl:gap-4 xl:py-0`};
  @media (max-width: 640px) {
    ${tw`text-80`}
  }
  @media (min-width: 1280px) {
    grid-template-columns: ${props => props.customColumns && props.customColumns};
  }
  ${props => props.isHeader && tw`hidden divide-none text-gray-200 capitalize xl:grid`}
  ${props => props.divider && tw`divide-y divide-gray-500 divide-dashed xl:divide-none`}
`

export enum xAlignment {
  LEFT,
  CENTER,
  RIGHT
}

interface CellProps {
  children: React.ReactNode
  xAlign?: xAlignment
  styles?: string
}

export const DataTableCell = ({ children, xAlign, styles }: CellProps) => {
  return (
    <CellWrapper xAlign={xAlign} className={styles}>
      {children}
    </CellWrapper>
  )
}

interface CellWrapperProps {
  xAlign?: xAlignment
}

const CellWrapper = styled.div<CellWrapperProps>`
  ${tw`flex gap-4 py-1 px-2 justify-between items-center xl:flex-col`}
  ${props =>
    props.xAlign === xAlignment.RIGHT
      ? tw`xl:justify-self-end`
      : props.xAlign === xAlignment.CENTER
        ? tw`xl:justify-self-center`
        : tw`xl:items-start`}
`

interface DataTableLabelProps {
  isHeader?: boolean
}

export const DataTableLabel = styled.div<DataTableLabelProps>`
  ${tw`text-gray-200 first-letter:capitalize`}
  ${props => !props.isHeader && tw`xl:hidden`}
`
export const DataTableValue = styled.div`
  ${tw`text-gray-50 break-all`}
`

interface InfoBoxText {
  text: string | null
  links?: HtmlLink[] | null | string
}

interface InfoBoxProps {
  textSections?: InfoBoxText[]
}

export const TabInfoBox = ({ textSections }: InfoBoxProps) => {
  const [showAll, setShowAll] = useState(false)
  const ExternalLink = ({ link }: { link: HtmlLink }) => {
    return (
      <a
        href={link.href}
        target={'_blank'}
        rel={'external noreferrer'}
        onClick={e => e.stopPropagation()}
        className={'first:mt-1 text-gray-200 underline hover:text-gray-50 flex gap-2 items-center max-w-max'}
      >
        {link.text}
        <CustomIcon iconType={IconType.VECTOR} path={blankIcon} styles={'h-2.5 w-2.5 min-w-2.5 bg-gray-50'} />
      </a>
    )
  }

  if (!textSections) return null

  return (
    <div className={`flex flex-col gap-4 border border-gray-500 p-6 mx-2 lg:mx-5`}>
      {textSections
        .filter((_, index) => showAll || index < 1)
        .map((section, index) => (
          <div
            key={section.text}
            onClick={() => setShowAll(!showAll)}
            className={`group flex w-full justify-between items-center gap-4 ${textSections.length > 1 && 'cursor-pointer'}`}
          >
            {typeof section.links === 'string' ? (
              <ExternalLink link={{ text: section.text ?? section.links, href: section.links }} />
            ) : (
              <div>
                {section.text && <CapitalizedText>{section.text}</CapitalizedText>}
                {section.links && section.links.length > 0 && (
                  <div className={'flex flex-col w-fit'}>
                    {section.links.map(link => (
                      <ExternalLink key={link.href} link={link} />
                    ))}
                  </div>
                )}
              </div>
            )}
            {index === 0 && textSections.length > 1 && (
              <CustomIcon
                iconType={IconType.VECTOR}
                path={arrow}
                styles={`min-w-6 h-6 bg-gray-300 group-hover:bg-gray-50 ${showAll && 'rotate-90'} transition-transform ease-in-out duration-200`}
              />
            )}
          </div>
        ))}
    </div>
  )
}
