import { CapitalizedText, GrayText, Heading } from './shared/TextComponents'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { Controller, useForm } from 'react-hook-form'
import { ContactCategory, ContactSupportForm, sendContactSupportForm } from '../api/settings/profile'
import { CustomInput, CustomLabel, CustomTextArea } from './shared/filters/FormComponents'
import { Button, ButtonSize, ButtonStyle, ButtonType } from './shared/buttons/Button'
import { useCancelToken } from '../api/client'
import { useErrorHandling } from '../hooks/handleError'
import { MessageContext, MessageType } from '../state/context/MessageContext'
import { RadioButton } from './shared/buttons/RadioButton'
import { capitalizeFirstLetter } from '../utils/formats'
import {
  getContactCategory,
  getContactMessage,
  getContactSubject,
  removeContactCategory,
  removeContactMessage,
  removeContactSubject,
  setContactCategory,
  setContactMessage,
  setContactSubject
} from '../state/storage'
import { DataContainer } from './shared/containers/DataContainer'
import { CustomIcon } from './shared/CustomIcon'
import heart from '../assets/svg/symbols/heart-flat.svg'

export const ContactSupport = () => {
  const { t } = useTranslation()
  const { createCancelToken } = useCancelToken()
  const { setMessage } = useContext(MessageContext)
  const handleError = useErrorHandling()

  const sendContactMessage = (request: ContactSupportForm) => {
    sendContactSupportForm(request, createCancelToken().token)
      .then(() => {
        setMessage({
          type: MessageType.SUCCESS,
          message: t('support.messageSendSuccess')
        })
      })
      .catch(handleError)
  }

  return (
    <DataContainer flexCol={true} looseSpacing={true}>
      <div className={'w-full pb-8 border-b border-gray-500'}>
        <div className={'flex flex-col w-full gap-1'}>
          <div className={'flex gap-3 items-center'}>
            <CapitalizedText className={'font-bold text-gray-50 text-150'}>{t('support.heading')}</CapitalizedText>
            <CustomIcon path={heart} styles={'h-5 w-5 bg-primary-400'} />
          </div>
          <GrayText>{t('support.subHeading')}</GrayText>
        </div>
      </div>
      <div className={'w-full min-h-[75vh] pt-8'}>
        <ContactForm submitAction={sendContactMessage} />
      </div>
    </DataContainer>
  )
}

interface ContactFormProps {
  submitAction: (request: ContactSupportForm) => void
}

const ContactForm = ({ submitAction }: ContactFormProps) => {
  const { t } = useTranslation()
  const {
    handleSubmit,
    register,
    control,
    watch,
    reset,
    formState: { isValid, isSubmitting }
  } = useForm<ContactSupportForm>({
    mode: 'all',
    defaultValues: {
      category: getContactCategory() ?? undefined,
      subject: getContactSubject() ?? '',
      message: getContactMessage() ?? ''
    }
  })

  const resetForm = () => {
    removeContactCategory()
    removeContactSubject()
    removeContactMessage()
    reset({
      category: undefined,
      subject: '',
      message: ''
    })
  }

  const onSubmit = (data: ContactSupportForm) => {
    submitAction(data)
    resetForm()
  }

  return (
    <form className={'flex flex-col gap-8 xl:max-w-170'}>
      <div className={'flex flex-col gap-4'}>
        <Heading>{t('support.formHeading')}</Heading>
        <GrayText>{t('support.formDescription_1')}</GrayText>
        <GrayText>{t('support.formDescription_2')}</GrayText>
      </div>
      <div className={'flex flex-col gap-5'}>
        <div>
          <CustomLabel>{t('support.category')}</CustomLabel>
          <Controller
            key={watch().category}
            name={'category'}
            control={control}
            render={({ field: { value, onChange } }) => (
              <ContactCategories
                value={value}
                onChange={category => {
                  setContactCategory(category)
                  onChange(category)
                }}
              />
            )}
            rules={{ required: true }}
          />
        </div>
        <div>
          <CustomLabel>{t('support.subject')}</CustomLabel>
          <CustomInput
            placeholder={t('support.subjectPlaceholder')}
            {...register('subject', {
              onChange: e => setContactSubject(e.target.value),
              required: true,
              minLength: 1,
              maxLength: 100
            })}
          />
        </div>
        <div>
          <CustomLabel>{t('support.message')}</CustomLabel>
          <CustomTextArea
            placeholder={t('support.messagePlaceholder')}
            rows={5}
            {...register('message', {
              onChange: e => setContactMessage(e.target.value),
              required: true,
              minLength: 1,
              maxLength: 5000
            })}
          />
        </div>
      </div>
      <div className={'flex w-full gap-5'}>
        <Button
          size={ButtonSize.SMALL}
          type={ButtonType.FORM}
          buttonStyle={ButtonStyle.SECONDARY}
          disabled={(watch().subject === '' && watch().message === '' && !watch().category) || isSubmitting}
          value={t('support.clear')}
          clickHandler={e => {
            e.preventDefault()
            resetForm()
          }}
        />
        <Button
          size={ButtonSize.SMALL}
          type={ButtonType.FORM}
          disabled={!isValid || isSubmitting}
          value={t('support.sendMessage')}
          clickHandler={handleSubmit(onSubmit)}
        />
      </div>
    </form>
  )
}

interface ContactCategoriesProps {
  value: ContactCategory | undefined
  onChange: (category: ContactCategory) => void
}

const ContactCategories = ({ value, onChange }: ContactCategoriesProps) => {
  return (
    <div className={'flex flex-col gap-2'}>
      <RadioButton
        type={'secondary'}
        checked={value === ContactCategory.BUG_REPORT}
        label={capitalizeFirstLetter(ContactCategory.BUG_REPORT.toLowerCase().replace('_', ' '))}
        onChange={() => onChange(ContactCategory.BUG_REPORT)}
      />
      <RadioButton
        type={'secondary'}
        checked={value === ContactCategory.FEATURE_REQUEST}
        label={capitalizeFirstLetter(ContactCategory.FEATURE_REQUEST.toLowerCase().replace('_', ' '))}
        onChange={() => onChange(ContactCategory.FEATURE_REQUEST)}
      />
      <RadioButton
        type={'secondary'}
        checked={value === ContactCategory.OTHER}
        label={capitalizeFirstLetter(ContactCategory.OTHER.toLowerCase())}
        onChange={() => onChange(ContactCategory.OTHER)}
      />
    </div>
  )
}
