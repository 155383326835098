import { useTranslation } from 'react-i18next'
import { TreeBranch } from '../../../shared/TreeBranch'
import { Vendor } from '../../../../utils/vendors'
import React from 'react'
import { DataTableCell, DataTableLabel, DataTableRow, DataTableValue } from '../../../shared/DataTableSharedComponents'
import { CapitalizedText } from '../../../shared/TextComponents'
import { formatNumber, roundNumber } from '../../../../utils/formats'
import { ClipboardCopy } from '../../../shared/ClipboardCopy'
import { OptimizationTabCollapsibleSummary, OptimizationTabTitleRow } from '../OptimizationTabsSharedComponents'
import { TabDataTableRegionCell } from '../../../shared/tabs/TabSharedComponents'
import {
  AwsCommitmentsSummary,
  AwsReservation,
  AwsReservedInstanceRecommendations,
  AwsSavingPlans,
  AwsSavingsPlan
} from '../../../../api/optimization/aws'
import { getCurrency } from '../../../../utils/Currency'

interface TabCommitmentsProps {
  data: AwsCommitmentsSummary
}

export const AwsTabCommitments = ({ data }: TabCommitmentsProps) => {
  const { t } = useTranslation()

  return (
    <TreeBranch
      id={`qa-optimization-${Vendor.AWS}-tab-commitments`}
      headerData={
        <OptimizationTabCollapsibleSummary
          headerLeft={t('optimization.common.commitments')}
          headerRight={t('optimization.tabs.common.annualSavingsCommitments', {
            count: data.recommendationCount,
            amount: formatNumber(data.potentialAnnualSavings.amount),
            currency: getCurrency(data.potentialAnnualSavings.currency).symbol
          })}
        />
      }
      contentData={
        <>
          <ReservedInstances data={data.recommendations.reservedInstances} />
          <SavingsPlans data={data.recommendations.savingsPlans} />
        </>
      }
    />
  )
}

interface ReservedInstanceProps {
  data: AwsReservedInstanceRecommendations[]
}

const ReservedInstances = ({ data }: ReservedInstanceProps) => {
  const { t } = useTranslation()

  return (
    <>
      {data.map((recommendationGroup, index) => (
        <TreeBranch
          id={`qa-optimization-${Vendor.AWS}-tab-content-${index}`}
          key={`${recommendationGroup.recommendation}`}
          depth={1}
          headerData={
            <OptimizationTabCollapsibleSummary
              headerLeft={recommendationGroup.recommendation}
              useExclamationMark={recommendationGroup.potentialAnnualSavings.amount > 400}
              headerRight={t('optimization.tabs.common.annualUpToSavings', {
                amount: formatNumber(recommendationGroup.potentialAnnualSavings.amount),
                currency: getCurrency(recommendationGroup.potentialAnnualSavings.currency).symbol
              })}
            />
          }
          contentData={
            <>
              {recommendationGroup.recommendations[0].recommendations.length === 1 && (
                <OptimizationTabTitleRow
                  widerIndex={[0, 1, 2, 3]}
                  labels={[
                    t('vendors.AWS.projectPhrase_one'),
                    t('common.region_one'),
                    t('optimization.tabs.aws.recommendedInstance'),
                    t('optimization.tabs.aws.paymentOption'),
                    t('optimization.tabs.aws.upfrontCost'),
                    t('optimization.tabs.aws.monthlyCost'),
                    t('optimization.tabs.common.monthlySavings'),
                    t('optimization.tabs.aws.breakEvenMonths')
                  ]}
                />
              )}
              {recommendationGroup.recommendations.map(account => (
                <TreeBranch
                  key={account.account.id}
                  depth={2}
                  headerData={
                    account.recommendations.length > 1 ? (
                      <OptimizationTabCollapsibleSummary
                        headerLeft={
                          <ClipboardCopy
                            restrictClickArea={true}
                            copyText={account.account.name}
                            secondCopyText={account.account.id}
                            limit={50}
                          />
                        }
                        useExclamationMark={account.potentialAnnualSavings.amount > 400}
                        headerRight={t('optimization.tabs.common.annualUpToSavings', {
                          amount: formatNumber(account.potentialAnnualSavings.amount),
                          currency: getCurrency(account.potentialAnnualSavings.currency).symbol
                        })}
                      />
                    ) : (
                      <ReservationRow showAccount={true} data={account.recommendations[0]} />
                    )
                  }
                  contentData={
                    account.recommendations.length > 1 && (
                      <>
                        <OptimizationTabTitleRow
                          widerIndex={[0, 1, 2]}
                          labels={[
                            t('common.region_one'),
                            t('optimization.tabs.aws.recommendedInstance'),
                            t('optimization.tabs.aws.paymentOption'),
                            t('optimization.tabs.aws.upfrontCost'),
                            t('optimization.tabs.aws.monthlyCost'),
                            t('optimization.tabs.common.monthlySavings'),
                            t('optimization.tabs.aws.breakEvenMonths')
                          ]}
                        />
                        {account.recommendations.map((recommendation, i) => (
                          <TreeBranch
                            key={i}
                            depth={3}
                            headerData={<ReservationRow data={recommendation} />}
                            contentData={false}
                          />
                        ))}
                      </>
                    )
                  }
                />
              ))}
            </>
          }
        />
      ))}
    </>
  )
}

interface ReservationRowProps {
  showAccount?: boolean
  data: AwsReservation
}

const ReservationRow = ({ showAccount, data }: ReservationRowProps) => {
  const { t } = useTranslation()
  const currency = getCurrency(data.currency)

  return (
    <DataTableRow styles={`grid-cols-1 ${showAccount ? 'xl:grid-cols-12' : 'xl:grid-cols-10'}`}>
      {showAccount && (
        <DataTableCell styles={'xl:col-span-2'}>
          <DataTableLabel>{t('vendors.AWS.projectPhrase_one')}</DataTableLabel>
          <DataTableValue>
            <ClipboardCopy copyText={data.account.name} secondCopyText={data.account.id} limit={30} />
          </DataTableValue>
        </DataTableCell>
      )}
      <TabDataTableRegionCell styles={'xl:col-span-2'} regionName={data.region.name} regionId={data.region.id} />
      <DataTableCell styles={'xl:col-span-2'}>
        <DataTableLabel>{t('optimization.tabs.aws.recommendedInstance')}</DataTableLabel>
        <DataTableValue>{`${data.instanceDetails.type} (${data.quantity} ${t('common.total.abbr')})`}</DataTableValue>
      </DataTableCell>
      <DataTableCell styles={'xl:col-span-2'}>
        <DataTableLabel>{t('optimization.tabs.aws.paymentOption')}</DataTableLabel>
        <DataTableValue>
          <CapitalizedText>{data.paymentOption}</CapitalizedText>
        </DataTableValue>
      </DataTableCell>
      <DataTableCell>
        <DataTableLabel>{t('optimization.tabs.aws.upfrontCost')}</DataTableLabel>
        <DataTableValue>
          {data.upfrontCost ? `${formatNumber(data.upfrontCost)} ${currency.symbol}` : '-'}
        </DataTableValue>
      </DataTableCell>
      <DataTableCell>
        <DataTableLabel>{t('optimization.tabs.aws.monthlyCost')}</DataTableLabel>
        <DataTableValue>
          {formatNumber(data.monthlyCost)} {currency.symbol}
        </DataTableValue>
      </DataTableCell>
      <DataTableCell>
        <DataTableLabel>{t('optimization.tabs.common.monthlySavings')}</DataTableLabel>
        <DataTableValue>
          {formatNumber(data.monthlySavings)} {currency.symbol}
        </DataTableValue>
      </DataTableCell>
      <DataTableCell>
        <DataTableLabel>{t('optimization.tabs.aws.breakEvenMonths')}</DataTableLabel>
        <DataTableValue>{roundNumber(data.breakEvenMonths, 0)}</DataTableValue>
      </DataTableCell>
    </DataTableRow>
  )
}

interface SavingsPlansProps {
  data: AwsSavingPlans[]
}

const SavingsPlans = ({ data }: SavingsPlansProps) => {
  const { t } = useTranslation()
  return (
    <>
      {data.map((recommendationGroup, index) => (
        <TreeBranch
          id={`qa-optimization-${Vendor.AWS}-tab-content-${index}`}
          key={`${recommendationGroup.recommendation}`}
          depth={1}
          headerData={
            <OptimizationTabCollapsibleSummary
              headerLeft={recommendationGroup.recommendation}
              useExclamationMark={recommendationGroup.potentialAnnualSavings.amount > 400}
              headerRight={t('optimization.tabs.common.annualUpToSavings', {
                amount: formatNumber(recommendationGroup.potentialAnnualSavings.amount),
                currency: getCurrency(recommendationGroup.potentialAnnualSavings.currency).symbol
              })}
            />
          }
          contentData={recommendationGroup.recommendations.map((byAccount, index) => (
            <TreeBranch
              key={`${byAccount.account.id}-${index}`}
              depth={2}
              headerData={
                <OptimizationTabCollapsibleSummary
                  headerLeft={
                    <ClipboardCopy
                      restrictClickArea={true}
                      copyText={byAccount.account.name}
                      secondCopyText={byAccount.account.id}
                      limit={50}
                    />
                  }
                  useExclamationMark={byAccount.potentialAnnualSavings.amount > 400}
                  headerRight={t('optimization.tabs.common.annualUpToSavings', {
                    amount: formatNumber(byAccount.potentialAnnualSavings.amount),
                    currency: byAccount.potentialAnnualSavings.currency
                  })}
                />
              }
              contentData={
                <>
                  <OptimizationTabTitleRow
                    labels={[
                      t('common.region_one'),
                      recommendationGroup.recommendation.includes('EC2') && t('optimization.tabs.aws.instanceFamily'),
                      t('optimization.tabs.aws.paymentOption'),
                      t('optimization.tabs.aws.upfrontCost'),
                      t('optimization.tabs.aws.monthlyCost'),
                      t('optimization.tabs.common.monthlySavings'),
                      t('optimization.tabs.aws.roi')
                    ]}
                  />
                  {byAccount.recommendations.map((recommendation, i) => (
                    <TreeBranch
                      key={i}
                      depth={3}
                      headerData={<SavingsPlanRow data={recommendation} />}
                      contentData={false}
                    />
                  ))}
                </>
              }
            />
          ))}
        />
      ))}
    </>
  )
}

interface SavingsPlanRowProps {
  data: AwsSavingsPlan
}

const SavingsPlanRow = ({ data }: SavingsPlanRowProps) => {
  const { t } = useTranslation()
  const currency = getCurrency(data.currency)

  return (
    <DataTableRow styles={`grid-cols-1 ${data.instanceFamily ? 'xl:grid-cols-7' : 'xl:grid-cols-6'}`}>
      <TabDataTableRegionCell regionName={data.region.name} regionId={data.region.id} />
      {data.instanceFamily && (
        <DataTableCell>
          <DataTableLabel>{t('optimization.tabs.aws.instanceFamily')}</DataTableLabel>
          <DataTableValue>
            <CapitalizedText>{data.instanceFamily}</CapitalizedText>
          </DataTableValue>
        </DataTableCell>
      )}
      <DataTableCell>
        <DataTableLabel>{t('optimization.tabs.aws.paymentOption')}</DataTableLabel>
        <DataTableValue>
          <CapitalizedText>{data.paymentOption}</CapitalizedText>
        </DataTableValue>
      </DataTableCell>
      <DataTableCell>
        <DataTableLabel>{t('optimization.tabs.aws.upfrontCost')}</DataTableLabel>
        <DataTableValue>
          {data.upfrontCost ? `${formatNumber(data.upfrontCost)} ${currency.symbol}` : '-'}
        </DataTableValue>
      </DataTableCell>
      <DataTableCell>
        <DataTableLabel>{t('optimization.tabs.aws.monthlyCost')}</DataTableLabel>
        <DataTableValue>
          {formatNumber(data.monthlyCost)} {currency.symbol}
        </DataTableValue>
      </DataTableCell>
      <DataTableCell>
        <DataTableLabel>{t('optimization.tabs.common.monthlySavings')}</DataTableLabel>
        <DataTableValue>
          {formatNumber(data.monthlySavings)} {currency.symbol}
        </DataTableValue>
      </DataTableCell>
      <DataTableCell>
        <DataTableLabel>{t('optimization.tabs.aws.roi')}</DataTableLabel>
        <DataTableValue>{roundNumber(data.roi, 0)} %</DataTableValue>
      </DataTableCell>
    </DataTableRow>
  )
}
