import React, { useContext } from 'react'
import { Redirect, Route } from 'react-router-dom'
import { MainLayout } from '../MainLayout'
import { UserInfoContext } from '../../state/context/UserInfoContext'

interface PrivateRouteProps {
  children: JSX.Element
  exact: boolean
  path: string
}

export const PrivateRoute = ({ children, exact, path }: PrivateRouteProps) => {
  const { authInfo } = useContext(UserInfoContext)

  return (
    <Route
      exact={exact}
      path={path}
      render={() => (authInfo.isLoggedIn ? <MainLayout>{children}</MainLayout> : <Redirect to={'/login'} />)}
    />
  )
}
