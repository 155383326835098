import styled from 'styled-components'
import tw from 'twin.macro'
import { Vendor } from '../../../utils/vendors'
import React from 'react'

interface StackedBarChartData {
  vendor?: Vendor
  color?: string
  value: number
}

interface StackedVendorBarChartProps {
  data: StackedBarChartData[]
  size?: 'sm' | 'lg'
}

export const StackedBarChart = ({ data, size = 'sm' }: StackedVendorBarChartProps) => {
  const total = data.reduce((acc, curr) => acc + curr.value, 0)
  const percentConvertedData = data.map(item => ({
    ...item,
    value: total === 0 ? 100 / data.length : (item.value / total) * 100
  }))

  const barWidth = (percent: number) =>
    percentConvertedData.every(item => item.value === 0)
      ? 34
      : percentConvertedData.every(item => item.value <= 20)
        ? 40
        : percent === 0
          ? 5
          : percent + 5

  return (
    <Stack>
      {percentConvertedData.map((item, i) =>
        item.vendor ? (
          <VendorBar key={i} size={size} vendor={item.vendor} style={{ width: `${barWidth(item.value)}%` }} />
        ) : (
          <Bar key={i} className={item.color} size={size} style={{ width: `${barWidth(item.value)}%` }} />
        )
      )}
    </Stack>
  )
}

const Stack = styled.div`
  ${tw`flex justify-evenly gap-0.5 py-1 rounded-xl bg-gray-800/20`}
`

interface BarProps {
  vendor?: Vendor
  size?: 'sm' | 'lg'
}

const Bar = styled.div<BarProps>`
  ${tw`rounded first:rounded-l-xl last:rounded-r-xl`}
  ${({ size }) => (size === 'sm' ? tw`h-3 lg:h-4` : tw`h-4 lg:h-5`)}
`

const VendorBar = styled(Bar)`
  ${tw`border bg-gradient-to-t`}
  ${({ vendor }) =>
    vendor === Vendor.AWS
      ? tw`from-brand-aws/60 via-brand-aws to-brand-aws border-brand-aws/60`
      : vendor === Vendor.AZURE
        ? tw`from-brand-azure/60 via-brand-azure to-brand-azure border-brand-azure/60`
        : vendor === Vendor.GCP
          ? tw`from-brand-gcp/60 via-brand-gcp to-brand-gcp border-brand-gcp/60`
          : tw``}
`
