import { useTranslation } from 'react-i18next'
import { TreeBranch } from '../../../shared/TreeBranch'
import { Vendor } from '../../../../utils/vendors'
import { OptimizationTabCollapsibleSummary, OptimizationTabTitleRow } from '../OptimizationTabsSharedComponents'
import { formatNumber, roundNumber } from '../../../../utils/formats'
import { DataTableCell, DataTableLabel, DataTableRow, DataTableValue } from '../../../shared/DataTableSharedComponents'
import { ClipboardCopy } from '../../../shared/ClipboardCopy'
import React from 'react'
import { Tree } from '../../../shared/Tree'
import { TabDataTableRegionCell } from '../../../shared/tabs/TabSharedComponents'
import { AzureCommitment, AzureCommitments, AzureRecommendationsByName } from '../../../../api/optimization/azure'
import { getCurrency } from '../../../../utils/Currency'

interface CommitmentTabProps {
  data: AzureCommitments
}

export const AzureTabCommitments = ({ data }: CommitmentTabProps) => {
  const { t } = useTranslation()

  return (
    <Tree>
      {data.recommendations.length > 0 && (
        <TreeBranch
          id={`qa-optimization-${Vendor.AZURE}-tab-commitments`}
          headerData={
            <OptimizationTabCollapsibleSummary
              headerLeft={t('optimization.common.commitments')}
              headerRight={t('optimization.tabs.common.annualSavingsCommitments', {
                count: data.recommendationCount,
                amount: formatNumber(data.annualSavings.amount),
                currency: getCurrency(data.annualSavings.currency).symbol
              })}
            />
          }
          contentData={<Commitments data={data.recommendations} />}
        />
      )}
    </Tree>
  )
}

interface CommitmentsProps {
  data: AzureRecommendationsByName[]
}

const Commitments = ({ data }: CommitmentsProps) => {
  const { t } = useTranslation()
  const isReservation = (recommendationGroup: AzureRecommendationsByName) =>
    recommendationGroup.recommendationName.toLowerCase().includes('reserved instance')

  return (
    <>
      {data.map((recommendationGroup, index) => (
        <TreeBranch
          id={`qa-optimization-${Vendor.AZURE}-tab-content-${index}`}
          key={index}
          depth={1}
          headerData={
            <OptimizationTabCollapsibleSummary
              headerLeft={recommendationGroup.recommendationName}
              useExclamationMark={recommendationGroup.annualSavings.amount > 400}
              headerRight={t('optimization.tabs.common.annualUpToSavings', {
                amount: formatNumber(recommendationGroup.annualSavings.amount),
                currency: getCurrency(recommendationGroup.annualSavings.currency).symbol
              })}
            />
          }
          contentData={
            <>
              <OptimizationTabTitleRow
                widerIndex={isReservation(recommendationGroup) ? [0, 2, 3] : [0, 1]}
                labels={[
                  t('vendors.AZURE.projectPhrase_one'),
                  isReservation(recommendationGroup) && t('common.region_one'),
                  isReservation(recommendationGroup) && t('optimization.tabs.common.recommendedPurchase'),
                  t('common.resourceType_one'),
                  t('optimization.tabs.common.monthlySavings'),
                  t('optimization.tabs.common.annualSavings')
                ]}
              />
              {recommendationGroup.recommendations.map((recommendation, i) => (
                <TreeBranch
                  key={i}
                  depth={2}
                  headerData={
                    <CommitmentRow isReservation={isReservation(recommendationGroup)} recommendation={recommendation} />
                  }
                  contentData={false}
                />
              ))}
            </>
          }
        />
      ))}
    </>
  )
}

interface CommitmentRowProps {
  recommendation: AzureCommitment
  isReservation: boolean
}

const CommitmentRow = ({ recommendation, isReservation }: CommitmentRowProps) => {
  const { t } = useTranslation()
  const currency = getCurrency(recommendation.savingsCurrency)

  return (
    <DataTableRow styles={`grid-cols-1 ${isReservation ? 'xl:grid-cols-9' : 'xl:grid-cols-6'} `}>
      <DataTableCell styles={'xl:col-span-2'}>
        <DataTableLabel>{t('vendors.AZURE.projectPhrase_one')}</DataTableLabel>
        <DataTableValue>
          <ClipboardCopy copyText={recommendation.sub.name} secondCopyText={recommendation.sub.id} limit={25} />
        </DataTableValue>
      </DataTableCell>
      {isReservation && (
        <>
          <TabDataTableRegionCell regionName={recommendation.region?.name} regionId={recommendation.region?.id} />
          <DataTableCell styles={'xl:col-span-2'}>
            <DataTableLabel>{t('optimization.tabs.common.recommendedPurchase')}</DataTableLabel>
            <DataTableValue>
              {recommendation.recommendedPurchase
                ? `${recommendation.recommendedPurchase} (${recommendation.quantity} ${t('common.total.abbr')})`
                : '-'}
            </DataTableValue>
          </DataTableCell>
        </>
      )}
      <DataTableCell styles={'xl:col-span-2'}>
        <DataTableLabel>{t('common.resourceType_one')}</DataTableLabel>
        <DataTableValue>{recommendation.resource.type}</DataTableValue>
      </DataTableCell>
      <DataTableCell>
        <DataTableLabel>{t('optimization.tabs.common.monthlySavings')}</DataTableLabel>
        <DataTableValue>
          {formatNumber(roundNumber(recommendation.monthlySavings, 0))} {currency.symbol}
        </DataTableValue>
      </DataTableCell>
      <DataTableCell>
        <DataTableLabel>{t('optimization.tabs.common.annualSavings')}</DataTableLabel>
        <DataTableValue>
          {formatNumber(roundNumber(recommendation.annualSavings, 0))} {currency.symbol}
        </DataTableValue>
      </DataTableCell>
    </DataTableRow>
  )
}
