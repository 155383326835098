import React from 'react'
import { DataTableCell, DataTableLabel, DataTableRow } from '../../shared/DataTableSharedComponents'
import { CapitalizedText } from '../../shared/TextComponents'
import { Trans } from 'react-i18next'
import styled from 'styled-components'
import tw from 'twin.macro'

interface OptimizationTabTitleRowProps {
  labels: (string | false)[]
  widerIndex?: number[] | null
  customColumns?: string
}

export const OptimizationTabTitleRow = ({ labels, widerIndex = null, customColumns }: OptimizationTabTitleRowProps) => {
  const filteredLabels = labels.filter(Boolean) as string[]

  const Cell = (label: string, index: number) => {
    return (
      <DataTableCell
        key={label}
        styles={
          widerIndex !== null ? (index === 0 || widerIndex?.includes(index) ? 'xl:col-span-2' : undefined) : undefined
        }
      >
        <DataTableLabel isHeader={true}>{label}</DataTableLabel>
      </DataTableCell>
    )
  }

  return (
    <DataTableRow
      isHeader={true}
      styles={`mt-4 px-5 xl:grid-cols-${filteredLabels.length + (widerIndex?.length ?? 0)}`}
      customColumns={customColumns}
    >
      {filteredLabels.map((label, index) => Cell(label, index))}
    </DataTableRow>
  )
}

interface OptimizationTabCollapsibleSummaryProps {
  headerLeft: React.ReactNode
  useExclamationMark?: boolean
  headerRight: string
}

export const OptimizationTabCollapsibleSummary = ({
  headerLeft,
  useExclamationMark = true,
  headerRight
}: OptimizationTabCollapsibleSummaryProps) => {
  return (
    <OptimizationTabHeaderWrapper>
      <CapitalizedText className={'min-w-30'}>{headerLeft}</CapitalizedText>
      <CapitalizedText className={'text-right text-80 lg:text-100'}>
        <Trans>{headerRight}</Trans>
        {useExclamationMark ? '!' : ''}
      </CapitalizedText>
    </OptimizationTabHeaderWrapper>
  )
}

export const OptimizationTabHeaderWrapper = styled.div`
  ${tw`flex gap-8 justify-between items-center text-gray-50`}
  div:first-child {
    div {
      ${tw`normal-case`}
    }
  }

  &.div:nth-of-type(2) {
    ${tw`text-right`}
  }

  strong {
    ${tw`text-112`}
  }

  strong:first-of-type {
    ${tw`bg-clip-text bg-gradient-to-b from-tertiary-100 to-success-100 text-transparent`}
  }

  strong:last-of-type:not(:first-of-type) {
    ${tw`bg-clip-text bg-gradient-to-b from-primary-100 to-primary-200 text-transparent`}
  }
`
