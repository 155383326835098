import { Vendor } from '../utils/vendors'
import { CancelToken } from 'axios'
import { client } from './client'
import { FilterOption, GroupedOptions } from '../components/shared/filters/FilterSelect'

export const globalRegion = {
  id: 'global',
  description: 'Global',
  latitude: 34.895698874185356,
  longitude: -41.37778362240773
}

export const getInfraMapData = async (
  vendors: Vendor[],
  regions: string[],
  projects: string[],
  resourceGroups: string[],
  resources: string[],
  cancelToken: CancelToken
): Promise<InfraMapData[]> => {
  const resp = await client.post<InfraMapData[]>(
    '/infra/map-data',
    {
      vendors,
      regions,
      projects,
      resourceGroups,
      resources
    },
    {
      cancelToken: cancelToken
    }
  )
  return resp.data
}

export const getInfraBreakDown = async (
  vendors: Vendor[],
  regions: string[],
  projects: string[],
  resourceGroups: string[],
  resources: string[],
  cancelToken: CancelToken
): Promise<InfraBreakDown[]> => {
  const resp = await client.post<InfraBreakDown[]>(
    `/infra/breakdown`,
    {
      vendors,
      regions,
      projects,
      resourceGroups,
      resources
    },
    { cancelToken }
  )
  return resp.data
}

export const getInfraFilterOptions = async (cancelToken: CancelToken): Promise<InfraFilterData> => {
  const resp = await client.get<InfraFilterData>('/infra/filters/options', {
    cancelToken
  })
  return resp.data
}

export interface InfraFilterData {
  regionOptions: GroupedOptions[]
  projectOptions: GroupedOptions[]
  resourceGroupOptions: FilterOption[]
  resourceOptions: GroupedOptions[]
}

export const saveInfraFilterPreset = async (
  filter: InfraFilterPreset,
  cancelToken: CancelToken
): Promise<InfraFilterPreset> => {
  const resp = await client.post<InfraFilterPreset>('/infra/filters/presets', filter, {
    cancelToken
  })
  return resp.data
}

export const deleteInfraFilter = async (filterName: string, cancelToken: CancelToken): Promise<void> => {
  const resp = await client.delete<void>(`/infra/filters/${filterName}`, {
    cancelToken
  })
  return resp.data
}

export const getInfraFilterPresets = async (cancelToken: CancelToken): Promise<InfraFilterPreset[]> => {
  const resp = await client.get<InfraFilterPreset[]>('/infra/filters/presets', {
    cancelToken
  })
  return resp.data
}

export const downloadInfraCsv = async (
  vendors: Vendor[],
  projects: string[],
  resourceGroups: string[],
  regions: string[],
  resourceTypes: string[],
  cancelToken: CancelToken
) => {
  const resp = await client.post<string>(
    `/infra/download-csv`,
    {
      vendors,
      projects,
      resourceGroups,
      regions,
      resourceTypes
    },
    {
      cancelToken: cancelToken,
      responseType: 'blob'
    }
  )
  const url = window.URL.createObjectURL(new Blob([resp.data]))
  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', `spotter_resources_${new Date().toLocaleDateString()}.csv`)
  document.body.appendChild(link)
  link.click()
}

export interface InfraFilterPreset {
  name: string
  vendors: FilterOption[]
  regions: FilterOption[]
  projects: FilterOption[]
  resourceGroups: FilterOption[]
  resources: FilterOption[]
}

export interface InfraMapData {
  vendor: Vendor
  region: Region
  count: number
}

export interface Region {
  id: string | null
  description: string | null
  latitude: number | null
  longitude: number | null
}

export interface InfraBreakDown {
  vendor: Vendor
  regions: IBRegion[]
}

export interface IBRegion {
  id: string
  name: string
  projects: IBProject[]
}

export interface IBProject {
  id: string
  name: string
  resourceGroups: IBProject[]
  resources: IBType[]
}

export interface IBType {
  type: string
  resources: IBResource[]
}

export interface IBResource {
  id: string
  name: string
}
