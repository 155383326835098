import React, { useEffect, useState } from 'react'
import { getHomeCostChange } from '../../api/home'
import { numberToPositive, roundNumber } from '../../utils/formats'
import { Trans, useTranslation } from 'react-i18next'
import { CountUp } from '../shared/CountUp'
import { useCancelToken } from '../../api/client'
import { useErrorHandling } from '../../hooks/handleError'
import { HomeNoDataSummaryContainer, HomeSummaryContainer, SummaryAmount, SummaryText } from './HomeSharedComponents'
import styled from 'styled-components'
import tw from 'twin.macro'

enum ChangeType {
  INCREASE = 'increase',
  DECREASE = 'decrease'
}

export const CostSummary = () => {
  const handleError = useErrorHandling()
  const { createCancelToken } = useCancelToken()
  const { t } = useTranslation()
  const [loading, setLoading] = useState<boolean>(true)
  const [changePercent, setChangePercent] = useState<number | null>(null)

  useEffect(() => {
    const cancelToken = createCancelToken()
    setLoading(true)
    getHomeCostChange(cancelToken.token)
      .then(setChangePercent)
      .catch(handleError)
      .finally(() => setLoading(false))
    return () => {
      setLoading(false)
      setChangePercent(null)
      cancelToken.cancel()
    }
  }, [createCancelToken, handleError])

  if (loading || changePercent === null)
    return <HomeNoDataSummaryContainer title={t('home.costSummary.title')} loading={loading} />

  const changeType = changePercent > 0 ? ChangeType.INCREASE : ChangeType.DECREASE
  const changeBarHeight = changePercent < 0 ? 100 - Math.abs(changePercent) : 100 + changePercent

  return (
    <HomeSummaryContainer title={t('home.costSummary.title')}>
      <>
        <div className={'flex gap-4'}>
          <Bar style={{ height: 100 }} />
          <Bar style={{ height: changeBarHeight }} changeType={changeType} />
        </div>

        <div>
          <SummaryAmount color={changePercent > 0 ? 'gradient-primary' : 'gradient-green'}>
            <CountUp countTo={numberToPositive(roundNumber(changePercent, 0))} id={'qa-home-cost-change'} />
            {'%'}
          </SummaryAmount>
          <SummaryText strongColor={changeType === ChangeType.INCREASE ? 'primary' : 'gray'}>
            <Trans>{t(`home.costSummary.${changeType}`)}</Trans>
          </SummaryText>
        </div>
      </>
    </HomeSummaryContainer>
  )
}

interface BarProps {
  changeType?: ChangeType
}

const Bar = styled.div<BarProps>`
  ${tw`self-end w-8 bg-gradient-to-b from-gray-400 via-gray-400 to-gray-500 min-h-2 max-h-40 md:max-h-[168px] md:w-12`}
  ${({ changeType }) =>
    changeType &&
    (changeType === ChangeType.INCREASE
      ? tw`from-primary-500 via-primary-600 to-primary-700`
      : tw`from-tertiary-100 via-success-100 to-success-500`)}
`
