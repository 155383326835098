import React, { createContext, useMemo, useState } from 'react'
import { AuthInfo } from '../../api/auth'
import { TenantIntegrationStates, UserSettings } from '../../api/settings/profile'
import { CurrencyId } from '../../utils/Currency'
import { Vendors } from '../../utils/vendors'
import { CostAnomalyLimits } from '../../api/notifications'

export interface UserInfoState {
  authInfo: AuthInfo
  setAuthInfo: (user: AuthInfo) => void
  userSettings: UserSettings
  setUserSettings: (settings: UserSettings) => void
  integrationStates: TenantIntegrationStates
  setIntegrationStates: (states: TenantIntegrationStates) => void
}

const defaultCostAnomalyLimits: CostAnomalyLimits = {
  dailyChangePercent: {
    decrease: -10,
    increase: 10
  },
  estMonthlyImpact: {
    decrease: -100,
    increase: 100
  }
}

export const defaultUserInfoState: UserInfoState = {
  authInfo: {
    id: '',
    firstName: '',
    lastName: '',
    emailAddress: '',
    tenant: { id: '', name: '' },
    roles: [],
    isSsoEnabled: false,
    awsIntegration: null,
    gcpIntegration: null,
    azureIntegration: null,
    site247Integration: undefined,
    freshIntegration: undefined,
    awsAccess: false,
    gcpAccess: false,
    azureAccess: false,
    costsAccess: false,
    optimizationAccess: false,
    complianceAccess: false,
    sustainabilityAccess: false,
    infraAccess: false,
    ticketAccess: false,
    notificationsAccess: false,
    lastLoginAt: null,
    isLoggedIn: false
  },
  setAuthInfo: () => undefined,
  userSettings: {
    currency: CurrencyId.EUR,
    defaultCostAnomalyLimits: defaultCostAnomalyLimits,
    visibleVendors: Vendors
  },
  setUserSettings: () => undefined,
  integrationStates: {
    aws: null,
    azure: null,
    gcp: null
  },
  setIntegrationStates: () => undefined
}

export const UserInfoContext = createContext<UserInfoState>(defaultUserInfoState)

interface Props {
  children: JSX.Element
}

export const UserInfoContextProvider = React.memo(({ children }: Props) => {
  const [authInfo, setAuthInfo] = useState<AuthInfo>(defaultUserInfoState.authInfo)
  const [userSettings, setUserSettings] = useState<UserSettings>(defaultUserInfoState.userSettings)
  const [integrationStates, setIntegrationStates] = useState<TenantIntegrationStates>(
    defaultUserInfoState.integrationStates
  )
  const value = useMemo(
    () => ({
      authInfo,
      setAuthInfo,
      userSettings,
      setUserSettings,
      integrationStates,
      setIntegrationStates
    }),
    [integrationStates, authInfo, userSettings]
  )

  return <UserInfoContext.Provider value={value}>{children}</UserInfoContext.Provider>
})
