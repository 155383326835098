import { IBRegion } from '../../../api/infra'
import { TreeBranch } from '../../shared/TreeBranch'
import { InfraRegionBreakdown } from './InfraRegionBreakdown'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import tw from 'twin.macro'
import React from 'react'
import { Vendor } from '../../../utils/vendors'

interface InfraRegionProps {
  region: IBRegion
  vendor: Vendor
}

export const InfraRegion = ({ region, vendor }: InfraRegionProps) => {
  const getResourceCount = (region: IBRegion) =>
    vendor === Vendor.AZURE
      ? region.projects.reduce(
          (acc, project) =>
            acc +
            project.resourceGroups.reduce(
              (acc, resourceGroup) =>
                acc + resourceGroup.resources.reduce((acc, resourceTypes) => acc + resourceTypes.resources.length, 0),
              0
            ),
          0
        )
      : region.projects.reduce(
          (acc, project) =>
            acc + project.resources.reduce((acc, resourceTypes) => acc + resourceTypes.resources.length, 0),
          0
        )

  return (
    <TreeBranch
      headerData={
        <RegionHeader
          vendor={vendor}
          region={region}
          resourceCount={getResourceCount(region)}
          projectCount={region.projects.length}
        />
      }
      contentData={<InfraRegionBreakdown vendor={vendor} projects={region.projects} region={region.id} />}
    />
  )
}

interface HeaderProps {
  vendor: Vendor
  region: IBRegion
  resourceCount: number
  projectCount: number
}

const RegionHeader = ({ vendor, region, resourceCount, projectCount }: HeaderProps) => {
  const { t } = useTranslation()
  return (
    <HeaderWrapper>
      <div>
        <Title>{region.name}</Title>
        <Subtitle>{region.id !== 'null' && region.id}</Subtitle>
      </div>
      <Subtitle>
        <Count>{resourceCount} </Count>
        {t(`vendors.${vendor}.resourcePhrase`, {
          count: resourceCount
        })}
        {` ${t('infra.in')} `}
        <Count>{projectCount} </Count>
        {t(`vendors.${vendor}.projectPhrase`, {
          count: projectCount
        })}
      </Subtitle>
    </HeaderWrapper>
  )
}

const HeaderWrapper = styled.div`
  ${tw`flex flex-col justify-between gap-1 px-2 py-1 md:flex-row md:items-center`}
`

const Title = styled.h1`
  ${tw`text-gray-50 text-125 font-bold`}
`

const Subtitle = styled.h2`
  ${tw`text-gray-200`}
`

const Count = styled.span`
  ${tw`text-gray-50 font-semibold text-112`}
`
