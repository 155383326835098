import { CancelToken } from 'axios'
import { client } from '../client'
import { FilterOption, GroupedOptions } from '../../components/shared/filters/FilterSelect'

export const getComplianceFilterData = async (
  projects: string[],
  cancelToken: CancelToken
): Promise<ComplianceFilterData> => {
  return await client
    .post<ComplianceFilterData>(`/compliance/filters/options`, projects, {
      cancelToken
    })
    .then(resp => resp.data)
}

export const getComplianceFilterPresets = async (cancelToken: CancelToken): Promise<ComplianceFilterPreset[]> => {
  const resp = await client.get<ComplianceFilterPreset[]>('/compliance/filters/presets', {
    cancelToken: cancelToken
  })
  return resp.data
}

export const saveComplianceFilterPreset = async (
  filter: ComplianceFilterPreset,
  cancelToken: CancelToken
): Promise<ComplianceFilterPreset> => {
  const resp = await client.post<ComplianceFilterPreset>('/compliance/filters/presets', filter, {
    cancelToken: cancelToken
  })
  return resp.data
}

export const deleteComplianceFilterPreset = async (filterName: string, cancelToken: CancelToken): Promise<void> => {
  const resp = await client.delete<void>(`/compliance/filters/${filterName}`, {
    cancelToken: cancelToken
  })
  return resp.data
}

export interface ComplianceFilterData {
  projectOptions: GroupedOptions[]
  resourceGroupOptions: GroupedOptions[]
  standardOptions: GroupedOptions[]
}

export interface ComplianceFilterPreset {
  name: string
  vendors: FilterOption[]
  projects: FilterOption[]
  resourceGroups: FilterOption[]
  standards: FilterOption[]
}
