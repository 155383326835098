import okIcon from '../../../assets/svg/gestures/ok-hand.svg'
import hand from '../../../assets/svg/gestures/stop.svg'
import point from '../../../assets/svg/gestures/point.svg'
import questionMark from '../../../assets/svg/symbols/question-mark.svg'
import { CustomIcon, IconType } from '../CustomIcon'
import React from 'react'
import { CapitalizedText } from '../TextComponents'

export enum StatusType {
  ERROR = 'ERROR',
  WARNING = 'WARNING',
  OK = 'OK',
  NOT_AVAILABLE = 'NOT_AVAILABLE'
}

interface StatusStyle {
  svg: string
  color: string
}

interface StatusIndicatorProps {
  type: StatusType
}

export const StatusIndicator = ({ type }: StatusIndicatorProps) => {
  const getStyle = (type?: StatusType): StatusStyle =>
    type === StatusType.OK
      ? {
          svg: okIcon,
          color: 'bg-success-100'
        }
      : type === StatusType.ERROR
        ? {
            svg: hand,
            color: 'bg-error-100'
          }
        : type === StatusType.WARNING
          ? {
              svg: point,
              color: 'bg-warn-100'
            }
          : {
              svg: questionMark,
              color: 'bg-gray-300'
            }

  return (
    <div className={'flex items-center gap-1 w-max'}>
      <CustomIcon iconType={IconType.VECTOR} path={getStyle(type).svg} styles={`h-5 w-5 ${getStyle(type).color}`} />
      <CapitalizedText>{type.toLowerCase().replace('_', ' ')}</CapitalizedText>
    </div>
  )
}
