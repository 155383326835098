import { client } from '../client'
import { CancelToken } from 'axios'
import { DataBlockData } from '../../components/shared/containers/DataBlock'
import { FilterOption, GroupedOptions } from '../../components/shared/filters/FilterSelect'
import { Vendor } from '../../utils/vendors'
import { CurrencyFilterOption } from '../../components/shared/filters/Filters'
import { CurrencyId } from '../../utils/Currency'

export const getOptimizationFilterOptions = async (cancelToken: CancelToken): Promise<OptimizationFilterOptions> => {
  const resp = await client.get<OptimizationFilterOptions>('optimization/filters/options', { cancelToken })
  return resp.data
}

export interface OptimizationFilterOptions {
  projects: GroupedOptions[]
  recommendations: GroupedOptions[]
  currencies: CurrencyFilterOption[]
}

export const getOptimizationFilterPresets = async (cancelToken: CancelToken): Promise<OptimizationFilterPreset[]> => {
  const resp = await client.get<OptimizationFilterPreset[]>('optimization/filters/presets', { cancelToken })
  return resp.data
}

export const saveOptimizationFilterPreset = async (
  filter: OptimizationFilterPreset,
  cancelToken: CancelToken
): Promise<OptimizationFilterPreset> => {
  const resp = await client.post<OptimizationFilterPreset>('optimization/filters/presets', filter, { cancelToken })
  return resp.data
}

export interface OptimizationFilterPreset {
  name: string
  vendors: FilterOption[]
  projects: FilterOption[]
  recommendations: FilterOption[]
}

export const deleteOptimizationFilterPreset = async (name: string, cancelToken: CancelToken): Promise<void> => {
  await client.delete(`optimization/filters/${name}`, { cancelToken })
}

export enum OptimizationCommitmentTerm {
  ONE_YEAR = 'ONE_YEAR',
  THREE_YEARS = 'THREE_YEARS'
}

export const getOptimizationSummaryTotal = async (
  vendors: Vendor[],
  projects: string[],
  recommendations: string[],
  currency: CurrencyId,
  term: OptimizationCommitmentTerm,
  cancelToken: CancelToken
): Promise<RecommendationsSavingsSummary> => {
  const resp = await client.post<RecommendationsSavingsSummary>(
    `optimization/summary/total-savings`,
    {
      vendors,
      projects,
      recommendations,
      currency,
      term
    },
    { cancelToken }
  )
  return resp.data
}

export interface RecommendationsSavingsSummary {
  annualSavings: number
  monthlySavings: number
  currency: CurrencyId
  monthlySavingsPercent: number
}

export const getOptimizationSummaryByCategory = async (
  vendors: Vendor[],
  projects: string[],
  recommendations: string[],
  currency: CurrencyId,
  term: OptimizationCommitmentTerm,
  cancelToken: CancelToken
): Promise<SavingsByCategory> => {
  const resp = await client.post<SavingsByCategory>(
    `optimization/summary/savings-by-category`,
    {
      vendors,
      projects,
      recommendations,
      currency,
      term
    },
    { cancelToken }
  )
  return resp.data
}

export interface SavingsByCategory {
  commitments: CategorySavings
  rightsizing: CategorySavings
  utilization: CategorySavings
}

export interface CategorySavings {
  monthly: number
  annual: number
  currency: CurrencyId
}

export const getTopRecommendations = async (
  vendors: Vendor[],
  projects: string[],
  recommendations: string[],
  currency: CurrencyId,
  cancelToken: CancelToken
): Promise<TopRecommendations> => {
  const resp = await client.post<TopRecommendations>(
    'optimization/tabs/top-recommendations',
    {
      vendors,
      projects,
      recommendations,
      currency
    },
    { cancelToken }
  )

  return resp.data
}

export interface TopRecommendations {
  commitments: DataBlockData[]
  rightsizing: DataBlockData[]
  utilization: DataBlockData[]
}

export const exportOptimizationTabData = async (
  vendors: Vendor[],
  projects: string[],
  recommendations: string[],
  currency: CurrencyId,
  awsCategories: OptimizationCategory[],
  azureCategories: OptimizationCategory[],
  gcpCategories: OptimizationCategory[],
  cancelToken: CancelToken
) => {
  const request = async (vendor: Vendor, category: OptimizationCategory) => {
    await client
      .post(
        `/optimization/tabs/download-csv/${vendor.toLowerCase()}/${category}`,
        {
          vendors,
          projects,
          recommendations,
          currency
        },
        {
          cancelToken: cancelToken,
          responseType: 'blob'
        }
      )
      .then(resp => {
        const url = window.URL.createObjectURL(new Blob([resp.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute(
          'download',
          `spotter_${vendor.toLowerCase()}_${category.toLowerCase()}_${new Date().toLocaleDateString()}.csv`
        )
        document.body.appendChild(link)
        link.click()
      })
  }

  return vendors.forEach(vendor => {
    return vendor === Vendor.AWS
      ? awsCategories.forEach(async category => {
          return request(vendor, category)
        })
      : vendor === Vendor.AZURE
        ? azureCategories.forEach(async category => {
            return request(vendor, category)
          })
        : vendor === Vendor.GCP
          ? gcpCategories.forEach(async category => {
              return request(vendor, category)
            })
          : null
  })
}

export enum OptimizationCategory {
  COMMITMENTS = 'COMMITMENTS',
  RIGHTSIZING = 'RIGHTSIZING',
  UTILIZATION = 'UTILIZATION'
}
