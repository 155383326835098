import React, { useContext, useRef, useState } from 'react'
import { ViewWrapper } from '../shared/layout/ViewWrapper'
import { FilterOption } from '../shared/filters/FilterSelect'
import { CostFilters } from './CostFilters'
import { CostsTotalSummary } from './CostsTotalSummary'
import { Vendor } from '../../utils/vendors'
import { CurrencyFilterOption } from '../shared/filters/Filters'
import { getCurrency } from '../../utils/Currency'
import { CostsTabs } from './tabs/CostsTabs'
import { CostsChart } from './CostsChart'
import { TimeframeOption } from '../../utils/classes'
import { UserInfoContext } from '../../state/context/UserInfoContext'
import { useTranslation } from 'react-i18next'

export const Costs = () => {
  const { t } = useTranslation()
  const { userSettings } = useContext(UserInfoContext)
  const [selectedVendors, setSelectedVendors] = useState<FilterOption[]>([])
  const [selectedProjects, setSelectedProjects] = useState<FilterOption[]>([])
  const [selectedResourceGroups, setSelectedResourceGroups] = useState<FilterOption[]>([])
  const [selectedServices, setSelectedServices] = useState<FilterOption[]>([])
  const [selectedTags, setSelectedTags] = useState<FilterOption[]>([])
  const [selectedCurrency, setSelectedCurrency] = useState<CurrencyFilterOption>({
    value: userSettings.currency,
    label: getCurrency(userSettings.currency).symbolLong
  })
  const [selectedTimeframe, setSelectedTimeframe] = useState<TimeframeOption>(TimeframeOption.LAST_MONTH)
  const refVisibleVendors = useRef(userSettings.visibleVendors)

  if (
    (!selectedVendors.length && userSettings.visibleVendors.length > 0) ||
    refVisibleVendors.current !== userSettings.visibleVendors
  ) {
    setSelectedVendors(
      userSettings.visibleVendors.map(v => ({
        label: t(`vendors.${v}.short`),
        value: v
      }))
    )
    refVisibleVendors.current = userSettings.visibleVendors
  }

  return (
    <ViewWrapper>
      <CostFilters
        selectedVendors={selectedVendors}
        setSelectedVendors={setSelectedVendors}
        selectedProjects={selectedProjects}
        setSelectedProjects={setSelectedProjects}
        selectedResourceGroups={selectedResourceGroups}
        setSelectedResourceGroups={setSelectedResourceGroups}
        selectedServices={selectedServices}
        setSelectedServices={setSelectedServices}
        selectedTags={selectedTags}
        setSelectedTags={setSelectedTags}
        selectedCurrency={selectedCurrency}
        setSelectedCurrency={setSelectedCurrency}
      />
      <CostsTotalSummary
        selectedVendors={selectedVendors.map(v => v.value as Vendor)}
        selectedProjects={selectedProjects.map(p => p.value)}
        selectedResourceGroups={selectedResourceGroups.map(r => r.value)}
        selectedServices={selectedServices.map(r => r.value)}
        selectedTags={selectedTags}
        selectedCurrency={selectedCurrency.value}
      />
      <CostsChart
        selectedVendors={selectedVendors.map(v => v.value as Vendor)}
        selectedProjects={selectedProjects.map(p => p.value)}
        selectedResourceGroups={selectedResourceGroups.map(r => r.value)}
        selectedServices={selectedServices.map(r => r.value)}
        selectedTags={selectedTags}
        selectedCurrency={selectedCurrency.value}
      />
      <CostsTabs
        selectedVendors={selectedVendors.map(v => v.value as Vendor)}
        selectedProjects={selectedProjects.map(p => p.value)}
        selectedResourceGroups={selectedResourceGroups.map(rg => rg.value)}
        selectedServices={selectedServices.map(s => s.value)}
        selectedTags={selectedTags}
        selectedCurrency={selectedCurrency.value}
        selectedTimeframe={selectedTimeframe}
        setSelectedTimeframe={setSelectedTimeframe}
      />
    </ViewWrapper>
  )
}
