// session storage
import { ContactCategory } from '../api/settings/profile'

export const setSignupEmail = (email: string) => sessionStorage.setItem('signupEmail', email)
export const getSignupEmail = () => {
  const email = sessionStorage.getItem('signupEmail')
  return email && email !== 'null' ? email : null
}
export const removeSignupEmail = () => sessionStorage.removeItem('signupEmail')

export const setFirstLogin = () => sessionStorage.setItem('firstLogin', 'true')
export const firstLogin = () => sessionStorage.getItem('firstLogin') === 'true'
export const removeFirstLogin = () => sessionStorage.removeItem('firstLogin')
export const setContactCategory = (category: ContactCategory) => sessionStorage.setItem('contactCategory', category)
export const getContactCategory = (): ContactCategory | null =>
  sessionStorage.getItem('contactCategory') as ContactCategory
export const removeContactCategory = () => sessionStorage.removeItem('contactCategory')
export const setContactSubject = (subject: string) => sessionStorage.setItem('contactSubject', subject)
export const getContactSubject = () => sessionStorage.getItem('contactSubject')
export const removeContactSubject = () => sessionStorage.removeItem('contactSubject')
export const setContactMessage = (message: string) => sessionStorage.setItem('contactMessage', message)
export const getContactMessage = () => sessionStorage.getItem('contactMessage')
export const removeContactMessage = () => sessionStorage.removeItem('contactMessage')

// local storage
export const setNotificationProject = (project: string) => localStorage.setItem('notificationProject', project)
export const getNotificationProject = () => localStorage.getItem('notificationProject')
export const removeNotificationProject = () => localStorage.removeItem('notificationProject')
export const setNotificationService = (service: string) => localStorage.setItem('notificationService', service)
export const getNotificationService = () => localStorage.getItem('notificationService')
export const removeNotificationService = () => localStorage.removeItem('notificationService')
