import {
  createContainer,
  Tuple,
  VictoryAxis,
  VictoryChart,
  VictoryGroup,
  VictoryLabel,
  VictoryLegend,
  VictoryLine,
  VictoryPortal,
  VictoryScatter
} from 'victory'
import { awsColor, azureColor, gcpColor, gray300, gray400, gray500, primary500, small } from '../../../design/constants'
import { capitalizeFirstLetter, formatMonthShortString, formatToDay, formatToYear } from '../../../utils/formats'
import { Vendor } from '../../../utils/vendors'
import React from 'react'
import { SustainabilityChartData } from '../../../api/sustainability'
import { ComplianceChartData } from '../../../api/compliance/common'
import { Timescale } from '../../../utils/classes'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import tw from 'twin.macro'
import { Button, ButtonStyle, ButtonType } from '../buttons/Button'
import { CustomIcon, IconType } from '../CustomIcon'
import caret from '../../../assets/svg/directional/caret-heavy.svg'
import { Heading } from '../TextComponents'

interface CustomLineChartProps {
  chartData: SustainabilityChartData[] | ComplianceChartData[]
  domain: [number, number]
  tooltip?: JSX.Element
  tooltipLabel?: (datum: any) => any
  selectedVendors: Vendor[]
  showVendorLegend?: boolean
  timescale?: Timescale
  showLeftAxis: boolean
  leftAxisTicks?: number[]
  formatLeftTicks?: (tick: number) => number | string
  leftYAxisDataKey: (datum: any) => any
  showRightYAxis?: boolean
  rightAxisTicks?: number[]
  formatRightTicks?: (tick: number) => string
  hideComboLine: boolean
  zoomProps?: ChartZoomProps
}

export const CustomLineChart = ({
  chartData,
  domain,
  tooltip,
  tooltipLabel,
  selectedVendors,
  showVendorLegend,
  timescale = Timescale.YEAR,
  showLeftAxis,
  leftAxisTicks,
  formatLeftTicks,
  leftYAxisDataKey,
  showRightYAxis,
  rightAxisTicks,
  formatRightTicks,
  hideComboLine,
  zoomProps
}: CustomLineChartProps) => {
  const { t } = useTranslation()

  const vendorColor = (vendor: Vendor) => {
    switch (vendor) {
      case Vendor.AWS:
        return awsColor
      case Vendor.AZURE:
        return azureColor
      case Vendor.GCP:
        return gcpColor
      default:
        return primary500
    }
  }

  const legendData = selectedVendors
    .map(vendor => ({
      name: t(`vendors.${vendor}.short`),
      symbol: {
        fill: vendorColor(vendor)
      }
    }))
    .concat(
      !hideComboLine && chartData.some(i => i.vendor === Vendor.ALL)
        ? {
            name: t('common.total.reg'),
            symbol: {
              fill: primary500
            }
          }
        : []
    )

  return (
    <CustomVictoryChart
      domain={domain}
      zoomDomain={zoomProps?.domain}
      tooltip={tooltip}
      tooltipLabel={tooltipLabel}
      legendData={showVendorLegend && legendData}
    >
      {showLeftAxis && (
        <VictoryAxis
          dependentAxis
          style={{
            axis: { stroke: 'none' },
            tickLabels: {
              fontSize: small,
              fontWeight: 200,
              letterSpacing: '1px',
              padding: 16,
              fill: gray300
            },
            grid: { stroke: gray500 }
          }}
          tickValues={leftAxisTicks}
          tickFormat={formatLeftTicks}
        />
      )}
      {showRightYAxis && (
        <VictoryAxis
          dependentAxis
          orientation={'right'}
          style={{
            axis: { stroke: 'none' },
            grid: { stroke: selectedVendors.length > 1 ? 'none' : gray300 },
            tickLabels: {
              fontSize: small,
              fontWeight: 200,
              padding: 20,
              fill: gray300
            }
          }}
          tickValues={rightAxisTicks}
          tickFormat={formatRightTicks}
        />
      )}
      <VictoryAxis
        style={{
          axis: { stroke: 'none' },
          ticks: { size: 0 },
          tickLabels: {
            fontSize: small,
            fontWeight: 200,
            letterSpacing: '1px',
            padding: 20,
            fill: gray300
          }
        }}
        tickFormat={tick =>
          !chartData.length ? '' : timescale === Timescale.YEAR ? formatMonthShortString(tick) : formatToDay(tick)
        }
      />
      <VictoryAxis
        style={{
          axis: { stroke: gray400 },
          tickLabels: {
            letterSpacing: '1px',
            fontSize: '12px',
            fontWeight: 200,
            padding: 40,
            fill: gray300
          }
        }}
        tickFormat={tick =>
          timescale === Timescale.YEAR && tick.toString().includes('01-')
            ? formatToYear(tick)
            : timescale === Timescale.MONTH && tick.toString().includes('-01')
              ? formatMonthShortString(tick)
              : ''
        }
      />
      {chartData.map(
        (vendorData, index) =>
          selectedVendors.includes(vendorData.vendor) &&
          vendorData.vendor !== Vendor.ALL && (
            <VictoryGroup key={index}>
              <VictoryLine
                data={vendorData.chartEntries}
                x={'date'}
                y={leftYAxisDataKey}
                style={{
                  data: {
                    stroke: vendorColor(vendorData.vendor),
                    strokeWidth: 1
                  }
                }}
              />
              <VictoryScatter
                data={vendorData.chartEntries}
                x={'date'}
                y={leftYAxisDataKey}
                size={({ active }) => (active ? 4 : 3)}
                style={{
                  data: {
                    fill: vendorColor(vendorData.vendor)
                  }
                }}
              />
            </VictoryGroup>
          )
      )}
      {chartData.map(
        (vendorData, index) =>
          vendorData.vendor === Vendor.ALL &&
          !hideComboLine && (
            <VictoryGroup key={index}>
              <VictoryLine
                data={vendorData.chartEntries}
                x={'date'}
                y={leftYAxisDataKey}
                style={{
                  data: {
                    stroke: vendorColor(vendorData.vendor),
                    strokeWidth: 1
                  }
                }}
              />
              <VictoryScatter
                data={vendorData.chartEntries}
                x={'date'}
                y={leftYAxisDataKey}
                size={({ active }) => (active ? 4 : 3)}
                style={{
                  data: {
                    fill: hideComboLine ? 'transparent' : vendorColor(vendorData.vendor)
                  }
                }}
              />
            </VictoryGroup>
          )
      )}
    </CustomVictoryChart>
  )
}

interface VictoryLegendData {
  name: string
  symbol: { fill: string }
}

interface CustomVictoryChartProps {
  domain: Tuple<number>
  zoomDomain?: Tuple<number>
  tooltip?: JSX.Element
  tooltipLabel?: (data: any) => any
  legendData?: VictoryLegendData[] | false
  children?: React.ReactNode
}

export const CustomVictoryChart = ({
  domain,
  zoomDomain,
  tooltip,
  tooltipLabel,
  legendData,
  children
}: CustomVictoryChartProps) => {
  const height = 360
  return (
    <ChartWrapper grab={!!zoomDomain}>
      <VictoryChart
        width={950}
        height={360}
        padding={{
          top: 30,
          bottom: 80,
          left: 70,
          right: 70
        }}
        animate={false}
        domainPadding={10}
        domain={{ y: domain }}
        containerComponent={
          <VictoryZoomVoronoiContainer
            labels={tooltipLabel}
            labelComponent={tooltip && <VictoryPortal>{tooltip}</VictoryPortal>}
            allowZoom={false}
            zoomDomain={{ x: zoomDomain }}
            zoomDimension={'x'}
          />
        }
      >
        {children}
        {legendData && (
          <VictoryLegend
            orientation={'horizontal'}
            gutter={40}
            y={height - 20}
            x={40}
            labelComponent={
              <VictoryLabel
                style={{
                  fill: gray300,
                  fontWeight: 300,
                  letterSpacing: 0.5,
                  fontFamily: 'Open sans',
                  fontSize: '13px'
                }}
              />
            }
            data={legendData.map(data => ({ ...data, name: capitalizeFirstLetter(data.name) }))}
          />
        )}
      </VictoryChart>
    </ChartWrapper>
  )
}

interface ChartWrapperProps {
  grab: boolean
}

const ChartWrapper = styled.div<ChartWrapperProps>`
  ${tw`self-center w-full max-w-6xl`}
  svg {
    ${tw`max-w-305 overflow-visible m-auto`}
    cursor: ${({ grab }) => (grab ? 'grab' : 'default')};

    :active {
      cursor: ${({ grab }) => (grab ? 'grabbing' : 'default')};
    }
  }
`

interface LineChartHeaderProps {
  heading: string
  subheading?: React.ReactNode
  actions: React.ReactNode
}

export const LineChartHeader = ({ heading, subheading, actions }: LineChartHeaderProps) => {
  return (
    <div className={'flex w-full gap-3 justify-between items-start flex-col md:flex-row'}>
      <div className={'flex flex-col w-full text-center items-center md:text-left md:items-start'}>
        <Heading>{heading}</Heading>
        <div>{subheading}</div>
      </div>
      <div className={'flex flex-col justify-center items-end gap-2 w-full max-w-max'}>{actions}</div>
    </div>
  )
}

interface ChartZoomProps {
  setDomain: (value: Tuple<number>) => void
  domain: Tuple<number>
  minDomain: Tuple<number>
  maxDomain: Tuple<number>
}

export const ChartZoomToggles = ({ domain, setDomain, minDomain, maxDomain }: ChartZoomProps) => {
  return (
    <div className={'flex gap-2 w-max'}>
      <Button
        type={ButtonType.ICON}
        buttonStyle={ButtonStyle.GHOST}
        clickHandler={() => (domain[0] > 1 ? setDomain([domain[0] - 1, domain[1] - 1]) : setDomain(minDomain))}
        value={
          <CustomIcon
            iconType={IconType.VECTOR}
            path={caret}
            styles={'bg-gray-300 group-hover:bg-gray-50 rotate-90 w-4 h-4'}
          />
        }
      />
      <Button
        type={ButtonType.ICON}
        buttonStyle={ButtonStyle.GHOST}
        clickHandler={() => (domain[1] < 23 ? setDomain([domain[0] + 1, domain[1] + 1]) : setDomain(maxDomain))}
        value={
          <CustomIcon
            iconType={IconType.VECTOR}
            path={caret}
            styles={'bg-gray-300 group-hover:bg-gray-50 -rotate-90 w-4 h-4'}
          />
        }
      />
    </div>
  )
}

export const VictoryZoomVoronoiContainer: any = createContainer('zoom', 'voronoi')

export const LineChartContainer = styled.div`
  ${tw`flex flex-col w-full transition-all ease-in-out gap-4 lg:gap-8`}
`
