import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'
import caretDown from '../../../assets/svg/directional/caret.svg'
import { TicketTable } from './TicketTable'
import { FreshTicket, getTicketsByStatus } from '../../../api/tickets'
import { Loading } from '../../shared/Loading'
import { useTranslation } from 'react-i18next'
import { CountUp } from '../../shared/CountUp'
import { useErrorHandling } from '../../../hooks/handleError'
import { useCancelToken } from '../../../api/client'
import { CustomIcon, IconType } from '../../shared/CustomIcon'
import { Button, ButtonStyle, ButtonType } from '../../shared/buttons/Button'
import { GrayText, Heading3 } from '../../shared/TextComponents'

interface Props {
  status: string
}

export const TicketStatusSummary = ({ status }: Props) => {
  const [isOpen, setIsOpen] = useState(false)
  const { t } = useTranslation()
  const handleError = useErrorHandling()
  const { createCancelToken } = useCancelToken()
  const [loading, setLoading] = useState<boolean>(false)
  const [ticketData, setTicketData] = useState<FreshTicket[]>([])

  useEffect(() => {
    setLoading(true)
    const cancelToken = createCancelToken()
    getTicketsByStatus(status, cancelToken.token)
      .then(setTicketData)
      .catch(handleError)
      .finally(() => setLoading(false))

    return () => {
      cancelToken.cancel()
      setLoading(false)
      setTicketData([])
    }
  }, [status, handleError, createCancelToken])

  const incidentCount = ticketData.filter(ticket => ticket.type === 'Incident').length
  const serviceRequestCount = ticketData.filter(ticket => ticket.type === 'Service Request').length

  return (
    <>
      <Container onClick={() => setIsOpen(!isOpen)}>
        <ColouredEdge status={status} />
        {loading ? (
          <Loading height={64} paddingY={'0.75rem'} />
        ) : (
          <Wrapper>
            <div className={'flex items-center gap-4'}>
              <Button
                clickHandler={() => setIsOpen(!isOpen)}
                type={ButtonType.ICON}
                buttonStyle={ButtonStyle.GHOST}
                value={
                  <CustomIcon
                    iconType={IconType.VECTOR}
                    path={caretDown}
                    styles={`w-6 h-6 bg-gray-50 transition-all ease ${isOpen ? 'rotate-180' : 'rotate-0'}`}
                    alt={t('tickets.toggleMenu')}
                  />
                }
              />
              <StatusSummary>
                <InnerWrapper>
                  <Heading3>{status}</Heading3>
                  <GrayText>
                    {incidentCount} {t('tickets.ticketTypes.incidents', { count: incidentCount })}
                    {' - '}
                    {serviceRequestCount} {t('tickets.ticketTypes.serviceRequests', { count: serviceRequestCount })}
                  </GrayText>
                </InnerWrapper>
              </StatusSummary>
            </div>
            <StatusCount status={status}>
              <CountUp countTo={ticketData.length} id={`qa-tickets-${status}-count`} />
            </StatusCount>
          </Wrapper>
        )}
      </Container>
      {isOpen && <TicketTable tickets={ticketData} />}
    </>
  )
}

interface ColorProps {
  status: string
}

const ColouredEdge = styled.div<ColorProps>`
  ${tw`w-full rounded-t-md pb-1`}
  ${({ status }) =>
    status === 'OPEN'
      ? tw`bg-primary-500`
      : status === 'PENDING'
        ? tw`bg-primary-700/60`
        : status === 'RESOLVED'
          ? tw`bg-success-500`
          : status === 'CLOSED'
            ? tw`bg-gray-400`
            : tw`bg-primary-100`}
`

const StatusCount = styled.div<ColorProps>`
  ${tw`flex justify-center items-center w-8 h-8 p-1 text-gray-50 font-semibold rounded-full`}
  ${({ status }) =>
    status === 'OPEN'
      ? tw`bg-primary-500`
      : status === 'PENDING'
        ? tw`bg-primary-700/60`
        : status === 'RESOLVED'
          ? tw`bg-success-500`
          : status === 'CLOSED'
            ? tw`bg-gray-400`
            : tw`bg-primary-100`}
`

const Container = styled.div`
  ${tw`flex flex-col bg-gray-600 relative rounded-md gap-1 cursor-pointer`}
`

const Wrapper = styled.div`
  ${tw`flex justify-between items-center gap-5 p-6 w-full`}
`

const StatusSummary = styled.div`
  ${tw`flex flex-row items-center justify-start gap-3`}
`

const InnerWrapper = styled.div`
  ${tw`flex flex-col text-left`}
`
