import React, { useContext, useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom'
import { useCancelToken } from '../../api/client'
import { useErrorHandling } from '../../hooks/handleError'
import { getUserDetails, OrganizationUser } from '../../api/admin/users'
import { CapitalizedText, GrayText, Heading } from '../shared/TextComponents'
import { NestedPageHeader } from '../shared/NestedPageHeader'
import { formatDate } from '../../utils/formats'
import { AdminContentWrapper, AdminTabContent } from '../admin/adminStyles'
import { EditAccessRights } from '../admin/users/tabs/EditAccessRights'
import { UserRole } from '../../api/auth'
import {
  changeUsersCurrentTenant,
  deleteUser,
  deleteUserRole,
  getAllTenantDetails,
  grantUserRole
} from '../../api/master-admin'
import { CheckboxOption } from '../shared/buttons/CheckboxOption'
import { Button, ButtonSize, ButtonStyle, ButtonType } from '../shared/buttons/Button'
import { MessageContext, MessageType } from '../../state/context/MessageContext'
import { UserDetailsTabOptions, UserEditTab } from '../admin/users/UserDetails'
import { EditVisibility } from '../admin/users/tabs/EditVisibility'
import { MasterAdminTenantMenu } from './SystemAdminDetails'
import { FilterOption } from '../shared/filters/FilterSelect'
import { ModalContext } from '../../state/context/ModalContext'
import { ModalActions } from '../shared/modal/Modal'
import { Loading } from '../shared/Loading'
import styled from 'styled-components'
import tw from 'twin.macro'
import { LabeledMenu } from './Shared'
import { CustomIcon, IconType } from '../shared/CustomIcon'
import deleteIcon from '../../assets/svg/objects/trashcan.svg'

export const MasterAdminUserDetails = () => {
  const { t } = useTranslation()
  const { setMessage } = useContext(MessageContext)
  const history = useHistory()
  const { createCancelToken } = useCancelToken()
  const handleError = useErrorHandling()
  const { userId } = useParams<{ userId: string }>()
  const { setModal } = useContext(ModalContext)
  const [loading, setLoading] = useState(true)
  const [user, setUser] = useState<OrganizationUser | null>(null)
  const [selectedTab, setSelectedTab] = useState<UserEditTab>(UserEditTab.ACCESS_RIGHTS)
  const [tenants, setTenants] = useState<FilterOption[]>([])
  const [filteredTenants, setFilteredTenants] = useState<FilterOption[]>([])
  const [searchText, setSearchText] = useState('')
  const [currentTenant, setCurrentTenant] = useState<FilterOption | null>(null)

  useEffect(() => {
    const cancelToken = createCancelToken()
    setLoading(true)
    Promise.all([
      getUserDetails(userId, cancelToken.token).then(setUser),
      getAllTenantDetails(cancelToken.token).then(tenants =>
        setTenants(tenants.map(t => ({ label: t.tenant.name, value: t.tenant.id })))
      )
    ])
      .catch(handleError)
      .finally(() => setLoading(false))

    return () => {
      cancelToken.cancel()
      setLoading(false)
    }
  }, [createCancelToken, handleError, userId])

  useEffect(() => {
    user && setCurrentTenant(tenants.find(t => t.value === user.tenantId) || null)
  }, [user, tenants])

  useEffect(() => {
    if (searchText !== '') {
      setFilteredTenants(
        tenants.filter(
          t =>
            t.label.toLowerCase().includes(searchText.toLowerCase()) ||
            t.value.toLowerCase().includes(searchText.toLowerCase())
        )
      )
    } else setFilteredTenants(tenants)
  }, [searchText, tenants])

  if (!user || !currentTenant) return null

  if (loading) return <Loading />

  const isSystemAdmin = user.roles.includes(UserRole.SYSTEM_ADMIN)
  const handleSystemAdminRoleUpdate = () => {
    ;(isSystemAdmin
      ? deleteUserRole(user.id, UserRole.SYSTEM_ADMIN, createCancelToken().token)
      : grantUserRole(user.id, UserRole.SYSTEM_ADMIN, createCancelToken().token)
    )
      .then(resp => {
        setUser(resp)
        setMessage({
          message: t('admin.tabs.rolesAndAccessRights.roleChangeSuccess'),
          type: MessageType.SUCCESS
        })
      })
      .catch(handleError)
  }

  const changeUsersTenant = (selectedTenant: FilterOption) => {
    const cancelToken = createCancelToken()
    const changeTenant = () => {
      changeUsersCurrentTenant(userId, selectedTenant.value, cancelToken.token)
        .then(resp => {
          setUser(resp)
          setMessage({
            message: t('masterAdmin.tenantChanged', { name: selectedTenant.label }),
            type: MessageType.SUCCESS
          })
          setModal(null)
        })
        .catch(handleError)
    }

    setModal({
      header: t('masterAdmin.users.tenantChangeModalHeader'),
      body: (
        <>
          <div className={'max-w-125'}>
            <ModalText>
              <Trans>
                {t('masterAdmin.users.tenantChangeModalQuestion', {
                  name: `${user.firstName} ${user.lastName}`,
                  email: user.emailAddress,
                  currentTenant: currentTenant.label,
                  newTenant: selectedTenant.label
                })}
              </Trans>
            </ModalText>
          </div>
          <ModalActions>
            <Button value={t('common.no')} clickHandler={() => setModal(null)} />
            <Button value={t('common.yes')} clickHandler={changeTenant} />
          </ModalActions>
        </>
      )
    })
  }

  const deleteUserAction = () => {
    const cancelToken = createCancelToken()
    setModal({
      header: t('masterAdmin.users.deleteUserModalHeader'),
      body: (
        <DeleteUserModal
          user={user}
          deleteAction={() => {
            deleteUser(user.id, cancelToken.token)
              .then(() => {
                setMessage({
                  type: MessageType.SUCCESS,
                  message: t('admin.usersList.deleteUserSuccessToast', {
                    name: `${user.firstName} ${user.lastName}`
                  })
                })
                setModal(null)
                history.push('/master-admin/users')
              })
              .catch(handleError)
          }}
        />
      )
    })
  }

  return (
    <>
      <div className={'flex flex-col w-full'}>
        <NestedPageHeader
          mainHeading={`${user.firstName} ${user.lastName}`}
          subHeading={<GrayText>{`${t('admin.updated')} ${formatDate(user.updatedAt, true, true)}`}</GrayText>}
          actions={
            <Button
              value={<CustomIcon path={deleteIcon} iconType={IconType.VECTOR} styles={'w-6 h-6 bg-gray-50'} />}
              type={ButtonType.ICON}
              clickHandler={deleteUserAction}
            />
          }
          backButtonPath={
            // @ts-ignore
            history.location?.state?.from === 'system-admins'
              ? history.location.pathname.replace(`/users`, '/system-admins')
              : history.location.pathname.replace(`/${userId}`, '')
          }
        />
        <AdminContentWrapper>
          <UserDetailsTabOptions selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
          {selectedTab === UserEditTab.ACCESS_RIGHTS ? (
            <div>
              <ChangeTenant
                user={user}
                currentTenant={currentTenant}
                options={filteredTenants}
                searchText={searchText}
                setSearchText={setSearchText}
                handleSelect={changeUsersTenant}
              />
              <SystemAdminRole isSystemAdmin={isSystemAdmin} roleUpdateHandler={handleSystemAdminRoleUpdate} />
              <EditAccessRights user={user} setUser={setUser} />
            </div>
          ) : (
            <EditVisibility user={user} setUser={setUser} />
          )}
        </AdminContentWrapper>
      </div>
    </>
  )
}

const ModalText = styled(CapitalizedText)`
  ${tw`text-center text-gray-200`}
  strong {
    ${tw`text-gray-50`}
  }
`

interface DeleteUserModalProps {
  user: OrganizationUser
  deleteAction: () => void
}

const DeleteUserModal = ({ user, deleteAction }: DeleteUserModalProps) => {
  const { t } = useTranslation()
  const { setModal } = useContext(ModalContext)
  return (
    <>
      <div className={'max-w-125'}>
        <ModalText>
          <Trans>
            {t('masterAdmin.users.deleteUserModalQuestion', {
              name: `${user.firstName} ${user.lastName}`,
              email: user.emailAddress
            })}
          </Trans>
        </ModalText>
      </div>
      <ModalActions>
        <Button
          size={ButtonSize.SMALL}
          value={t('common.cancel')}
          clickHandler={() => setModal(null)}
          type={ButtonType.FORM}
          buttonStyle={ButtonStyle.SECONDARY}
        />
        <Button
          value={t('common.delete')}
          size={ButtonSize.SMALL}
          clickHandler={() => deleteAction()}
          type={ButtonType.FORM}
        />
      </ModalActions>
    </>
  )
}

interface ChangeTenantProps {
  user: OrganizationUser
  currentTenant: FilterOption
  options: FilterOption[]
  searchText: string
  setSearchText: (text: string) => void
  handleSelect: (tenant: FilterOption) => void
}

const ChangeTenant = ({ user, currentTenant, options, searchText, setSearchText, handleSelect }: ChangeTenantProps) => {
  const { t } = useTranslation()
  return (
    <AdminTabContent className={'border-b border-gray-500'}>
      <Heading>{t('common.tenant')}</Heading>
      <div className={'flex flex-col gap-4'}>
        <CapitalizedText className={'text-gray-50'}>
          {t('masterAdmin.users.currentlyOn')} <span className={'font-bold'}>{currentTenant.label}</span>
        </CapitalizedText>
        <LabeledMenu
          label={t('masterAdmin.users.changeUsersTenant')}
          menu={
            <MasterAdminTenantMenu
              type={'single'}
              menuPlaceholder={currentTenant.label}
              searchText={searchText}
              setSearchText={setSearchText}
              options={options}
              currentTenantId={user.tenantId}
              handleSelect={handleSelect}
              selectedTenants={[currentTenant]}
            />
          }
        />
      </div>
    </AdminTabContent>
  )
}

interface SystemAdminRoleProps {
  isSystemAdmin: boolean
  roleUpdateHandler: () => void
}

const SystemAdminRole = ({ isSystemAdmin, roleUpdateHandler }: SystemAdminRoleProps) => {
  const { t } = useTranslation()
  const history = useHistory()
  return (
    <AdminTabContent className={'border-b border-gray-500'}>
      <Heading>{t('admin.roles.SYSTEM_ADMIN')}</Heading>
      <div className={'flex flex-col gap-4'}>
        <CheckboxOption
          checked={isSystemAdmin}
          capitalize={true}
          label={t('admin.roles.SYSTEM_ADMIN')}
          value={t('admin.roles.SYSTEM_ADMIN_description')}
          clickHandler={() => roleUpdateHandler()}
        />
        {isSystemAdmin && (
          <div className={'pl-6'}>
            <Button
              size={ButtonSize.XSMALL}
              buttonStyle={ButtonStyle.GHOST}
              value={t('masterAdmin.viewTenantAccess')}
              clickHandler={() => history.push(history.location.pathname.replace(`/users`, '/system-admins'))}
            />
          </div>
        )}
      </div>
    </AdminTabContent>
  )
}
