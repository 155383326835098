import React, { MouseEventHandler, useState } from 'react'
import tw from 'twin.macro'
import styled from 'styled-components'
import { InfoTooltip } from '../tooltips/InfoTooltip'
import { useMousePosition } from '../../../hooks/useMousePosition'
import { CustomIcon } from '../CustomIcon'
import deleteIcon from '../../../assets/svg/objects/trashcan.svg'

export enum ButtonStyle {
  PRIMARY,
  SECONDARY,
  GHOST
}

export enum ButtonType {
  TEXT,
  ICON,
  FORM
}

export enum ButtonSize {
  XSMALL,
  SMALL,
  MEDIUM
}

export interface ButtonProps {
  buttonStyle?: ButtonStyle
  type?: ButtonType
  disabled?: boolean
  hidden?: boolean
  size?: ButtonSize
  value: React.ReactNode
  id?: string
  clickHandler?: MouseEventHandler<HTMLButtonElement>
  tooltipText?: string
}

export const Button = ({
  buttonStyle = ButtonStyle.PRIMARY,
  type = ButtonType.TEXT,
  disabled = false,
  hidden = false,
  size = ButtonSize.SMALL,
  value,
  id,
  clickHandler,
  tooltipText
}: ButtonProps) => {
  const mousePos = useMousePosition()
  const [showTooltip, setShowTooltip] = useState(false)
  const typeStyles =
    buttonStyle === ButtonStyle.PRIMARY
      ? 'text-gray-50 bg-primary-500 border border-primary-500 hover:scale-103 hover:bg-primary-400 hover:border-primary-400 active:bg-primary-500/50 active:border-primary-500/40'
      : buttonStyle === ButtonStyle.SECONDARY
        ? 'text-gray-50 bg-primary-500/5 border border-primary-500/50 hover:scale-103 hover:bg-primary-400/10 hover:border-primary-400 active:bg-primary-500/5 active:border-primary-500/5'
        : 'bg-transparent border-none text-gray-200 group hover:scale-103 hover:text-gray-50 active:text-gray-50'

  const sizeStyles =
    size === ButtonSize.XSMALL ? 'py-1 px-4 text-90' : size === ButtonSize.SMALL ? 'py-1 px-6' : 'py-1 px-8'

  const hiddenStyles = hidden && 'hidden'

  return (
    <>
      <ButtonWrapper
        onMouseOver={() => setShowTooltip(true)}
        onMouseLeave={() => setShowTooltip(false)}
        buttonType={type}
        onClick={clickHandler}
        type={'submit'}
        id={id}
        disabled={disabled}
        className={`group ${typeStyles} ${sizeStyles} ${hiddenStyles}`}
      >
        {value}
      </ButtonWrapper>
      {tooltipText && showTooltip && (
        <InfoTooltip styles={'shadow-none'} x={mousePos.x - 8} y={mousePos.y + 16}>
          {tooltipText}
        </InfoTooltip>
      )}
    </>
  )
}

interface ButtonWrapperProps {
  buttonType?: ButtonType
}

const ButtonWrapper = styled.button<ButtonWrapperProps>`
  ${tw`inline w-max cursor-pointer active:scale-100 rounded-md h-fit transition-all ease-linear duration-150 whitespace-nowrap transform first-letter:capitalize disabled:scale-100 disabled:text-gray-400 disabled:border-gray-400 disabled:bg-transparent disabled:cursor-not-allowed`}
  ${({ buttonType }) =>
    buttonType === ButtonType.ICON
      ? tw`p-1 sm:p-1.5 w-fit justify-center items-center`
      : buttonType === ButtonType.FORM
        ? tw`w-full`
        : tw`w-fit`}
`

interface DeleteButtonProps {
  size: 5 | 6
  clickHandler?: () => void
  tooltipText?: string
}

export const DeleteButton = ({ size, clickHandler, tooltipText }: DeleteButtonProps) => {
  const iconSize = size === 5 ? 'w-5 h-5' : 'w-6 h-6'
  return (
    <Button
      type={ButtonType.ICON}
      buttonStyle={ButtonStyle.GHOST}
      clickHandler={clickHandler}
      value={<CustomIcon path={deleteIcon} styles={`bg-primary-600 group-hover:bg-primary-600 ${iconSize}`} />}
      tooltipText={tooltipText}
    />
  )
}
