import { useTranslation } from 'react-i18next'
import { TreeBranch } from '../../../shared/TreeBranch'
import { Vendor } from '../../../../utils/vendors'
import { OptimizationTabCollapsibleSummary, OptimizationTabTitleRow } from '../OptimizationTabsSharedComponents'
import { DataTableCell, DataTableLabel, DataTableRow, DataTableValue } from '../../../shared/DataTableSharedComponents'
import { formatNumber } from '../../../../utils/formats'
import React from 'react'
import { ClipboardCopy } from '../../../shared/ClipboardCopy'
import { CapitalizedText, GrayText } from '../../../shared/TextComponents'
import { TabDataTableRegionCell } from '../../../shared/tabs/TabSharedComponents'
import { InfoTooltip } from '../../../shared/tooltips/InfoTooltip'
import { useMousePosition } from '../../../../hooks/useMousePosition'
import { AwsRightsizingRecommendation, AwsRightsizingSummary } from '../../../../api/optimization/aws'
import { CurrencyEntry, getCurrency } from '../../../../utils/Currency'

interface RightsizingProps {
  data: AwsRightsizingSummary[]
}

export const AwsTabRightSizing = ({ data }: RightsizingProps) => {
  const { t } = useTranslation()
  const annualSavings: CurrencyEntry = {
    amount: data.reduce((acc, curr) => acc + curr.potentialAnnualSavings.amount, 0),
    currency: data[0]?.potentialAnnualSavings?.currency
  }
  const rightSizeableInstances = data.reduce((acc, curr) => acc + curr.instanceCount, 0)

  return (
    <TreeBranch
      id={`qa-optimization-${Vendor.AWS}-tab-right-sizing`}
      headerData={
        <OptimizationTabCollapsibleSummary
          headerLeft={t('optimization.common.rightsizing')}
          headerRight={t('optimization.tabs.aws.rightSizingAnnualSavings', {
            count: rightSizeableInstances,
            amount: formatNumber(annualSavings.amount),
            currency: getCurrency(annualSavings.currency).symbol
          })}
        />
      }
      contentData={<RightsizingByAccount data={data} />}
    />
  )
}

interface RightsizingRecommendationsProps {
  data: AwsRightsizingSummary[]
}

const RightsizingByAccount = ({ data }: RightsizingRecommendationsProps) => {
  const { t } = useTranslation()

  return (
    <>
      {data.map((recommendationGroup, index) => (
        <TreeBranch
          id={`qa-optimization-${Vendor.AWS}-tab-content-${index}`}
          key={`${recommendationGroup.account.id}-${index}`}
          depth={1}
          headerData={
            <OptimizationTabCollapsibleSummary
              headerLeft={
                <ClipboardCopy
                  copyText={recommendationGroup.account.name}
                  secondCopyText={recommendationGroup.account.id}
                  limit={50}
                  restrictClickArea={true}
                />
              }
              useExclamationMark={recommendationGroup.potentialAnnualSavings.amount > 400}
              headerRight={t('optimization.tabs.aws.rightSizingAnnualSavings', {
                count: recommendationGroup.instanceCount,
                amount: formatNumber(recommendationGroup.potentialAnnualSavings.amount),
                currency: getCurrency(recommendationGroup.potentialAnnualSavings.currency).symbol
              })}
            />
          }
          contentData={
            <>
              <OptimizationTabTitleRow
                widerIndex={[0]}
                labels={[
                  t('optimization.tabs.common.impactedResource'),
                  t('common.region_one'),
                  t('optimization.tabs.aws.currentInstance'),
                  t('optimization.tabs.aws.targetInstance'),
                  t('optimization.tabs.aws.monthlyCost'),
                  t('optimization.tabs.common.monthlySavings'),
                  t('optimization.tabs.common.savingsPercent')
                ]}
              />
              {recommendationGroup.recommendations.map((recommendation, index) => (
                <TreeBranch
                  key={`${recommendation.currentInstance.resourceId}-${index}`}
                  depth={2}
                  headerData={<RightsizingRow recommendation={recommendation} />}
                  contentData={false}
                />
              ))}
            </>
          }
        />
      ))}
    </>
  )
}

interface RightsizingRowProps {
  recommendation: AwsRightsizingRecommendation
}

const RightsizingRow = ({ recommendation }: RightsizingRowProps) => {
  const { t } = useTranslation()
  const mousePos = useMousePosition()
  const typeIsTerminate = recommendation?.targetInstance === null
  const currency = getCurrency(recommendation.currency)
  const CellTooltip = (heading: string, content: string[]) => {
    return (
      <InfoTooltip x={mousePos.x} y={mousePos.y} useIcon={true} styles={'-ml-30 mt-2 xl:m-0'}>
        <div className={'p-2 text-left'}>
          <GrayText>{heading}:</GrayText>
          {content.map((item, index) => (
            <CapitalizedText key={index}>{item.toLowerCase().replaceAll('_', ' ')}</CapitalizedText>
          ))}
        </div>
      </InfoTooltip>
    )
  }

  return (
    <DataTableRow styles={`grid-cols-1 xl:grid-cols-8`}>
      <DataTableCell styles={'xl:col-span-2'}>
        <DataTableLabel>{t('optimization.tabs.common.impactedResource')}</DataTableLabel>
        <DataTableValue>
          <ClipboardCopy
            copyText={
              recommendation.currentInstance.name !== ''
                ? recommendation.currentInstance.name
                : recommendation.currentInstance.resourceId
            }
            secondCopyText={
              recommendation.currentInstance.name !== '' ? recommendation.currentInstance.resourceId : undefined
            }
            limit={30}
          />
        </DataTableValue>
      </DataTableCell>
      <TabDataTableRegionCell regionName={recommendation.region.name} regionId={recommendation.region.id} />
      <DataTableCell>
        <DataTableLabel>{t('optimization.tabs.aws.currentInstance')}</DataTableLabel>
        <DataTableValue className={'inline-flex gap-4 items-center'}>
          {recommendation.currentInstance.type}
          {recommendation.currentInstance.reasons.length > 0 &&
            CellTooltip(t('optimization.tabs.aws.findingReasons'), recommendation.currentInstance.reasons)}
        </DataTableValue>
      </DataTableCell>
      <DataTableCell>
        <DataTableLabel>{t('optimization.tabs.aws.targetInstance')}</DataTableLabel>
        <DataTableValue className={'inline-flex gap-4 items-center'}>
          {typeIsTerminate ? (
            <CapitalizedText>{t('optimization.tabs.aws.terminate')}</CapitalizedText>
          ) : (
            recommendation.targetInstance?.type
          )}
          {recommendation.targetInstance?.platformDifferences &&
            recommendation.targetInstance.platformDifferences.length > 0 &&
            CellTooltip(t('optimization.tabs.aws.differences'), recommendation.targetInstance.platformDifferences)}
        </DataTableValue>
      </DataTableCell>
      <DataTableCell>
        <DataTableLabel>{t('optimization.tabs.aws.monthlyCost')}</DataTableLabel>
        <DataTableValue>{`${formatNumber(recommendation.monthlyCost)} ${currency.symbol}`}</DataTableValue>
      </DataTableCell>
      <DataTableCell>
        <DataTableLabel>{t('optimization.tabs.common.monthlySavings')}</DataTableLabel>
        <DataTableValue>
          {formatNumber(recommendation.monthlySavings)} {currency.symbol}
        </DataTableValue>
      </DataTableCell>
      <DataTableCell>
        <DataTableLabel>{t('optimization.tabs.common.savingsPercent')}</DataTableLabel>
        <DataTableValue>{`${formatNumber(recommendation.savingPercent)} %`}</DataTableValue>
      </DataTableCell>
    </DataTableRow>
  )
}
