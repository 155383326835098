import React, { useContext, useEffect, useRef, useState } from 'react'
import { SustainabilitySummary } from './SustainabilitySummary'
import { SustainabilityChart } from './SustainabilityChart'
import { ViewWrapper } from '../shared/layout/ViewWrapper'
import { SustainabilityFilters } from './SustainabilityFilters'
import { FilterOption } from '../shared/filters/FilterSelect'
import { Vendor } from '../../utils/vendors'
import { SustainabilityTabs } from './SustainabilityTabs'
import { TimeframeOption } from '../../utils/classes'
import { MessageContext, MessageType } from '../../state/context/MessageContext'
import { useTranslation } from 'react-i18next'
import { UserInfoContext } from '../../state/context/UserInfoContext'
import { capitalizeFirstLetter } from '../../utils/formats'
import { ADMIN_ROLES } from '../admin/roleConstants'
import { ToastActionComponent } from '../../global-message/GlobalMessage'
import { useHistory } from 'react-router-dom'

export const Sustainability = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const { authInfo, integrationStates, userSettings } = useContext(UserInfoContext)
  const { setMessage } = useContext(MessageContext)
  const [selectedVendors, setSelectedVendors] = useState<FilterOption[]>([])
  const [selectedProjects, setSelectedProjects] = useState<FilterOption[]>([])
  const [selectedResourceGroups, setSelectedResourceGroups] = useState<FilterOption[]>([])
  const [selectedServices, setSelectedServices] = useState<FilterOption[]>([])
  const [selectedRegions, setSelectedRegions] = useState<FilterOption[]>([])
  const [selectedTimeframe, setSelectedTimeframe] = useState<TimeframeOption>(TimeframeOption.CURRENT_YEAR)
  const refVisibleVendors = useRef(userSettings.visibleVendors)

  if (
    (!selectedVendors.length && userSettings.visibleVendors.length > 0) ||
    refVisibleVendors.current !== userSettings.visibleVendors
  ) {
    setSelectedVendors(
      userSettings.visibleVendors.map(v => ({
        label: t(`vendors.${v}.short`),
        value: v
      }))
    )
    refVisibleVendors.current = userSettings.visibleVendors
  }

  useEffect(() => {
    integrationStates.aws?.find(i => i.lastUsageIntegrationAt === null || i.lastResourceIntegrationAt === null) &&
      setMessage({
        type: MessageType.INFO,
        heading: t('integrationStates.vendorErrorHeading', { vendor: Vendor.AWS, label: t('sideNav.sustainability') }),
        message: t('integrationStates.awsConfigAccountError'),
        action: authInfo.roles.some(role => ADMIN_ROLES.includes(role)) ? (
          <ToastActionComponent
            label={t('admin.integrations.viewError')}
            onClick={() => history.push('/admin/integrations/aws')}
          />
        ) : undefined
      })
    integrationStates.azure?.find(i => i.lastUsageIntegrationAt === null) &&
      setMessage({
        type: MessageType.INFO,
        heading: t('integrationStates.vendorErrorHeading', {
          vendor: capitalizeFirstLetter(Vendor.AZURE.toLowerCase()),
          label: t('sideNav.sustainability')
        }),
        message: t('integrationStates.azureTenantError'),
        action: authInfo.roles.some(role => ADMIN_ROLES.includes(role)) ? (
          <ToastActionComponent
            label={t('admin.integrations.viewError')}
            onClick={() => history.push('/admin/integrations/azure')}
          />
        ) : undefined
      })
    integrationStates.gcp?.find(i => i.lastUsageIntegrationAt === null) &&
      setMessage({
        type: MessageType.INFO,
        heading: t('integrationStates.vendorErrorHeading', { vendor: Vendor.GCP, label: t('sideNav.sustainability') }),
        message: t('integrationStates.gcpBillingAccountError'),
        action: authInfo.roles.some(role => ADMIN_ROLES.includes(role)) ? (
          <ToastActionComponent
            label={t('admin.integrations.viewError')}
            onClick={() => history.push('/admin/integrations/gcp')}
          />
        ) : undefined
      })
  }, [integrationStates])

  return (
    <ViewWrapper>
      <SustainabilityFilters
        selectedTimeframe={selectedTimeframe}
        setSelectedTimeframe={setSelectedTimeframe}
        selectedVendors={selectedVendors}
        setSelectedVendors={setSelectedVendors}
        selectedProjects={selectedProjects}
        setSelectedProjects={setSelectedProjects}
        selectedResourceGroups={selectedResourceGroups}
        setSelectedResourceGroups={setSelectedResourceGroups}
        selectedServices={selectedServices}
        setSelectedServices={setSelectedServices}
        selectedRegions={selectedRegions}
        setSelectedRegions={setSelectedRegions}
      />
      <SustainabilitySummary
        selectedTimeframe={selectedTimeframe}
        selectedVendors={selectedVendors.map(vendor => vendor.value as Vendor)}
        selectedProjects={selectedProjects.map(project => project.value)}
        selectedResourceGroups={selectedResourceGroups.map(rg => rg.value)}
        selectedServices={selectedServices.map(service => service.value)}
        selectedRegions={selectedRegions.map(region => region.value)}
      />
      <SustainabilityChart
        selectedVendors={selectedVendors.map(vendor => vendor.value as Vendor)}
        selectedProjects={selectedProjects.map(project => project.value)}
        selectedResourceGroups={selectedResourceGroups.map(rg => rg.value)}
        selectedServices={selectedServices.map(service => service.value)}
        selectedRegions={selectedRegions.map(region => region.value)}
      />
      <SustainabilityTabs
        selectedTimeframe={selectedTimeframe}
        selectedVendors={selectedVendors.map(vendor => vendor.value as Vendor)}
        selectedProjects={selectedProjects.map(project => project.value)}
        selectedResourceGroups={selectedResourceGroups.map(rg => rg.value)}
        selectedServices={selectedServices.map(service => service.value)}
        selectedRegions={selectedRegions.map(region => region.value)}
      />
    </ViewWrapper>
  )
}
