import styled from 'styled-components'
import tw from 'twin.macro'

export const FullScreenContainer = styled.div`
  ${tw`flex flex-col justify-center items-center h-screen bg-gradient-to-tl from-gray-800 via-gray-600 to-gray-600 font-sans`}
`

export const FullScreenFooter = styled.div`
  ${tw`bg-gray-800 w-screen py-10 lg:px-10 flex justify-center lg:justify-end text-90 font-semibold text-gray-400 shadow-inner-xl`}
`
