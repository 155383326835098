import React from 'react'
import { TicketsHeader } from './TicketsHeader'
import { ViewWrapper } from '../shared/layout/ViewWrapper'
import { TicketStatusSummary } from './ticket/TicketStatusSummary'
import { TicketStatuses } from '../../api/tickets'

export const Tickets = () => {
  return (
    <ViewWrapper>
      <TicketsHeader />
      {TicketStatuses.map(status => (
        <TicketStatusSummary key={status} status={status} />
      ))}
    </ViewWrapper>
  )
}
