import { CancelToken } from 'axios'
import { client } from './client'
import { UserRole } from './auth'
import { EndUser, Tenant } from './system-admin'
import { OrganizationUser } from './admin/users'

export const getAllTenantDetails = async (cancelToken: CancelToken): Promise<TenantDetails[]> => {
  return client.get('master-admin/tenants', { cancelToken }).then(res => res.data)
}

export const addTenant = async (tenant: AddTenantRequest, cancelToken: CancelToken): Promise<TenantDetails[]> => {
  return client.post('master-admin/tenants', tenant, { cancelToken }).then(res => res.data)
}

export const deleteTenant = async (tenantId: string, cancelToken: CancelToken): Promise<void> => {
  await client.delete(`master-admin/tenants/${tenantId}`, { cancelToken })
}

export const addSsoPoolForTenant = async (
  tenantId: string,
  ssoPool: TenantSsoPool,
  cancelToken: CancelToken
): Promise<TenantDetails[]> => {
  return client.post(`master-admin/tenants/${tenantId}`, ssoPool, { cancelToken }).then(res => res.data)
}

export const deleteTenantSsoPool = async (tenantId: string, cancelToken: CancelToken): Promise<TenantDetails[]> => {
  return client.delete(`master-admin/tenants/${tenantId}/sso-pool`, { cancelToken }).then(res => res.data)
}

export const changeTenantRestrictedAccess = async (
  tenantId: string,
  restrictedAccess: boolean,
  cancelToken: CancelToken
): Promise<TenantDetails[]> => {
  return client.put(`master-admin/tenants/${tenantId}`, { restrictedAccess }, { cancelToken }).then(res => res.data)
}

export const getSystemAdmins = async (cancelToken: CancelToken): Promise<SystemAdminBasicInfo[]> => {
  return await client.get<SystemAdminBasicInfo[]>('master-admin/system-admins', { cancelToken }).then(res => res.data)
}

export const getSystemAdminDetails = async (userId: string, cancelToken: CancelToken): Promise<SystemAdmin> => {
  return await client.get<SystemAdmin>(`master-admin/system-admins/${userId}`, { cancelToken }).then(res => res.data)
}

export const grantSystemAdminTenantAccess = async (
  userId: string,
  tenantId: string,
  cancelToken: CancelToken,
  tenantIds?: string[]
): Promise<SystemAdmin> => {
  return await client
    .post<SystemAdmin>(`master-admin/system-admins/${userId}/${tenantId}`, tenantIds || [], { cancelToken })
    .then(resp => resp.data)
}

export const deleteSystemAdminTenantAccess = async (
  userId: string,
  tenantId: string,
  cancelToken: CancelToken,
  tenantIds?: string[]
): Promise<SystemAdmin> => {
  return await client({
    method: 'delete',
    url: `master-admin/system-admins/${userId}/${tenantId}`,
    data: tenantIds || [],
    cancelToken
  }).then(resp => resp.data)
}

export const changeSystemAdminCurrentTenant = async (
  userId: string,
  tenantId: string,
  cancelToken: CancelToken
): Promise<SystemAdmin> => {
  return await client
    .put<SystemAdmin>(`master-admin/system-admins/${userId}`, tenantId, { cancelToken })
    .then(resp => resp.data)
}

export const changeUsersCurrentTenant = async (
  userId: string,
  tenantId: string,
  cancelToken: CancelToken
): Promise<OrganizationUser> => {
  return await client.put<OrganizationUser>(`master-admin/users/${userId}`, tenantId, { cancelToken }).then(resp => ({
    ...resp.data,
    updatedAt: new Date(resp.data.updatedAt),
    lastLoginAt: resp.data.lastLoginAt && new Date(resp.data.lastLoginAt)
  }))
}

export const getUsersByTenant = async (cancelToken: CancelToken): Promise<UsersByTenant[]> => {
  return await client.get<UsersByTenant[]>('master-admin/users', { cancelToken }).then(res =>
    res.data.map(tenant => ({
      ...tenant,
      users: tenant.users.map(user => ({
        ...user,
        updatedAt: new Date(user.updatedAt),
        lastLoginAt: user.lastLoginAt && new Date(user.lastLoginAt)
      }))
    }))
  )
}

export const grantUserRole = async (
  userId: string,
  role: UserRole,
  cancelToken: CancelToken
): Promise<OrganizationUser> => {
  return await client.post<OrganizationUser>(`master-admin/users/${userId}`, role, { cancelToken }).then(resp => ({
    ...resp.data,
    updatedAt: new Date(resp.data.updatedAt),
    lastLoginAt: resp.data.lastLoginAt && new Date(resp.data.lastLoginAt)
  }))
}

export const deleteUserRole = async (
  userId: string,
  role: UserRole,
  cancelToken: CancelToken
): Promise<OrganizationUser> => {
  return await client({
    method: 'delete',
    url: `master-admin/users/${userId}/${role}`,
    cancelToken
  }).then(resp => ({
    ...resp.data,
    updatedAt: new Date(resp.data.updatedAt),
    lastLoginAt: resp.data.lastLoginAt && new Date(resp.data.lastLoginAt)
  }))
}

export const deleteUser = async (userId: string, cancelToken: CancelToken) => {
  await client.delete(`master-admin/users/${userId}`, { cancelToken })
}

export interface TenantDetails {
  tenant: Tenant
  hasRestrictedAccess: boolean
  ssoPool: TenantSsoPool
}

export interface AddTenantRequest {
  name: string
  hasRestrictedAccess: boolean
}

export interface TenantSsoPool {
  cognitoRegion: string
  cognitoPoolId: string
  cognitoClientId: string
  cognitoPoolDomain: string
  cognitoIdp: string
  ssoDomains: string[]
}

export interface SystemAdminBasicInfo {
  userId: string
  currentTenant: string
  firstName: string
  lastName: string
  emailAddress: string
}

export interface SystemAdmin {
  userId: string
  currentTenant: Tenant
  firstName: string
  lastName: string
  emailAddress: string
  roles: UserRole[]
  permittedTenants: Tenant[]
}

export interface UsersByTenant {
  tenant: Tenant
  users: EndUser[]
}
