import React, { useContext, useEffect, useState } from 'react'
import { getHomeAccountsSummary, HomeHeaderSummary } from '../../api/home'
import { roundNumber } from '../../utils/formats'
import { CountUp } from '../shared/CountUp'
import { useTranslation } from 'react-i18next'
import { Loading } from '../shared/Loading'
import { useCancelToken } from '../../api/client'
import { useErrorHandling } from '../../hooks/handleError'
import { StackedBarChart } from '../shared/charts/StackedBarChart'
import { GrayText, VendorName, WhiteText } from '../shared/TextComponents'
import { SummaryAmount } from './HomeSharedComponents'
import { MissingDataNotification } from '../shared/MissingDataNotification'
import { getCurrency } from '../../utils/Currency'
import { DataContainer } from '../shared/containers/DataContainer'
import { UserInfoContext } from '../../state/context/UserInfoContext'

export const AccountsSummary = () => {
  const handleError = useErrorHandling()
  const { userSettings } = useContext(UserInfoContext)
  const { createCancelToken } = useCancelToken()
  const [loading, setLoading] = useState(true)
  const [summaryData, setSummaryData] = useState<HomeHeaderSummary[]>([])

  useEffect(() => {
    setLoading(true)
    const cancelToken = createCancelToken()
    getHomeAccountsSummary(cancelToken.token)
      .then(resp => setSummaryData(resp.filter(item => userSettings.visibleVendors.includes(item.vendor))))
      .catch(handleError)
      .finally(() => setLoading(false))

    return () => {
      cancelToken.cancel()
      setLoading(false)
      setSummaryData([])
    }
  }, [createCancelToken, handleError])

  if (loading || !summaryData.length)
    return (
      <DataContainer>
        {loading ? <Loading paddingY={'1.5rem'} /> : <MissingDataNotification paddingY={12} justify={'center'} />}
      </DataContainer>
    )

  const totalCosts = summaryData.reduce((acc, curr) => acc + curr.currentCosts, 0)
  const totalForecast = summaryData.reduce((acc, curr) => acc + curr.forecast, 0)
  const currencySymbol = getCurrency(summaryData[0].currency).symbol

  return (
    <DataContainer>
      <div className={'flex w-full justify-between flex-col gap-8 items-center lg:items-start lg:flex-row'}>
        <CurrentCosts costs={totalCosts} forecast={totalForecast} currency={currencySymbol} />
        <VendorSummaries data={summaryData} currency={currencySymbol} />
      </div>
    </DataContainer>
  )
}

interface CurrentCostsProps {
  costs: number
  forecast: number
  currency: string
}

const CurrentCosts = ({ costs, forecast, currency }: CurrentCostsProps) => {
  const { t } = useTranslation()
  return (
    <div className={'flex flex-col gap-2 text-center lg:text-left'}>
      <div>
        <GrayText>{t('home.accountsSummary.totalCost')}</GrayText>
        <SummaryAmount textSize={'large'} fontWeight={'bold'}>
          <CountUp id={'qa-home-total-costs'} countTo={roundNumber(costs, 0)} /> {currency}
        </SummaryAmount>
      </div>
      <div className={'flex gap-1 items-baseline'}>
        <GrayText>{t('home.accountsSummary.forecast')}:</GrayText>
        <SummaryAmount textSize={'medium'}>
          <CountUp id={'qa-home-total-forecast'} countTo={roundNumber(forecast, 0)} /> {currency}
        </SummaryAmount>
      </div>
    </div>
  )
}

interface VendorSummariesProps {
  data: HomeHeaderSummary[]
  currency: string
}

const VendorSummaries = ({ data, currency }: VendorSummariesProps) => {
  const { t } = useTranslation()
  const chartData = data.map(data => ({
    vendor: data.vendor,
    value: data.currentCosts
  }))

  return (
    <div className={'flex flex-col gap-4 transition-all ease-in-out w-full lg:w-1/2'}>
      <StackedBarChart data={chartData} />
      <div className={'flex w-full justify-between px-1'}>
        <div className={'flex flex-col min-w-max'}>
          <GrayText>{t('home.accountsSummary.vendor')}</GrayText>
          {data.length > 1 && <GrayText className={'leading-7'}>{t('home.accountsSummary.cost')}</GrayText>}
          <GrayText capitalizeAll={true}>{t('home.accountsSummary.projects')}</GrayText>
          <GrayText>{t('common.resource_other')}</GrayText>
        </div>
        {data.map((item, i) => (
          <div className={'flex w-full flex-col text-right'} id={`qa-home-account-summary-${item.vendor}`} key={i}>
            <VendorName vendor={item.vendor}>{item.vendor}</VendorName>
            {data.length > 1 && (
              <WhiteText className={'text-112 font-semibold'}>
                <CountUp countTo={roundNumber(item.currentCosts, 0)} />
                <span className={'text-100'}>{currency}</span>
              </WhiteText>
            )}
            <WhiteText>
              <CountUp countTo={item.projects} />
            </WhiteText>
            <WhiteText>
              <CountUp countTo={item.resources} />
            </WhiteText>
          </div>
        ))}
      </div>
    </div>
  )
}
